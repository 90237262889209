import Multiselect from 'multiselect-react-dropdown'
import React from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import AddEditSeller from './AddEditSeller'

const sellersBreadCrumbs: Array<PageLink> = [
  {
    title: 'Seller Management',
    path: '/seller-management/sellers',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EditSeller = () => {
  const navigate = useNavigate()
  const navigateToListBuyer = () => {
    // 👇️ navigate to /contacts
    navigate('/seller-management/sellers')
  }

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <>
      <PageTitle>Edit Seller</PageTitle>
      <Col className='position-absolute  me-5 end-0 mt-n20 '>
        <button
          type='button'
          onClick={navigateToListBuyer}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
      </Col>
      <div className='card card-body pt-9 ps-5 pe-5 pb-5'>
        <AddEditSeller />
      </div>
    </>
  )
}

export default EditSeller
