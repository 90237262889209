import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import { checkDisplayValue } from '../../../utils/utils'
import { getThresholdlimitApi } from '../_requests'

type Props = {
  setMessage: (type:string,message:string) => void
}

const ThresholdOffers = ({setMessage}:Props) => {
 
  const [thresholdData,setThresholdData] = useState<any>([])
  const [loading,setLoading] = useState(true)

  const callDashboardReport = async () => {
    try{
      setLoading(true)
      let {responseStatus, responseMessage, responseData} = await getThresholdlimitApi()
      if(responseStatus == 200){
        if(responseData && responseData.length){
          setThresholdData(responseData)
        }
        else{
          setThresholdData([])
        }
        setLoading(false)
        setLoading(false)
      }
      else{
        setMessage("danger",responseMessage)
        setLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setMessage("danger",message)
      setLoading(false)
    }
  } 

  useEffect(()=>{
    callDashboardReport()
  },[])

  return (
  <>
    {thresholdData.map((x:any,i:number)=>{
      return(
        <div className='mb-7' key={x.key}>
          <div className=' card-xl-stretch'>
            <div>
              <div
                style={{backgroundColor: '#f5dee4', position:'relative'}}
                className='d-flex align-items-center  rounded px-10 py-2'
              >
                <span className='svg-icon svg-icon-danger svg-icon-1 me-5 my-9'>
                  <KTSVG
                    className='svg-icon svg-icon-danger svg-icon-1 me-5 svg-icon-3x ms-n1'
                    path='/media/icons/duotune/abstract/abs027.svg'
                  />
                </span>
                <div className='me-2 w-100 '>
                  <div className='d-flex  flex-column '>
                    <div className='d-flex w-100  align-items-center justify-content-between'>
                      <span className='fw-bold text-gray-800  fs-2'>#{i+1}</span>
                      <span className='fw-bold text-danger py-1 fs-2 offer-threshold-rate'>{checkDisplayValue(x.price)}
                      {checkDisplayValue(x.price) !== "-" ? <i className="fa-solid fa-indian-rupee-sign fs-3 "></i>:""}</span>
                    </div>
                    <span className='fw-bold text-gray-800 fs-4'>{checkDisplayValue(x.product_variation)}  |  {checkDisplayValue(x?.product_group_name)}</span>
                  </div>
                  <div className='d-flex text-muted fw-semibold'>
                    <span className='fw-normal fs-6 text-grey-500 me-5'>{checkDisplayValue(x?.buyerSellerInfo?.firm_name)}</span>
                    <div className='d-flex align-items-center'>
                      <span className='fw-bold text-500 me-2'>
                        {checkDisplayValue(x.whatsapp_number) !== "-" ? <i className='fa-brands fa-whatsapp fs-3' />:""}
                      </span>
                      <span className='fw-normal fs-6 text-grey-500'>{checkDisplayValue(x.whatsapp_number)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })}

    {/* <div className='mb-7'>
      <div className=' card-xl-stretch'>
        <div>
          <div
            style={{backgroundColor: '#f5dee4', position:'relative'}}
            className='d-flex align-items-center  rounded px-10 py-2'
          >
            <span className='svg-icon svg-icon-danger svg-icon-1 me-5 my-9'>
              <KTSVG
                className='svg-icon svg-icon-danger svg-icon-1 me-5 svg-icon-3x ms-n1'
                path='/media/icons/duotune/abstract/abs027.svg'
              />
            </span>
            <div className='me-2 w-100 '>
              <div className='d-flex  flex-column '>
                <div className='d-flex w-100  align-items-center justify-content-between'>
                  <span className='fw-bold text-gray-800  fs-2'>#002</span>
                  <span className='fw-bold text-danger py-1 fs-2 offer-threshold-rate' >  10 <i className="fa-solid fa-indian-rupee-sign fs-3 "></i></span>
                </div>
                <span className='fw-bold text-gray-800 fs-4'>ABS MOULDING BHANSALI HI-40B</span>
              </div>
              <div className='d-flex text-muted fw-semibold'>
                <span className='fw-normal fs-6 text-grey-500 me-5'>Mr.Darshak Prajapati</span>
                <div className='d-flex align-items-center'>
                  <span className='fw-bold text-500 me-2'>
                    <i className='fa-brands fa-whatsapp fs-3' />
                  </span>
                  <span className='fw-normal fs-6 text-grey-500'>98254 23568</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='mb-7'>
      <div className=' card-xl-stretch'>
        <div>
          <div
            style={{backgroundColor: '#f5dee4', position:'relative'}}
            className='d-flex align-items-center  rounded px-10 py-2'
          >
            <span className='svg-icon svg-icon-danger svg-icon-1 me-5 my-9'>
              <KTSVG
                className='svg-icon svg-icon-danger svg-icon-1 me-5 svg-icon-3x ms-n1'
                path='/media/icons/duotune/abstract/abs027.svg'
              />
            </span>
            <div className='me-2 w-100 '>
              <div className='d-flex  flex-column '>
                <div className='d-flex w-100  align-items-center justify-content-between'>
                  <span className='fw-bold text-gray-800  fs-2'>#003</span>
                  <span className='fw-bold text-danger py-1 fs-2 offer-threshold-rate' >  10 <i className="fa-solid fa-indian-rupee-sign fs-3 "></i></span>
                </div>
                <span className='fw-bold text-gray-800 fs-4'>ABS MOULDING BHANSALI MIF-45</span>
              </div>
              <div className='d-flex text-muted fw-semibold'>
                <span className='fw-normal fs-6 text-grey-500 me-5'>Mr.Dharmendra Kushwah</span>
                <div className='d-flex align-items-center'>
                  <span className='fw-bold text-500 me-2'>
                    <i className='fa-brands fa-whatsapp fs-3' />
                  </span>
                  <span className='fw-normal fs-6 text-grey-500'>96254 35678</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='mb-7'>
      <div className=' card-xl-stretch'>
        <div>
          <div
            style={{backgroundColor: '#f5dee4', position:'relative'}}
            className='d-flex align-items-center  rounded px-10 py-2'
          >
            <span className='svg-icon svg-icon-danger svg-icon-1 me-5 my-9'>
              <KTSVG
                className='svg-icon svg-icon-danger svg-icon-1 me-5 svg-icon-3x ms-n1'
                path='/media/icons/duotune/abstract/abs027.svg'
              />
            </span>
            <div className='me-2 w-100 '>
              <div className='d-flex  flex-column '>
                <div className='d-flex w-100  align-items-center justify-content-between'>
                  <span className='fw-bold text-gray-800  fs-2'>#004</span>
                  <span className='fw-bold text-danger py-1 fs-2 offer-threshold-rate' >  10 <i className="fa-solid fa-indian-rupee-sign fs-3 "></i></span>
                </div>
                <span className='fw-bold text-gray-800 fs-4'>ABS MOULDING CHIMEI PA746H</span>
              </div>
              <div className='d-flex text-muted fw-semibold'>
                <span className='fw-normal fs-6 text-grey-500 me-5'>Mr. Parth Dave</span>
                <div className='d-flex align-items-center'>
                  <span className='fw-bold text-500 me-2'>
                    <i className='fa-brands fa-whatsapp fs-3' />
                  </span>
                  <span className='fw-normal fs-6 text-grey-500'>96457 25364</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className='mb-7'>
      <div className=' card-xl-stretch'>
        <div>
          <div
            style={{backgroundColor: '#f5dee4', position:'relative'}}
            className='d-flex align-items-center  rounded px-10 py-2'
          >
            <span className='svg-icon svg-icon-danger svg-icon-1 me-5 my-9'>
              <KTSVG
                className='svg-icon svg-icon-danger svg-icon-1 me-5 svg-icon-3x ms-n1'
                path='/media/icons/duotune/abstract/abs027.svg'
              />
            </span>
            <div className='me-2 w-100 '>
              <div className='d-flex  flex-column '>
                <div className='d-flex w-100  align-items-center justify-content-between'>
                  <span className='fw-bold text-gray-800  fs-2'>#005</span>
                  <span className='fw-bold text-danger py-1 fs-2 offer-threshold-rate' >  10 <i className="fa-solid fa-indian-rupee-sign fs-3 "></i></span>
                </div>
                <span className='fw-bold text-gray-800 fs-4'>ABS MOULDING FORMOSA AG12A0</span>
              </div>
              <div className='d-flex text-muted fw-semibold'>
                <span className='fw-normal fs-6 text-grey-500 me-5'>Mr. Akib Javed</span>
                <div className='d-flex align-items-center'>
                  <span className='fw-bold text-500 me-2'>
                    <i className='fa-brands fa-whatsapp fs-3' />
                  </span>
                  <span className='fw-normal fs-6 text-grey-500'>98254 36547</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='mb-7'>
      <div className=' card-xl-stretch'>
        <div>
          <div
            style={{backgroundColor: '#f5dee4', position:'relative'}}
            className='d-flex align-items-center  rounded px-10 py-2'
          >
            <span className='svg-icon svg-icon-danger svg-icon-1 me-5 my-9'>
              <KTSVG
                className='svg-icon svg-icon-danger svg-icon-1 me-5 svg-icon-3x ms-n1'
                path='/media/icons/duotune/abstract/abs027.svg'
              />
            </span>
            <div className='me-2 w-100 '>
              <div className='d-flex  flex-column '>
                <div className='d-flex w-100  align-items-center justify-content-between'>
                  <span className='fw-bold text-gray-800  fs-2'>#006</span>
                  <span className='fw-bold text-danger py-1 fs-2 offer-threshold-rate' >  10 <i className="fa-solid fa-indian-rupee-sign fs-3 "></i></span>
                </div>
                <span className='fw-bold text-gray-800 fs-4'>ABS MOULDING GPPC D-150</span>
              </div>
              <div className='d-flex text-muted fw-semibold'>
                <span className='fw-normal fs-6 text-grey-500 me-5'>Mr. Ashok Vaghela</span>
                <div className='d-flex align-items-center'>
                  <span className='fw-bold text-500 me-2'>
                    <i className='fa-brands fa-whatsapp fs-3' />
                  </span>
                  <span className='fw-normal fs-6 text-grey-500'>998365 45789</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
  </>  
  )
}

export default ThresholdOffers
