
// import { useNavigate } from 'react-router-dom';
// import { KTSVG } from '../../../../../../_metronic/helpers'
// import {useListView} from '../../core/ListViewProvider'
//  import {SufalamsListFilter} from './SufalamsListFilter'

import { StatesListFilter } from "./StatesListFilter"

const StateListToolbar = () => {
  // const {setItemIdForUpdate} = useListView()
  // const openAddUserModal = () => {
  //   setItemIdForUpdate(null)
  // }
  
  // const navigate = useNavigate();
  // const navigateViewUser = () => {
  //   // 👇️ navigate to /
  //   navigate('/viewuser');
  // };
  // const navigateAddUser = () => {
  //   // 👇️ navigate to /
  //   navigate('/adduser');
  // };

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
{/* <StatesListFilter /> */}

      {/* <button 
          type="button" 
          onClick={navigateViewUser}
          value="" 
          className="btn btn-sm  me-5 btn btn-primary"> 
          View User
          </button>
          <button 
          type="button" 
          onClick={navigateAddUser}
          value="" 
          className="btn btn-sm  me-5 btn btn-primary"> 
          Add User
          </button> */}
      {/* <SufalamsListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add User
      </button> */}
      {/* end::Add user */}


    </div>
  )
}

export {StateListToolbar}
