/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Button, Col, Form, FormLabel, Modal, Row } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import AddEditGradeGroup from '../AddEditGradeGroup'
import { useListView } from '../grades-list/core/ListViewProvider'

type Props = {
  show: boolean
  handleClose: () => void
}

const AddGradeGroupManagementModal = ({ show, handleClose }: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const {isEditGradegroup} = useListView()

  return (
    <Modal
      id='gradegroup_add_edit'
      // dialogClassName='modal-dialog modal-dialog-centered  modal fade show d-block'
      dialogClassName='modal-dialog modal-dialog-centered modalheight-scrlbr modal fade show d-block'
      show={show}
      size={'xl'}
      onHide={handleClose}
    >
      <div className='modal-header'>
        <h2 className='fw-bolder'> {isEditGradegroup ? 'Edit Grade group' : 'Add Grade group'}</h2>
        <div className='btn btn-icon btn-sm btn-active-icon-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body my-7'>
        <div>
          <AddEditGradeGroup handleClose={handleClose} />
        </div>
      </div>
    </Modal>
  )

}

export default AddGradeGroupManagementModal
