/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef} from 'react'
import {createPortal} from 'react-dom'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewStateManagementModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)

  return createPortal(
    <Modal
      id='kt_modal_create_user'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-650px modal fade show d-block'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2 className='fw-bolder'>View State Management</h2>
        <div className='btn btn-icon btn-sm btn-active-icon-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
        <div ref={stepperRef}>
          <Form noValidate id='kt_modal_add_user_form'>
            <Row className='mb-7'>
              <Col>
                <Form.Label className='fw-bold text-muted d-block m-0 form-label'>State Name</Form.Label>
                <div className='fw-bold fs-6 mb-2 form-label'>Brad Simmons</div>
              </Col>
            </Row>
            {/* <Row className='mb-7'>
              <div className='text-end pt-10'>
                <Button
                  type='reset'
                  onClick={handleClose}
                  className='btn btn-light-primary me-3'
                  data-kt-users-modal-action='cancel'
                >
                  <span className='svg-icon svg-icon-2'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr002.svg'
                      className='svg-icon-muted'
                    />
                  </span>
                  Back
                </Button>
              </div>
            </Row> */}
          </Form>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ViewStateManagementModal}
