import clsx from 'clsx'
import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import District from './District'
import State from './State'
// import {defaultAlerts, defaultLogs, KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
// import {PageLink, PageTitle} from '../../../_metronic/layout/core'

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Locations ',
    path: '/crafted/pages/wizards/horizontal',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Location = () => {
  return (
    <>
      <PageTitle>Location</PageTitle>
      <Col className='mb-5'></Col>
      <Col className='card card-body backgroung-transparent   p-12  mt-10   p-12'>
        <Col xl={12} className='d-flex justify-content-center'>
          <div className='menu menu-column ' data-kt-menu='true'>
            <div
              className='d-flex flex-column bgi-no-repeat rounded-top'
              style={{backgroundImage: `url('{toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
            >
              <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold  px-0'>
                <li className='nav-item'>
                  <a
                    className='pe-9 px-9 nav-link text-dark opacity-75 fs-4 px-5 opacity-state-100 pb-4  active'
                    data-bs-toggle='tab'
                    href='#kt_topbar_notifications_1'
                  >
                    District
                  </a>
                </li>

                <li className='nav-item'>
                  <a
                    className='pe-9 px-9 nav-link text-dark opacity-75 fs-4 opacity-state-100 pb-4'
                    data-bs-toggle='tab'
                    href='#kt_topbar_notifications_2'
                  >
                    State
                  </a>
                </li>
              </ul>
            </div>

            <div className='tab-content'>
              <div
                className='tab-pane show active fade'
                id='kt_topbar_notifications_1'
                role='tabpanel'
              >
                <District />
              </div>

              <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
                <State />
              </div>
            </div>
          </div>
        </Col>
        
      </Col>
    </>
  )
}

export default Location
