import React, { useEffect, useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import {InterestGradeGroup} from './interest/InterestGradeGroup'
import {LocationInterestAndOffers} from './location/LocationInterestAndOffers'
import {OffersGradeGroup} from './offers/OffersGradeGroup'
import Statistics from './statistics/Statistics'
import StatusInquiryWithCounter from './status-inquiry/StatusInquiryWithCounter'
import ThresholdOffers from './threshold/ThresholdOffers'
import ThresholdPage from './threshold/thresholdPage'
import SalesMan from './top/SalesMan'
import TopMain from './top/TopMain'
import UserWishOpenInquiry from './user-wish/UserWishOpenInquiry'

const Dashboard = () => {
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  useEffect(()=>{
    setTimeout(()=>{
      setSessionMessage({type: '',message: '',show: false})
    },5000)
  },[sessionMessage.show])
  
  const setMessage = (type:string,message:string) => {
    setSessionMessage({type: type, message: message, show: true})
  }

  return (
    <div>
      <div className='row mt-sm-4 mt-md-2 mt-xl-0'>
        <div className='col-xl-12 col-lg-12 col-md-12'>
          <Statistics setMessage={(type:string,message:string)=>{
            setMessage(type,message)
          }}></Statistics>
        </div>
      </div>
      <div className='row mt-sm-4 mt-md-2 mt-xl-0'>
        <div className='col-xl-12 col-lg-12 col-md-12 mb-md-5 mb-xl-10'>
          <StatusInquiryWithCounter setMessage={(type:string,message:string)=>{
            setMessage(type,message)
          }}></StatusInquiryWithCounter>
        </div>
      </div>
      <div className='row mt-sm-4 mt-md-2 mt-xl-0'>
        <div className='col-xl-12 col-lg-12 col-md-12 mb-md-5 mb-xl-10'>
          <ThresholdPage setMessage={(type:string,message:string)=>{
            setMessage(type,message)
          }}></ThresholdPage>
        </div>
      </div>
      <div className='row mt-sm-4 mt-md-2 mt-xl-0'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-md-5 mb-xl-10'>
          <UserWishOpenInquiry setMessage={(type:string,message:string)=>{
            setMessage(type,message)
          }}></UserWishOpenInquiry>
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-md-5 mb-xl-10'>
          <SalesMan setMessage={(type:string,message:string)=>{
            setMessage(type,message)
          }}></SalesMan>
        </div>
      </div>
      <div className='row mt-sm-4 mt-md-2 mt-xl-0'>
        <div className='col-xl-12 col-lg-12 col-md-12 mb-md-5 mb-xl-10'>
          <TopMain setMessage={(type:string,message:string)=>{
            setMessage(type,message)
          }}></TopMain>
        </div>
      </div>
      <div className='row mt-sm-4 mt-md-2 mt-xl-0'>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-md-4'>
          <InterestGradeGroup setMessage={(type:string,message:string)=>{
            setMessage(type,message)
          }}></InterestGradeGroup>
        </div>
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-md-4'>
          <OffersGradeGroup setMessage={(type:string,message:string)=>{
            setMessage(type,message)
          }}></OffersGradeGroup>
        </div>
      </div>
      <div className='row mt-sm-4 mt-md-2 mt-xl-0'>
        <div className='col-xl-12 col-lg-12 col-md-12 mb-md-5 mb-xl-10'>
          <LocationInterestAndOffers setMessage={(type:string,message:string)=>{
            setMessage(type,message)
          }}></LocationInterestAndOffers>
        </div>
      </div>
      <ToastContainer className='p-3 position-fixed' position={'top-center'}>
        <Toast
          show={sessionMessage.show}
          bg={sessionMessage.type}
        >
          <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  )
}

export default Dashboard
