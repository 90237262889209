import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Seller, SellersQueryResponse} from './_models'
import {Buyer} from '../../../buyer-management/buyers-list/core/_models'

const API_URL_NEW = process.env.REACT_APP_API_URL_NEW
const API_URL = process.env.REACT_APP_THEME_API_URL
const SELLER_URL = `${API_URL}/seller`
const GET_SELLERS_URL = `${API_URL}/users/query`

const GET_SELLERS_LIST_URL = `${API_URL_NEW}/buyer-seller`
const GET_UPLOAD = `${API_URL_NEW}/uploads/upload`

const GET_ALL_STATE = `${API_URL_NEW}/state/allStates`
const ALL_LOCATION_URL = `${API_URL_NEW}/location`
const BUYER_SELLER_EDIT_HISTORY = `${API_URL_NEW}/buyer-seller-edit-history/?buyer_seller_id=`
const DOWNLOAD_MEDIA_SELLER= `${API_URL_NEW}/downloads/download?folder_name=buyerSeller&image=`
const CHANGE_SELLER_EDIT_HSITORY_STATUS= `${API_URL_NEW}/buyer-seller-edit-history/changeBuyerSellerEditHistoryStatus`

const getSellerFilter = (): Promise<SellersQueryResponse> => {
  const setQuery = `type=2&perPage=5000&page=1`
  return axios
    .get(`${GET_SELLERS_LIST_URL}?${setQuery}`)
    .then((d: AxiosResponse<SellersQueryResponse>) => d.data)
}

const getSeller = (query: string): Promise<SellersQueryResponse> => {
  const setQuery = `${query}&type=2`
  return axios
    .get(`${GET_SELLERS_LIST_URL}?${setQuery}`)
    .then((d: AxiosResponse<SellersQueryResponse>) => d.data)
}

const getSellerById = (id: ID): Promise<Seller | undefined> => {
  return axios
    .get(`${SELLER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Seller>>) => response.data)
    .then((response: Response<Seller>) => response.data)
}
const getSellerByIdNew = (id: string): Promise<Seller | undefined> => {
  return axios
    .get(`${GET_SELLERS_LIST_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data)
}

const createSeller = (seller: Seller): Promise<Seller | undefined> => {
  return axios
    .put(SELLER_URL, seller)
    .then((response: AxiosResponse<Response<Seller>>) => response.data)
    .then((response: Response<Seller>) => response.data)
}
const updateSellerNew = (id: string, seller: any): Promise<Buyer | undefined> => {
  return axios
    .patch(`${GET_SELLERS_LIST_URL}/${id}`, seller)
    .then((response: AxiosResponse<any>) => response.data)
}
const updateSeller = (seller: Seller): Promise<Seller | undefined> => {
  return axios
    .post(`${SELLER_URL}/${seller.id}`, seller)
    .then((response: AxiosResponse<Response<Seller>>) => response.data)
    .then((response: Response<Seller>) => response.data)
}

const getAllStateList = (): Promise<any> => {
  return axios.get(`${GET_ALL_STATE}`).then((d: AxiosResponse<any>) => d.data)
}

const deleteSeller = (ID: string): Promise<any> => {
  return axios
    .delete(`${GET_SELLERS_LIST_URL}/${ID}`)
    .then((response: AxiosResponse<any>) => response.data)
}

const deleteSelectedSellers = (sellerIds: Array<ID>): Promise<void> => {
  const requests = sellerIds.map((id) => axios.delete(`${SELLER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const createSellerNew = (buyer: any): Promise<any | undefined> => {
  return (
    axios
      .post(GET_SELLERS_LIST_URL, buyer)
      .then((response: AxiosResponse<Response<any>>) => response.data)
      // .then((response: Response<any>) => response.data)
  )
}
const uploadGSTFile = (formData: any): Promise<any | undefined> => {
  return axios.post(GET_UPLOAD, formData).then((response: AxiosResponse<Response<any>>) => response.data)
}

const getDistrictLocation = (id: any): Promise<any> => {
  let state = `?state_name=${id}&perPage=10&page=1`
  console.log(state, 'state')

  // @ts-ignore
  return axios.get(`${ALL_LOCATION_URL}${state}`).then((d: AxiosResponse<any>) => d.data)
}

const getDistrictLocationNew = (id: any, search: any): Promise<any> => {
  let state = `?state_name=${id}&search=${search}&perPage=10&page=1&sort=name&order=ASC`
  console.log(state, 'state')

  // @ts-ignore
  return axios.get(`${ALL_LOCATION_URL}${state}`).then((d: AxiosResponse<any>) => d.data)
}

const getBuyerSellerEditHistoryList = (id:any): Promise<any> => { 
  return axios
    .get(`${BUYER_SELLER_EDIT_HISTORY}${id}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const downloadSellerEditHistoryFile = (payload:any) => { 
  return `${DOWNLOAD_MEDIA_SELLER}${payload}`
}

const changeSellerEditHistory = (id:any,payload:any): Promise<any> => { 
  return axios
    .patch(`${CHANGE_SELLER_EDIT_HSITORY_STATUS}/${id}`,payload)
    .then((d: AxiosResponse<any>) => d.data)
}

export {
  getSeller,
  deleteSeller,
  deleteSelectedSellers,
  getSellerById,
  getSellerFilter,
  createSeller,
  updateSeller,
  getSellerByIdNew,
  createSellerNew,
  updateSellerNew,
  getAllStateList,
  uploadGSTFile,
  getDistrictLocation,
  getDistrictLocationNew,
  getBuyerSellerEditHistoryList,
  downloadSellerEditHistoryFile,
  changeSellerEditHistory
}
