import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Inquiry, InquirysQueryResponse} from './_models'
//  import {Inquiry, InquiriesQueryResponse} from './_models'

const API_URL_NEW = process.env.REACT_APP_API_URL_NEW
const API_URL = process.env.REACT_APP_THEME_API_URL
const INQUIRY_URL = `${API_URL}/inquiry`
const INQUIRY_URL_NEW = `${API_URL_NEW}/inquiry`
const GET_INQUIRIES_URL = `${API_URL}/users/query`
const ALL_FIRMS_URL = `${API_URL_NEW}/buyer-seller/allfirms`
const ALL_BUYER_URL = `${API_URL_NEW}/buyer-seller?page=1&perPage=5000&type=2`
const ALL_LOCATION_URL = `${API_URL_NEW}/location/allLocations`
const ALL_GROUP_URL = `${API_URL_NEW}/grade-group/allGradeGroups`

const ALL_USER_URL = `${API_URL_NEW}/user/allusers`
const ALL_PRODUCT_URL = `${API_URL_NEW}/product/allProducts`
const ALL_TRANSPORT_URL = `${API_URL_NEW}/transport`

const PURCAHSE_TC = `${API_URL_NEW}/setting/ba2b6bcc-b942-438e-bdc4-655f51b32be4`
const QUOTATION_TC = `${API_URL_NEW}/setting/9738891a-427b-4d6f-9ab1-827fa55179b4`

const ALL_SGST_URL = `${API_URL_NEW}/setting/477ae9ca-43f9-490e-b947-d910da148bc7`
const ALL_IGST_URL = `${API_URL_NEW}/setting/60dda98e-e592-4564-b71d-8b6cc2f7561a`
const ALL_CGST_URL = `${API_URL_NEW}/setting/77968037-c89c-4b88-ba8d-da650ff53d39`

const ALL_OFFER_URL = `${API_URL_NEW}/offer/allOffers`
const GET_UPLOAD = `${API_URL_NEW}/uploads/upload`
const QUTATION_HISTORY_URL_NEW = `${API_URL_NEW}/quotation/list/history`
const INQUIRY_STATUS_LIST = `${API_URL_NEW}/quotation/list/status?page=1&perPage=50`

const purchaseTC = (): Promise<any> => {
  return axios.get(`${PURCAHSE_TC}`).then((d: AxiosResponse<any>) => d.data)
}

const quotationTC = (): Promise<any> => {
  return axios.get(`${QUOTATION_TC}`).then((d: AxiosResponse<any>) => d.data)
}

const getInquiryNew = (query: string): Promise<InquirysQueryResponse> => {
  return axios
    .get(`${INQUIRY_URL_NEW}?${query}`)
    .then((d: AxiosResponse<InquirysQueryResponse>) => d.data)
}

const getInquiry = (query: string): Promise<InquirysQueryResponse> => {
  return axios
    .get(`${GET_INQUIRIES_URL}?${query}`)
    .then((d: AxiosResponse<InquirysQueryResponse>) => d.data)
}

const getInquiryByIdNew = (id: string): Promise<any | undefined> => {
  return axios
    .get(`${INQUIRY_URL_NEW}/${id}`)
    .then((response: AxiosResponse<Response<Inquiry>>) => response.data)
    .then((response: Response<Inquiry>) => response)
}

const getQuotationHistoryByIdNew = (query: string): Promise<any | undefined> => {
  return axios
    .get(`${QUTATION_HISTORY_URL_NEW}${query}`)
    .then((response: AxiosResponse<Response<Inquiry>>) => response.data)
    .then((response: Response<Inquiry>) => response)
}

const getBuyerSeller = (): Promise<any> => {
  return axios.get(`${ALL_BUYER_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getAllOffer = (): Promise<any> => {
  return axios.get(`${ALL_OFFER_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getLocation = (): Promise<any> => {
  return axios.get(`${ALL_LOCATION_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getGroups = (): Promise<any> => {
  return axios.get(`${ALL_GROUP_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getAllUsers = (): Promise<any> => {
  return axios.get(`${ALL_USER_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getAllProduct = (): Promise<any> => {
  return axios.get(`${ALL_PRODUCT_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getFirmListApi = (): Promise<any> => {
  return axios.get(`${ALL_FIRMS_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getTransportListApi = (): Promise<any> => {
  return axios
    .get(`${ALL_TRANSPORT_URL}?sort=firm_name&perPage=50&page=1`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getSGST = (): Promise<any> => {
  return axios.get(`${ALL_SGST_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getIGST = (): Promise<any> => {
  return axios.get(`${ALL_IGST_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getCGST = (): Promise<any> => {
  return axios.get(`${ALL_CGST_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getInquiryById = (id: ID): Promise<Inquiry | undefined> => {
  return axios
    .get(`${INQUIRY_URL}/${id}`)
    .then((response: AxiosResponse<Response<Inquiry>>) => response.data)
    .then((response: Response<Inquiry>) => response.data)
}

const createInquiryNew = (inquiry: any): Promise<any | undefined> => {
  return axios
    .post(INQUIRY_URL_NEW, inquiry)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const deleteInquiryNew = (inquiryId: string): Promise<void> => {
  return axios.delete(`${INQUIRY_URL_NEW}/${inquiryId}`).then(() => {})
}


const createInquiry = (inquiry: Inquiry): Promise<Inquiry | undefined> => {
  return axios
    .put(INQUIRY_URL, inquiry)
    .then((response: AxiosResponse<Response<Inquiry>>) => response.data)
    .then((response: Response<Inquiry>) => response.data)
}

const updateInquiry = (inquiry: Inquiry): Promise<Inquiry | undefined> => {
  return axios
    .post(`${INQUIRY_URL}/${inquiry.id}`, inquiry)
    .then((response: AxiosResponse<Response<Inquiry>>) => response.data)
    .then((response: Response<Inquiry>) => response.data)
}

const deleteInquiry = (inquiryId: ID): Promise<void> => {
  return axios.delete(`${INQUIRY_URL}/${inquiryId}`).then(() => {})
}

const deleteSelectedInquiries = (inquiryIds: Array<ID>): Promise<void> => {
  const requests = inquiryIds.map((id) => axios.delete(`${INQUIRY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const editInquiryNew = (id: string, inquiry: any): Promise<any | undefined> => {
  return axios
    .patch(`${INQUIRY_URL_NEW}/${id}`, inquiry)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const uploadInvoice = (formData: any): Promise<any | undefined> => {
  // return axios.post(GET_UPLOAD, formData).then((response: Response<any>) => response.data)
  return axios.post(GET_UPLOAD, formData)
  .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getOnlyBuyerList = (): Promise<any> => {
  return axios.get(`${ALL_FIRMS_URL}?type=1`).then((d: AxiosResponse<any>) => d.data)
}

const getInquiryStatusList = (): Promise<any> => {
  return axios.get(`${INQUIRY_STATUS_LIST}`).then((d: AxiosResponse<any>) => d.data)
}

export {
  getInquiry,
  deleteInquiry,
  deleteSelectedInquiries,
  getInquiryById,
  createInquiry,
  updateInquiry,
  getInquiryNew,
  getInquiryByIdNew,
  getFirmListApi,
  getBuyerSeller,
  getLocation,
  getAllUsers,
  getAllProduct,
  createInquiryNew,
  deleteInquiryNew,
  getSGST,
  getIGST,
  getCGST,
  getAllOffer,
  editInquiryNew,
  getTransportListApi,
  uploadInvoice,
  getGroups,
  getQuotationHistoryByIdNew,
  purchaseTC,
  quotationTC,
  getOnlyBuyerList,
  getInquiryStatusList
}
