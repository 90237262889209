/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef } from 'react'
import { createPortal } from 'react-dom'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import 'react-upload-gallery/dist/style.css'
import { useDropzone } from 'react-dropzone'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const AddInvoiceManagementModal = ({ show, handleClose }: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return createPortal(
    <Modal
      id='kt_modal_create_user'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-650px modal fade show d-block'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2 className='fw-bolder'>Add Invoice</h2>
        <div className='btn btn-icon btn-sm btn-active-icon-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
        <div ref={stepperRef}>
          <Form noValidate id='kt_modal_add_user_form'>
            <Row className='mb-7'>
              <Col>
                <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                  Select Quotation Number
                </Form.Label>
                <Form.Select aria-label='Default select example'>
                  <option>Select Quotation Number</option>
                  <option value='3243242'>3243242</option>
                  <option value='7657652'>7657652</option>
                  <option value='7676212'>7676212</option>
                  <option value='6543652'>6543652</option>
                  <option value='3432112'>3432112</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Form.Label className='fw-bold fs-6 mb-2 form-label'>
                Upload Invoice File
              </Form.Label>
              <Col className='text-end mt-2'>
                <div onClick={open}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div className='dz-message needsclick'>
                      <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                        <KTSVG
                          path='/media/icons/duotune/files/fil009.svg'
                          className='svg-icon-muted svg-icon-2hx'
                        />
                      </span>
                      <div className='ms-4'>
                        <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                          Drag/Drop files here or click here
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <aside className='mt-4 text-start'>
                  {/* <h4>Files</h4> */}
                  <ul>{files}</ul>
                </aside>
              </Col>
            </Row>
            <Row className='mb-7'>
              <div className='text-end pt-10'>
                <Button
                  type='reset'
                  onClick={handleClose}
                  className='btn btn-light-primary me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Cancel
                </Button>
                <Button
                  type='reset'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                >
                  <span className='indicator-label'>Save</span>
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export default AddInvoiceManagementModal
