import React, { useRef } from "react";
import { Col, Modal, Row} from "react-bootstrap";
import { createPortal } from "react-dom";
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import { KTSVG } from "../../../_metronic/helpers";

type Props = {
    show: boolean
    handleClose: () => void
  }
  
  const modalsRoot = document.getElementById('root-modals') || document.body

const ViewDistrictModal = ({show, handleClose}: Props) => {

    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)

  
    const loadStepper = () => {
      stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }


    return createPortal(
        <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-650px'
        show={show}
        onHide={handleClose}
        onEntered={loadStepper}
        backdrop={true}
      >
        <div className='modal-header border-bottom border-gray-300 border-bottom-dashed'>
          <h2> District Information </h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
  
        <div className='modal-body '>
        <div className='p-5'>
        <Row xxl={12} className='justify-content-between'>
            
                     <Col xl={6} className='align-items-center pt-3 pb-3'>
                      <Col  className='fs-5 fw-bolder text-gray-600  text-hover-primary'>
                        District
                      </Col>
                      <Col  className='fw-bolder text-dark fs-5 mt-1 text-hover-primary '>
                       District Name
                      </Col>
                    </Col>

                    <Col xl={6} className='align-items-center pt-3 pb-3'>
                      <Col  className='fs-5 fw-bolder text-gray-600  text-hover-primary'>
                        State
                      </Col>
                      <Col className='text-dark fw-bold fs-5 mt-1 text-hover-primary '>
                      State Name
                      </Col>
                    </Col>
                 
            </Row>
    </div>
        </div>
     
      </Modal>,
      modalsRoot

    )

}
export default ViewDistrictModal