import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {SERVER_URL} from '../../../../../ServerConfig'
// import { ID, Response } from '../../../../../_metronic/helpers'
import {District, DistrictsQueryResponse} from './_models'

const API_URL = SERVER_URL
const DISTRICT_URL = `${API_URL}/district`
const GET_DISTRICTS_URL = `${API_URL}/location`
const GET_STATES_URL = `${API_URL}/state`

const getDistrict = (query: string): Promise<DistrictsQueryResponse> => {
  return axios
    .get(`${GET_DISTRICTS_URL}?full=${true}&${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
      },
    })
    .then((d: AxiosResponse<DistrictsQueryResponse>) => d.data)
}

const getDistrictById = (id: ID): Promise<District | undefined> => {
  return axios
    .get(`${DISTRICT_URL}/${id}`)
    .then((response: AxiosResponse<Response<District>>) => response.data)
    .then((response: Response<District>) => response.data)
}

const createDistrict = (district: District): Promise<District | undefined> => {
  return axios
    .put(DISTRICT_URL, district)
    .then((response: AxiosResponse<Response<District>>) => response.data)
    .then((response: Response<District>) => response.data)
}

const updateDistrict = (district: District): Promise<District | undefined> => {
  return axios
    .post(`${DISTRICT_URL}/${district.id}`, district)
    .then((response: AxiosResponse<Response<District>>) => response.data)
    .then((response: Response<District>) => response.data)
}

const deleteDistrict = (districtId: ID): Promise<void> => {
  return axios.delete(`${DISTRICT_URL}/${districtId}`).then(() => {})
}

const deleteSelectedDistricts = (districtIds: Array<ID>): Promise<void> => {
  const requests = districtIds.map((id) => axios.delete(`${DISTRICT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const createDistrictApi = (data:any): Promise<any> => {
  return axios
    .post(GET_DISTRICTS_URL, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const updateDistrictApi = (id:any,data:any): Promise<any> => {
  return axios
    .patch(`${GET_DISTRICTS_URL}/${id}`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getStateData = () : Promise<any> => {
  return axios
    .get(`${GET_STATES_URL}?perPage=100000&page=1`,{
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
      },
    })
    .then((d: AxiosResponse<any>) => d.data)
}

export {
  getDistrict,
  deleteDistrict,
  deleteSelectedDistricts,
  getDistrictById,
  createDistrict,
  updateDistrict,
  createDistrictApi,
  updateDistrictApi,
  getStateData
}
