import React, { useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import Select from 'react-select';
import { KTSVG } from "../../../../_metronic/helpers";
import AddEditDistrict from "./AddEditDistrict";

type Props = {
  show: boolean
  handleClose: () => void
  id:any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const EditDistrictModal = ({show, handleClose, id}: Props) => {
  return createPortal(
    <Modal
      id='kt_modal_create_user'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-850px modal fade show d-block'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2 className='fw-bolder'>Edit District </h2>
        <div className='btn btn-icon btn-sm btn-active-icon-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-15'>
      <AddEditDistrict isEditing={true} id={id} handleClose={handleClose}/>
      </div>
    </Modal>,
    modalsRoot
  )
}


export default EditDistrictModal