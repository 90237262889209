import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle, useLayout } from '../../../_metronic/layout/core'
import { CertificateModel } from './CertificateModel'
import { getSingleProductByID } from './buyers-list/core/_requests'
import moment from 'moment'
import { checkDisplayValue, checkEmptyValue, getFormatedAddress } from '../../utils/utils'

const ViewBuyer = () => {
  const wizardsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Buyer Management',
      path: '/crafted/pages/wizards/horizontal',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const { config } = useLayout()
  const params = useParams()
  const id: string = params.id ? params.id : ''

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [objData, setObjData] = useState<any>({})
  const [firmNameList, setFirmNameList] = useState<any>([])
  // const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
  //   ? 'btn-light'
  //   : 'bg-body btn-color-gray-700 btn-active-color-primary'

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      getByID(id)
    }
  }, [id])

  const getByID = async (id: string) => {
    let data: any = await getSingleProductByID(id)
    // console.log(data, 'data--')
    if (data.responseData) {
      
      setFirmNameList(data?.responseData?.firmPersonInfo.sort((a:any, b:any) => {
           return a.sequence - b.sequence;
         }) ?? [])
      setObjData(data.responseData)
    }
  }

  const navigateToListBuyer = () => {
    // 👇️ navigate to /contacts
    navigate('/buyer-management/buyers')
  }

  const navigateAddBuyer = () => {
    // 👇️ navigate to /
    navigate('/addbuyer')
  }

  return (
    <>
      <PageTitle>Buyer's Detail</PageTitle>
      <Col className='position-absolute me-10 end-0 mt-n20 '>
        <button
          type='button'
          onClick={navigateToListBuyer}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
      </Col>
      {objData && (
        <div className='card card-body backgroung-transparent p-12  mt-10 p-12 mt-10'>
          <Row className='d-flex justify-content-between'>
            {/* <Col xl={12} className='d-flex flex-wrap justify-content-between'> */}
            <Col xl={5} className='flex-column flex-lg-row-auto mb-15'>
              <Col>
                <Col>
                  {firmNameList.map((x: any, i: any) => {
                    return <div className='mb-5'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <h3 className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                            {checkDisplayValue(x?.name)}
                            
                            {x?.status ? (
                              <span
                                className='badge fs-4 badge-light-success ms-10'
                                data-kt-menu-dismiss='true'
                              >
                                Active
                              </span>
                            ) : (
                              <span
                                className='badge fs-4 badge-light-danger ms-10'
                                data-kt-menu-dismiss='true'
                              >
                                Inactive
                              </span>
                            )}

                          </h3>
                      <span className="text-primary"><b>{(x.is_primary == 1 || x.is_primary == true) ? "Primary" : ""}</b></span>
                        </div>
                      </div>
                      <Col className='row'>
                        <div className='col-md-6'>
                          <Col className='mt-5 text-hover-primary'>
                            <span className='fw-bold  text-500 me-2'>
                              <i className='fa-brands fa-whatsapp fs-3' />
                            </span>
                            <span className='fw-semibold fs-6 text-grey-500'>
                              {' '}
                              {checkDisplayValue(x?.whatsapp_number)}
                              {' '}
                            </span>
                          </Col>
                          <Col className='mt-3 text-hover-primary'>
                            <span className='fw-bold  text-500  me-2'>
                              <i className='fa-sharp fa-solid fa-envelope fs-3'></i>
                            </span>
                            <span className='fw-semibold fs-6 text-grey-500'>
                              {' '}
                              {checkDisplayValue(x?.email)}
                              {' '}
                            </span>
                          </Col>
                          <Col className='mt-3 text-hover-primary'>
                            <span className='fw-bold  text-500 me-2'>
                              <i className='fa-sharp fa-solid fa-bell fs-3'></i>
                            </span>
                            {x?.notify_firm_person ? (
                             <span className='fw-semibold fs-6 text-grey-500 text-primary'>
                                Enabled
                              </span>
                            ) : (
                              <span className='fw-semibold fs-6 text-grey-500 text-danger'>
                                Disabled
                              </span>
                            )}
                          </Col>
                          
                        </div>
                      </Col>
                      {/* <Col className='mt-5'>
                    
                  </Col> */}
                    </div>
                  })}

                  <Col className='mb-5 mt-15'>
                    <h2 className='fw-bolder mb-2'> Product Detail </h2>
                  </Col>

                  <Col className='mb-10 mt-5'>
                    <h4 className='fw-bolder mb-6 text-hover-primary'> Grade Group </h4>
                    <Col className='d-flex flex-wrap'>
                      {objData.buyerSellerInterestInfo
                        ? (objData.buyerSellerInterestInfo || []).map((el: any) => {
                          return (
                            <>
                              <Col className='border text-center text-hover-primary border-hover-primary border-dashed py-3 px-1 fs-6 fw-bold text-gray-700 border-gray-400 me-4'>
                                {el.gradeGroupInfo.name ? el.gradeGroupInfo.name : '-'}
                              </Col>
                            </>
                          )
                        })
                        : ''}
                    </Col>
                  </Col>

                  <Row>
                    {objData.buyerSellerInterestInfo
                      ? (objData.buyerSellerInterestInfo || []).map((el: any) => {
                        return (
                          <>
                            <Col xl={4} className=' align-items-center pt-3 pb-3'>
                              <Col className='fs-5 fw-bolder d-flex text-dark text-hover-primary'>
                                <h4 className=' text-gray-600 mb-2 pt-1 text-hover-primary'>
                                  {el.gradeGroupInfo.name
                                    ? `Preferred Grade ${el.gradeGroupInfo.name}`
                                    : '-'}
                                </h4>
                              </Col>
                              <h4 className='fw-bolded text-dark-800 fs-4 text-hover-primary'>
                                {el?.productGroupInfo?.name ? el?.productGroupInfo?.name : '-'}
                              </h4>
                            </Col>
                          </>
                        )
                      })
                      : ''}

                  </Row>
                  <Row>
                    <Col xl={4} className=' align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder d-flex text-dark text-hover-primary'>
                        <span> </span>
                        <h4 className=' text-gray-600 mb-2 pt-1 text-hover-primary'>
                          {objData ? objData.epr : '-'}
                        </h4>
                      </Col>
                      <h4 className='fw-bolded text-dark-800 fs-4 text-hover-primary'>Applied</h4>
                    </Col>
                    <Col xl={4} className=' align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder d-flex text-dark text-hover-primary'>
                        <span> </span>
                        <h4 className=' text-gray-600 mb-2 pt-1 text-hover-primary'>SUP</h4>
                      </Col>
                      <h4 className='fw-bolded text-dark-800 fs-4 text-hover-primary'>
                        {objData.sup ? objData.sup : 'Not Applicable'}
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} className=' align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder d-flex text-dark text-hover-primary'>
                        <span> </span>
                        <h4 className=' text-gray-600 mb-2 pt-1 text-hover-primary'>Remarks</h4>
                      </Col>
                      <h4 className='fw-bolded text-dark-800 fs-4 text-hover-primary'>
                        {objData.remarks && objData.remarks ? objData.remarks : '-'}
                      </h4>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Col>
            <div
              className='justify-content-center'
              style={{ borderRight: '1px solid #eaeaea', width: '1px' }}
            ></div>
            <Col xl={6} className='flex-lg-row-auto '>
              <Col className='h-100'>
                <Col className=''>
                  <Col className='mb-8'>
                    <h2 className='fw-bolder'> Firm Information </h2>
                    <h4 className='text-gray-600 fw-bold fs-3 mt-1 text-hover-primary'>
                      {objData ? objData.firm_name : ''}
                    </h4>
                  </Col>

                  <Row className='mb-3'>
                    {objData.category && (
                      <Col xl={6} className='align-items-center pt-3 pb-3'>
                        <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                          Category
                        </Col>
                        <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                          {objData ? objData?.category_name : ''}
                        </Col>
                      </Col>
                    )}
                    <Col xl={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        Latest Installed Capacity
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {objData ? objData.latest_installed_capacity : '-'}
                      </Col>
                    </Col>
                  </Row>

                  <Row className='mb-3'>
                    <Col xl={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        Sector
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {objData ? objData.sector : ''}
                      </Col>
                    </Col>
                    <Col xl={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        TDS/TCS
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {objData.tds_tcs ? objData.tds_tcs : '-'}
                      </Col>
                    </Col>
                  </Row>

                  <Row className='mb-3'>
                    <Col xl={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        GST Certificate
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        <div className='d-flex align-items-center gap-2 gap-lg-3 '>
                          {config.app?.toolbar?.primaryButton && (
                            <Link
                              to='#'
                              onClick={() => setShowCreateAppModal(true)}
                              className='btn btn-sm fw-bold badge-light-primary mt-2'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_create_app'
                            >
                              <img
                                alt=''
                                src={toAbsoluteUrl('/media/demos/download-pdf.png')}
                                className='border-white h-30px w-30px me-3'
                              />
                              Click to View
                            </Link>
                          )}
                          <CertificateModel
                            fileName={objData.GST_file_name}
                            show={showCreateAppModal}
                            handleClose={() => setShowCreateAppModal(false)}
                          />
                        </div>
                      </Col>
                    </Col>
                    <Col xl={6} className='text-hover-primary align-items-center pt-3 pb-1'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        Verification
                      </Col>
                      <Col className='d-flex justify-content-start mt-2'>
                        <span
                          className='badge fs-4 badge-light-primary'
                          data-kt-menu-dismiss='true'
                        >
                          {objData.verification_title}
                        </span>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Col>
            {/* </Col> */}
          </Row>
          {/* {console.log("objDataobjData",objData)} */}
          <Row className='mt-15'>
            <Col xl={12}>
              <h3>Billing Address</h3>
            </Col>
            {objData?.buyerSellerPrimaryBillingInfo?.map((addressItem: any, index: number) => {
              return <Row>
                {index == 0 ? <hr className='mt-5' /> : ""}
                <Col xl={12}>
                  <span className="text-primary"><b>
                    {" "}{addressItem.is_primary == 1 || addressItem.is_primary == "1" ? "Primary Address" : ""}
                  </b></span>
                </Col>
                <Col xl={6}>
                  <Col className='text-hover-primary align-items-center pt-3 pb-3'>
                    <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                      Address Line 1
                    </Col>
                    <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                      {checkDisplayValue(addressItem?.address_line_1)}
                    </Col>
                  </Col>
                  <Col className='text-hover-primary align-items-center pt-3 pb-3'>
                    <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                      Address Line 2
                    </Col>
                    <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                      {checkDisplayValue(addressItem?.address_line_2)}
                    </Col>
                  </Col>
                  <Col className='text-hover-primary align-items-center pt-3 pb-3'>
                    <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                      GST Number
                    </Col>
                    <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                      {checkDisplayValue(addressItem?.gst)}
                    </Col>
                  </Col>
                </Col>
                <Col xl={6}>
                  <Row>
                    <Col md={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        State
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {checkDisplayValue(addressItem?.addressLocationInfo?.stateInfo?.name)}
                      </Col>
                    </Col>
                    <Col md={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        District
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {checkDisplayValue(addressItem?.addressLocationInfo?.name)}
                      </Col>
                    </Col>
                    <Col md={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        City
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {checkDisplayValue(addressItem?.city)}
                      </Col>
                    </Col>
                    <Col md={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        Pincode
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {checkDisplayValue(addressItem?.pincode)}
                      </Col>
                    </Col>
                  </Row>
                </Col>
                <hr className='mt-5' />
              </Row>
            })}
          </Row>

          <Row className='mt-15'>
            <Col xl={12}>
              <h3>Shipping Address</h3>
            </Col>
            {objData?.buyerSellerPrimaryShippingInfo?.map((addressItem: any, index: number) => {
              return <Row>
                {index == 0 ? <hr className='mt-5' /> : ""}
                <Col xl={12}>
                  <span className="text-primary"><b>
                    {" "}{addressItem.is_primary == 1 || addressItem.is_primary == "1" ? "Primary Address" : ""}
                  </b></span>
                </Col>
                <Col xl={6}>
                  <Col className='text-hover-primary align-items-center pt-3 pb-3'>
                    <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                      Store Keeper Name
                    </Col>
                    <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                      {checkDisplayValue(addressItem?.store_keeper_name)}
                    </Col>
                  </Col>
                  <Col className='text-hover-primary align-items-center pt-3 pb-3'>
                    <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                      Address Line 1
                    </Col>
                    <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                      {checkDisplayValue(addressItem?.address_line_1)}
                    </Col>
                  </Col>
                  <Col className='text-hover-primary align-items-center pt-3 pb-3'>
                    <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                      Address Line 2
                    </Col>
                    <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                      {checkDisplayValue(addressItem?.address_line_2)}
                    </Col>
                  </Col>
                </Col>
                <Col xl={6}>
                  <Row>
                    <Col md={12} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        Store Keeper Number
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {checkDisplayValue(addressItem?.store_keeper_number)}
                      </Col>
                    </Col>
                    <Col md={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        State
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {checkDisplayValue(addressItem?.addressLocationInfo?.stateInfo?.name)}
                      </Col>
                    </Col>
                    <Col md={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        District
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {checkDisplayValue(addressItem?.addressLocationInfo?.name)}
                      </Col>
                    </Col>
                    <Col md={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        City
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {checkDisplayValue(addressItem?.city)}
                      </Col>
                    </Col>
                    <Col md={6} className='text-hover-primary align-items-center pt-3 pb-3'>
                      <Col className='fs-5 fw-bolder text-gray-600   text-hover-primary'>
                        Pincode
                      </Col>
                      <Col className='fw-bolder text-dark-800 fs-5 mt-1 text-hover-primary'>
                        {checkDisplayValue(addressItem?.pincode)}
                      </Col>
                    </Col>
                  </Row>
                </Col>
                <hr className='mt-5' />
              </Row>
            })}
          </Row>

          <Row className='mt-15'>
            <Col xl={3} className='d-flex'>
              <Col className='justify-content-start text-muted fs-6 w-80px'>
                <div className='timeline-icon symbol symbol-circle symbol-40px '>
                  <div className='symbol-label bg-light'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com003.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </Col>
              <Col xl={10}>
                <Col className='justify-content-end text-muted fs-6'>
                  Created By:{' '}
                  <span className='text-dark'> {objData.created_by ? objData.created_by : 'Self'} </span>
                </Col>
                <Col className='justify-content-end text-muted'>
                  <span className=''>
                    {' '}
                    {objData?.created_at ? moment(new Date(objData?.created_at)).format('LLL') : '-'}
                  </span>
                </Col>
              </Col>
            </Col>

            <Col xl={3} className='d-flex'>
              <Col className='justify-content-start text-muted fs-6 w-80px'>
                <div className='timeline-icon symbol symbol-circle symbol-40px '>
                  <div className='symbol-label bg-light'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com003.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </Col>

              <Col xl={10}>
                <Col className='justify-content-end text-muted fs-6 '>
                  Verified By:{' '}
                  <span className='text-dark'>
                    {' '}
                    {objData.verified_by ? objData.verified_by : '-'}{''}
                  </span>
                </Col>
                <Col className='justify-content-end text-muted'>
                  <span className=''>
                    {objData?.verified_at ? moment(new Date(objData?.verified_at)).format('LLL') : '-'}
                  </span>
                </Col>
              </Col>
            </Col>

            <Col xl={4} className='d-flex'>
              <Col className='justify-content-start text-muted fs-6 w-80px'>
                <div className='timeline-icon symbol symbol-circle symbol-40px '>
                  <div className='symbol-label bg-light'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com003.svg'
                      className='svg-icon-2 svg-icon-gray-500'
                    />
                  </div>
                </div>
              </Col>

              <Col xl={10}>
                <Col className='justify-content-end text-muted fs-6'>
                  Last Modified By:{' '}
                  <span className='text-dark'>
                    {' '}
                    {objData.last_modified_by ? objData.last_modified_by : '-'}
                  </span>
                </Col>
                <Col className='justify-content-end text-muted'>
                  <span className=''>
                    {objData?.modified_at ? moment(new Date(objData?.modified_at)).format('LLL') : '-'}
                  </span>
                </Col>
              </Col>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default ViewBuyer
