import React, { useState } from "react"; 
import { Col, Row } from "react-bootstrap";
import ThresholdOffers from "./ThresholdOffers";

type Props = {
  setMessage: (type:string,message:string) => void
}

const ThresholdPage = ({setMessage}:Props) => {
  return (
    <>
      <div className='accordion' id='kt_accordion_1'>
      <div className='accordion-item'>
        <h2 className='accordion-header' id='kt_accordion_1_header_1'>
          <button
            className='accordion-button fs-4 fw-bold collapsed text-danger '
            style={{backgroundColor: '#f5dee4'}}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_accordion_1_body_1'
            aria-expanded='false'
            aria-controls='kt_accordion_1_body_1'
          >
            Offers With Threshold Limit
          </button>
        </h2>
        <div
          id='kt_accordion_1_body_1'
          className='accordion-collapse collapse'
          aria-labelledby='kt_accordion_1_header_1'
          data-bs-parent='#kt_accordion_1'
        > 
          <div className='accordion-body' style={{overflowY: "scroll", height:"400px"}}>
          <Row className="mb-7">
            <Col>
              <ThresholdOffers 
                setMessage={(type:any,message:any)=>{
                  setMessage(type,message)
                }}
              />
            </Col>
          </Row>
          </div>
        </div>
      </div>
{/* <div className='accordion-item'>
  <h2 className='accordion-header' id='kt_accordion_1_header_2'>
    <button
      className='accordion-button fs-4 fw-bold collapsed'
      type='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_accordion_1_body_2'
      aria-expanded='false'
      aria-controls='kt_accordion_1_body_2'
    >
      Accordion Item #2
    </button>
  </h2>
  <div
    id='kt_accordion_1_body_2'
    className='accordion-collapse collapse'
    aria-labelledby='kt_accordion_1_header_2'
    data-bs-parent='#kt_accordion_1'
  >
    <div className='accordion-body'>
      <strong>This is the second item's accordion body.</strong>It is hidden by
      default, until the collapse plugin adds the appropriate classes that we use to
      style each element. These classes control the overall appearance, as well as the
      showing and hiding via CSS transitions. You can modify any of this with custom
      CSS or overriding our default variables. It's also worth noting that just about
      any HTML can go within the
      <code>.accordion-body</code>, though the transition does limit overflow.
    </div>
  </div>
</div>
<div className='accordion-item'>
  <h2 className='accordion-header' id='kt_accordion_1_header_3'>
    <button
      className='accordion-button fs-4 fw-bold collapsed'
      type='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_accordion_1_body_3'
      aria-expanded='false'
      aria-controls='kt_accordion_1_body_3'
    >
      Accordion Item #3
    </button>
  </h2>
  <div
    id='kt_accordion_1_body_3'
    className='accordion-collapse collapse'
    aria-labelledby='kt_accordion_1_header_3'
    data-bs-parent='#kt_accordion_1'
  >
    <div className='accordion-body'>
      <strong>This is the third item's accordion body.</strong>It is hidden by
      default, until the collapse plugin adds the appropriate classes that we use to
      style each element. These classes control the overall appearance, as well as the
      showing and hiding via CSS transitions. You can modify any of this with custom
      CSS or overriding our default variables. It's also worth noting that just about
      any HTML can go within the
      <code>.accordion-body</code>, though the transition does limit overflow.
    </div>
  </div>
</div> */}
</div>
        
        </>
    )

}


export default ThresholdPage