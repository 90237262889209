/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import Multiselect from 'multiselect-react-dropdown'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const AddTransportModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [isShowTrsError, setIsShowTrsError] = useState(false)
  const [isShowCPnameError, setIsShowCPnameError] = useState(false)
  const [isShowMnumberError, setIsShowMnumberError] = useState(false)
  const [isShowGSTnumberError, setIsShowGSTnumberError] = useState(false)
  const [isShowStateError, setIsShowStateError] = useState(false)
  const [isShowCityError, setIsShowCityError] = useState(false)
  const [isShowInput, setIsShowInput] = useState(false)

  return createPortal(
    <Modal
      id='kt_modal_create_user'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-650px modal fade show d-block'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2 className='fw-bolder'>Add Transport Management</h2>
        <div
          className='btn btn-icon btn-sm btn-active-icon-primary'
          onClick={() => {
            setIsShowTrsError(false)
            setIsShowCPnameError(false)
            setIsShowMnumberError(false)
            setIsShowGSTnumberError(false)
            setIsShowStateError(false)
            setIsShowCityError(false)
            setIsShowInput(false)
            handleClose()
          }}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
        <div ref={stepperRef}>
          <Form id='kt_modal_add_user_form'>
            <Row className='mb-7'>
              <Col md={6}>
                <Form.Label className='required fw-bold fs-6 mb-2 form-label'>Firm Name</Form.Label>
                <Form.Control type='text' className='form-control form-control-solid mb-lg-0' />
                {isShowTrsError && (
                  <div className=' mb-3 fv-plugins-message-container'>
                    <div className='fv-help-block'>“Please enter valid Name”</div>
                  </div>
                )}
              </Col>
              <Col md={6}>
                <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                  Contact Person Name
                </Form.Label>
                <Form.Control type='text' className='form-control form-control-solid mb-lg-0' />
                {isShowCPnameError && (
                  <div className=' mb-3 fv-plugins-message-container'>
                    <div className='fv-help-block'>“Please enter valid Person Name”</div>
                  </div>
                )}
              </Col>
            </Row>
            <Row className='mb-7'>
              {/* <Col md={6}>
                <Form.Label className='required fw-bold fs-6 mb-2 form-label'>State</Form.Label>
                <Form.Control type='text' className='form-control form-control-solid mb-lg-0' />
                {isShowStateError && (
                  <div className=' mb-3 fv-plugins-message-container'>
                  <div className='fv-help-block'>“Please enter valid State”</div>
                  </div>
                  )}
                </Col> */}
              <Col md={6}>
                <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                  GST Number
                </Form.Label>
                <Form.Control type='number' className='form-control form-control-solid mb-lg-0' />
                {isShowGSTnumberError && (
                  <div className=' mb-3 fv-plugins-message-container'>
                    <div className='fv-help-block'>“Please enter valid GST Number”</div>
                  </div>
                )}
              </Col>
              <Col md={6}>
                <div className='d-flex align-items-center'>
                  <div className='me-2'>
                    <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                      Mobile Number
                    </Form.Label>
                    <Form.Control
                      type='number'
                      className='form-control form-control-solid mb-lg-0'
                    />
                    {isShowMnumberError && (
                      <div className=' mb-3 fv-plugins-message-container'>
                        <div className='fv-help-block'>“Please enter valid Mobile Number”</div>
                      </div>
                    )}
                  </div>
                  <Button
                    onClick={() => {
                      setIsShowInput(true)
                    }}
                    className='p-1 mt-8 btn btn-light-success'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr009.svg'
                      className='svg-icon-muted svg-icon-2hx'
                    />
                  </Button>
                </div>
              </Col>
              <Col md={6}></Col>
              {isShowInput && (
                <Col md={6} className='mt-5'>
                  <div className='d-flex align-items-center'>
                    <div>
                      <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                        Mobile Number
                      </Form.Label>
                      <Form.Control
                        type='number'
                        className='form-control form-control-solid mb-lg-0'
                      />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            <Row className='mb-7'>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className='required fw-bold fs-6 mb-2 form-label'>State</Form.Label>
                  <Multiselect
                    className='fw-bold fs-6 mb-2 bg-red form-label multi-sel form-control-solid mb-lg-0'
                    displayValue='key'
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck() {}}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck() {}}
                    options={[
                      {
                        cat: 'Group 1',
                        key: 'Ahmedabad',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Palanpur',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Surat',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Jaipur',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Rajasthan',
                      },
                    ]}
                    selectionLimit={1}
                    // singleSelect
                  />
                  {isShowStateError && (
                    <div className=' mb-3 fv-plugins-message-container'>
                      <div className='fv-help-block'>“Please enter valid State”</div>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className='required fw-bold fs-6 mb-2 form-label'>City</Form.Label>
                  <Multiselect
                    className='fw-bold fs-6 mb-2 bg-red form-label multi-sel form-control-solid mb-lg-0'
                    displayValue='key'
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck() {}}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck() {}}
                    options={[
                      {
                        cat: 'Group 1',
                        key: 'Ahmedabad',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Palanpur',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Surat',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Jaipur',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Rajasthan',
                      },
                    ]}
                    selectionLimit={1}
                    // singleSelect
                  />
                  {isShowCityError && (
                    <div className=' mb-3 fv-plugins-message-container'>
                      <div className='fv-help-block'>“Please enter valid City”</div>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col md={6}>
                <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                  Select Source
                </Form.Label>
                <Multiselect
                  className='fw-bold fs-6 mb-2 bg-red form-label mb-lg-0 height-auto'
                  displayValue='key'
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={function noRefCheck() {}}
                  options={[
                    {
                      cat: 'Group 1',
                      key: 'Ahmedabad',
                    },
                    {
                      cat: 'Group 1',
                      key: 'Palanpur',
                    },
                    {
                      cat: 'Group 1',
                      key: 'Gujarat',
                    },
                    {
                      cat: 'Group 2',
                      key: 'Mumbai',
                    },
                    {
                      cat: 'Group 2',
                      key: 'Rajasthan',
                    },
                    {
                      cat: 'Group 2',
                      key: 'Jaipur',
                    },
                    {
                      cat: 'Group 2',
                      key: 'Mahesana',
                    },
                  ]}
                  selectionLimit={1}
                  // singleSelect
                />
              </Col>
              <Col md={6}>
                <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                  Select Destination
                </Form.Label>
                <Multiselect
                  className='fw-bold fs-6 mb-2 bg-red form-label mb-lg-0 height-auto'
                  displayValue='key'
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={function noRefCheck() {}}
                  options={[
                    {
                      cat: 'Group 1',
                      key: 'Ahmedabad',
                    },
                    {
                      cat: 'Group 1',
                      key: 'Palanpur',
                    },
                    {
                      cat: 'Group 1',
                      key: 'Gujarat',
                    },
                    {
                      cat: 'Group 2',
                      key: 'Mumbai',
                    },
                    {
                      cat: 'Group 2',
                      key: 'Rajasthan',
                    },
                    {
                      cat: 'Group 2',
                      key: 'Jaipur',
                    },
                    {
                      cat: 'Group 2',
                      key: 'Mahesana',
                    },
                  ]}
                  selectionLimit={1}
                  // singleSelect
                />
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col className='d-flex justify-content-between'>
                <Form.Label className='d-block fw-bold text-start'>Status</Form.Label>
                <Form.Check type='switch' id='custom-switch' label='' />
              </Col>
            </Row>
            <Row className='mb-7'>
              <div className='text-end pt-10'>
                <Button
                  data-kt-users-modal-action='cancel'
                  onClick={() => {
                    setIsShowTrsError(true)
                    setIsShowCPnameError(true)
                    setIsShowMnumberError(true)
                    setIsShowGSTnumberError(true)
                    setIsShowStateError(true)
                    setIsShowCityError(true)
                  }}
                  className='btn btn-primary btn btn-primary'
                >
                  Save
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {AddTransportModal}
