import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../_metronic/helpers'
import { SERVER_URL } from '../../ServerConfig'

const API_URL = SERVER_URL
const STATICS_REPORT_URL = `${API_URL}/dashboard/statics/reports`
const INQUIRY_STATUS_STATICS_URL = `${API_URL}/dashboard/report/inquiry-status-counter`
const THRESHOLD_LIMIT_URL = `${API_URL}/dashboard/report/offers-with-threshold-limit`
const USERWISE_INQUIRY_URL = `${API_URL}/dashboard/report/user-wise-inquiry`
const TOP_SALESMAN_URL = `${API_URL}/dashboard/report/top-five-salesman`
const TOP_BUYERS_URL = `${API_URL}/dashboard/report/top-five-buyers`
const TOP_SELLERS_URL = `${API_URL}/dashboard/report/top-five-sellers`
const TOP_SOURCE_URL = `${API_URL}/dashboard/report/top-five-source-location`
const TOP_DESTINATION_URL = `${API_URL}/dashboard/report/top-five-destination-location`
const INQUIRY_GRADEGROUP_URL = `${API_URL}/dashboard/report/inquiries-by-grade-group`
const OFFER_GRADEGROUP_URL = `${API_URL}/dashboard/report/offer-by-grade-group`
const LOCATIONWISE_INQUIRIES_URL = `${API_URL}/dashboard/report/location-wise-inquiries-offers`

const getStaticsReportApi = (): Promise<any> => {
  return axios
    .get(`${STATICS_REPORT_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getInquiryStatusApi = (): Promise<any> => {
  return axios
    .get(`${INQUIRY_STATUS_STATICS_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getThresholdlimitApi = (): Promise<any> => {
  return axios
    .get(`${THRESHOLD_LIMIT_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getUserwiseInquiryApi = (): Promise<any> => {
  return axios
    .get(`${USERWISE_INQUIRY_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getTopSalesmanApi = (): Promise<any> => {
  return axios
    .get(`${TOP_SALESMAN_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getTopBuyersApi = (): Promise<any> => {
  return axios
    .get(`${TOP_BUYERS_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getTopSellersApi = (): Promise<any> => {
  return axios
    .get(`${TOP_SELLERS_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getTopSourceApi = (): Promise<any> => {
  return axios
    .get(`${TOP_SOURCE_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}
  
const getTopDestinationApi = (): Promise<any> => {
  return axios
    .get(`${TOP_DESTINATION_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getInquiryGradeGroupApi = (): Promise<any> => {
  return axios
    .get(`${INQUIRY_GRADEGROUP_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getOfferGradeGroupApi = (): Promise<any> => {
  return axios
    .get(`${OFFER_GRADEGROUP_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}
  
const getLocationWiseInquiriesApi = (): Promise<any> => {
  return axios
    .get(`${LOCATIONWISE_INQUIRIES_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

export {getStaticsReportApi,getInquiryStatusApi,getThresholdlimitApi,
    getUserwiseInquiryApi, getTopSalesmanApi, getTopBuyersApi,
    getTopSellersApi, getTopSourceApi, getTopDestinationApi,
    getInquiryGradeGroupApi, getOfferGradeGroupApi, getLocationWiseInquiriesApi
}
