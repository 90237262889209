import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Toast, ToastContainer } from 'react-bootstrap'
import { verifyotpApi } from "../core/_requests";

const initialValues = {
  token: '',
}

const verifyOTPSchema = Yup.object().shape({
  token: Yup.string()
    .min(6, 'OTP must be 6 digits')
    .max(6, 'OTP must be 6 digits')
    .required('OTP is required'),
})

export function VerifyOTP() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { state } = useLocation() as any
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  useEffect(() => {
    if (sessionMessage.show) {
      setTimeout(() => {
        setSessionMessage({ type: '', message: '', show: false })
      }, 4500)
    }
  }, [sessionMessage.message])

  useEffect(() => {
    if(!state?.token || state?.token == "" || state?.user == ""){
      navigate("/auth/login")
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: verifyOTPSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        let userData = JSON.parse(state.user) ?? {}
        // let userData = localStorage.getItem("objUser") as any
        // userData = JSON.parse(userData)
        await verifyotpApi({ user_id: userData?.id ?? "", token: values.token }).then((data:any)=>{
          if (data?.responseStatus == 200) {
            localStorage.setItem('authtoken', state?.token ?? "")
            localStorage.setItem('objUser', state?.user ?? "")
            setLoading(false)
            setSessionMessage({type: 'success', message: data?.responseMessage ?? "Response Close With 200 Status Code", show: true})
            setTimeout(()=>{
              window.location.replace('/offer-management/offers')
            },1500)
          }else{
            setSessionMessage({type: 'danger', message: data?.responseMessage ?? "SomeThing Went Wrong", show: true})
            setLoading(false)
          }
        }).catch((err:any)=>{
          if(err?.response?.status == 429){
            setSessionMessage({type: 'danger', message: err?.response?.statusText ?? "SomeThing Went Wrong", show: true})
          }
          else{
            setSessionMessage({type: 'danger', message: err?.message ?? "SomeThing Went Wrong", show: true})
          }
          setSubmitting(false)
          setLoading(false)
        })

      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Verify OTP</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Open the two-step verfication app on your mobile device to get your verfication code.
        </div>
        {/* end::Link */}
      </div>

      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
        <input
          type='text'
          placeholder='Enter OTP'
          autoComplete='off'
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const key = e.key;
            const isNumeric = /^[0-9]$/.test(key);
            const isSpecialKey =
              key === 'Backspace' ||
              key === 'Delete' ||
              key === 'ArrowLeft' ||
              key === 'ArrowRight' ||
              key === 'ArrowUp' ||
              key === 'ArrowDown';
            if (!isNumeric && !isSpecialKey) {
              e.preventDefault();
            }
          }}
          {...formik.getFieldProps('token')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.token && formik.errors.token },
            {
              'is-valid': formik.touched.token && !formik.errors.token,
            }
          )}
        />
        {formik.touched.token && formik.errors.token && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.token}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' disabled={loading}>
          {loading ? "Please Wait..." : "Verify OTP"}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            onClick={() => {
              navigate('/auth')
            }}
            disabled={loading}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      <ToastContainer className='p-3' position={'top-end'}>
        <Toast
          show={sessionMessage.show}
          onClose={() => {
            setSessionMessage({
              type: '',
              message: '',
              show: false,
            })
          }}
          bg={sessionMessage.type}
        >
          {/* <Toast.Header>Error</Toast.Header> */}
          <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
        </Toast>
      </ToastContainer>
      {/* end::Form group */}
    </form>
  )
}
