import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {forgotPasswordApi, requestPassword} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { Toast, ToastContainer } from 'react-bootstrap'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 character is required')
    .max(50, 'Maximum 50 character is required')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  useEffect(()=>{
    if(sessionMessage.show){
      setTimeout(()=>{
        setSessionMessage({type: '', message: '', show: false})
      },4500)
    }
  },[sessionMessage.message])

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await forgotPasswordApi(values).then((data:any)=>{
          if (data?.responseStatus == 200) {
            setLoading(false)
            setSessionMessage({type: 'success', message: data?.responseMessage ?? "Response Close With 200 Status Code", show: true})
            setTimeout(()=>{
              navigate('/auth')
            },3000)
          }else{
            setSessionMessage({type: 'danger', message: data?.responseMessage ?? "SomeThing Went Wrong", show: true})
            setLoading(false)
          }
        }).catch((err:any)=>{
          if(err?.response?.status == 429){
            setSessionMessage({type: 'danger', message: err?.response?.statusText ?? "SomeThing Went Wrong", show: true})
          }
          else{
            setSessionMessage({type: 'danger', message: err?.message ?? "SomeThing Went Wrong", show: true})
          }
          setSubmitting(false)
          setLoading(false)
        })
        
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
  }})

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </div>
        {/* end::Link */}
      </div>

      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder='Enter Email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' disabled={loading}>
          {loading ? "Please Wait..." : "Submit"}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            onClick={()=>{
              navigate('/auth')
            }}
            disabled={loading}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      <ToastContainer className='p-3' position={'top-end'}>
        <Toast
          show={sessionMessage.show}
          onClose={() => {
            setSessionMessage({
              type: '',
              message: '',
              show: false,
            })
          }}
          bg={sessionMessage.type}
        >
          {/* <Toast.Header>Error</Toast.Header> */}
          <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
        </Toast>
      </ToastContainer>
      {/* end::Form group */}
    </form>
  )
}
