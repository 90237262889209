import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row, Spinner, Table, Toast, ToastContainer } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { KTSVG } from '../../../_metronic/helpers'
import * as Yup from 'yup'
import {
  getAllOffer,
  getAllProduct,
  getCGST,
  getFirmListApi,
  getIGST,
  getLocation,
  getSGST,
  getTransportListApi,
} from '../inquiry-management/Inquiry-list/core/_requests'
import { useFormik, FormikProvider, FieldArray, Field, getIn } from 'formik'
import {
  createPurchaseNew,
  getPOStatusList,
  getPurchaseByIdNew,
  updatePurchaseNew,
  uploadFile,
} from './purchases-list/core/_requests'
import moment from 'moment'
import { useDropzone } from 'react-dropzone'
import { getDistrictListByStateID } from '../masters/transports/transports-list/core/_requests'
import { getAllStateList } from '../quotation-management/quotatuins-list/core/_requests'

// const InvoiceReceivedList = [
//   {value: '', label: 'Select Status'},
//   {value: '1', label: 'Draft'},
//   {value: '2', label: 'Sent'},
//   {value: '3', label: 'Canceled'},
//   {value: '4', label: 'Confirmed'},
//   {value: '5', label: 'Invoice Received'},
// ]

const loginSchema = Yup.object().shape({
  date: Yup.string().required('This field is required'),
  offer_id: Yup.string().required('This field is required'),
  firm_id: Yup.string().required('This field is required'),
  billing_location_id: Yup.string().required('This field is required'),
  purchase_order_status: Yup.string().required('This field is required'),
  invoice_file: Yup.string().when('purchase_order_status', {
    is: (purchase_order_status: any) => purchase_order_status == '5',
    then: Yup.string().required('This Field is required'),
  }),
  eway_bill_file: Yup.string().when('purchase_order_status', {
    is: (purchase_order_status: any) => purchase_order_status == '5',
    then: Yup.string().required('This Field is required'),
  }),
  transport_id: Yup.string().when('purchase_order_status', {
    is: (purchase_order_status: any) => purchase_order_status == '4' as any,
    then: Yup.string().required('This Field is required'),
  }),
  mobile_number: Yup.string().when('purchase_order_status', {
    is: (purchase_order_status: any) => purchase_order_status == '4' as any,
    then: Yup.string().required('This Field is required'),
  }),
  driver_name: Yup.string().when('purchase_order_status', {
    is: (purchase_order_status: any) => purchase_order_status == '4' as any,
    then: Yup.string().required('This Field is required'),
  }),
  vehicle_number: Yup.string().when('purchase_order_status', {
    is: (purchase_order_status: any) => purchase_order_status == '4' as any,
    then: Yup.string().required('This Field is required'),
  }),
})

const initialValues = {
  date: '',
  reference: '',
  offer_id: '',
  purchase_order_status: '',
  firm_id: '',
  purchase_order_number: '',
  invoice_file: '',
  eway_bill_file: '',
  transport_id: '',
  driver_name: '',
  mobile_number: '',
  vehicle_number: '',
  billing_location_id: '',
  seller_billing_address_id: '',
  seller_shipping_address_id: '',
  source_id: '',
  shipping_address: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    district: '',
    state_id: '',
    district_id: '',
    country: 'India',
    pincode: '',
  }
}

const AddEditPurchaseOrder = (props: any) => {
  const params = useParams()
  const id: string = params.id ? params.id : ''
  const navigate = useNavigate()

  const [loadingList, setLoadingList] = useState(false)
  const [loadingGetData, setLoadingGetData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lstFirm, setLstFirm] = useState([])
  const [lstBuyyerSeller, setLstBuyyerSeller] = useState([])
  const [lstLocation, setLstLocation] = useState([])
  const [lstOffer, setLstOffer] = useState([])
  const [lstOfferMaster, setLstOfferMaster] = useState([])
  const [lstProduct, setLstProduct] = useState([])
  const [lstMasterProduct, setLstMasterProduct] = useState([])
  const [valueSGST, setValueSGST] = useState(0)
  const [valueIGST, setValueIGST] = useState(0)
  const [valueCGST, setValueCGST] = useState(0)
  const [isGujarat, setIsGujarat] = useState<any>('')
  const [lstShippingAddress, setLstShippingAddress] = useState([])
  const [districtShipping, setDistrictShipping] = useState<any>(null)
  const [lstLocationShipping, setLstLocationShipping] = useState([])
  const [lstLocationState, setLstLocationState] = useState([])
  const [InvoiceReceivedList, setInvoiceReceivedList] = useState([])
  const [transporterList, setTransporterList] = useState([])
  const [billingAddressList, setBillingAddressList] = useState([])
  const [shippingAddressList, setShippingAddressList] = useState([])
  const [oldDataById, setOldDataById] = useState<any>({})

  const [lstProductDetails, setLstProductDetails] = useState([
    {
      product_id: '',
      quantity: '',
      rate: '',
    },
  ])

  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  useEffect(() => {
    getLists()
    if (!id) {
      formik.setFieldValue('date', moment(new Date()).format('YYYY-MM-DD'))
    }
  }, [])

  useEffect(() => {
    if (id) {
      getByID(id)
    }
  }, [id])

  const setDistrictShippingList = async () => {
    let dataLocation: any = await getDistrictListByStateID(districtShipping)
    let lstTmpLocation = dataLocation.data.map((obj: any) => {
      return { value: obj.id, label: obj.name }
    })
    setLstLocationShipping(lstTmpLocation)
  }

  useEffect(() => {
    setDistrictShippingList()
  }, [districtShipping, id])

  console.log("shippingAddressList",shippingAddressList)
  console.log("billingAddressList",billingAddressList)

  // Edit
  const getByID = async (id: string) => {

    setLoadingGetData(true)
    let data: any = await getPurchaseByIdNew(id)
    console.log(data, 'data--')
    if (data.responseData) {
      setOldDataById(data?.responseData ?? {})
      formik.setFieldValue('date', data?.responseData?.date ?? '')
      formik.setFieldValue('reference', data?.responseData?.reference ?? '')
      formik.setFieldValue('offer_id', data?.responseData?.offer_id ?? '')
      formik.setFieldValue('purchase_order_status', data?.responseData?.purchase_order_status ?? '')
      formik.setFieldValue('firm_id', data?.responseData?.buyerSellerInfo?.id ?? '')
      formik.setFieldValue('transport_id', data?.responseData?.transport_id ?? "")
      formik.setFieldValue('driver_name', data?.responseData?.driver_name ?? '')
      formik.setFieldValue('mobile_number', data?.responseData?.mobile_number ?? '')
      formik.setFieldValue('vehicle_number', data?.responseData?.vehicle_number ?? '')
      formik.setFieldValue('billing_location_id', data?.responseData?.billing_location_id ?? '')
      formik.setFieldValue('seller_billing_address_id', data?.responseData?.seller_billing_address_id ?? '')
      formik.setFieldValue('seller_shipping_address_id', data?.responseData?.seller_shipping_address_id ?? '')
      formik.setFieldValue('source_id', data?.responseData?.source_id ?? '')
      let dataBuyerSeller: any = await getFirmListApi()
      let lstTmpBuyerSeller = dataBuyerSeller.responseData.map((obj: any) => {
        return { ...obj, value: obj.id, label: obj.name }
      })
      selectedBuyerDetails(lstTmpBuyerSeller.find((x: any) => x.value == data?.responseData?.buyerSellerInfo?.id), "edit", data?.responseData ?? {})
      setIsGujarat(data?.responseData?.billingLocationInfo?.state_id)
      formik.setFieldValue('purchase_order_number', data?.responseData?.purchase_order_number ?? '')
      formik.setFieldValue('invoice_file', data?.responseData?.invoice_file ?? '')
      formik.setFieldValue('eway_bill_file', data?.responseData?.eway_bill_file ?? '')
      formik.setFieldValue('shipping_address.address_line_1', data?.responseData?.shipping_address?.address_line_1 ?? '')
      formik.setFieldValue('shipping_address.address_line_2', data?.responseData?.shipping_address?.address_line_2 ?? '')
      formik.setFieldValue('shipping_address.country', data?.responseData?.shipping_address?.country ?? 'India')
      formik.setFieldValue(`shipping_address.state_id`, data?.responseData?.shipping_address?.state_id ?? '')
      setDistrictShipping(data?.responseData?.shipping_address?.state_id ?? '')
      formik.setFieldValue(`shipping_address.state`, data?.responseData?.shipping_address?.state ?? '')
      formik.setFieldValue(`shipping_address.district_id`, data?.responseData?.shipping_address?.district_id ?? '')
      formik.setFieldValue(`shipping_address.district`, data?.responseData?.shipping_address?.district ?? '')
      formik.setFieldValue('shipping_address.city', data?.responseData?.shipping_address?.city ?? '')
      formik.setFieldValue('shipping_address.pincode', data?.responseData?.shipping_address?.pincode ?? '')
      let lstTmpLstProductDetails = data?.responseData?.purchaseOrderDetailsInfo.map((obj: any) => {
        return {
          quantity: obj.quantity ? obj.quantity : 0,
          rate: obj?.rate,
          product_id: obj?.product_id,
        }
      })
      setLstProductDetails(lstTmpLstProductDetails)
      formik.validateForm()
      setLoadingGetData(false)
    }
  }

  // Firm
  const getLists = async () => {
    try{

      setLoadingList(true)
  
      //PO Status List
      let dataPOStatusList: any = await getPOStatusList()
      let lstTmpPOStatusList = dataPOStatusList?.responseData?.list.map((obj: any) => {
        return { value: obj.id, label: obj.name }
      })
      setInvoiceReceivedList(lstTmpPOStatusList)
  
      // Firm
      let dataFirm: any = await getFirmListApi()
      let lstTmpFirm = dataFirm.responseData.map((obj: any) => {
        let stateID = ""
        return { value: obj.id, label: obj.firm_name, state_id: stateID }
      })
      // console.log("lstTmpFirm",typeOf lstTmpFirm[0].billing_state_id)
      setLstFirm(lstTmpFirm)
  
      // Buyer Seller
      let dataBuyerSeller: any = await getFirmListApi()
      let lstTmpBuyerSeller = dataBuyerSeller.responseData.map((obj: any) => {
        return { ...obj, value: obj.id, label: obj.name }
      })
      setLstBuyyerSeller(lstTmpBuyerSeller)
  
      // Transporter List
      let dataTransport: any = await getTransportListApi()
      let lstTmpTransport = dataTransport.data.map((obj: any) => {
        return { value: obj.id, label: obj.firm_name }
      })
      setTransporterList(lstTmpTransport)
  
      //State
      let dataLocationState: any = await getAllStateList()
      let lstTmpLocationState = dataLocationState.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.name }
      })
      setLstLocationState(lstTmpLocationState)
  
      // Location
      let dataLocation: any = await getLocation()
      let lstTmpLocation = dataLocation.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.name }
      })
      setLstLocation(lstTmpLocation)
      // setLstLocation([...locationList] as any)
  
      // Product
      let dataProduct: any = await getAllProduct()
      let lstTmpProduct = dataProduct.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.product_variation }
      })
      setLstProduct(lstTmpProduct)
      setLstMasterProduct(dataProduct.responseData)
  
      // Offer
      let dataOffer: any = await getAllOffer()
      let lstTmpOffer = dataOffer.responseData.map((obj: any) => {
        console.log(obj, 'obj===')
  
        return { value: obj.id, label: obj.offer_number }
      })
      setLstOfferMaster(dataOffer.responseData)
      setLstOffer(lstTmpOffer)
  
      // SGST
      let SGST: any = await getSGST()
  
      let tmpSGST = SGST.responseData.value
      setValueSGST(tmpSGST)
  
      // IGST
      let IGST: any = await getIGST()
      console.log(IGST, 'IGST')
      let tmpIGST = IGST.responseData.value
      setValueIGST(tmpIGST)
  
      // CGST
      let CGST: any = await getCGST()
      let tmpCGST = CGST.responseData.value
      setValueCGST(tmpCGST)
  
      console.log(SGST, 'SGST')
  
      setLoadingList(false)
    }
    catch(err){
      console.log(err)
      setLoadingList(false)
    }
  }

  const selectedValueFirm = (id: string) => {
    let selected = lstFirm.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Seller
  const selectedValueStatus = (id: string) => {
    let selected = InvoiceReceivedList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Billing Location
  const selectedValueBillingLocation = (id: string) => {
    let selected = billingAddressList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Shipping Location
  const selectedValueShippingLocation = (id: string) => {
    let selected = shippingAddressList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Offer
  const selectedValueOffer = (id: string) => {
    let selected = lstOffer.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return [] as any
    }
  }

  // Product
  const selectedValueProduct = (id: string) => {
    let selected = lstProduct.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // offer master
  const selectedValueMasterOffer = (id: string) => {
    let selected = lstOfferMaster.filter((obj: any) => obj.id == id)
    console.log(lstOfferMaster, 'selectedselected')

    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  const selectedValueTransport = (id: string) => {
    let selected = transporterList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  const uploadPOFile = async (type: any, file: any) => {
    var formData = new FormData()
    formData.append(`invoice`, file)
    let filePath: any = await uploadFile(formData)
    formik.setFieldValue(type, filePath.newFilename)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        let total = 0
        lstProductDetails.map((obj: any) => {
          return (total = total + parseFloat(obj.quantity) * parseFloat(obj.rate)) * 1000
        })
        let objFinal = {
          ...values,
          tax: getSGSTValue() + getCGSTValue() + getIGSTValue(),
          cgst: getCGSTValue(),
          igst: getIGSTValue(),
          sgst: getSGSTValue(),
          total_amount: getTotalAmoutWithTex(),
          purchase_order_details: lstProductDetails,
          seller_id: values.firm_id,
          // total_amount: total * 1000 + getSGSTValue() + getCGSTValue() + getIGSTValue(),
        }

        if (id) {
          console.log(objFinal, 'values')
          const objResponse: any = await updatePurchaseNew(id, objFinal)
          console.log(objResponse, 'objResponse.responseStatus')

          if (objResponse.responseStatus == 200) {
            setSessionMessage({ type: 'success', message: objResponse.responseMessage, show: true })
            navigate('/purchase-order/purchases', {
              state: { type: 'success', message: objResponse.responseMessage, show: true },
            })
            setLoading(false)
          } else {
            setSessionMessage({ type: 'danger', message: objResponse.responseMessage, show: true })
            setLoading(false)
          }
        } else {
          const dataNew: any = await createPurchaseNew(objFinal)
          console.log(dataNew, 'dataNew')
          if (dataNew.responseStatus == 400) {
            setSessionMessage({ type: 'danger', message: dataNew.responseMessage, show: true })
            setLoading(false)
          } else if (dataNew.responseStatus == 200) {
            setSessionMessage({ type: 'success', message: dataNew.responseMessage, show: true })
            navigate('/purchase-order/purchases', {
              state: { type: 'success', message: dataNew.responseMessage, show: true },
            })
            setLoading(false)
          }
        }
      } catch (error) {
        console.error(error)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  // Get master object
  const getMasterProductObject = (id: string) => {
    let selected = lstMasterProduct.filter((obj: any) => obj.id == id)
    if (selected.length) {
      return selected[0]
    } else {
      return {}
    }
  }

  const onChangeProductDetail = (index: number, key: string, newVal: string) => {
    let lstTmp: any = [...lstProductDetails]
    if (key == 'product_id') {
      let objectMain = getMasterProductObject(newVal) as any
      lstTmp[index] = { ...lstTmp[index], [key]: newVal, rate: objectMain.price } as any
    } else {
      lstTmp[index] = { ...lstTmp[index], [key]: newVal } as any
    }
    console.log({ ...lstTmp[index], [key]: newVal }, 'lstTmp')

    setLstProductDetails(lstTmp)
  }

  const getTotalAmoutWithoutTex = () => {
    let total: any = 0

    lstProductDetails.map((obj) => {
      console.log(obj, 'obj--=-')

      let tmp =
        parseFloat(obj.rate) && parseFloat(obj.quantity)
          ? parseFloat(obj.rate) * parseFloat(obj.quantity)
          : 0
      total = total + tmp
      console.log(tmp, 'tmptmp')
    })
    return total * 1000
  }

  const getSGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let sGst = (total / 100) * valueSGST
    return isGujarat == '3749b049-6434-4e8b-8358-478251a33f8d' ? sGst : 0
  }

  const getCGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let cGst = (total / 100) * valueCGST
    return isGujarat == '3749b049-6434-4e8b-8358-478251a33f8d' ? cGst : 0
  }

  const getIGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let iGst = (total / 100) * valueIGST
    console.log(total, valueIGST, 'total,iGst')
    return isGujarat !== '3749b049-6434-4e8b-8358-478251a33f8d' ? iGst : 0
  }

  const getTotalAmoutWithTex = () => {
    let total: any =
      getTotalAmoutWithoutTex() +
      (isGujarat !== '3749b049-6434-4e8b-8358-478251a33f8d'
        ? getIGSTValue()
        : getSGSTValue() + getCGSTValue())
    // let total: any = getTotalAmoutWithoutTex() + getSGSTValue() + getCGSTValue() + getIGSTValue()
    return total.toFixed(2)
  }

  useEffect(() => {
    formik.validateForm()
  }, [])

  const dateForPicker = (dateString: any) => {
    return moment(new Date(dateString)).format('YYYY-MM-DD')
  }

  const selectedBuyerDetails = (objTmp: any, type: any, oldData: any = {}) => {

    let buyerSellerBillingAddress = objTmp?.buyerSellerPrimaryBillingInfo?.map((x: any) => {
      // return {
      //   label: x?.addressLocationInfo.name ?? "",
      //   value: x?.addressLocationInfo.id ?? "",
      //   state_id: x?.state_id ?? "",
      //   id: x?.id ?? ""
      // }
      return {
        label: x?.city ?? "",
        district: x?.addressLocationInfo.name ?? "",
        id: x?.addressLocationInfo.id ?? "",
        state_id: x?.state_id ?? "",
        value: x?.id ?? ""
      }
    }) ?? []

    let buyerSellerShippingAddress = objTmp?.buyerSellerPrimaryShippingInfo?.map((x: any) => {
      return {
        label: x?.address_line_1 ?? "",
        value: x?.id ?? "",
        address_line_1: x?.address_line_1 ?? "",
        address_line_2: x?.address_line_2 ?? "",
        district_id: x?.district_id ?? "",
        district: x?.addressLocationInfo?.name ?? "",
        state_id: x?.state_id ?? "",
        country: x?.country ?? "",
        pincode: x?.pincode ?? "",
        state: x?.addressLocationInfo?.stateInfo?.name ?? "", 
        city: x?.city ?? "",
        id: x?.id ?? ""
      }
    }) ?? []

    if (type == "edit" && oldData?.billingLocationInfo) {
      if (!buyerSellerBillingAddress.find((x: any) => x.id == oldData?.billingLocationInfo?.id && x.value == oldData?.seller_billing_address_id)) {
        // buyerSellerBillingAddress.push({ 
        //   label: oldData?.billingLocationInfo?.name, 
        //   value: oldData?.billingLocationInfo?.id, 
        //   id: oldData?.seller_billing_address_id, 
        //   state_id: oldData?.billingLocationInfo?.state_id, 
        // })
        buyerSellerBillingAddress.push({
          label: oldData?.billingAddressLocationInfo?.city ?? "",
          district: oldData?.billingLocationInfo?.name ?? "",
          id: oldData?.billingLocationInfo?.id ?? "",
          state_id: oldData?.billingLocationInfo?.state_id ?? "",
          value: oldData?.seller_billing_address_id ?? "",
        })
      }
    }
    // else if (type == "add" && oldDataById?.billingLocationInfo && id) {
    //   if (objTmp?.id == oldDataById?.buyerSellerInfo?.id && !buyerSellerBillingAddress.find((x: any) => x.value == oldDataById?.billingLocationInfo?.id)) {
    //     buyerSellerBillingAddress.push({ 
    //       label: oldDataById?.billingLocationInfo?.name, 
    //       value: oldDataById?.billingLocationInfo?.id, 
    //       id: oldDataById?.seller_billing_address_id, 
    //       state_id: oldDataById?.billingLocationInfo?.state_id, 
    //     })
    //   }
    // }

    if (type == "edit" && oldData?.shippingLocationInfo) {
      if (!buyerSellerShippingAddress.find((x: any) => x.value == oldData?.seller_shipping_address_id)) {
        buyerSellerShippingAddress.push({
          label: oldData?.shipping_address?.address_line_1 ?? "",
          value: oldData?.seller_shipping_address_id ?? "",
          address_line_1: oldData?.shipping_address?.address_line_1 ?? "",
          address_line_2: oldData?.shipping_address?.address_line_2 ?? "",
          district_id: oldData?.shippingLocationInfo?.district_id ?? "",
          district: oldData?.shippingLocationInfo?.name ?? "",
          state_id: oldData?.shippingLocationInfo?.state_id ?? "",
          country: oldData?.shipping_address?.country ?? "India",
          pincode: oldData?.shipping_address?.pincode ?? "",
          state: oldData?.shippingLocationInfo?.state_id ?? "", 
          city: oldData?.shipping_address?.city ?? "",
          id: oldData?.seller_shipping_address_id ?? ""
        })
      }
    }
    else if (type == "add" && oldDataById?.shippingLocationInfo && id) {
      if (objTmp?.id == oldDataById?.buyerSellerInfo?.id && !buyerSellerShippingAddress.find((x: any) => x.value == oldDataById?.seller_shipping_address_id)) {
        buyerSellerShippingAddress.push({
          label: oldDataById?.shipping_address?.address_line_1 ?? "",
          value: oldDataById?.shippingAddressLocationInfo?.id ?? "",
          address_line_1: oldDataById?.shipping_address?.address_line_1 ?? "",
          address_line_2: oldDataById?.shipping_address?.address_line_2 ?? "",
          district_id: oldDataById?.shippingLocationInfo?.district_id ?? "",
          district: oldDataById?.shippingLocationInfo?.name ?? "",
          state_id: oldDataById?.shippingLocationInfo?.state_id ?? "",
          country: oldDataById?.shipping_address?.country ?? "India",
          pincode: oldDataById?.shipping_address?.pincode ?? "",
          state: oldDataById?.shippingLocationInfo?.state_id ?? "", 
          city: oldDataById?.shippingAddressLocationInfo?.city ?? "",
          id: oldDataById?.shippingAddressLocationInfo?.id ?? ""
        })
      }
    }

    setBillingAddressList(buyerSellerBillingAddress)
    setShippingAddressList(buyerSellerShippingAddress)
  }

  return (
    <>
      <FormikProvider value={formik}>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {loadingGetData || loadingList ? (
            <div
              style={{
                height: '550px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner animation='border' />
            </div>
          ) : (
            <>
              <Row className='mb-5'>
                {id && (
                  <Col xl={4}>
                    <Form.Group className='mb-5'>
                      <Form.Label className='fw-bold form-label'>Purchase Order Number</Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-solid'
                        placeholder='Purchase Order Number'
                        value={formik.values.purchase_order_number}
                        readOnly
                      />
                      {formik.touched.purchase_order_number && formik.errors.purchase_order_number && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className={'text-danger'}>
                            {formik.errors.purchase_order_number}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                )}
              </Row>

              <Row className='mb-5'>
                <Col xl={4}>
                  {/* purvik */}
                  <Form.Group className='mb-5'>
                    <Form.Label className='required form-label fw-bold'> Date </Form.Label>
                    <Form.Control
                      type='date'
                      className='form-control-solid'
                      placeholder='Date'
                      value={formik.values.date ? dateForPicker(formik.values.date) : ''}
                      onChange={(e: any) => {
                        console.log(e.target.value, 'eee')
                        formik.setFieldValue('date', e.target.value)
                      }}
                    />
                    {formik.touched.date && formik.errors.date && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.date}
                        </span>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col xl={4}>
                  <Form.Group>
                    <Form.Label className='fw-bold required form-label'> Offer Id </Form.Label>
                    <Select
                      options={lstOffer}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Offer Id'
                      value={selectedValueOffer(formik.values.offer_id)}
                      onChange={(e: any) => {
                        let objSelected: any = selectedValueMasterOffer(e.value)
                        console.log(objSelected, 'objSelected')
                        formik.setFieldValue('offer_id', e.value)
                        formik.setFieldValue('firm_id', objSelected.firm_id)
                        let objTmp: any = selectedValueFirm(objSelected.firm_id)
                        setLstProductDetails([
                          {
                            product_id: objSelected.product_id,
                            quantity: objSelected.quantity,
                            rate: objSelected.price,
                          },
                        ])
                        selectedBuyerDetails(lstBuyyerSeller.find((x: any) => x.value == objSelected.firm_id), "add")
                        formik.setFieldValue('shipping_address.address_line_1', '')
                        formik.setFieldValue('shipping_address.address_line_2', '')
                        formik.setFieldValue('shipping_address.country', '')
                        formik.setFieldValue(`shipping_address.state_id`, '')
                        formik.setFieldValue(`shipping_address.state`, '')
                        formik.setFieldValue(`shipping_address.district_id`, '')
                        formik.setFieldValue(`shipping_address.district`, '')
                        formik.setFieldValue('shipping_address.city', '')
                        formik.setFieldValue('shipping_address.pincode', '')
                        formik.setFieldValue('billing_location_id', '')
                        formik.setFieldValue('seller_billing_address_id', '')
                        formik.setFieldValue('seller_shipping_address_id', '')
                        formik.setFieldValue('source_id', '')
                      }}
                    />
                    {formik.touched.offer_id && formik.errors.offer_id && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.offer_id}
                        </span>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col xl={4}>
                  <Form.Group className='mb-5'>
                    <Form.Label className='form-label fw-bold'> Reference </Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-solid'
                      placeholder='Reference'
                      value={formik.values.reference}
                      onChange={(e: any) => {
                        formik.setFieldValue('reference', e.target.value)
                      }}
                    />
                  </Form.Group>
                </Col>

              </Row>
              <Row className='mb-5'>
                <Col xl={4}>
                  <Form.Group>
                    <Form.Label className='fw-bold required form-label'>Firm Name</Form.Label>
                    <Select
                      options={lstFirm}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Firm Name'
                      value={selectedValueFirm(formik.values.firm_id)}
                      onChange={(e: any) => {
                        formik.setFieldValue('firm_id', e.value)
                        selectedBuyerDetails(lstBuyyerSeller.find((x: any) => x.value == e.value), "add")
                        formik.setFieldValue('shipping_address.address_line_1', '')
                        formik.setFieldValue('shipping_address.address_line_2', '')
                        formik.setFieldValue('shipping_address.country', '')
                        formik.setFieldValue(`shipping_address.state_id`, '')
                        formik.setFieldValue(`shipping_address.state`, '')
                        formik.setFieldValue(`shipping_address.district_id`, '')
                        formik.setFieldValue(`shipping_address.district`, '')
                        formik.setFieldValue('shipping_address.city', '')
                        formik.setFieldValue('shipping_address.pincode', '')
                      }}
                      isDisabled={true}
                    />
                    {formik.touched.firm_id && formik.errors.firm_id && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.firm_id}
                        </span>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col xl={4} className={'mb-4'}>
                  <Form.Group>
                    <Form.Label className='required fw-bold form-label'> Billing City </Form.Label>
                    <Select
                      options={billingAddressList}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Billing City'
                      value={selectedValueBillingLocation(formik.values.seller_billing_address_id)}
                      onChange={(e: any) => {
                        formik.setFieldValue('billing_location_id', e.id)
                        formik.setFieldValue('seller_billing_address_id', e.value)
                        setIsGujarat(e.state_id)
                      }}
                    />
                    {formik.touched.billing_location_id && formik.errors.billing_location_id && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.billing_location_id}
                        </span>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col xl={4} className={'mb-4'}>
                  <Form.Group>
                    <Form.Label className='required fw-bold form-label'> Status </Form.Label>
                    <Select
                      options={InvoiceReceivedList}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Status'
                      value={selectedValueStatus(formik.values.purchase_order_status)}
                      onChange={(e: any) => {
                        formik.setFieldValue('purchase_order_status', e.value)
                      }}
                    />
                    {formik.touched.purchase_order_status && formik.errors.purchase_order_status && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.purchase_order_status}
                        </span>
                      </div>
                    )}
                  </Form.Group>
                </Col>

              </Row>
              {formik.values.purchase_order_status == '4' as any && (
                <Row>
                  <Col xl={4}>
                    <Form.Label className='required form-label'> Select Transporter </Form.Label>
                    <Select
                      options={transporterList}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Select Transporter'
                      value={selectedValueTransport(formik.values.transport_id)}
                      onChange={(e: any) => {
                        formik.setFieldValue('transport_id', e.value)
                      }}
                    />
                    {formik.touched.transport_id && formik.errors.transport_id && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.transport_id}
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xl={4}>
                    <Form.Group className='mb-3'>
                      <Form.Label className='required form-label'> Driver Name </Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-solid'
                        placeholder='Driver Name'
                        value={formik.values.driver_name}
                        onChange={(e: any) => {
                          formik.setFieldValue('driver_name', e.target.value)
                        }}
                      />
                      {formik.touched.driver_name && formik.errors.driver_name && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className={'text-danger'}>
                            {formik.errors.driver_name}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xl={4}>
                    <Form.Group className='mb-3'>
                      <Col className='d-flex justify-content-between'>
                        <Form.Label className='required form-label'>Contact Number</Form.Label>
                      </Col>
                      <Form.Control
                        type='text'
                        className='required form-control-solid'
                        placeholder='Contact Number'
                        value={formik.values.mobile_number}
                        onChange={(e: any) => {
                          formik.setFieldValue('mobile_number', e.target.value)
                        }}
                      />
                      {formik.touched.mobile_number && formik.errors.mobile_number && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className={'text-danger'}>
                            {formik.errors.mobile_number}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xl={4}>
                    <Form.Group className='mt-3'>
                      <Col className='d-flex justify-content-between'>
                        <Form.Label className='required form-label'>Vehicle Number</Form.Label>
                      </Col>
                      <Form.Control
                        type='text'
                        className='required form-control-solid'
                        placeholder='Vehicle Number'
                        value={formik.values.vehicle_number}
                        onChange={(e: any) => {
                          formik.setFieldValue('vehicle_number', e.target.value)
                        }}
                      />
                      {formik.touched.vehicle_number && formik.errors.vehicle_number && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className={'text-danger'}>
                            {formik.errors.vehicle_number}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {formik?.values?.purchase_order_status == '5' as any ?
                <Row className='mb-5'>
                  <Col className='text-start' md={4}>
                    <Form.Label className='required form-label text'>
                      Upload E Way Bill{' '}
                    </Form.Label>
                    <input
                      type={'file'}
                      id='file-input-1'
                      accept='application/pdf, application/vnd.ms-excel'
                      style={{ display: 'none' }}
                      // {...getInputProps()}
                      onChange={(e) => {
                        if (e.target.files) {
                          uploadPOFile("eway_bill_file", e.target.files[0])
                        }
                      }}
                    />
                    <label htmlFor='file-input-1'>
                      <div>
                        <div {...getRootProps({ className: 'dropzone py-12' })}>
                          <div className='dz-message needsclick'>
                            <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                              <KTSVG
                                path='/media/icons/duotune/files/fil009.svg'
                                className='svg-icon-muted svg-icon-2hx'
                              />
                            </span>
                            <div className='ms-4'>
                              <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                                Drag/Drop files here or click here
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                    {formik.touched.eway_bill_file && formik.errors.eway_bill_file && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.eway_bill_file}
                        </span>
                      </div>
                    )}
                    <Form.Label className='form-label text'>
                      {formik?.values?.eway_bill_file ?
                        <a href={`${process.env.REACT_APP_API_URL_NEW}/downloads/download?folder_name=invoice&image=${formik?.values?.eway_bill_file}`}
                          download
                          target="_blank"
                        >
                          {formik?.values?.eway_bill_file}
                        </a>
                        : ""}
                    </Form.Label>
                  </Col>
                  <Col className='text-start' md={4}>
                    <Form.Label className='required form-label text'>
                      Upload Invoice{' '}
                    </Form.Label>
                    <input
                      type={'file'}
                      id='file-input-2'
                      accept='application/pdf, application/vnd.ms-excel'
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        if (e.target.files) {
                          uploadPOFile("invoice_file", e.target.files[0])
                        }
                      }}
                    />
                    <label htmlFor='file-input-2'>
                      <div>
                        <div {...getRootProps({ className: 'dropzone py-12' })}>
                          <div className='dz-message needsclick'>
                            <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                              <KTSVG
                                path='/media/icons/duotune/files/fil009.svg'
                                className='svg-icon-muted svg-icon-2hx'
                              />
                            </span>
                            <div className='ms-4'>
                              <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                                Drag/Drop files here or click here
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                    {formik.touched.invoice_file && formik.errors.invoice_file && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.invoice_file}
                        </span>
                      </div>
                    )}
                    <Form.Label className='form-label text'>
                      {formik?.values?.invoice_file ?
                        <a href={`${process.env.REACT_APP_API_URL_NEW}/downloads/download?folder_name=invoice&image=${formik?.values?.invoice_file}`}
                          download
                          target="_blank"
                        >
                          {formik?.values?.invoice_file}
                        </a>
                        : ""}
                    </Form.Label>
                  </Col>
                </Row> : ""}

              <Row className='mt-10'>
                <h2 className=''> Shipping Address </h2>
              </Row>
              <Row className='mt-10 mb-6'>
                <Col xl={4}>
                  <Form.Label className='required form-label'> Address Line 1</Form.Label>
                  <Select
                    options={shippingAddressList}
                    className='custom_select'
                    placeholder='Select Shipping Address'
                    value={selectedValueShippingLocation(formik.values.seller_shipping_address_id)}
                    onChange={(e: any) => {
                      formik.setFieldValue('seller_shipping_address_id', e.value)
                      formik.setFieldValue('shipping_address.address_line_1', e.address_line_1)
                      formik.setFieldValue('shipping_address.address_line_2', e.address_line_2)
                      formik.setFieldValue('shipping_address.country', e.country)
                      formik.setFieldValue(`shipping_address.state_id`, e.state_id)
                      formik.setFieldValue(`shipping_address.state`, e.state)
                      formik.setFieldValue('source_id', e.district_id)
                      formik.setFieldValue(`shipping_address.district_id`, e.district_id)
                      formik.setFieldValue(`shipping_address.district`, e.district)
                      formik.setFieldValue('shipping_address.city', e.city)
                      formik.setFieldValue('shipping_address.pincode', e.pincode)
                    }}
                  />
                  {formik.touched.shipping_address?.address_line_1 &&
                    formik.errors.shipping_address?.address_line_1 && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.shipping_address?.address_line_1}
                        </span>
                      </div>
                    )}
                </Col>
                <Col xl={4}>
                  <Form.Label className='form-label'> Address Line 2</Form.Label>
                  <Form.Control
                    type='text'
                    className='form-control-solid mb-3'
                    placeholder='Shipping Address'
                    value={formik.values.shipping_address?.address_line_2}
                    onChange={(e: any) => {
                      formik.setFieldValue('shipping_address.address_line_2', e.target.value)
                    }}
                    disabled
                  />
                </Col>
                <Col xl={4}>
                  <Form.Label className='form-label fw-bold'> Country </Form.Label>
                  <Form.Control
                    type='text'
                    className='form-control-solid'
                    placeholder='India'
                    disabled={true}
                    value={formik.values.shipping_address?.country}
                    onChange={(e: any) => {
                      formik.setFieldValue('shipping_address.country', e.target.value)
                    }}
                  />
                  {formik.touched.shipping_address?.country && formik.errors.shipping_address?.country && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.shipping_address?.country}
                      </span>
                    </div>
                  )}
                </Col>
              </Row>

              <Row className='mb-10'>

                <Col xl={4}>
                  <Row>
                    <Col xl={6}>
                      <Form.Label className='form-label'> State </Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-solid'
                        placeholder='State'
                        disabled={true}
                        value={formik.values.shipping_address?.state}
                      />
                      {formik.touched.shipping_address?.state && formik.errors.shipping_address?.state && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className={'text-danger'}>
                            {formik.errors.shipping_address?.state}
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xl={6}>
                      <Form.Label className='required form-label'> District </Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-solid'
                        placeholder='District'
                        disabled={true}
                        value={formik.values.shipping_address?.district}
                      />
                      {formik.touched.shipping_address?.district &&
                        formik.errors.shipping_address?.district && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className={'text-danger'}>
                              {formik.errors.shipping_address?.district}
                            </span>
                          </div>
                        )}
                    </Col>
                  </Row>
                </Col>

                <Col xl={4}>
                  <Row>
                    <Col xl={6}>

                      <Form.Label className='required form-label fw-bold'> City </Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-solid'
                        placeholder='City'
                        value={formik.values.shipping_address?.city}
                        disabled={true}
                      />
                      {formik.touched.shipping_address?.city && formik.errors.shipping_address?.city && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className={'text-danger'}>
                            {formik.errors.shipping_address?.city}
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col xl={6}>
                      <Form.Label className='required form-label'> Pincode </Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-solid me-3'
                        placeholder='Pincode'
                        value={formik.values.shipping_address?.pincode}
                        disabled={true}
                      />
                      {formik.touched.shipping_address?.pincode &&
                        formik.errors.shipping_address?.pincode && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className={'text-danger'}>
                              {formik.errors.shipping_address?.pincode}
                            </span>
                          </div>
                        )}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Table responsive className='quot-table'>
                  <thead>
                    <tr>
                      <th style={{ width: '12%' }}>Sr. No.</th>
                      <th>
                        Product Name <span className='text-danger fs-5'> * </span>
                      </th>
                      <th>
                        {' '}
                        HSN Code <span className='text-danger fs-5'> * </span>{' '}
                      </th>
                      <th style={{ width: '16%' }}>
                        Qty(MT) <span className='text-danger fs-5'> * </span>{' '}
                      </th>
                      <th style={{ width: '14%' }}>
                        Rate/Kg <span className='text-danger fs-5'> * </span>{' '}
                      </th>
                      <th style={{ width: '16%' }}>Amount </th>
                      <th style={{ width: '4%' }}> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lstProductDetails.map((objProduct, index) => {
                      {
                        console.log(objProduct, 'objProduct')
                      }
                      let objMain = getMasterProductObject(objProduct.product_id) as any
                      return (
                        <tr id='row1'>
                          <td>{index + 1}</td>
                          <td>
                            <Form.Group>
                              <Select
                                options={lstProduct}
                                className='custom_select'
                                classNamePrefix='Select'
                                placeholder='Select Product'
                                value={selectedValueProduct(objProduct.product_id)}
                                onChange={(e: any) => {
                                  onChangeProductDetail(index, 'product_id', e.value)
                                }}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Control
                              type='text'
                              className='form-control-solid'
                              placeholder='HSN Code'
                              value={objMain.hsn}
                            />
                          </td>
                          <td>
                            <Form.Group className=''>
                              <Form.Control
                                type='text'
                                className='form-control-solid'
                                placeholder='Qty (MT)'
                                value={objProduct.quantity}
                                onChange={(e: any) => {
                                  let decimalPattern = /^\d+(\.\d{0,3})?$/;
                                  if(decimalPattern.test(e.target.value)){
                                    let n = e.target.value
                                    onChangeProductDetail(index, 'quantity', n)
                                  }else if(e.target.value == ""){
                                    onChangeProductDetail(index, 'quantity', e.target.value)
                                  }
                                  // onChangeProductDetail(index, 'quantity', e.target.value)
                                }}
                              />
                            </Form.Group>
                          </td>

                          <td>
                            <Form.Group className=''>
                              <Form.Control
                                type='text'
                                className='form-control-solid'
                                placeholder='Rate/Kg'
                                value={objProduct.rate}
                                onChange={(e: any) => {
                                  onChangeProductDetail(index, 'rate', e.target.value)
                                }}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group className=''>
                              <Form.Control
                                type='text'
                                className='form-control-solid'
                                placeholder='Amount'
                                value={
                                  parseFloat(objProduct.rate) && parseFloat(objProduct.quantity)
                                    ? (parseFloat(objProduct.rate) *
                                      parseFloat(objProduct.quantity) *
                                      1000
                                    ).toFixed(2)
                                    : 0
                                }
                              />
                            </Form.Group>
                          </td>
                          <td className='text-center align-items-center'>
                            <div>
                              {lstProductDetails.length > 1 &&
                                lstProductDetails.length - 1 != index ? (
                                <a
                                  onClick={() => {
                                    let lstTmp = lstProductDetails.filter((obj, i) => i != index)
                                    setLstProductDetails(lstTmp)
                                  }}
                                  className='btn btn-sm btn-icon btn-color-danger'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen036.svg'
                                    className='svg-icon-muted svg-icon-2hx'
                                  />
                                </a>
                              ) : (
                                <a
                                  // href='#'
                                  onClick={() => {
                                    setLstProductDetails([
                                      ...lstProductDetails,
                                      {
                                        quantity: '',
                                        rate: '',
                                        product_id: '',
                                      },
                                    ])
                                  }}
                                  className='btn btn-sm btn-icon  btn-color-success'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen035.svg'
                                    className='svg-icon-muted svg-icon-2hx me-2'
                                  />
                                </a>
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    })}

                    <tr>
                      <td> Total </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                    </tr>
                    <tr>
                      <td rowSpan={7} colSpan={4}>
                        {' '}
                        &nbsp;{' '}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}> Amount (Without Tax) </td>
                      <td colSpan={2}>
                        {' '}
                        <Form.Group className=''>
                          <Form.Control
                            type='text'
                            className='required form-control-solid'
                            value={getTotalAmoutWithoutTex().toFixed(2)}
                            placeholder='Amount'
                          />
                        </Form.Group>{' '}
                      </td>
                    </tr>

                    {isGujarat !== '3749b049-6434-4e8b-8358-478251a33f8d' ? (
                      <tr>
                        <td style={{ verticalAlign: 'middle' }}> IGST </td>
                        <td colSpan={2}>
                          {' '}
                          <Form.Group className=''>
                            <Form.Control
                              type='text'
                              className='required form-control-solid'
                              placeholder='IGST'
                              value={getIGSTValue().toFixed(2)}
                            />
                          </Form.Group>{' '}
                        </td>
                      </tr>
                    ) : (
                      <>
                        <tr>
                          <td style={{ verticalAlign: 'middle' }}> CGST </td>
                          <td colSpan={2}>
                            {' '}
                            <Form.Group className=''>
                              <Form.Control
                                type='text'
                                className='required form-control-solid'
                                placeholder='CGST'
                                value={getCGSTValue().toFixed(2)}
                              />
                            </Form.Group>{' '}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: 'middle' }}> SGST </td>
                          <td colSpan={2}>
                            {' '}
                            <Form.Group className=''>
                              <Form.Control
                                type='text'
                                className='required form-control-solid'
                                placeholder='SGST'
                                value={getSGSTValue().toFixed(2)}
                              />
                            </Form.Group>{' '}
                          </td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td style={{ verticalAlign: 'middle' }}> Total Amount (With Tax) </td>
                      <td colSpan={2}>
                        {' '}
                        <Form.Group className=''>
                          <Form.Control
                            type='text'
                            className='required form-control-solid'
                            placeholder='Total Amount'
                            value={getTotalAmoutWithTex()}
                          />
                        </Form.Group>{' '}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>

              <div className='d-grid mb-10'>
                <button type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
                  {!loading && <span className='indicator-label'>{id ? 'Edit' : 'Add'}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </>
          )}
          <ToastContainer className='p-3' position={'top-end'}>
            <Toast
              show={sessionMessage.show}
              onClose={() => {
                setSessionMessage({
                  type: '',
                  message: '',
                  show: false,
                })
              }}
              bg='danger'
            >
              <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
            </Toast>
          </ToastContainer>
        </form>
      </FormikProvider>
    </>
  )
}

export default AddEditPurchaseOrder