import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
// import { ID, Response } from '../../../../../_metronic/helpers'
import {Cate, CatesQueryResponse} from './_models'
import {SERVER_URL} from '../../../../../ServerConfig'

const API_URL = SERVER_URL
const CATE_URL = `${API_URL}/cate`
const CATEGORY_URL = `${API_URL}/news-category`
const GET_CATES_URL = `${API_URL}/users/query`

const getCate = (query: string): Promise<any> => {
  return axios
    .get(`${CATEGORY_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getCateById = (id: ID): Promise<Cate | undefined> => {
  return axios
    .get(`${CATE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Cate>>) => response.data)
    .then((response: Response<Cate>) => response.data)
}

const createCate = (cate: Cate): Promise<Cate | undefined> => {
  return axios
    .put(CATE_URL, cate)
    .then((response: AxiosResponse<Response<Cate>>) => response.data)
    .then((response: Response<Cate>) => response.data)
}

const updateCate = (cate: Cate): Promise<Cate | undefined> => {
  return axios
    .post(`${CATE_URL}/${cate.id}`, cate)
    .then((response: AxiosResponse<Response<Cate>>) => response.data)
    .then((response: Response<Cate>) => response.data)
}

const deleteCate = (cateId: ID): Promise<void> => {
  return axios.delete(`${CATE_URL}/${cateId}`).then(() => {})
}

const deleteSelectedCates = (cateIds: Array<ID>): Promise<void> => {
  const requests = cateIds.map((id) => axios.delete(`${CATE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const createCategoryApi = (data: any): Promise<any> => {
  return axios
    .post(`${CATEGORY_URL}`,data)
    .then((d: AxiosResponse<any>) => d.data)
}

export {getCate, deleteCate, deleteSelectedCates, getCateById, createCate, updateCate, createCategoryApi}
