/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import AddEditGradeGroup from '../AddEditGradeGroup'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const EditGradeGroupManagementModal = ({ show, handleClose }: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [isShowNameError, setIsShowNameError] = useState(false)
  const [isShowSourceFild, setIsShowSourceFild] = useState(false)
  return createPortal(
    <Modal
      id='kt_modal_create_user'
      tabIndex={-1}
      aria-hidden='true'
      // dialogClassName='modal-dialog modal-dialog-centered mw-650px modalheight-scrlbr modal fade show d-block'
      dialogClassName='modal-dialog modal-dialog-centered modalheight-scrlbr modal fade show d-block'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-header'>
        <h2 className='fw-bolder'>Edit Grade Group</h2>
        <div className='btn btn-icon btn-sm btn-active-icon-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body  mx-5 mx-xl-15 my-7'>
        <div ref={stepperRef}>
          <AddEditGradeGroup handleClose={handleClose} />
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export default EditGradeGroupManagementModal
