import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../_metronic/helpers'
import {SERVER_URL} from '../../ServerConfig'

const API_URL = SERVER_URL
const UPDATE_SETTING_URL = `${API_URL}/setting`
const GET_SETTING_URL = `${API_URL}/setting?perPage=100&page=1`
const ADD_GRAPH_URL = `${API_URL}/graph/import`
const GRAPH_DOWNLOAD_URL = `${API_URL}/graph/download`
const DOWNLOAD_MEDIA_URL = `${API_URL}/downloads/download?folder_name=graph&image=`


const getSettingApi = (): Promise<any | undefined> => {
  return axios
    .get(`${GET_SETTING_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const updateSettingApi = (id:any,data:any): Promise<any | undefined> => {
  return axios
    .patch(`${UPDATE_SETTING_URL}/${id}`,data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const addGraphApi = (data:any): Promise<any | undefined> => {
  return axios
    .post(`${ADD_GRAPH_URL}`,data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const downloadGraphApi = (): Promise<any | undefined> => {
  return axios
    .get(`${GRAPH_DOWNLOAD_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const downloadMediaApi = (data:any) => {
  return `${DOWNLOAD_MEDIA_URL}${data}`
}

export {getSettingApi,updateSettingApi,addGraphApi, downloadGraphApi, downloadMediaApi}
