import React, { useEffect, useState } from 'react'
import {Col, Row} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { downloadMediaApi, getProductByIdApi } from './products-list/core/_requests'
import axios from 'axios'

const ViewProduct = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const [isLoading,setIsLoading] = useState(false)
  const [checkValidUrl,setCheckValidUrl] = useState<any>(false)
  const [viewData,setViewData] = useState<any>({
    product_variation: '-',
    hsn: '-',
    price: '-',
    grade_group_id: '-',
    product_group_id: '-',
    manufactured_by: '-',
    sector: '-',
    mfi_kvalue: '-',
    density: '-',
    special_characteristics: '-',
    application: '-',
    place_of_origin: '-',
    remarks: '-'
  })
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  const navigateToProductListing = () => {
    // 👇️ navigate to /contacts
    navigate('/product-management/products')
  }

  const navigateAddProduct = () => {
    // 👇️ navigate to /
    navigate('/addproduct')
  }

  const checkValue = (data:any) => {
    if(!data || data == ""){
      return "-"
    }
    else{
      return data
    }
  }

  const wizardsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Buyer Management',
      path: '/crafted/pages/wizards/horizontal',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const getProduct = async() => {
    try{
      setIsLoading(true)
      let {responseData, responseStatus, responseMessage} = await getProductByIdApi(id)
      if(responseStatus == 200){
        setViewData(responseData)
        setIsLoading(false)
      }
      else{
        setSessionMessage({type: 'danger',message: responseMessage ,show: true})
        setIsLoading(false)
      }
    }
    catch(err:any){
      setIsLoading(false)
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setSessionMessage({type: 'danger',message: message ,show: true})
      setTimeout(()=>{
        navigateToProductListing()
      },2000)
    }
  }

  useEffect(()=>{
    getProduct()
  },[])

  useEffect(()=>{
    setTimeout(()=>{
      setSessionMessage({type: '',message: '',show: false})
    },3000)
  },[sessionMessage.show])

  const checkPdf = () => {
    axios.get(downloadMediaApi(viewData.product_brochure)).then((data:any)=>{
      setCheckValidUrl(true)
    }).catch((error:any)=>{
      setCheckValidUrl(false)
    })
  }

  useEffect(()=>{
    checkPdf()
  },[viewData])

  return (
    <>
      <PageTitle>Product Detail</PageTitle>
      <Col className='position-absolute me-10 end-0 mt-n20 '>
        <button
          type='button'
          onClick={navigateToProductListing}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
      </Col>

      <Col className='card card-body backgroung-transparent p-12 mt-10 p-12'>
        <Col xl={6} className=''>
          <Row className='d-flex justify-content-between'>
            <h2 className='fw-bolder mb-12'> Product Information </h2>
          </Row>
          <Row className='mb-3'>
            <Col xl={6} className='align-items-center pt-3 pb-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>Product Name</Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                {' '}
                {checkValue(viewData?.product_variation)}
                {' '}
              </Col>
            </Col>
            <Col xl={6} className='align-items-center pt-3 pb-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>HSN Code</Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                {' '}
                {checkValue(viewData?.hsn)}
                {' '}
              </Col>
            </Col>
          </Row>

          <Row className='mb-3'></Row>

          <Row className='mb-6'>
            <Col xl={4} className='align-items-center pt-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>Price/Kg</Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                {checkValue(viewData?.price) != "-" ? "Rs." + checkValue(viewData?.price):"-"} </Col>
            </Col>

            <Col xl={4} className='align-items-center pt-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>Grade Group</Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>{checkValue(viewData?.gradeGroupInfo?.name)} </Col>
            </Col>

            <Col xl={4} className='align-items-center pt-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>Product Group</Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>{checkValue(viewData?.productGroupInfo?.name)}</Col>
            </Col>
          </Row>

          <Row className='mb-6'>
            <Col xl={4} className='align-items-center pt-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>
                Manufactured By
              </Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>{checkValue(viewData?.manufactured_by)}</Col>
            </Col>
            <Col xl={6} className='align-items-center pt-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>Sector</Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'> {checkValue(viewData?.sector)} </Col>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col xl={4} className='align-items-center pt-3 pb-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>MFI</Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>{checkValue(viewData?.mfi_kvalue)}</Col>
            </Col>
            <Col xl={4} className='align-items-center pt-3 pb-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>Density</Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'>
                {' '}
                {checkValue(viewData?.density) != "-" ? checkValue(viewData?.density) + " Density" : "-"}{' '}
              </Col>
            </Col>
            <Col xl={4} className='align-items-center pt-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>
                Special Characteristics
              </Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'> {checkValue(viewData?.special_characteristics)} </Col>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col xl={4} className='align-items-center pt-3 '>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>Application</Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'> {checkValue(viewData?.application)} </Col>
            </Col>
            <Col xl={4} className='align-items-center pt-3 pb-3'>
              <Col className='fs-5 fw-bolder text-gray-600  text-hover-primary'>
                Place Of Origin
              </Col>
              <Col className='fw-bolder text-dark fs-5 mt-1 text-hover-primary'> 
              {viewData.place_of_origin == "2" ? "Domestic" : viewData.place_of_origin == "1" ? "International" : "-"} 
              </Col>
            </Col>
          </Row>
          <Row>
            <Col xl={6} className=' align-items-center pt-3 pb-3'>
              <Col className='fs-5 fw-bolder d-flex text-dark text-hover-primary'>
                <span> </span>
                <h4 className=' text-gray-600 mb-2 pt-1 text-hover-primary'>Remarks</h4>
              </Col>
              <h4 className='fw-bolded text-dark-800 fs-4 text-hover-primary'>
                {checkValue(viewData?.remarks)}
              </h4>
            </Col>
            {viewData && viewData != "" ? 
            <Col xl={6} className=' align-items-center pt-3 pb-3'>
              <Col className='fs-5 fw-bolder d-flex text-dark text-hover-primary'>
                <span> </span>
                <h4 className=' text-gray-600 mb-2 pt-1 text-hover-primary'>Product Brochure</h4>
              </Col>
              <h4 className='fw-bolded text-dark-800 fs-4 text-hover-primary'>
                {checkValidUrl ? 
                <a href={downloadMediaApi(viewData.product_brochure)} target="_black">View Pdf</a>:
                <a href="#" onClick={(e:any)=>e.preventDefault()}>File Not Found</a>}
              </h4>
            </Col>:""}
          </Row>
        </Col>
      </Col>
    </>
  )
}

export default ViewProduct
