import {useEffect} from 'react'
import { Col } from 'react-bootstrap'
import { StateEditModalFormWrapper } from './StateEditModalFormWrapper'
import { StateEditModalHeader } from './StateEditModalHeader'
//  import {StateEditModalHeader, SufalamEditModalHeader} from './StateEditModalHeader'
// import {SufalamEditModalFormWrapper} from './StateEditModalFormWrapper'

const StateEditModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <StateEditModalHeader />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5  my-7 '>
            <h6>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy
      </h6>
      <Col className='me-5  mt-20 text-end border-light-dark border-top'>
        <button type='button' value='' className='btn btn-sm  me-5 btn btn-danger'>
          Delete
        </button>
      </Col>
              <StateEditModalFormWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {StateEditModal}
