import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
// import { ID, Response } from '../../../../../_metronic/helpers'
import {Article, ArticlesQueryResponse} from './_models'
import {SERVER_URL} from '../../../../../ServerConfig'

// const API_URL = process.env.REACT_APP_THEME_API_URL
const API_URL = SERVER_URL
const ARTICLE_URL = `${API_URL}/article`
const GET_ARTICLES_URL = `${API_URL}/users/query`
const ARTICLE_URL_API = `${API_URL}/news-content`
const UPLOAD_MEDIA_URL = `${API_URL}/uploads/upload`
const DOWNLOAD_MEDIA_URL = `${API_URL}/downloads/download?folder_name=news&image=`

const getArticle = (query: string): Promise<ArticlesQueryResponse> => {
  return axios
    .get(`${ARTICLE_URL_API}?${query}`)
    .then((d: AxiosResponse<ArticlesQueryResponse>) => d.data)
}

const getArticleById = (id: ID): Promise<Article | undefined> => {
  return axios
    .get(`${ARTICLE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Article>>) => response.data)
    .then((response: Response<Article>) => response.data)
}

const createArticle = (article: Article): Promise<Article | undefined> => {
  return axios
    .put(ARTICLE_URL, article)
    .then((response: AxiosResponse<Response<Article>>) => response.data)
    .then((response: Response<Article>) => response.data)
}

const updateArticle = (article: Article): Promise<Article | undefined> => {
  return axios
    .post(`${ARTICLE_URL}/${article.id}`, article)
    .then((response: AxiosResponse<Response<Article>>) => response.data)
    .then((response: Response<Article>) => response.data)
}

const deleteArticle = (articleId: ID): Promise<void> => {
  return axios.delete(`${ARTICLE_URL}/${articleId}`).then(() => {})
}

const deleteSelectedArticles = (articleIds: Array<ID>): Promise<void> => {
  const requests = articleIds.map((id) => axios.delete(`${ARTICLE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const createArticleApi = (article: any): Promise<any> => {
  return axios
    .post(ARTICLE_URL_API, article)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const editArticleApi = (id: any): Promise<any> => {
  return axios
    .get(`${ARTICLE_URL_API}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const updateArticleApi = (id: any,data: any): Promise<any> => {
  return axios
    .patch(`${ARTICLE_URL_API}/${id}`,data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const uploadMedia = (data: any): Promise<any> => {
  return axios
    .post(`${UPLOAD_MEDIA_URL}`,data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const downloadMediaApi = (data:any) => {
  return `${DOWNLOAD_MEDIA_URL}${data}`
}

const deleteArticleApi = (id: any): Promise<any> => {
  return axios
    .delete(`${ARTICLE_URL_API}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

export {getArticle, deleteArticle, deleteSelectedArticles, getArticleById, createArticle, updateArticle, createArticleApi, editArticleApi, updateArticleApi, downloadMediaApi, uploadMedia, deleteArticleApi}
