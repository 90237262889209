// @ts-nocheck
import {Column} from 'react-table'
import {StateInfoCell} from './StateInfoCell'
import {StateLastLoginCell} from './StateLastLoginCell'
import {StateTwoStepsCell} from './StateTwoStepsCell'
import {StateActionsCell} from './StateActionsCell'
// import {StateSelectionCell} from './StateSelectionCell'
import {StateCustomHeader} from './StateCustomHeader'
// import {StateSelectionHeader} from './StateSelectionHeader'
import {State} from '../../core/_models'

const statesColumns: ReadonlyArray<Column<State>> = [

  {
    Header: (props) => <StateCustomHeader tableProps={props} title='State Name' className='min-w-125px' />,
    accessor: 'name',
  },

  {
    Header: (props) => (
      <StateCustomHeader tableProps={props} title='Actions' className='text-start min-w-100px'  />
    ),
    id: 'actions',
    Cell: ({...props}) =>  <StateActionsCell  id={props.data[props.row.index].id} status={props.data[props.row.index].status}  />,
  },

  
]

export {statesColumns}
