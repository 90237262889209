/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef} from 'react'
import {createPortal} from 'react-dom'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewTransportModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)

  return createPortal(
    <Modal
      id='kt_modal_create_user'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-650px modal fade show d-block'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2 className='fw-bolder'>View Transport Management</h2>
        <div className='btn btn-icon btn-sm btn-active-icon-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
        <div ref={stepperRef}>
          <Form noValidate id='kt_modal_add_user_form'>
            <Row className='mb-7'>
              <Col md={6}>
                <Form.Label className='fw-bold text-muted d-block m-0'>Firm Name</Form.Label>
                <span className='fw-bold fs-6'>Brad Simmons</span>
                <div className='badge badge-light-success fw-bolder ms-6 px-4 py-3'>Active</div>
              </Col>
              <Col md={6}>
                <Form.Label className='fw-bold text-muted d-block m-0'>
                  Contact Person Name
                </Form.Label>
                <div className='fw-bold fs-6'>Max Smith</div>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col md={6}>
                <Form.Label className='fw-bold text-muted d-block m-0'>GST Number</Form.Label>
                <div className='fw-bold fs-6'>123456</div>
              </Col>
              <Col md={6}>
                <Form.Label className='fw-bold text-muted d-block m-0'>Mobile Number</Form.Label>
                <div className='fw-bold fs-6 mb-2 form-label'>1234567890</div>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col md={6}>
                <Form.Label className='fw-bold text-muted d-block m-0'>State</Form.Label>
                <div className='fw-bold fs-6'>Gujrat</div>
              </Col>
              <Col md={6}>
                <Form.Label className='fw-bold text-muted d-block m-0'>City</Form.Label>
                <div className='fw-bold fs-6'>Ahmedabad</div>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col md={6}>
                <Form.Label className='fw-bold text-muted d-block m-0'>Source</Form.Label>
                <div className='fw-bold fs-6'>Palanpur</div>
              </Col>
              <Col md={6}>
                <Form.Label className='fw-bold text-muted d-block m-0'>Destination</Form.Label>
                <div className='fw-bold fs-6'>Ahmedabad</div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ViewTransportModal}
