import React, {useEffect, useRef, useState} from 'react'
import {Col, Modal, Row} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import { downloadMediaApi } from './products-list/core/_requests'
import axios from 'axios'

type Props = {
  show: boolean
  handleClose: () => void
  fileName: any
  loadingFile: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ProductBrochurePDFView = ({show, handleClose, fileName, loadingFile}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [checkValidUrl,setCheckValidUrl] = useState<any>(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const checkPdf = () => {
    axios.get(downloadMediaApi(fileName)).then((data:any)=>{
      setCheckValidUrl(true)
      // console.log("checkPdfcheckPdfdata",data)
    }).catch((error:any)=>{
      setCheckValidUrl(false)
      // console.log("checkPdfcheckPdferror",error)
    })
  }

  useEffect(()=>{
    checkPdf()
  },[fileName])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header border-bottom border-gray-300 border-bottom-dashed'>
        <h2> Product Brochure </h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
      {checkValidUrl ? 
      !loadingFile && (
        <iframe
          src={downloadMediaApi(fileName)}
          frameBorder='0'
          scrolling='auto'
          height='600px'
          width='100%'
        ></iframe>
      ):
      <div className='d-flex justify-content-center m-5 p-5'>
        <h3>File Not Found</h3>
      </div>
      }
    </Modal>,
    modalsRoot
  )
}

export default ProductBrochurePDFView
