/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import Multiselect from 'multiselect-react-dropdown'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const AddStateManagementModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [isShowStateError, setIsShowStateError] = useState(false)

  return createPortal(
    <Modal
      id='kt_modal_create_user'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-650px modal fade show d-block'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2 className='fw-bolder'>Add State Management</h2>
        <div className='btn btn-icon btn-sm btn-active-icon-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
        <div ref={stepperRef}>
          <Form noValidate id='kt_modal_add_user_form'>
            <Row className='mb-7'>
              <Col>
                <Form.Group>
                  <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                    State Name
                  </Form.Label>
                  <Multiselect
                    className='fw-bold fs-6 mb-2 bg-red form-label multi-sel form-control-solid mb-lg-0'
                    displayValue='key'
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck() {}}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck() {}}
                    options={[
                      {
                        cat: 'Group 1',
                        key: 'Ahmedabad',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Palanpur',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Surat',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Jaipur',
                      },
                      {
                        cat: 'Group 1',
                        key: 'Rajasthan',
                      },
                    ]}
                    selectionLimit={1}
                    // singleSelect
                  />
                  {isShowStateError && (
                    <div className=' mb-3 fv-plugins-message-container'>
                      <div className='fv-help-block'>“State is already exist”</div>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-7'>
              <div className='text-end pt-10'>
                <Button
                  type='reset'
                  onClick={() => {
                    setIsShowStateError(false)
                    handleClose()
                  }}
                  className='btn btn-light-primary me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setIsShowStateError(true)
                  }}
                  type='reset'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                >
                  <span className='indicator-label'>Save</span>
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {AddStateManagementModal}
