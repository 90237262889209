import Multiselect from 'multiselect-react-dropdown'
import React, {useRef, useState} from 'react'
import {Button, Col, Form, Row, Table} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import AddEditQuotation from './AddEditQuotation'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Quotation Management',
    path: '/buyer',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

type Props = {
  show: boolean
  handleClose: () => void
}

const AddQuotation = () => {
  const navigate = useNavigate()
  const navigateViewQuotation = () => {
    // 👇️ navigate to /
    navigate('/viewquotation')
  }
  const navigateQuotationListing = () => {
    // 👇️ navigate to /
    navigate('/quotation-management/quotations')
  }

  const [isShowSourceFild, setIsShowSourceFild] = useState(false)

  return (
    <>
      <PageTitle> Add Quotation </PageTitle>
      <Col className='position-absolute me-10 end-0 mt-n20 '>
        <Button
          type='button'
          value=''
          className='btn btn-sm fs-5 btn-primary me-5'
          onClick={navigateQuotationListing}
        >
          Back
        </Button>
      </Col>

      <div className='card card-body p-12 mt-10'>
      <AddEditQuotation />
      </div>
    </>
  )
}

export default AddQuotation
