import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PageLink, PageTitle, useLayout } from "../../../_metronic/layout/core";
import AddDistrictModal from "./AddDistrictModal";
import ViewDistrictModal from "./ViewDistrictModal";






const wizardsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Inquiry Management',
      path: '/crafted/pages/wizards/horizontal',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]



const DistrictListing = ( ) =>{

  const {config} = useLayout()
  const [ShowDistrictModal, setShowDistrictModal] = useState<boolean>(false)
  const [ShowViewDistrictModal, setShowViewDistrictModal] = useState<boolean>(false)


return (
    <>
    <PageTitle>District Listing</PageTitle>
    <Col className="d-flex position-absolute me-5 end-0 tp-btn-mn  ">
          {/* <button type="button" value="" onClick={navigateAddProduct}  className="btn btn-sm btn-primary btn-primary"> Add Product </button> */}
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
                          {config.app?.toolbar?.primaryButton && (
                            <Link
                              to='#'
                              onClick={() => setShowViewDistrictModal(true)}
                              className='btn btn-sm btn-primary btn-primary me-3 '
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_create_app1'
                            >
                              View District
                            </Link>
                          )}
                          <ViewDistrictModal
                            show={ShowViewDistrictModal}
                            handleClose={() => setShowViewDistrictModal(false)}
                          />
                        </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
                          {config.app?.toolbar?.primaryButton && (
                            <Link
                              to='#'
                              onClick={() => setShowDistrictModal(true)}
                              className='btn btn-sm btn-primary btn-primary '
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_create_app'
                            >
                              Add District
                            </Link>
                          )}
                          <AddDistrictModal
                            show={ShowDistrictModal}
                            handleClose={() => setShowDistrictModal(false)}
                          />
                        </div>

                        
          </Col>

    
    </>
)

}

export default DistrictListing