/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
// import {KTSVG} from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import adminRoles from '../../../../../app/utils/adminRoles'
import leaderRoles from '../../../../../app/utils/leaderRoles'
import marketingTeamRoles from '../../../../../app/utils/marketingTeamRoles'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      {adminRoles() ?
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/graphs/gra010.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
        : ""}
      {(adminRoles() || leaderRoles() || marketingTeamRoles()) ?
        <SidebarMenuItem
          to='offer-management/offers'
          title='Offers'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/general/gen026.svg'
        >
          {/* <SidebarMenuItem to='/apps/chat/private-chat' title='Add Location' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='List Location' hasBullet={true} /> */}
        </SidebarMenuItem> :
        <> </>}

      {adminRoles() ?
        <SidebarMenuItem
          to='/offer-with-price'
          title='Offer With Price'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/general/gen026.svg'
        />
        : ""}

      {(adminRoles() || leaderRoles()) ?
        <>
          <SidebarMenuItem
            to='/inquiry-management/inquiries'
            title='Inquiries'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/communication/com003.svg'
          >
            {/* <SidebarMenuItem to='/apps/chat/private-chat' title='Add inquiry' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='List Inquiry' hasBullet={true} /> */}
          </SidebarMenuItem>
          <SidebarMenuItem
            to='quotation-management/quotations'
            title='Quotations'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/files/fil006.svg'
          >
            {/* <SidebarMenuItem to='/apps/chat/private-chat' title='Add Quotation' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='List Quotation' hasBullet={true} /> */}
          </SidebarMenuItem>

          <SidebarMenuItem
            to='purchase-order/purchases'
            title='Purchase orders'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/general/gen005.svg'
          >
            {/* <SidebarMenuItem to='/apps/chat/private-chat' title='Add PO' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='List PO' hasBullet={true} /> */}
          </SidebarMenuItem>

          <SidebarMenuItem
            to='buyer-management/buyers'
            title='Buyers'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/ecommerce/ecm001.svg'
          />

          <SidebarMenuItem
            to='seller-management/sellers'
            title='Sellers'
            icon='/media/icons/duotune/ecommerce/ecm004.svg'
            fontIcon='bi-person'
          >
            {/* <SidebarMenuItem to='/crafted/account/overview' title='Add Seller' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='List Seller' hasBullet={true} /> */}
          </SidebarMenuItem>
        </> : <></>}
      {adminRoles() ?
        <>
          <SidebarMenuItemWithSub
            to='/#'
            title='Masters'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/abstract/abs015.svg'
          >
            <SidebarMenuItem
              to='/grade-group-management/grades'
              title='Grade Groups'
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/product-group-management/Products'
              title='Product Groups'
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/product-management/products'
              title='Products'
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/masters/locations'
              title='Locations'
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/freight-management/freights'
              title='Freights'
              hasBullet={true}
            ></SidebarMenuItem>

            <SidebarMenuItem
              to='masters/transports/transports'
              title='Transporters'
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem to='masters/users/users' title='Users' hasBullet={true}></SidebarMenuItem> :
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/#'
            title='Reports'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/general/gen005.svg'
          >
            <SidebarMenuItem
              to='/buyerseller-report/buyersellers'
              title='Usage Analysis'
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/order-report/orders'
              title='Order Duration Analysis'
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/inquiry-report/inquirys'
              title='Inquiry to Invoice Analysis'
              hasBullet={true}
            ></SidebarMenuItem>

            <SidebarMenuItem
              to='/grade-group-report/gradegroups'
              title='Grade Group Wise Report'
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/near-expire-offer/expireoffers'
              title='Near to Expiry Offers '
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/active-inquiry-report/activeinquiries'
              title='Active Inquiries '
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/complete-inquiry-report/completeinquiries'
              title='Recently Completed Inquiries '
              hasBullet={true}
            ></SidebarMenuItem>
            <SidebarMenuItem
              to='/userwise-inquiry-report/userinquiries'
              title='User Wise Inquiry '
              hasBullet={true}
            ></SidebarMenuItem>
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/news-content'
            title='Manage News'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/coding/cod001.svg'
          >
            <SidebarMenuItem
              to='/news-articles/articles'
              title='News Articles'
              hasBullet={true}
            ></SidebarMenuItem>

            <SidebarMenuItem
              to='/news-category/cates'
              title='Category'
              hasBullet={true}
            ></SidebarMenuItem>

          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to='/setting'
            title='Settings'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/coding/cod001.svg'
          ></SidebarMenuItem>
        </> : <></>}
    </>
  )
}

export { SidebarMenuMain }
