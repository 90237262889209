import React, { useRef } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import { KTSVG } from "../../../_metronic/helpers";

type Props = {
    show: boolean
    handleClose: () => void
  }
  
  const modalsRoot = document.getElementById('root-modals') || document.body
  
const AddDistrictModal = ({show, handleClose}: Props) => {

    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)

  
    const loadStepper = () => {
      stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }


    return createPortal(
        <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-650px'
        show={show}
        onHide={handleClose}
        onEntered={loadStepper}
        backdrop={true}
      >
        <div className='modal-header border-bottom border-gray-300 border-bottom-dashed'>
          <h2> Add District </h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
  
        <div className='modal-body '>
        <div className=' p-5'>
        <Form>
          <Row className=' d-flex justify-content-between flex-wrap mb-5'>
            <Col xl={6} >
              <Form.Group className='mb-8'>
                <Form.Label className='form-label' > Add District </Form.Label>
                <Form.Control type='text' className="form-control-solid" placeholder='Add Distric' />
              </Form.Group>
            </Col>
            <Col xl={6}>
            <Form.Group className='mb-0'>
                <Form.Label className='form-label'> Select State </Form.Label>
              </Form.Group>
              <Form.Select aria-label='Default select example' className='form-control-solid border-0'>
                <option value='1'> Gujarat </option>
                <option value='2'> Maharashtra </option>
                <option value='3'> Madhya Pradesh </option>
                <option value='4'> Rajasthan </option>
                <option value='5'> Uttar Pradesh </option>
              </Form.Select>
            </Col>

          </Row>
          <Row className='mb-5 mt-5'>
            <Col className='d-flex justify-content-end'>
            {/* <Button  type='button' value='' className="btn btn-sm btn-light-primary me-5">
                Cancel{' '}
              </Button> */}

              <Button  type='button' value='' className='btn fs-4 btn-sm btn-primary '>
                Add {' '}
              </Button>
             
            </Col>
          </Row>
          </Form>
      </div>
    </div>
     
      </Modal>,
      modalsRoot

    )
}

export default AddDistrictModal