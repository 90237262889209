import { Modal, Toast, ToastContainer } from "react-bootstrap"
import { KTSVG } from "../../../../_metronic/helpers"
import * as Yup from 'yup'
import { useEffect, useState } from "react"
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { generate2faOTPApi , setup2faApi } from "../core/_requests";
import clsx from "clsx";
import QRCode from "qrcode.react";

const styles = {
    heading3: `text-xl font-semibold text-gray-900 p-4 border-b`,
    heading4: `text-base text-ct-blue-600 font-medium border-b mb-2`,
    modalOverlay: `overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`,
    orderedList: `space-y-1 text-sm list-decimal`,
    buttonGroup: `flex items-center py-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600`,
    buttonBlue: `text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`,
    buttonGrey: `text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600`,
    inputField: `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/5 p-2.5`,
};

const Setup2FA = ({ show2FA, setShow2FA, setShowEnableBtn }: any) => {
    const [loading, setLoading] = useState(false)
    const [base32, setBASE32] = useState("")
    const [qrcode, setQrcode] = useState("")
    const [generateOtpLoading, setGenerateOtpLoading] = useState(false)
    const navigate = useNavigate()
    const [sessionMessage, setSessionMessage] = useState({
        type: '',
        message: '',
        show: false,
    })

    const initialValues = {
        token: '',
    }

    const verifyOTPSchema = Yup.object().shape({
        token: Yup.string()
            .min(6, 'OTP must be 6 digits')
            .max(6, 'OTP must be 6 digits')
            .required('OTP is required'),
    })

    useEffect(() => {
        if (sessionMessage.show) {
            setTimeout(() => {
                setSessionMessage({ type: '', message: '', show: false })
            }, 4500)
        }
    }, [sessionMessage.message])

    const generateOTP = async () => {
        try {
            setGenerateOtpLoading(true)
            let userData = localStorage.getItem("objUser") as any
            userData = JSON.parse(userData)
            await generate2faOTPApi({ user_id: userData?.id ?? "" }).then((data: any) => {
                if (data?.responseStatus == 200) {
                    setQrcode(data?.responseData?.otpauth_url ?? "")
                    setBASE32(data?.responseData?.base32_secret ?? "")
                    setGenerateOtpLoading(false)
                } else {
                    setSessionMessage({ type: 'danger', message: data?.responseMessage ?? "SomeThing Went Wrong", show: true })
                    setGenerateOtpLoading(false)
                    setShow2FA(false)
                }
            }).catch((error: any) => {
                setGenerateOtpLoading(false)
                setShow2FA(false)
                setSessionMessage({ type: 'danger', message: error?.responseMessage ?? "SomeThing Went Wrong", show: true })
            })
        }
        catch (error: any) {
            setGenerateOtpLoading(false)
            setShow2FA(false)
            setSessionMessage({ type: 'danger', message: error?.message ?? "SomeThing Went Wrong", show: true })
            console.log(error)
        }
    }

    useEffect(() => {
        if (show2FA) {
            generateOTP()
        }
    }, [show2FA])

    const formik = useFormik({
        initialValues,
        validationSchema: verifyOTPSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                let userData = localStorage.getItem("objUser") as any
                userData = JSON.parse(userData)
                await setup2faApi({ user_id: userData?.id ?? "", token: values.token }).then((data: any) => {
                    if (data?.responseStatus == 200) {
                        setLoading(false)
                        setTimeout(()=>{
                            setShowEnableBtn(false)
                            setShow2FA(false)
                        },2000)
                        userData.is_2fa_enable = true
                        localStorage.setItem("objUser",JSON.stringify(userData))
                        setSessionMessage({ type: 'success', message: data?.responseMessage ?? "Response Close With 200 Status Code", show: true })
                    } else {
                        setSessionMessage({ type: 'danger', message: data?.responseMessage ?? "SomeThing Went Wrong", show: true })
                        setLoading(false)
                    }
                }).catch((err: any) => {
                    if (err?.response?.status == 429) {
                        setSessionMessage({ type: 'danger', message: err?.response?.statusText ?? "SomeThing Went Wrong", show: true })
                    }
                    else {
                        setSessionMessage({ type: 'danger', message: err?.message ?? "SomeThing Went Wrong", show: true })
                    }
                    setSubmitting(false)
                    setLoading(false)
                })

            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }
    })

    return <Modal
        id='setup_2fa'
        dialogClassName='modal-dialog modal-dialog-centered modalheight-scrlbr modal fade show d-block'
        show={show2FA}
        size={'lg'}
        onHide={() => { setShow2FA(false) }}
    >
        <div className='modal-header'>
            <h2 className='fw-bolder'> Setup 2FA</h2>
            <div className='btn btn-icon btn-sm btn-active-icon-primary' onClick={() => { setShow2FA(false) }}>
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
        </div>

        <div className='modal-body my-2'>
            <div className="relative w-full max-w-xl h-full md:h-auto left-1/2 -translate-x-1/2">
                <div className="relative bg-white rounded-lg shadow">
                    <h3 className={styles.heading3}>Two-Factor Authentication (2FA)</h3>
                    {/* Modal body */}
                    <div className="p-6 space-y-4">
                        <h4 className={styles.heading4}>
                            Configuring Google Authenticator or Authy
                        </h4>
                        <div className={styles.orderedList}>
                            <li>
                                Install Google Authenticator (IOS - Android) or Authy (IOS -
                                Android).
                            </li>
                            <li>In the authenticator app, select "+" icon.</li>
                            <li>
                                Select "Scan a barcode (or QR code)" and use the phone's camera
                                to scan this barcode.
                            </li>
                        </div>
                        <div>
                            <h4 className={styles.heading4}>Scan QR Code</h4>
                            <div className="flex justify-center">
                                <QRCode
                                    value={qrcode} 
                                    style={{ marginRight: 50 }} 
                                />
                            </div>
                        </div>
                        <div>
                            <h4 className={styles.heading4}>Or Enter Code Into Your App</h4>
                            <p className="text-sm">SecretKey: {base32} (Base32 encoded)</p>
                        </div>
                        <div>
                            <h4 className={styles.heading4}>Verify Code</h4>
                            <p className="text-sm">
                                For changing the setting, please verify the authentication code:
                            </p>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
                            <input
                                type='text'
                                placeholder='Enter OTP'
                                autoComplete='off'
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                    const key = e.key;
                                    const isNumeric = /^[0-9]$/.test(key);
                                    const isSpecialKey =
                                        key === 'Backspace' ||
                                        key === 'Delete' ||
                                        key === 'ArrowLeft' ||
                                        key === 'ArrowRight' ||
                                        key === 'ArrowUp' ||
                                        key === 'ArrowDown';
                                    if (!isNumeric && !isSpecialKey) {
                                        e.preventDefault();
                                    }
                                }}
                                {...formik.getFieldProps('token')}
                                className={clsx(
                                    'form-control bg-transparent',
                                    { 'is-invalid': formik.touched.token && formik.errors.token },
                                    {
                                        'is-valid': formik.touched.token && !formik.errors.token,
                                    }
                                )}
                            />
                            {formik.touched.token && formik.errors.token && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.token}</span>
                                    </div>
                                </div>
                            )}

                            <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-3'>
                                <button type='submit' className='btn btn-primary me-4' disabled={loading}>
                                    {loading ? "Please Wait..." : "Verify OTP"}
                                </button>
                                <button type='submit' className='btn btn-light me-4' disabled={loading} onClick={() => { setShow2FA(false) }}>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer className='p-3' position={'top-end'}>
                <Toast
                    show={sessionMessage.show}
                    onClose={() => {
                        setSessionMessage({
                            type: '',
                            message: '',
                            show: false,
                        })
                    }}
                    bg={sessionMessage.type}
                >
                    {/* <Toast.Header>Error</Toast.Header> */}
                    <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    </Modal>
}

export default Setup2FA