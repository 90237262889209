import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import { QueryResponseProvider as StateResponseProvider } from '../states-list/core/QueryResponseProvider'
// import {KTCard} from '../../../../_metronic/helpers'
import { DistrictsListHeader } from './components/header/DistrictsListHeader'
import { DistrictsTable } from './table/DistrictsTable'
import { DistrictEditModal } from './district-edit-modal/DistrictEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
// import { SufalamsTable } from './table/SufalamsTable'
// import { SufalamEditModal } from './sufalam-edit-modal/SufalamEditModal'

const DistrictsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <DistrictsListHeader />
        <DistrictsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <DistrictEditModal />}
    </>
  )
}

const DistrictsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <StateResponseProvider>
        <ListViewProvider>
          <DistrictsList />
        </ListViewProvider>
      </StateResponseProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DistrictsListWrapper}
