import React from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {useDropzone} from 'react-dropzone'
import Multiselect from 'multiselect-react-dropdown'
import Select from 'react-select'
import AddEditBuyer from './AddEditBuyer'

const AddBuyer = () => {
  const wizardsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Buyer Management',
      path: '/crafted/pages/wizards/horizontal',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const countryList = [
    {value: 'India', label: 'India'},
    {value: 'country2', label: 'Country Name 02'},
    {value: 'country3', label: 'Country Name 03'},
    {value: 'country4', label: 'Country Name 04'},
    {value: 'country5', label: 'Country Name 05'},
    {value: 'country6', label: 'Country Name 06'},
    {value: 'country7', label: 'Country Name 07'},
    {value: 'country8', label: 'Country Name 08'},
    {value: 'country9', label: 'Country Name 09'},
    {value: 'country10', label: 'Country Name 10'},
    {value: 'country11', label: 'Country Name 11'},
    {value: 'country12', label: 'Country Name 12'},
  ]

  const stateList = [
    {value: 'state1', label: 'State Name 01'},
    {value: 'state2', label: 'State Name 02'},
    {value: 'state3', label: 'State Name 03'},
    {value: 'state4', label: 'State Name 04'},
    {value: 'state5', label: 'State Name 05'},
    {value: 'state6', label: 'State Name 06'},
    {value: 'state7', label: 'State Name 07'},
    {value: 'state8', label: 'State Name 08'},
    {value: 'state9', label: 'State Name 09'},
    {value: 'state10', label: 'State Name 10'},
    {value: 'state11', label: 'State Name 11'},
    {value: 'state12', label: 'State Name 12'},
  ]

  const districtList = [
    {value: 'district1', label: 'District Name 01'},
    {value: 'district2', label: 'District Name 02'},
    {value: 'district3', label: 'District Name 03'},
    {value: 'district4', label: 'District Name 04'},
    {value: 'district5', label: 'District Name 05'},
    {value: 'district6', label: 'District Name 06'},
    {value: 'district7', label: 'District Name 07'},
    {value: 'district8', label: 'District Name 08'},
    {value: 'district9', label: 'District Name 09'},
    {value: 'district10', label: 'District Name 10'},
    {value: 'district11', label: 'District Name 11'},
    {value: 'district12', label: 'District Name 12'},
  ]

  const cityList = [
    {value: 'city1', label: 'City Name 01'},
    {value: 'city2', label: 'City Name 02'},
    {value: 'city3', label: 'City Name 03'},
    {value: 'city4', label: 'City Name 04'},
    {value: 'city5', label: 'City Name 05'},
    {value: 'city6', label: 'City Name 06'},
    {value: 'city7', label: 'City Name 07'},
    {value: 'city8', label: 'City Name 08'},
    {value: 'city9', label: 'City Name 09'},
    {value: 'city10', label: 'City Name 10'},
    {value: 'city11', label: 'City Name 11'},
    {value: 'city12', label: 'City Name 12'},
  ]
  const navigate = useNavigate()
  // const navigateViewBuyer = () => {
  //   // 👇️ navigate to /
  //   navigate('/viewbuyer');
  // };

  const navigateToListBuyer = () => {
    // 👇️ navigate to /contacts
    navigate('/buyer-management/buyers')
  }

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <>
      <PageTitle>Add Buyer</PageTitle>

      <Col className='position-absolute me-5 end-0 tp-btn-mn'>
        {/* <button 
          type="button" 
          onClick={navigateViewBuyer}
          value="" 
          className="btn btn-sm  me-5 btn btn-primary"> 
          View Buyer
          </button> */}

        <button
          type='button'
          onClick={navigateToListBuyer}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
      </Col>
      <div className='card card-body p-12 mt-10'>
       <AddEditBuyer />
      </div>
    </>
  )
}

export default AddBuyer
