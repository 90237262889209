import React from 'react'

const Report = () => {
  return (
    <div className='card card-body pt-9 pe-5 pb-5 ps-5'>
      <div className='d-flex align-items-center justify-content-between'>
        <h2>Report Overview</h2>
      </div>
    </div>
  )
}

export default Report
