import React, { useEffect, useState } from 'react'
import { checkDisplayValue } from '../../../utils/utils'
import { getUserwiseInquiryApi } from '../_requests'

type Props = {
  setMessage: (type:string,message:string) => void
}

const UserWishOpenInquiry = ({setMessage}:Props) => {

  const [userwishInquiryData,setUserwishInquiryData] = useState([])
  const [loading,setLoading] = useState(true)

  const callDashboardReport = async () => {
    try{
      setLoading(true)
      let {responseStatus, responseMessage, responseData} = await getUserwiseInquiryApi()
      if(responseStatus == 200){
        if(responseData.list && responseData.list.length){
          setUserwishInquiryData(responseData.list)
        }
        else{
          setUserwishInquiryData([])
        }
        setLoading(false)
      }
      else{
        setMessage("danger",responseMessage)
        setLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setMessage("danger",message)
      setLoading(false)
    }
  } 

  useEffect(()=>{
    callDashboardReport()
  },[])

  return (
    <div className='row'>
      <div className='col-xl-12'>
        <div className='card  users-inquiry p-9'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold mb-2 text-dark fs-2'>Users Wise Open Inquiry</span>
          </h3>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr>
                <th className='fs-6 fw-bold border-0 text-gray-400 text-uppercase'>User Name</th>
                <th className='fs-6 text-end fw-bold border-0 text-gray-400 text-uppercase'>Open Inquiry</th>
              </tr>
            </thead>
            <tbody>
            {loading ? 
            <h4 className='d-flex justify-content-center mt-3'>Loading...</h4> : userwishInquiryData.length == 0 ?
            <h4 className='d-flex justify-content-center mt-3'>No data available in table</h4> :
            userwishInquiryData.map((x:any)=>{
              return (
                <tr key={x.id}>
                  <td className='text-dark fw-bold fs-6'>{checkDisplayValue(x?.name)}</td>
                  <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>{checkDisplayValue(x?.inquiriesCount)}</td>
                </tr>
              )
            })}
              {/* <tr>
                <td className='text-dark fw-bold fs-6'>Brian Cox</td>
                <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>10</td>
              </tr>
              <tr>
                <td className='text-dark fw-bold fs-6'>Francis Mitcham</td>
                <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>13</td>
              </tr>
              <tr>
                <td className='text-dark fw-bold fs-6'>Francis Mitcham</td>
                <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>13</td>
              </tr>
              <tr>
                <td className='text-dark fw-bold fs-6'>Brian Cox</td>
                <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>10</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default UserWishOpenInquiry
