import Multiselect from 'multiselect-react-dropdown'
import React from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Select from 'react-select'
import AddEditProduct from './AddEditProduct'

const countryList = [
  {value: 'India', label: 'Country Name 01'},
  {value: 'country2', label: 'Country Name 02'},
  {value: 'country3', label: 'Country Name 03'},
  {value: 'country4', label: 'Country Name 04'},
  {value: 'country5', label: 'Country Name 05'},
  {value: 'country6', label: 'Country Name 06'},
  {value: 'country7', label: 'Country Name 07'},
  {value: 'country8', label: 'Country Name 08'},
  {value: 'country9', label: 'Country Name 09'},
  {value: 'country10', label: 'Country Name 10'},
  {value: 'country11', label: 'Country Name 11'},
  {value: 'country12', label: 'Country Name 12'},
]

const AddProduct = () => {
  const wizardsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Buyer Management',
      path: '/crafted/pages/wizards/horizontal',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const navigate = useNavigate()

  const navigateToProductListing = () => {
    // 👇️ navigate to /contacts
    navigate('/product-management/products')
  }

  return (
    <>
      <PageTitle>Add Product</PageTitle>

      <Col className='position-absolute me-5 end-0 tp-btn-mn  '>
        <button
          type='button'
          onClick={navigateToProductListing}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
      </Col>
      <div className='card card-body p-12 mt-10'>
      <AddEditProduct isEditing={false}/>
      </div>
    </>
  )
}

export default AddProduct
