import Multiselect from 'multiselect-react-dropdown'
import React, {useState} from 'react'
import {Button, Col, Form, Row, Table} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Select from 'react-select'
import { KTSVG } from '../../../_metronic/helpers'
import AddEditQuotation from './AddEditQuotation'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Quotation Management',
    path: 'quotation/quotations',
    isSeparator: false,
    isActive: false,
    
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const buyerList = [
  {value: 'buyer1', label: 'Buyer Name 01'},
  {value: 'buyer2', label: 'Buyer Name 02'},
  {value: 'buyer3', label: 'Buyer Name 03'},
  {value: 'buyer4', label: 'Buyer Name 04'},
  {value: 'buyer5', label: 'Buyer Name 05'},
  {value: 'buyer6', label: 'Buyer Name 06'},
  {value: 'buyer7', label: 'Buyer Name 07'},
  {value: 'buyer8', label: 'Buyer Name 08'},
  {value: 'buyer9', label: 'Buyer Name 09'},
  {value: 'buyer10', label: 'Buyer Name 10'},
  {value: 'buyer11', label: 'Buyer Name 11'},
  {value: 'buyer12', label: 'Buyer Name 12'},
]

const stateList = [
  {value: 'state1', label: 'State Name 01'},
  {value: 'state2', label: 'State Name 02'},
  {value: 'state3', label: 'State Name 03'},
  {value: 'state4', label: 'State Name 04'},
  {value: 'state5', label: 'State Name 05'},
  {value: 'state6', label: 'State Name 06'},
  {value: 'state7', label: 'State Name 07'},
  {value: 'state8', label: 'State Name 08'},
  {value: 'state9', label: 'State Name 09'},
  {value: 'state10', label: 'State Name 10'},
  {value: 'state11', label: 'State Name 11'},
  {value: 'state12', label: 'State Name 12'},
]

const districtList = [
  {value: 'district1', label: 'District Name 01'},
  {value: 'district2', label: 'District Name 02'},
  {value: 'district3', label: 'District Name 03'},
  {value: 'district4', label: 'District Name 04'},
  {value: 'district5', label: 'District Name 05'},
  {value: 'district6', label: 'District Name 06'},
  {value: 'district7', label: 'District Name 07'},
  {value: 'district8', label: 'District Name 08'},
  {value: 'district9', label: 'District Name 09'},
  {value: 'district10', label: 'District Name 10'},
  {value: 'district11', label: 'District Name 11'},
  {value: 'district12', label: 'District Name 12'},
]

const countryList = [
  {value: 'India', label: 'India'},
  {value: 'country2', label: 'Country Name 02'},
  {value: 'country3', label: 'Country Name 03'},
  {value: 'country4', label: 'Country Name 04'},
  {value: 'country5', label: 'Country Name 05'},
  {value: 'country6', label: 'Country Name 06'},
  {value: 'country7', label: 'Country Name 07'},
  {value: 'country8', label: 'Country Name 08'},
  {value: 'country9', label: 'Country Name 09'},
  {value: 'country10', label: 'Country Name 10'},
  {value: 'country11', label: 'Country Name 11'},
  {value: 'country12', label: 'Country Name 12'},
]

const cityList = [
  {value: 'city1', label: 'City Name 01'},
  {value: 'city2', label: 'City Name 02'},
  {value: 'city3', label: 'City Name 03'},
  {value: 'city4', label: 'City Name 04'},
  {value: 'city5', label: 'City Name 05'},
  {value: 'city6', label: 'City Name 06'},
  {value: 'city7', label: 'City Name 07'},
  {value: 'city8', label: 'City Name 08'},
  {value: 'city9', label: 'City Name 09'},
  {value: 'city10', label: 'City Name 10'},
  {value: 'city11', label: 'City Name 11'},
  {value: 'city12', label: 'City Name 12'},
]

const hsnList = [
  {value: 'hsn1', label: 'HSN 01'},
  {value: 'hsn2', label: 'HSN 02'},
  {value: 'hsn3', label: 'HSN 03'},
  {value: 'hsn4', label: 'HSN 04'},
  {value: 'hsn5', label: 'HSN 05'},
  {value: 'hsn6', label: 'HSN 06'},
  {value: 'hsn7', label: 'HSN 07'},
  {value: 'hsn8', label: 'HSN 08'},
  {value: 'hsn9', label: 'HSN 09'},
  {value: 'hsn10', label: 'HSN 10'},
  {value: 'hsn11', label: 'HSN 11'},
  {value: 'hsn12', label: 'HSN 12'},
]

const productList = [
  {value: 'product1', label: 'Product Name 01'},
  {value: 'product2', label: 'Product Name 02'},
  {value: 'product3', label: 'Product Name 03'},
  {value: 'product4', label: 'Product Name 04'},
  {value: 'product5', label: 'Product Name 05'},
  {value: 'product6', label: 'Product Name 06'},
  {value: 'product7', label: 'Product Name 07'},
  {value: 'product8', label: 'Product Name 08'},
  {value: 'product9', label: 'Product Name 09'},
  {value: 'product10', label: 'Product Name 10'},
  {value: 'product11', label: 'Product Name 11'},
  {value: 'product12', label: 'Product Name 12'},
]

const EditQuotation = () => {
  const navigate = useNavigate()
  const navigateQuotationListing = () => {
    // 👇️ navigate to /
    navigate('/quotation-management/quotations')
  }

  const [isShowSourceFild, setIsShowSourceFild] = useState(false)
  return (
    <>
      <PageTitle> Edit Quotation </PageTitle>
      <Col className='position-absolute me-5 end-0 tp-btn-mn  '>
        <button
          type='button'
          onClick={navigateQuotationListing}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
      </Col>

      <div className='card card-body p-12 mt-10'>
   <AddEditQuotation />
      </div>
    </>
  )
}

export default EditQuotation
