import React, { useEffect, useState } from 'react'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Pie} from 'react-chartjs-2'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { getInquiryGradeGroupApi } from '../_requests'
import { rgbaCode } from '../../../utils/colorRgbacode'
import { colorHexcode } from '../../../utils/hexcodeInterestGradeGroup'

ChartJS.register(ArcElement, Tooltip, Legend)

type Props = {
  setMessage: (type:string,message:string) => void
}

export const data = {
  labels: [],
  datasets: [
    {
      label: '# of Votes',
      data: [],
      backgroundColor: [],
      // borderColor:[],
      borderWidth: 1,
    },
  ],
}

export function InterestGradeGroup({setMessage}:Props) {

  const [interestGrade,setInterestGradeData] = useState<any>(data)
  const [loading,setLoading] = useState(true)

  const callDashboardReport = async () => {
    try{
      setLoading(true)
      let {responseStatus, responseMessage, responseData} = await getInquiryGradeGroupApi()
      if(responseStatus == 200){
        if(responseData && responseData.list && responseData.list.length){
          interestGrade.labels = responseData.list.map((x:any)=>x.gradeGroupName)
          interestGrade.datasets[0].data = responseData.list.map((x:any)=>x.totalQuantity)
          colorHexcode.length = interestGrade.labels.length
          interestGrade.datasets[0].backgroundColor = colorHexcode
          setInterestGradeData(interestGrade)
        }
        setTimeout(()=>{
          setLoading(false)
        },200)
      }
      else{
        setMessage("danger",responseMessage)
        setLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setMessage("danger",message)
      setLoading(false)
    }
  } 

  useEffect(()=>{
    callDashboardReport()
  },[])

  return (
    <div className='row g-5 g-xl-8' onClick={(e:any)=>{
      e.preventDefault()
    }}>
      <div className='col-xl-12'>
        <a href='#' className='card interest-grade hoverable card-xl-stretch mb-xl-8'>
        <CardHeader className='pt-10 pb-0 border-0'>
          <h2> Inquiry By Grade Group </h2>
          </CardHeader>
          <div className='card-body'>
          {loading ? 
            <h4 className='d-flex justify-content-center mt-3'>Loading...</h4> : interestGrade.labels.length == 0 ?
            <h4 className='d-flex justify-content-center mt-3'>No data available</h4>
          : 
            <Pie data={interestGrade} style={{height:'400px'}} 
              options={{ maintainAspectRatio: false}}
            />
          }
          </div>
        </a>
      </div>
    </div>
  )
}
