// import { ID, Response } from "../../../../../_metronic/helpers"

import { ID, Response } from "../../../../../../_metronic/helpers"

export type State = {
  id?: ID
  name?: string
  state_name?: string
  whatsapp_number?: string
  avatar?: string
  email?: string
  firm_name?: string
  category?: string
  position?: string
  install_capacity?: string
  sales_district?: string
  approved?: string
  status?: string
  role?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  initials?: {
    label: string
    state: string
  }
}




export type StatesQueryResponse = Response<Array<State>>

export const initialState: State = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  // name: '',
  // email: '',

  name:"Aiyub Panara",
  whatsapp_number: "98254 23654",
  email:"aiyub@gmail.com",
  firm_name: "Aiyub Industries",
  category: "Domestic",
  install_capacity: "15T",
  sales_district: "Ahmedabad",
  approved: "Approved",
  status: "Active"


}
