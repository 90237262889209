import adminRoles from '../../../../../../utils/adminRoles'
import {useListView} from '../../core/ListViewProvider'
import {DistrictListToolbar} from './DistrictListToolbar'
import {DistrictListGrouping} from './DistrictsListGrouping'
import {DistrictsListSearchComponent} from './DistrictsListSearchComponent'

const DistrictsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 '>
      {adminRoles() ?
      <DistrictsListSearchComponent />: 
      <div className='d-flex align-items-center position-relative my-1'></div>
      }
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <DistrictListGrouping /> : <DistrictListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {DistrictsListHeader}
