import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useLayout } from '../../../_metronic/layout/core'
import { AddStateManagementModal } from './state-management-modal/AddStateManagementModal'
import { FileImportStateModal } from './state-management-modal/FileImportStateModal'
import { ViewStateManagementModal } from './state-management-modal/ViewStateManagementModal'

const StateManagement = () => {
  const {config} = useLayout()
  const [showEditStateModal, setShowEditStateModal] = useState<boolean>(false)
  const [showViewStateModal, setShowViewStateModal] = useState<boolean>(false)
  const [showFileStateModal, setShowFileStateModal] = useState<boolean>(false)


  return (
    <div className='card card-body pt-9 pe-5 pb-5 ps-5'>
      <div className='d-flex align-items-center justify-content-between'>
        <h2>State Overview</h2>
        <div>
          <Link to='#' className='btn btn-light-primary me-3'>
            <span className='svg-icon svg-icon-2'>
              <KTSVG path='/media/icons/duotune/files/fil018.svg' className='svg-icon-muted' />
            </span>
            Export
          </Link>
          {config.app?.toolbar?.primaryButton && (
            <Link
              to='#'
              onClick={() => setShowFileStateModal(true)}
              className='btn btn-light-primary me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_user'
            >
             <span className='svg-icon svg-icon-2'>
              <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-muted' />
            </span>
            Import
            </Link>
          )}
          <FileImportStateModal
            show={showFileStateModal}
            handleClose={() => setShowFileStateModal(false)}
          />
          {config.app?.toolbar?.primaryButton && (
            <Link
              to='#'
              onClick={() => setShowEditStateModal(true)}
              className='btn fw-bold btn-primary me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_user'
            >
              Add State
            </Link>
          )}
          <AddStateManagementModal
            show={showEditStateModal}
            handleClose={() => setShowEditStateModal(false)}
          />
          {config.app?.toolbar?.primaryButton && (
            <Link
              to='#'
              onClick={() => setShowViewStateModal(true)}
              className='btn fw-bold btn-primary me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_user'
            >
              View State
            </Link>
          )}
          <ViewStateManagementModal
            show={showViewStateModal}
            handleClose={() => setShowViewStateModal(false)}
          />
        </div>
      </div>
    </div>
  )
}

export default StateManagement
