import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { SERVER_URL } from '../../../../ServerConfig'
import {Product, ProductsQueryResponse} from './_models'

const API_URL = SERVER_URL
const USER_URL = `${API_URL}/sufalam`
const GET_PRODUCTS_URL = `${API_URL}/product`
const GET_GRADES_URL = `${API_URL}/grade-group`
const GET_PRODUCTS_GRADES_URL = `${API_URL}/product/group/list/all`
const UPLOAD_MEDIA_URL = `${API_URL}/uploads/upload`
const DOWNLOAD_MEDIA_URL = `${API_URL}/downloads/download?folder_name=product&image=`
const IMPORT_PRODUCT_URL = `${API_URL}/product/import`

const getProduct = (query: string): Promise<ProductsQueryResponse> => {
  return axios
  .get(`${GET_PRODUCTS_URL}?${query}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
    },
  })
    .then((d: AxiosResponse<ProductsQueryResponse>) => d.data)
}

const getProductById = (id: ID): Promise<Product | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Product>>) => response.data)
    .then((response: Response<Product>) => response.data)
}

const createProduct = (product: Product): Promise<Product | undefined> => {
  return axios
    .put(USER_URL, product)
    .then((response: AxiosResponse<Response<Product>>) => response.data)
    .then((response: Response<Product>) => response.data)
}

const updateProduct = (product: Product): Promise<Product | undefined> => {
  return axios
    .post(`${USER_URL}/${product.id}`, product)
    .then((response: AxiosResponse<Response<Product>>) => response.data)
    .then((response: Response<Product>) => response.data)
}

const deleteProduct = (productId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${productId}`).then(() => {})
}

const deleteSelectedProducts = (productIds: Array<ID>): Promise<void> => {
  const requests = productIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getGradeList = async (): Promise<any> => {
  const d = await axios
    .get(`${GET_GRADES_URL}?perPage=100000&page=1`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
      },
    })
  return d.data
}

const getProductGradeList = async (id:any): Promise<any> => {
  const d = await axios
    .get(`${GET_PRODUCTS_GRADES_URL}?perPage=100000&page=1&grade_group_id=${id ?? ""}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
      },
    })
  return d.data
}

const getProductByIdApi = (id: any): Promise<any> => {
  return axios
    .get(`${GET_PRODUCTS_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const createProductApi = (product: any): Promise<any> => {
  return axios
    .post(GET_PRODUCTS_URL, product)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const updateProductApi = (id:any, product: any): Promise<any> => {
  return axios
    .patch(`${GET_PRODUCTS_URL}/${id}`, product)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const deleteProductApi = (id:any): Promise<any> => {
  return axios
    .delete(`${GET_PRODUCTS_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const uploadMedia = (data: any): Promise<any> => {
  return axios
    .post(`${UPLOAD_MEDIA_URL}`,data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const downloadMediaApi = (data:any) => {
  return `${DOWNLOAD_MEDIA_URL}${data}`
}

const importProductApi = (buyer: any): Promise<any | undefined> => {
  return (
    axios
      .post(IMPORT_PRODUCT_URL, buyer)
      .then((response: AxiosResponse<Response<any>>) => response.data)
  )
}

export { getProduct, deleteProduct, deleteSelectedProducts, 
  getProductById, createProduct, updateProduct,
  getProductByIdApi, createProductApi, updateProductApi,
  getGradeList, getProductGradeList, deleteProductApi,
  uploadMedia, downloadMediaApi, importProductApi
}
