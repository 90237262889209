import axios, { AxiosResponse } from 'axios'

const API_URL_NEW = process.env.REACT_APP_API_URL_NEW
const PRODUCTS_GRADES_URL = `${API_URL_NEW}/product/group/list/all`
const LOCATION_LIST_URL = `${API_URL_NEW}/location/allLocations`
const CHECK_PRICE_URL = `${API_URL_NEW}/offer/offers/offersForBuyer`
const CHECK_FINAL_PRICE_URL = `${API_URL_NEW}/inquiry/qtyBasedInquiry`

const productGradeListApi = (search: any): Promise<any> => {
    return axios
        .get(`${PRODUCTS_GRADES_URL}?page=1&perPage=20&search=${search}`)
        .then((d: AxiosResponse<any>) => d.data)
}

const locationListApi = (search: any): Promise<any> => {
    return axios
        .get(`${LOCATION_LIST_URL}?page=1&perPage=20&search=${search}`)
        .then((d: AxiosResponse<any>) => d.data)
}


const checkOfferPriceApi = (product_group_id: any, destination_location_id: any): Promise<any> => {
    return axios
        .get(`${CHECK_PRICE_URL}?perPage=100&page=1&is_home=1&product_group_id=${product_group_id}&destination_location_id=${destination_location_id}&order=ASC`)
        .then((d: AxiosResponse<any>) => d.data)
}

const checkFinalOfferPriceApi = (data: any): Promise<any> => {
    return axios
        .post(CHECK_FINAL_PRICE_URL,data)
        .then((d: AxiosResponse<any>) => d.data)
}



export { productGradeListApi, locationListApi, checkOfferPriceApi, checkFinalOfferPriceApi }
