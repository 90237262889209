import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { Link } from 'react-router-dom'
import 'react-upload-gallery/dist/style.css'
import { KTSVG } from '../../../../_metronic/helpers'
import { useDropzone } from 'react-dropzone'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const FileImportStateModal = ({ show, handleClose }: Props) => {
  // const stepperRef = useRef<HTMLDivElement | null>(null)

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return createPortal(
    <Modal
      id='kt_modal_create_user'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-650px modal fade show d-block'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2 className='fw-bolder w-100 text-center'>Import State File</h2>
      </div>
      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
        <Row>
          <Form.Label className='fw-bold fs-6 mb-2 form-label'>
            Upload State File
          </Form.Label>
          <Col className='text-end mt-2'>
            <div onClick={open}>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className='dz-message needsclick'>
                  <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                    <KTSVG
                      path='/media/icons/duotune/files/fil009.svg'
                      className='svg-icon-muted svg-icon-2hx'
                    />
                  </span>
                  <div className='ms-4'>
                    <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                      Drag/Drop files here or click here
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <aside className='mt-4 text-start'>
              {/* <h4>Files</h4> */}
              <ul>{files}</ul>
            </aside>
          </Col>
        </Row>
        <div>
          <Link to='#' className='text-gray-500 text-hover-primary mb-2 pt-5 d-inline-block'>
            Download style sheet
          </Link>
        </div>
        <div>
          <Row>
            <div className='text-end pt-8'>
              <Button
                type='reset'
                onClick={handleClose}
                className='btn btn-danger me-3'
                data-kt-users-modal-action='cancel'
              >
                Cancel
              </Button>
              <Button type='reset' className='btn btn-primary' data-kt-users-modal-action='submit'>
                <span className='indicator-label'>Save</span>
              </Button>
            </div>
          </Row>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { FileImportStateModal }
