import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { checkDisplayValue } from '../../../utils/utils'
import { getInquiryStatusApi } from '../_requests'

type Props = {
  setMessage: (type:string,message:string) => void
}

const StatusInquiryWithCounter = ({setMessage}:Props) => {
  const [inquiryStatusData,setInquiryStatusData] = useState({
    "Pending": "",
    "Negotiation": "",
    "quotationSent": "",
    "quotationConfirmed": "",
    "vehicleAssigned": "",
    "invoiceGenerated": "",
    "vehicleDispatched": "",
    "paymentReceived": "",
    "vehicleDelivered": "",
    "transactionCompleted":""
  })
  const [loading,setLoading] = useState(true)

  const callDashboardReport = async () => {
    try{
      setLoading(true)
      let {responseStatus, responseMessage, responseData} = await getInquiryStatusApi()
      console.log(":responseDataresponseData",responseData)
      if(responseStatus == 200){
        let data = {} as any
        if(responseData.length){
          data = responseData[0]
        }
        setInquiryStatusData({
          "Pending": data?.Pending ?? "",
          "Negotiation": data?.Negotiation ?? "",
          "quotationSent": data?.quotationSent ?? "",
          "quotationConfirmed": data?.quotationConfirmed ?? "",
          "vehicleAssigned": data?.vehicleAssigned ?? "",
          "invoiceGenerated": data?.invoiceGenerated ?? "",
          "vehicleDispatched": data?.vehicleDispatched ?? "",
          "paymentReceived": data?.paymentReceived ?? "",
          "vehicleDelivered": data?.vehicleDelivered ?? "",
          "transactionCompleted": data?.transactionCompleted ?? "",
        })
        setLoading(false)
      }
      else{
        setMessage("danger",responseMessage)
        setLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setMessage("danger",message)
      setLoading(false)
    }
  } 

  useEffect(()=>{
    callDashboardReport()
  },[])

  return (
    <div className='row'>
      <div className='col-xl-12'>
        <div className='card inquirys-statues card-xl-stretch'>
          <div className='card-header align-items-center border-0 mt-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='fw-bold mb-2 text-dark fs-2'>Inquiry Statues Statistics</span>
            </h3>
          </div>
          <div className='card-body pt-5 overflow-scroll'>
            <div className='status-mian d-flex'>
              <div className='status d-flex flex-column'>
                <div className='fw-bold text-gray-800 fs-3'>
                  {loading ? 
                    <Spinner animation="border" variant="primary" /> : 
                    checkDisplayValue(inquiryStatusData?.Pending)
                  }
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-success fs-1'></i>
                </div>
                <span className='me-5 badge badge-light-success text-success'>Pending</span>
              </div>
              <div className='status d-flex flex-column'>
                <div className='fw-bold text-gray-800 fs-3'>
                  {loading ? 
                    <Spinner animation="border" variant="primary" /> : 
                    checkDisplayValue(inquiryStatusData?.Negotiation)
                  }
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-danger fs-1'></i>
                </div>
                <span className='me-5 badge badge-light-danger text-danger'>Revised</span>
              </div>
              {/* <div className='status d-flex flex-column'>
                <div className='fw-bold text-gray-800 fs-3'>
                  {loading ? 
                    <Spinner animation="border" variant="primary" /> : 
                    checkDisplayValue(inquiryStatusData?.quotationSent)
                  }
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-info fs-1'></i>
                </div>
                <span className='me-5 badge badge-light-info text-info'>Quotation Sent</span>
              </div> */}
              <div className='status d-flex flex-column'>
                <div className='fw-bold text-gray-800 fs-3'>
                  {loading ? 
                    <Spinner animation="border" variant="primary" /> : 
                    checkDisplayValue(inquiryStatusData?.quotationConfirmed)
                  }
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <span className='me-5 badge badge-light-primary text-primary'>Order Confirm</span>
              </div>
              <div className='status d-flex flex-column'>
                <div className='fw-bold text-gray-800 fs-3'>
                  {loading ? 
                    <Spinner animation="border" variant="primary" /> : 
                    checkDisplayValue(inquiryStatusData?.vehicleAssigned)
                  }
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-success fs-1'></i>
                </div>
                <span className='me-5 badge badge-light-success text-success'>Vehicle assigned</span>
              </div>
              <div className='status d-flex flex-column'>
                <div className='fw-bold text-gray-800 fs-3'>
                  {loading ? 
                    <Spinner animation="border" variant="primary" /> : 
                    checkDisplayValue(inquiryStatusData?.invoiceGenerated)
                  }
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-danger fs-1 '></i>
                </div>
                <span className='me-5 badge badge-light-danger text-danger'>Invoice Generated</span>
              </div>
              <div className='status d-flex flex-column'>
                <div className='fw-bold text-gray-800 fs-3'>
                  {loading ? 
                    <Spinner animation="border" variant="primary" /> : 
                    checkDisplayValue(inquiryStatusData?.vehicleDispatched)
                  }
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <span className='me-5 badge badge-light-primary text-primary'>Vehicle Dispatched</span>
              </div>
              <div className='status d-flex flex-column'>
                <div className='fw-bold text-gray-800 fs-3'>
                  {loading ? 
                    <Spinner animation="border" variant="primary" /> : 
                    checkDisplayValue(inquiryStatusData?.paymentReceived)
                  }
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-success fs-1'></i>
                </div>
                <span className='me-5 badge badge-light-success text-success'>Payment Received</span>
              </div>
              <div className='status d-flex flex-column'>
                <div className='fw-bold text-gray-800 fs-3'>
                  {loading ? 
                    <Spinner animation="border" variant="primary" /> : 
                    checkDisplayValue(inquiryStatusData?.vehicleDelivered)
                  }
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-danger fs-1 '></i>
                </div>
                <span className='me-5 badge badge-light-danger text-danger'>Vehicle Delivered</span>
              </div>
              <div className='status d-flex flex-column'>
                <div className='fw-bold text-gray-800 fs-3'>
                  {loading ? 
                    <Spinner animation="border" variant="primary" /> : 
                    checkDisplayValue(inquiryStatusData?.transactionCompleted)
                  }
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <span className=' badge bg-light-primary text-primary'>Transaction Completed</span>
              </div>
            </div>
          </div>
          {/* <div className='border-0'>
            <h3>
              <span className='card-label fw-bold fs-3 mb-1'>Inquirys statues Statistics </span>
            </h3>
          </div>
          <div className='py-3 pt-0'>
            <div className='d-flex my-2'>
              <div className='me-3'>
                <div
                  style={{backgroundColor: '#20B2AA', color: '#fff'}}
                  className='btn badged text-center flex flex-column fw-bold me-auto px-4 py-3'
                >
                  <span className='fs-1 d-block'>2,234 </span>
                  <span className='fs-6'> Pending</span>
                </div>
              </div>
              <div className='me-3 '>
                <div className='btn badged text-white text-center flex flex-column bg-danger badge-light-danger fw-bold me-auto px-4 py-3'>
                  <span className='fs-1 d-block'>5,123 </span>
                  <span className='fs-6'> Negotiation</span>
                </div>
              </div>
              <div className='me-3 '>
                <div className='btn badged text-white text-center flex flex-column bg-primary badge-light-danger fw-bold me-auto px-4 py-3'>
                  <span className='fs-1 d-block'>6,500 </span>
                  <span className='fs-6'>Confirmed</span>
                </div>
              </div>
              <div className='me-3 '>
                <div className='btn badged text-white text-center flex flex-column bg-success badge-light-danger fw-bold me-auto px-4 py-3'>
                  <span className='fs-1 d-block'>4,500 </span>
                  <span className='fs-6'>Completed</span>
                </div>
              </div>
              <div className='me-3 '>
                <div className='btn badged text-white text-center flex flex-column bg-danger badge-light-danger fw-bold me-auto px-4 py-3'>
                  <span className='fs-1 d-block'>1,523 </span>
                  <span className='fs-6'> Vehicle assigned</span>
                </div>
              </div>
              <div className='me-3 '>
                <div
                  style={{backgroundColor: '#20B2AA', color: '#fff'}}
                  className='btn badged text-center flex flex-column fw-bold me-auto px-4 py-3 '
                >
                  <span className='fs-1 d-block'>152 </span>
                  <span className='fs-6'> Invoice Generated</span>
                </div>
              </div>
              <div className='me-3'>
                <div className='btn badged text-white text-center flex flex-column bg-primary fw-bold me-auto px-4 py-3'>
                  <span className='fs-1 d-block'>1,352 </span>
                  <span className='fs-6'> Payment Received</span>
                </div>
              </div>
              <div className='me-3'>
                <div className='btn badged text-white text-center flex flex-column bg-success fw-bold me-auto px-4 py-3'>
                  <span className='fs-1 d-block'>2,342 </span>
                  <span className='fs-6'>Transaction Completed</span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default StatusInquiryWithCounter
