import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {districtsColumns} from './columns/_columns'
import {District} from '../core/_models'
// import {DistrictsListLoading} from '../components/loading/DistrictsListLoading'
// import {SufalamsListPagination} from '../components/pagination/DistrictsListPagination'
// import { DistrictModel, defaultDistricts, KTCardBody } from '../../../../../_metronic/helpers'
import { DistrictsListLoading } from '../components/loading/DistrictsListLoading'
import { DistrictsListPagination } from '../components/pagination/DistrictsListPagination'
import { defaultDistricts, KTCardBody } from '../../../../../../_metronic/helpers'
import leaderRoles from '../../../../../utils/leaderRoles'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { checkEmptyValue } from '../../../../../utils/utils'

const DistrictsTable = () => {
  const districts = useQueryResponseData()
  // const districts:District[] = defaultDistricts
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => districts, [districts])
  const columns = useMemo(() => districtsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const {state, updateState} = useQueryRequest()

  const leaderPremission = () => {
    return leaderRoles() && (state?.filter == undefined)
  }

  
  return (
    <KTCardBody className='py-4  '>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer dist-tbl-overflow'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light'>
              {headers.map((column: ColumnInstance<District>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          {!leaderPremission() ? <>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<District>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          </>:
          <tbody className='text-gray-600 fw-bold'>
            <tr>
              <td colSpan={7}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  No matching records found
                </div>
              </td>
            </tr>
          </tbody>
          }
        </table>
      </div>
      {!leaderPremission() ? <DistrictsListPagination /> : ""}
      {isLoading && <DistrictsListLoading />}
    </KTCardBody>
  )
}

export {DistrictsTable}
