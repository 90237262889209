import {useEffect, useState} from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
// import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
// import { initialQueryState, KTSVG } from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import Select from 'react-select'
import { useQueryResponseData } from '../../../states-list/core/QueryResponseProvider'
import { getStateData } from '../../core/_requests'

const DistrictsListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()
  const [reqData,setReqData] = useState<any>({})
  const [stateList,setStateList] = useState<any>([])
  const [isModelOpen, setIsModelOpen] = useState<any>(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const getStateList = async () => {
    let {responseStatus, data} = await getStateData()
    if(responseStatus == 200 && data.length){
      setStateList(data.map((x:any)=>{
        x.label = x.name
        x.value = x.id
        return x
      }))
    }
  }

  useEffect(()=>{
    getStateList()
  },[])

  const resetData = () => {
    setReqData({})
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    updateState({
      filter: reqData,
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        onClick={() => {
          setIsModelOpen(!isModelOpen)
        }}
        // data-kt-menu-trigger='click'
        // data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className={`menu menu-sub menu-sub-dropdown w-600px w-md-625px position-absolute ${
          isModelOpen ? 'd-block' : 'd-none'
        }`} data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <Row className='mb-7'>
            <Col xl={6}>
              <Form.Group>
                <Form.Label className=' fw-bold fs-6 mb-1 form-label'>District Name</Form.Label>
                <Form.Control type="email" 
                  placeholder="Enter District" 
                  onChange={(e:any)=>{
                    setReqData({...reqData,...{"district_name":e.target.value.trimStart()}})
                  }}
                  value={reqData?.district_name ?? ""}
                />
              </Form.Group>
            </Col>
            <Col xl={6}>
              <Form.Group>
                <Form.Label className=' fw-bold fs-6 mb-1 form-label'>Select State</Form.Label>
                <Select
                  options={stateList}
                  className='custom_select mb-3'
                  classNamePrefix='Select'
                  placeholder='Select State'
                  onChange={(e:any)=>{
                    setReqData({...reqData,...{"state_name":e.value}})
                  }}
                  value={reqData.state_name ? stateList.find((x:any)=>x.value == reqData.state_name) : ""}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className='d-flex justify-content-end'>
            <button
              disabled={isLoading}
              type='button'
              onClick={()=>{
                filterData()
                setIsModelOpen(false)
              }}
              className='btn btn-primary fw-bold px-6 me-5'
              // data-kt-menu-dismiss='true'
              // data-kt-user-table-filter='filter'
            >
              Apply
            </button>
            <button
              type='button'
              disabled={isLoading}
              onClick={()=>{
                resetData()
                setIsModelOpen(false)
              }}
              className='btn btn-light btn-active-light-primary fw-bold  px-6'
              // data-kt-menu-dismiss='true'
              // data-kt-user-table-filter='reset'
            >
              Reset
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {DistrictsListFilter}
