import { CKEditor } from "ckeditor4-react";

const CustomCkEditor = ({data,changeData}:any) => {
    return (
      <>
        <CKEditor
          initData={data}
          config={{
            toolbar: [
              // ['Styles', 'Format', 'Font', 'FontSize'],
              ['Bold', 'Italic'],
              ['NumberedList', 'BulletedList'],
              ['Undo', 'Redo'],
            ],
          
          }}
          onChange={evt => changeData(evt.editor.getData())}
        />
      </>
  )
}
 export default CustomCkEditor