// @ts-nocheck
import {Column} from 'react-table'
import {DistrictInfoCell} from './DistrictInfoCell'
import {DistrictLastLoginCell} from './DistrictLastLoginCell'
import {DistrictTwoStepsCell} from './DistrictTwoStepsCell'
import {DistrictActionsCell} from './DistrictActionsCell'
// import {DistrictSelectionCell} from './DistrictSelectionCell'
import {DistrictCustomHeader} from './DistrictCustomHeader'
// import {DistrictSelectionHeader} from './DistrictSelectionHeader'
import {District} from '../../core/_models'

const districtsColumns: ReadonlyArray<Column<District>> = [
  // {
  //   Header: (props) => <DistrictSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <DistrictSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <DistrictCustomHeader tableProps={props} title='District Name' className='min-w-125px' />,
    accessor: 'name',
    // Cell: ({...props}) => <DistrictInfoCell district={props.data[props.row.index]} />,
  },
  

  {
    Header: (props) => (
      <DistrictCustomHeader tableProps={props} title='State' className='min-w-125px' />
    ),
    accessor: 'stateInfo.name',
  },

  {
    Header: (props) => (
      <DistrictCustomHeader tableProps={props} title='Actions' className='text-start min-w-100px'  />
    ),
    id: 'actions',
    Cell: ({...props}) =>  <DistrictActionsCell  id={props.data[props.row.index].id} status={props.data[props.row.index].status}  />,
  },

  
]

export {districtsColumns}
