import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import {Button, Col, Form, Row, Toast, ToastContainer} from 'react-bootstrap'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import { SERVER_URL } from '../../ServerConfig'
import { getGradeList, getProductByIdApi, createProductApi, updateProductApi, getProductGradeList, uploadMedia, downloadMediaApi } from './products-list/core/_requests'
import {useDropzone} from 'react-dropzone'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { useLayout } from '../../../_metronic/layout/core'
import ProductBrochurePDFView from './ProductBrochurePDFView'

const AddEditProduct = ({isEditing}:any) => {
  const {config} = useLayout()
  const {id} = useParams<any>()
  const navigate = useNavigate()
  const [isLoading,setIsLoading] = useState(false)
  const [isSuccess,setIsSuccess] = useState(false)
  const [showProductBrochureModal, setShowProductBrochureModal] = useState<boolean>(false)
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })
  const [gradeGroupList,setGradeGroupList] = useState<any>([])
  const [productGroupList,setProductGroupList] = useState<any>([])
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<any>()

  const onDrop = useCallback((acceptedFiles:any) => {
    acceptedFiles.forEach((file:any) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = (event:any) => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result
        setFile(file)
        uploadProductPDFApi(file)
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  const uploadProductPDFApi = async (file:any) => {
    setIsLoading(true)
    try{
      let formData = new FormData()
      formData.append("product",file)
      var response = await uploadMedia(formData)
      if(response.newFilename != ""){
        setFileName(response.newFilename)
        setSessionMessage({type: 'success',message: "PDF Upload Successfully" ,show: true})
        formik.setFieldValue("product_brochure",response.newFilename)
      }
      else{
        setSessionMessage({type: 'danger',message: "Something Went Wrong" ,show: true})
      }
      setIsLoading(false)
    }
    catch(err){
      setSessionMessage({type: 'danger',message: "Something Went Wrong" ,show: true})
      setIsLoading(false)
    }
  }

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    }
  })

  const setGradeGroupData = async () => {
    try{  
      let data = await getGradeList()
      if(data.data){
        let newList = data.data.map((x:any,i:any)=>{
          x.label = x.name
          x.value = x.id
          return x
        })
        setGradeGroupList(newList)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setSessionMessage({type: 'danger',message: message ,show: true})
    }
  }

  const setProductGroupData = async (gradeid:any) => {
    try{  
      let data = await getProductGradeList(gradeid)
      if(data.data){
        let newList = data.data.map((x:any,i:any)=>{
          x.label = x.name
          x.value = x.id
          return x
        })
        setProductGroupList(newList)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setSessionMessage({type: 'danger',message: message ,show: true})
    }
  }

  const navigateToProductListing = () => {
    // 👇️ navigate to /contacts
    navigate('/product-management/products')
  }

  const validate = (values:any) => {
    const errors = {} as any;
    if(!values.product_variation){
      errors.product_variation = "The Product field is required"
    }
    if(!values.hsn){
      errors.hsn = "The HSN field is required"
    }
    if(!values.price && values.price != 0){
      errors.price = "The Rate field is required"
    }
    if(!values.grade_group_id){
      errors.grade_group_id = "The Grade Group field is required"
    }
    if(!values.product_group_id){
      errors.product_group_id = "The Product Group field is required"
    }
    if(!values.manufactured_by){
      errors.manufactured_by = "The Manufactured By field is required"
    }
    if(!values.sector){
      errors.sector = "The Sector field is required"
    }
    if(!values.place_of_origin){
      errors.place_of_origin = "The Place of origin field is required"
    }
    return errors
  };

  const formik = useFormik({
    validate,
    validateOnChange:false,
    initialValues: {
      product_variation: '',
      hsn: '',
      price: 0,
      grade_group_id:"",
      product_group_id:"",
      manufactured_by:"",
      sector:"",
      mfi_kvalue:"",
      density:"",
      special_characteristics:"",
      application:"",
      place_of_origin:2,
      remarks:"",
      status:1,
      product_brochure:"",
    },
    onSubmit: (values:any) => {
      addEditProductAction(values)
    }
  });

  console.log(formik)

  const addEditProductAction = async (data:any) => {
    try{
      if(!data.density || data.density == ""){
        delete data.density
      }
      setIsLoading(true)
      if(isEditing){
        var response = await updateProductApi(id,data)
      }
      else{
        var response = await createProductApi(data)
      }
      if(response.responseStatus == 200){
        setIsSuccess(true)
        setIsLoading(false)
        setTimeout(()=>{
          navigateToProductListing()
        },1500)
        setSessionMessage({type: 'success',message: response.responseMessage ,show: true})      
      }
      else{
        setSessionMessage({type: 'danger',message: response.responseMessage ,show: true})
        setIsLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setSessionMessage({type: 'danger',message: message ,show: true})
      setIsLoading(false)
    }
  }

  const setEditProduct = async() => {
    try{
      setIsLoading(true)
      let {responseData, responseStatus,responseMessage} = await getProductByIdApi(id)
      if(responseStatus == 200){
        formik.setFieldValue("product_variation",responseData?.product_variation ?? "")
        formik.setFieldValue("hsn",responseData?.hsn ?? "")
        formik.setFieldValue("price",responseData?.price ?? "")
        formik.setFieldValue("grade_group_id",responseData?.grade_group_id ?? "")
        formik.setFieldValue("product_group_id",responseData?.product_group_id ?? "")
        formik.setFieldValue("manufactured_by",responseData?.manufactured_by ?? "")
        formik.setFieldValue("sector",responseData?.sector ?? "")
        formik.setFieldValue("mfi_kvalue",responseData?.mfi_kvalue ?? "")
        formik.setFieldValue("density",responseData?.density ?? "")
        formik.setFieldValue("special_characteristics",responseData?.special_characteristics ?? "")
        formik.setFieldValue("application",responseData?.application ?? "")
        formik.setFieldValue("place_of_origin",responseData?.place_of_origin ?? "")
        formik.setFieldValue("remarks",responseData?.remarks ?? "")
        formik.setFieldValue("status",responseData?.status ?? "")
        formik.setFieldValue("product_brochure",responseData?.product_brochure ?? "")
        if(responseData.grade_group_id){
          setProductGroupData(responseData.grade_group_id)
        }
        setIsLoading(false)
      }
      else{
        setSessionMessage({type: 'danger',message: responseMessage ,show: true})
        setIsLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setSessionMessage({type: 'danger',message: message ,show: true})
      setIsLoading(false)
      setTimeout(()=>{
        navigateToProductListing()
      },2000)
    }
  }

  useEffect(()=>{
    setTimeout(()=>{
      setSessionMessage({type: '',message: '',show: false})
    },3000)
  },[sessionMessage.show])

  useEffect(()=>{
    setGradeGroupData()
  },[])

  useEffect(()=>{
    if(isEditing){
      setEditProduct()
    }
  },[id,isEditing])

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row className='mb-10'>
          <Col xs={4}>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'>Product Name</Form.Label>
              <Form.Control type='text' 
                className='form-control-solid' 
                placeholder='Product Name' 
                onChange={(e:any)=>{
                  formik.setFieldValue("product_variation",e.target.value.trimStart())
                }}
                value={formik.values.product_variation}
              />
            </Form.Group>
            <p className='text-danger'>{formik?.errors?.product_variation ?? "" as any} </p>
          </Col>
          <Col xs={4}>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'>HSN Code</Form.Label>
              <Form.Control type='text' 
                className='form-control-solid' 
                placeholder='HSN Code' 
                onChange={(e:any)=>{
                  formik.setFieldValue("hsn",e.target.value.trimStart())
                }}
                value={formik.values.hsn}
              />
            </Form.Group>
            <p className='text-danger'>{formik?.errors?.hsn ?? "" as any}</p>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs={4}>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'> Rate/Kg </Form.Label>
              <Form.Control type='number' 
                className='form-control-solid' 
                placeholder='Rate'
                onChange={(e:any)=>{
                  formik.setFieldValue("price",parseInt(e.target.value))
                }}
                value={formik.values.price} 
              />
            </Form.Group>
            <p className='text-danger'>{formik?.errors?.price ?? "" as any}</p>
          </Col>
          <Col xs={4}>
            <Form.Group className=' '>
              <Form.Label className='required form-label'> Grade Group</Form.Label>
            </Form.Group>
            <Select
              options={gradeGroupList as any}
              className='custom_select'
              classNamePrefix='Select'
              placeholder='Grade Group'
              onChange={(e:any)=>{
                setProductGroupData(e.value)
                formik.setFieldValue("grade_group_id",e.value)
              }}
              value={gradeGroupList.find((x:any)=>x.value == formik.values.grade_group_id)}
            />
            <p className='text-danger'>{formik?.errors?.grade_group_id ?? "" as any}</p>
          </Col>
          <Col xs={4}>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'> Product Group</Form.Label>
              <Select
                options={productGroupList as any}
                className='custom_select'
                classNamePrefix='Select'
                placeholder='Product Group'
                onChange={(e:any)=>{
                  formik.setFieldValue("product_group_id",e.value)
                }}
                value={productGroupList.find((x:any)=>x.value == formik.values.product_group_id)}
              />
            </Form.Group>
            <p className='text-danger'>{formik?.errors?.product_group_id ?? "" as any}</p>
          </Col>
        </Row>
        <Row className='mb-10'>
          <Col xs={4}>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'>Manufactured By</Form.Label>
              <Form.Control
                type='text'
                className='form-control-solid'
                placeholder='Manufactured By'
                onChange={(e:any)=>{
                  formik.setFieldValue("manufactured_by",e.target.value.trimStart())
                }}
                value={formik.values.manufactured_by}
              />
            </Form.Group>
            <p className='text-danger'>{formik?.errors?.manufactured_by ?? "" as any}</p>
          </Col>
          <Col xs={4}>
            <Form.Group className='mb-3'>
              <Form.Label className=' required form-label'> Sector </Form.Label>
              <Form.Control type='text' 
                className='form-control-solid' 
                placeholder='Sector'
                onChange={(e:any)=>{
                  formik.setFieldValue("sector",e.target.value.trimStart())
                }}
                value={formik.values.sector}
              />
            </Form.Group>
            <p className='text-danger'>{formik?.errors?.sector ?? "" as any}</p>
          </Col>
        </Row>
        <Row className='mb-5'>
          <Col xl={4}>
            <Col className='mb-5'>
              <Form.Group className='mb-3'>
                <Form.Label className='form-label'>MFI/Kvalue</Form.Label>
                <Form.Control type='text' 
                  className='form-control-solid' 
                  placeholder='MFI' 
                  onChange={(e:any)=>{
                    formik.setFieldValue("mfi_kvalue",e.target.value.trimStart())
                  }}
                  value={formik.values.mfi_kvalue}
                />
              </Form.Group>
              {/* <p className='text-danger'>{formik?.errors?.mfi_kvalue ?? ""}</p> */}
            </Col>
            <Col className='mb-5'>
              <Form.Group className='mb-3'>
                <Form.Label className=' form-label'> Density/Specific Gravity </Form.Label>
                <Form.Control
                  type='text'
                  className='form-control-solid'
                  placeholder='Density/Specific Gravity'
                  onChange={(e:any)=>{
                    formik.setFieldValue("density",e.target.value.trimStart())
                  }}
                  value={formik.values.density}
                />
              </Form.Group>
            </Col>
            <Col className='mb-3'>
              <Form.Group className='mb-3'>
                <Form.Label className=' required form-label'> Place Of Origin </Form.Label>
              </Form.Group>
              <Col className='d-flex mt-3'>
                <Form.Group className='mb-3 d-flex align-items-center me-5'>
                  <Form.Check
                    className='me-5 fs-5 border-radius-0 form-control-solid text-grey-800'
                    id={'domestic'}
                    label='Domestic'
                    type='radio'
                    name='category'
                    inline
                    checked={formik.values.place_of_origin === "2" as any}
                    onClick={()=>{
                      formik.setFieldValue('place_of_origin',"2")
                    }}
                  />
                  <Form.Check
                    className='me-5 fs-5 border-radius-0 form-control-solid text-grey-600'
                    id={'international'}
                    label='Overseas'
                    type='radio'
                    name='category'
                    inline
                    checked={formik.values.place_of_origin === "1" as any}
                    onClick={()=>{
                      formik.setFieldValue('place_of_origin',"1")
                    }}
                  />
                  {/* <Form.Check
                    className=' fs-5 border-radius-0 form-control-solid text-grey-800'
                    id={'both'}
                    label='Both'
                    type='radio'
                    name='category'
                    inline
                  /> */}
                </Form.Group>
              </Col>
              <p className='text-danger'>{formik?.errors?.place_of_origin ?? "" as any}</p>
            </Col>
          </Col>
          <Col xl={4}>
            <Form.Group className='mb-3'>
              <Form.Label className=' form-label'> Special Characteristics </Form.Label>
              <Form.Control
                type='text'
                as='textarea'
                rows={5}
                className='form-control-solid'
                onChange={(e:any)=>{
                  formik.setFieldValue("special_characteristics",e.target.value.trimStart())
                }}
                value={formik.values.special_characteristics}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group className='mb-3'>
              <Form.Label className='form-label'>Application</Form.Label>
              <Form.Control
                type='text'
                as='textarea'
                rows={5}
                className='form-control-solid'
                placeholder='Application'
                onChange={(e:any)=>{
                  formik.setFieldValue("application",e.target.value.trimStart())
                }}
                value={formik.values.application}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mb-5 mt-12'>
          <Col xl={4}>
            <Form.Group className='mb-3'>
              <Form.Label className=' form-label'>Remarks </Form.Label>
              <Form.Control
                type='text'
                as='textarea'
                rows={4}
                className='form-control-solid border-0 '
                placeholder=' '
                onChange={(e:any)=>{
                  formik.setFieldValue("remarks",e.target.value.trimStart())
                }}
                value={formik.values.remarks}
              />
              <Form.Label className='mt-1 d-block text-end form-label'> Maximum 50 words</Form.Label>
            </Form.Group>
          </Col>
          <Col xl={4}>
            <Col className='text-start'>
              <Form.Label className='form-label text'>Upload PDF</Form.Label>
              <div onClick={open}>
                <div {...getRootProps({className: 'dropzone py-12'})}>
                  <input {...getInputProps()} />
                  <div className='dz-message needsclick'>
                    <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil009.svg'
                        className='svg-icon-muted svg-icon-2hx'
                      />
                    </span>
                    <div className='ms-4'>
                      <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                        Drag/Drop PDF here or click here
                      </h3>
                      {/* <span className='fw-semibold fs-4 text-muted'>Upload files</span> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* {formik.values.product_brochure && 
                <Col className='my-2'>
                  <Col className='text-start'>
                    <a href={downloadMediaApi(formik.values.product_brochure)}>View Pdf</a>
                  </Col>
                </Col>} */}
            </Col>
          </Col>
          <Col xl={4}>
            <Col className='text-gray-600 fw-bold fs-5 mt-7'>
              <div className='d-flex align-items-center gap-2 gap-lg-3 position-relative'>
                {config.app?.toolbar?.primaryButton && (
                  <Link
                    to='#'
                    onClick={() =>{
                      if(formik.values?.product_brochure != '')
                      setShowProductBrochureModal(true)
                    }}
                    className='btn btn-sm fw-bold badge-light-primary mt-2'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_app'
                  >
                    <img
                      alt=''
                      src={toAbsoluteUrl('/media/demos/download-pdf.png')}
                      className='border-white h-30px w-100px h-100px me-3 '
                    />
                    Click to View
                  </Link>
                )}
                <a
                  style={{
                    position: 'absolute',
                    top: '-36px',
                    left: '-12px',
                    background: '#ff00004d',
                    borderRadius: '50%',
                  }}
                  className='btn btn-sm btn-icon mt-8 btn-color-danger'
                  onClick={() => {
                    formik.setFieldValue('product_brochure', '')
                  }}
                >
                  <i className='fa-solid fa-trash-can fs-3 text-danger'></i>
                </a>
                <ProductBrochurePDFView
                  loadingFile={false}
                  show={showProductBrochureModal}
                  handleClose={() => setShowProductBrochureModal(false)}
                  fileName={formik.values.product_brochure}
                />
              </div>
              <Form.Label className='form-label'> {formik.values.product_brochure}</Form.Label>
            </Col>
          </Col>
        </Row>
        <Row className='mb-5'>
          <Col className='d-flex justify-content-end'>
            <Button type='submit' 
              value='' 
              className='btn btn-sm fs-5 btn-primary  me-5'
              disabled={isLoading || isSuccess}
            >
              {isLoading ? "Please Wait...." : 
                (isEditing ? "Edit" : "Add")
              }
            </Button>
            <Button
              type='button'
              value=''
              onClick={navigateToProductListing}
              className='btn btn-sm fs-5 btn-light-primary'
            >
              Cancel{' '}
            </Button>
          </Col>
        </Row>
        <ToastContainer className='p-3 position-fixed' position={'top-center'}>
          <Toast
            show={sessionMessage.show}
            bg={sessionMessage.type}
          >
            <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      </Form>
    </>
  )
}

export default AddEditProduct
