import { Col, Modal, Toast, ToastContainer } from "react-bootstrap"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { disable2faApi } from "../core/_requests";

const Disable2fa = ({ showDisable2FA, setShowDisable2FA, setShowEnableBtn }: any) => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const modalsRoot = document.getElementById('root-modals') || document.body
    const [loading, setLoading] = useState(false)
    const [sessionMessage, setSessionMessage] = useState({
        type: '',
        message: '',
        show: false,
    })
    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const disable2FA = async () => {
        try {
            setLoading(true)
            let userData = localStorage.getItem("objUser") as any
            userData = JSON.parse(userData)
            await disable2faApi({ user_id: userData?.id ?? "" }).then((data: any) => {
                if (data?.responseStatus == 200) {
                    setTimeout(()=>{
                        setShowDisable2FA(false)
                        setShowEnableBtn(true)
                    },2000)
                    setLoading(false)
                    userData.is_2fa_enable = false
                    localStorage.setItem("objUser",JSON.stringify(userData))
                    setSessionMessage({ type: 'success', message: data?.responseMessage ?? "Response Close With 200 Status Code", show: true })                    
                } else {
                    setLoading(false)
                    setSessionMessage({ type: 'danger', message: data?.responseMessage ?? "SomeThing Went Wrong", show: true })
                }
            }).catch((error: any) => {
                setLoading(false)
                setSessionMessage({ type: 'danger', message: error?.responseMessage ?? "SomeThing Went Wrong", show: true })
            })
        }
        catch (error: any) {
            setSessionMessage({ type: 'danger', message: error?.message ?? "SomeThing Went Wrong", show: true })
            setLoading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        if (sessionMessage.show) {
            setTimeout(() => {
                setSessionMessage({ type: '', message: '', show: false })
            }, 4500)
        }
    }, [sessionMessage.message])

    const handleClose = () => { setShowDisable2FA(false) }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-450px'
            show={showDisable2FA}
            onHide={handleClose}
            onEntered={loadStepper}
            backdrop={true}
        >
            <div className='modal-body pt-10'>
                <div className='dlt-mdl-icon text-center mb-10'>
                    <i className='fa-solid fa-circle-exclamation text-danger'></i>
                    {/* <i class="fa-regular fa-circle-exclamation"></i> */}
                </div>
                <h3 className='text-danger text-center'>Disable 2FA?</h3>
                <p className='text-center mb-5 fs-4'> Are you Sure, you wan't to disable 2FA ?</p>

                <Col className='me-5  mt-10 mb-10 text-center '>
                    <button
                        type='button'
                        value=''
                        className='btn btn-sm px-12 py-3  me-5 btn btn-danger fs-4'
                        onClick={() => {
                            disable2FA()
                        }}
                        disabled={loading}
                    >
                        {' '}
                        {loading ? "Please Wait..." : "Disable 2FA"}
                    </button>
                    <button
                        type='button'
                        value=''
                        className='btn btn-sm px-12 py-3 me-5 btn btn-light fs-4'
                        onClick={() => setShowDisable2FA(false)}
                    >
                        {' '}
                        No{' '}
                    </button>
                </Col>
                <ToastContainer className='p-3' position={'top-end'}>
                    <Toast
                        show={sessionMessage.show}
                        onClose={() => {
                            setSessionMessage({
                                type: '',
                                message: '',
                                show: false,
                            })
                        }}
                        bg={sessionMessage.type}
                    >
                        {/* <Toast.Header>Error</Toast.Header> */}
                        <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </Modal>,
        modalsRoot
    )
}
export default Disable2fa