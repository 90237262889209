import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Transport, TransportsQueryResponse} from './_models'
// import { User, UsersQueryResponse } from './_models'

const API_URL_NEW = process.env.REACT_APP_API_URL_NEW
const API_URL = process.env.REACT_APP_THEME_API_URL
const TRANSPORT_URL = `${API_URL}/transport`
const GET_TRANSPORTS_URL = `${API_URL}/users/query`

const GET_TRANSPORTS_LIST_URL = `${API_URL_NEW}/transport`
const ALL_LOCATION_URL = `${API_URL_NEW}/location/allLocations`
const ALL_STATE_LOCATION_URL = `${API_URL_NEW}/location`

const getTransport = (query: string): Promise<TransportsQueryResponse> => {
  return axios
    .get(`${GET_TRANSPORTS_LIST_URL}?${query}`)
    .then((d: AxiosResponse<TransportsQueryResponse>) => d.data)
}
const getTransportByIdNew = (id: string): Promise<TransportsQueryResponse> => {
  return axios
    .get(`${GET_TRANSPORTS_LIST_URL}/${id}`)
    .then((d: AxiosResponse<TransportsQueryResponse>) => d.data)
}
const createTransportNew = (values: any): Promise<TransportsQueryResponse> => {
  return axios
    .post(`${GET_TRANSPORTS_LIST_URL}`, values)
    .then((d: AxiosResponse<TransportsQueryResponse>) => d.data)
}
const updateTransportNew = (id: string, values: any): Promise<TransportsQueryResponse> => {
  return axios
    .patch(`${GET_TRANSPORTS_LIST_URL}/${id}`, {...values})
    .then((d: AxiosResponse<TransportsQueryResponse>) => d.data)
}

const getAllLocation = (): Promise<any> => {
  return axios.get(`${ALL_LOCATION_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getTransportById = (id: ID): Promise<Transport | undefined> => {
  return axios
    .get(`${TRANSPORT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Transport>>) => response.data)
    .then((response: Response<Transport>) => response.data)
}

const createTransport = (transport: Transport): Promise<Transport | undefined> => {
  return axios
    .put(TRANSPORT_URL, transport)
    .then((response: AxiosResponse<Response<Transport>>) => response.data)
    .then((response: Response<Transport>) => response.data)
}

const updateTransport = (transport: Transport): Promise<Transport | undefined> => {
  return axios
    .post(`${TRANSPORT_URL}/${transport.id}`, transport)
    .then((response: AxiosResponse<Response<Transport>>) => response.data)
    .then((response: Response<Transport>) => response.data)
}

const deleteTransport = (id: string): Promise<any> => {
  return axios
    .delete(`${GET_TRANSPORTS_LIST_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data)
}

const deleteSelectedTransports = (transportIds: Array<ID>): Promise<void> => {
  const requests = transportIds.map((id) => axios.delete(`${TRANSPORT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getDistrictListByStateID = (id: any): Promise<any> => {
  let state = `?state_name=${id}&perPage=500&page=1`
  console.log(state, 'state')

  // @ts-ignore
  return axios.get(`${ALL_STATE_LOCATION_URL}${state}`).then((d: AxiosResponse<any>) => d.data)
}

export {
  getTransport,
  deleteTransport,
  deleteSelectedTransports,
  getTransportById,
  createTransport,
  updateTransport,
  getTransportByIdNew,
  createTransportNew,
  updateTransportNew,
  getAllLocation,
  getDistrictListByStateID,
}
