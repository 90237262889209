import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
// import { KTSVG } from "../../../_metronic/helpers";
// import { useLayout } from "../../../_metronic/layout/core";
import Select from 'react-select'
import { CKEditor } from 'ckeditor4-react';
import { KTSVG } from "../../../../_metronic/helpers";
import { PageTitle } from "../../../../_metronic/layout/core";
import { useNavigate } from "react-router-dom";
import AddEditNewsArticle from "./AddEditNewsArticle";

const categoryList = [
    {value: 'Select', label: 'Select Category'},
      {value: 'category1', label: 'Category Name 01'},
      {value: 'category2', label: 'Category Name 02'},
      {value: 'category3', label: 'Category Name 03'},
      {value: 'category4', label: 'Category Name 04'},
      {value: 'category5', label: 'Category Name 05'},
      {value: 'category6', label: 'Category Name 06'},

    ]


const AddArticleForm = () => {

  const navigate = useNavigate();
  const navigateListarticle = () => {
    // 👇️ navigate to /
    navigate('/news-articles/articles');
  };


    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
      })
    
      const files = acceptedFiles.map((file: any) => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      ))

    
return (

    <>
    <PageTitle> Add News Article </PageTitle>
    <Col className='position-absolute me-5 end-0 tp-btn-mn  '>
    <button
          type='button'
          onClick={navigateListarticle}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
        </Col>
          <Col xl={12} className='card card-body backgroung-transparent ms-auto me-auto   p-12  mt-10 2'>
           <AddEditNewsArticle isEditing={false}/>
          </Col>
    </>
)
}

export default AddArticleForm