import React from "react";
import { Col } from "react-bootstrap";
import {  PageTitle } from "../../../_metronic/layout/core";
// import CategoriesPage from "./NewsCategory.tsx/CategoriesPage";
import NewsArticles from "./news-articles/NewsArticles";
import CategoriesPage from "./news-category/CategoriesPage";
// import NewsArticles from "./NewsArticles";

// const wizardsBreadCrumbs: Array<PageLink> = [
//     {
//       title: 'Locations ',
//       path: '/crafted/pages/wizards/horizontal',
//       isSeparator: false,
//       isActive: false,
//     },
//     {
//       title: '',
//       path: '',
//       isSeparator: true,
//       isActive: false,
//     },
//   ]

const NewsContentManagement = () => {

return (
    <>

<PageTitle>Manage News</PageTitle>
      <Col className='mb-5'></Col>
      <Col className='card card-body backgroung-transparent   p-12  mt-10   p-12'>
        <Col xl={12} className='d-flex justify-content-center'>
          <div className='menu menu-column ' data-kt-menu='true'>
            <div
              className='d-flex flex-column bgi-no-repeat rounded-top'
              style={{backgroundImage: `url('{toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
            >
              <ul className='nav nav-line-tabs nav-line-tabs-2x  nav-stretch fw-bold  px-0'>
                <li className='nav-item mb-0'>
                  <a
                    className='pe-9 px-9 nav-link text-dark opacity-75 fs-4 px-5  opacity-state-100 pb-4  active'
                    data-bs-toggle='tab'
                    href='#kt_topbar_notifications_1'
                  >
                    News Articles
                  </a>
                </li>

                <li className='nav-item mb-0'>
                  <a
                    className='pe-9 px-9 nav-link text-dark opacity-75 fs-4  opacity-state-100 pb-4'
                    data-bs-toggle='tab'
                    href='#kt_topbar_notifications_2'
                  >
                    News Categories
                  </a>
                </li>
              </ul>
            </div>

            <div className='tab-content'>
              <div
                className='tab-pane show active fade'
                id='kt_topbar_notifications_1'
                role='tabpanel'
              >
                <NewsArticles />
              </div>

              <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
                <CategoriesPage />
              </div>
            </div>
          </div>
        </Col>
        
      </Col>

    
    </>
)



}
export default NewsContentManagement