import React, {useRef, useState} from 'react'
import {Button, Col, Form, Row, Table} from 'react-bootstrap'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
// import UserGstCertificate from '../../user-management/UserGstCertificate'



const CategoriesPage = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [isShowNameError, setIsShowNameError] = useState(false)
  const [isShowSourceFild, setIsShowSourceFild] = useState(false)

  return (
    <>
  
              
           
    </>
  )
}

export default CategoriesPage
