export interface MessageModel {
  user: number
  type: 'in' | 'out'
  text: string
  time: string
  template?: boolean
}

const defaultMessages: Array<MessageModel> = [
  {
    user: 4,
    type: 'in',
    text: 'How likely are you to recommend our company to your friends and family ?',
    time: '2 mins',
  },
  {
    user: 2,
    type: 'out',
    text: 'Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.',
    time: '5 mins',
  },
  {
    user: 4,
    type: 'in',
    text: 'Ok, Understood!',
    time: '1 Hour',
  },
  {
    user: 2,
    type: 'out',
    text: 'You’ll receive notifications for all issues, pull requests!',
    time: '2 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'You can unwatch this repository immediately by clicking here: <a href="https://keenthemes.com">Keenthemes.com</a>',
    time: '3 Hours',
  },
  {
    user: 2,
    type: 'out',
    text: 'Most purchased Business courses during this sale!',
    time: '4 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided',
    time: '5 Hours',
  },
  {
    template: true,
    user: 2,
    type: 'out',
    text: '',
    time: 'Just now',
  },
  {
    template: true,
    user: 4,
    type: 'in',
    text: 'Right before vacation season we have the next Big Deal for you.',
    time: 'Just now',
  },
]

export interface UserInfoModel {
  initials?: { label: string; state: 'warning' | 'danger' | 'primary' | 'success' | 'info' }
  name: string
  avatar?: string
  email: string
  position: string
  online: boolean
}

const defaultUserInfos: Array<UserInfoModel> = [
  {
    name: 'Emma Smith 2',
    avatar: 'avatars/300-6.jpg',
    email: 'e.smith@kpmg.com.au',
    position: 'Art Director',
    online: false,
  },
  {
    name: 'Melody Macy',
    initials: { label: 'M', state: 'danger' },
    email: 'melody@altbox.com',
    position: 'Marketing Analytic',
    online: true,
  },
  {
    name: 'Max Smith',
    avatar: 'avatars/300-1.jpg',
    email: 'max@kt.com',
    position: 'Software Enginer',
    online: false,
  },
  {
    name: 'Sean Bean',
    avatar: 'avatars/300-5.jpg',
    email: 'sean@dellito.com',
    position: 'Web Developer',
    online: false,
  },
  {
    name: 'Brian Cox',
    avatar: 'avatars/300-25.jpg',
    email: 'brian@exchange.com',
    position: 'UI/UX Designer',
    online: false,
  },
  {
    name: 'Mikaela Collins',
    initials: { label: 'M', state: 'warning' },
    email: 'mikaela@pexcom.com',
    position: 'Head Of Marketing',
    online: true,
  },
  {
    name: 'Francis Mitcham',
    avatar: 'avatars/300-9.jpg',
    email: 'f.mitcham@kpmg.com.au',
    position: 'Software Arcitect',
    online: false,
  },

  {
    name: 'Olivia Wild',
    initials: { label: 'O', state: 'danger' },
    email: 'olivia@corpmail.com',
    position: 'System Admin',
    online: true,
  },
  {
    name: 'Neil Owen',
    initials: { label: 'N', state: 'primary' },
    email: 'owen.neil@gmail.com',
    position: 'Account Manager',
    online: true,
  },
  {
    name: 'Dan Wilson',
    avatar: 'avatars/300-23.jpg',
    email: 'dam@consilting.com',
    position: 'Web Desinger',
    online: false,
  },
  {
    name: 'Emma Bold',
    initials: { label: 'E', state: 'danger' },
    email: 'emma@intenso.com',
    position: 'Corporate Finance',
    online: true,
  },
  {
    name: 'Ana Crown',
    avatar: 'avatars/300-12.jpg',
    email: 'ana.cf@limtel.com',
    position: 'Customer Relationship',
    online: false,
  },
  {
    name: 'Robert Doe',
    initials: { label: 'A', state: 'info' },
    email: 'robert@benko.com',
    position: 'Marketing Executive',
    online: true,
  },
  {
    name: 'John Miller',
    avatar: 'avatars/300-13.jpg',
    email: 'miller@mapple.com',
    position: 'Project Manager',
    online: false,
  },
  {
    name: 'Lucy Kunic',
    initials: { label: 'L', state: 'success' },
    email: 'lucy.m@fentech.com',
    position: 'SEO Master',
    online: true,
  },
  {
    name: 'Ethan Wilder',
    avatar: 'avatars/300-21.jpg',
    email: 'ethan@loop.com.au',
    position: 'Accountant',
    online: true,
  },
]

const messageFromClient: MessageModel = {
  user: 4,
  type: 'in',
  text: 'Thank you for your awesome support!',
  time: 'Just now',
}

export interface AlertModel {
  title: string
  description: string
  time: string
  icon: string
  state: 'primary' | 'danger' | 'warning' | 'success' | 'info'
}

const defaultAlerts: Array<AlertModel> = [
  {
    title: 'Project Alice',
    description: 'Phase 1 development',
    time: '1 hr',
    icon: 'icons/duotune/technology/teh008.svg',
    state: 'primary',
  },
  {
    title: 'HR Confidential',
    description: 'Confidential staff documents',
    time: '2 hrs',
    icon: 'icons/duotune/general/gen044.svg',
    state: 'danger',
  },
  {
    title: 'Company HR',
    description: 'Corporeate staff profiles',
    time: '5 hrs',
    icon: 'icons/duotune/finance/fin006.svg',
    state: 'warning',
  },
  {
    title: 'Project Red',
    description: 'New frontend admin theme',
    time: '2 days',
    icon: 'icons/duotune/files/fil023.svg',
    state: 'success',
  },
  {
    title: 'Project Breafing',
    description: 'Product launch status update',
    time: '21 Jan',
    icon: 'icons/duotune/maps/map001.svg',
    state: 'primary',
  },
  {
    title: 'Banner Assets',
    description: 'Collection of banner images',
    time: '21 Jan',
    icon: 'icons/duotune/general/gen006.svg',
    state: 'info',
  },
  {
    title: 'Icon Assets',
    description: 'Collection of SVG icons',
    time: '20 March',
    icon: 'icons/duotune/art/art002.svg',
    state: 'warning',
  },
]
export interface LogModel {
  code: string
  state: 'success' | 'danger' | 'warning'
  message: string
  time: string
}





const defaultLogs: Array<LogModel> = [
  { code: '200 OK', state: 'success', message: 'New order', time: 'Just now' },
  { code: '500 ERR', state: 'danger', message: 'New customer', time: '2 hrs' },
  { code: '200 OK', state: 'success', message: 'Payment process', time: '5 hrs' },
  { code: '300 WRN', state: 'warning', message: 'Search query', time: '2 days' },
  { code: '200 OK', state: 'success', message: 'API connection', time: '1 week' },
  { code: '200 OK', state: 'success', message: 'Database restore', time: 'Mar 5' },
  { code: '300 WRN', state: 'warning', message: 'System update', time: 'May 15' },
  { code: '300 WRN', state: 'warning', message: 'Server OS update', time: 'Apr 3' },
  { code: '300 WRN', state: 'warning', message: 'API rollback', time: 'Jun 30' },
  { code: '500 ERR', state: 'danger', message: 'Refund process', time: 'Jul 10' },
  { code: '500 ERR', state: 'danger', message: 'Withdrawal process', time: 'Sep 10' },
  { code: '500 ERR', state: 'danger', message: 'Mail tasks', time: 'Dec 10' },
]


export interface BuyerModel {
  name?: string
  grade_group_1?: string
  grade_group_2?: string
  grade_group_3?: string
  grade_group_4?: string
  grade_group_5?: string
  email?: string
  firm_name?: string
  category?: string
  install_capacity?: string
  sales_district?: string
  approved?: string
  status?: string
  epr?: string
  sup?: string

}

const defaultBuyers: Array<BuyerModel> = [
  {

    name: 'MR.TEJAS SHAH',
    grade_group_1: 'Grade Group 1',
    grade_group_2: 'Grade Group 2',
    grade_group_3: 'Grade Group 3',
    grade_group_4: 'Grade Group 4',
    grade_group_5: 'Grade Group 5',
    epr:'Confirmed',
    email: 'aiyub.panara@gmail.com',
    firm_name: 'M S PLAST',
    category: 'Domestic',
    install_capacity: '15T',
    sales_district: 'Ahmedabad',
    approved: 'Approved',
    status:'Active',
    sup:'Not Applicable'

  },


  {

    name: 'MR.KAUSHAL PATEL ',
    grade_group_1: 'Grade Group 1',
    grade_group_2: 'Grade Group 2',
    grade_group_3: 'Grade Group 3',
    grade_group_4: 'Grade Group 4',
    grade_group_5: 'Grade Group 5',
    email: 'dharmendra.panara@gmail.com',
    firm_name: 'ADARSH PACKAGING',
    category: 'Domestic',
    install_capacity: '10T',
    sales_district: 'Surat',
    approved: 'Pending',
    status: 'Active',
    epr:'Applied',
    sup:'Applicable'
  },

  {

    name: 'MR.AMIT AGARAWAL ',
    grade_group_1: 'Grade Group 1',
    grade_group_2: 'Grade Group 2',
    grade_group_3: 'Grade Group 3',
    grade_group_4: 'Grade Group 4',
    grade_group_5: 'Grade Group 5',
    email: 'dharmendra.panara@gmail.com',
    firm_name: 'AMRUT ENTERPRISE',
    category: 'International',
    install_capacity: '12T',
    sales_district: 'Vadodara',
    approved: 'Rejected',
    status: 'Inactive',
    epr:'Not Applied ',
    sup:'To be checked '

  },

  {

    name: 'MR. KANTI PATEL',
    grade_group_1: 'Grade Group 1',
    grade_group_2: 'Grade Group 2',
    grade_group_3: 'Grade Group 3',
    grade_group_4: 'Grade Group 4',
    grade_group_5: 'Grade Group 5',
    email: 'dharmendra.panara@gmail.com',
    firm_name: 'BAJARANG PLASTIC',
    category: 'Domestic',
    install_capacity: '18T',
    sales_district: 'Mumbai',
    approved: 'Pending',
    status: 'Active',
    epr:'Confirmed',
    sup:'Not Applicable'

  },


  {

    name: 'MR.DINESH ',
    grade_group_1: 'Grade Group 1',
    grade_group_2: 'Grade Group 2',
    grade_group_3: 'Grade Group 3',
    grade_group_4: 'Grade Group 4',
    grade_group_5: 'Grade Group 5',
    email: 'dharmendra.panara@gmail.com',
    firm_name: 'BHAGWATI AGENCY',
    category: 'International',
    install_capacity: '28T',
    sales_district: 'Bhavangar',
    approved: 'Pending',
    status: 'Inactive',
    epr:'Not Applied',
    sup:'Applicable'

  },

  {

    name: 'MR. MAHESHKUMAR AGRAWAL ',
    grade_group_1: 'Grade Group 1',
    grade_group_2: 'Grade Group 2',
    grade_group_3: 'Grade Group 3',
    grade_group_4: 'Grade Group 4',
    grade_group_5: 'Grade Group 5',
    email: 'dharmendra.panara@gmail.com',
    firm_name: 'GEETA TRADING COMPANY',
    category: 'Domestic',
    install_capacity: '30T',
    sales_district: 'Morbi',
    approved: 'Approved',
    status: 'Active',
    epr:'Applied ',
    sup:'To be checked '

  },

  // {

  //   name: 'Dharmendra Kushwah',
  //   whatsapp_number: '98254 32145',
  //   email: 'dharmendra.panara@gmail.com',
  //   firm_name: 'dharmendra Industried Pvt. Ltd.',
  //   category: 'Domestic',
  //   install_capacity: '10T',
  //   sales_district: 'Surat',
  //   approved: 'Approved',
  //   status: 'Active'

  // }


]


export interface OfferModel {
  offerid?: string
  name?: string
  district?: string
  product_group?:string
  productname?: string
  offer_qty: string,
  remaining_qut?:string
  offer_price?: string
  valid_till_date?: string
  status?: string


}

const defaultOffers: Array<OfferModel> = [
  {
    offerid: '001',
    name: 'EXCEL PETROCHEM',
    district: 'Ahmedabad',
    product_group:'Product Group1',
    productname: 'ABS MOULDING ASAHI KASEI A6310',
    offer_qty: '125',
    offer_price: '300',
    valid_till_date: '11/01/2023',
    status: 'Active'

  },

  {
    offerid: '001',
    name: 'EXCEL PETROCHEM',
    district: 'Rajkot',
    product_group:'Product Group3',
    productname: 'LDPE MILK FE8004-LOTRENE',
    offer_qty: '135',
    offer_price: '380',
    valid_till_date: '11/01/2023',
    status: 'Active'

  },

  {
    offerid: '001',
    name: 'EXCEL PETROCHEM',
    district: 'Ahmedabad',
    product_group:'Product Group2',
    productname: 'PET CP DHUNSERI AS22CJ',
    offer_qty: '155',
    offer_price: '220',
    valid_till_date: '11/01/2023',
    status: 'Active'

  },

  {
    offerid: '002',
    name: 'AARCH NONWOVEN',
    district: 'Rajkot',
    product_group:'Product Group3',
    productname: 'ABS MOULDING BHANSALI HI-40B',
    offer_qty: '180',
    offer_price: '100',
    valid_till_date: '11/01/2023',
    status: 'Active'

  },

  {
    offerid: '003',
    name: 'AASHIRWAD PLASTIC',
    district: 'Ahmedabad',
    product_group:'Product Group4',
    productname: 'ABS MOULDING BHANSALI IM17A',
    offer_qty: '350',
    offer_price: '400',
    valid_till_date: '11/01/2023',
    status: 'Active'

  },

  {
    offerid: '004',
    name: 'AASHRAY POLYMERS',
    district: 'Rajkot',
    product_group:'Product Group5',
    productname: 'ABS EXTRUSION LOTTE SV-0155',
    offer_qty: '420',
    offer_price: '200',
    valid_till_date: '11/01/2023',
    status: 'Inactive'

  },


  {
    offerid: '005',
    name: 'AASHRAY POLYMERS',
    district: 'Rajkot',
    product_group:'Product Group6',
    productname: 'ABS MOULDING BHANSALI MIF-45',
    offer_qty: '225',
    offer_price: '350',
    valid_till_date: '11/01/2023',
    status: 'Active'

  },

  {
    offerid: '006',
    name: 'EXCEL PETROCHEM',
    district: 'Gandhinagar',
    product_group:'Product Group7',
    productname: 'ABS MOULDING ASAHI KASEI A6310',
    offer_qty: '125',
    offer_price: '300',
    valid_till_date: '11/01/2023',
    status: 'Inactive'

  },


  {
    offerid: '007',
    name: 'AARCH NONWOVEN',
    district: 'Rajkot',
    product_group:'Product Group8',
    productname: 'ABS MOULDING BHANSALI HI-40B',
    offer_qty: '180',
    offer_price: '100',
    valid_till_date: '11/01/2023',
    status: 'Active'

  },

  {
    offerid: '008',
    name: 'AASHIRWAD PLASTIC',
    district: 'Gandhinagar',
    product_group:'Product Group9',
    productname: 'ABS MOULDING BHANSALI IM17A',
    offer_qty: '350',
    offer_price: '400',
    valid_till_date: '11/01/2023',
    status: 'Active'

  },

  {
    offerid: '009',
    name: 'AASHRAY POLYMERS',
    district: 'Rajkot',
    product_group:'Product Group10',
    productname: 'ABS EXTRUSION LOTTE SV-0155',
    offer_qty: '420',
    offer_price: '200',
    valid_till_date: '11/01/2023',
    status: 'Inactive'

  },


  {
    offerid: '010',
    name: 'AASHRAY POLYMERS',
    district: 'Gandhinagart',
    product_group:'Product Group11',
    productname: 'ABS MOULDING BHANSALI MIF-45',
    offer_qty: '225',
    offer_price: '350',
    valid_till_date: '11/01/2023',
    status: 'Active'

  },


  

  // {
  //   offerid: '006',
  //   name: 'Mr. Aiyub Panara',
  //   whatsapp_number: '98254 32145',
  //   productname: 'Product #1',
  //   offer_qty: '375',
  //   offer_price: '500',
  //   validity: '45',
  //   status: 'Active'

  // },

  // {
  //   offerid: '007',
  //   name: 'Mr. Aiyub Panara',
  //   whatsapp_number: '98254 32145',
  //   productname: 'Product #1',
  //   offer_qty: '125',
  //   offer_price: '500',
  //   validity: '45 ',
  //   status: 'Active'

  // },

  // {
  //   offerid: '008',
  //   name: 'Mr. Aiyub Panara',
  //   whatsapp_number: '98254 32145',
  //   productname: 'Product #1',
  //   offer_qty: '480',
  //   offer_price: '500',
  //   validity: '45 Days',
  //   status: 'Active'

  // },

  // {
  //   offerid: '009',
  //   name: 'Mr. Aiyub Panara',
  //   whatsapp_number: '98254 32145',
  //   productname: 'Product #1',
  //   offer_qty: '380',
  //   offer_price: '500',
  //   validity: '45 Days',
  //   status: 'Active'

  // },

  // {
  //   offerid: '010',
  //   name: 'Mr. Aiyub Panara',
  //   whatsapp_number: '98254 32145',
  //   productname: 'Product #1',
  //   offer_qty: '600',
  //   offer_price: '500',
  //   validity: '45 Days',
  //   status: 'Active'

  // }


]

export interface InquiryModel {
  inquiryid?: string
  name?: string
  whatsapp_number?: string
  product_name?: string
  inquiry_quantity?: string
  inquiry_rate?: string
  inquiry_status?: string
  inquiry_assigned?: string
  sales_district?: string
  created_by?: string



}

const defaultInquiries: Array<InquiryModel> = [
  {
    inquiryid: '001',
    name: 'Arya Packthread Pvt. Ltd.',
    whatsapp_number: '9879437260',
    product_name: 'Product #1',
    inquiry_quantity: '250',
    inquiry_rate: '300',
    inquiry_status: 'Payment Received',
    inquiry_assigned: 'Ramesh BHai',
    sales_district: 'Morbi',
    created_by: 'LOTTE',

  },


  {
    inquiryid: '002',
    name: 'Aashirwad Plastic',
    whatsapp_number: '9825485869',
    product_name: 'Product #1',
    inquiry_quantity: '250',
    inquiry_rate: '300',
    inquiry_status: 'Confirmed',
    inquiry_assigned: 'Akash Bhai',
    sales_district: 'Rajkot',
    created_by: 'Bhansali',

  },

  {
    inquiryid: '003',
    name: 'Accord Polytech Pvt. Ltd',
    whatsapp_number: '9925949192',
    product_name: 'Product #1',
    inquiry_quantity: '250',
    inquiry_rate: '300',
    inquiry_status: 'Negotiation',
    inquiry_assigned: 'Mr. Mavani Bhavesh',
    sales_district: 'Mehasana',
    created_by: 'Chimei',

  },

  {
    inquiryid: '004',
    name: 'Active Polymers Pvt. Ltd',
    whatsapp_number: '9852660810',
    product_name: 'Product #1',
    inquiry_quantity: '250',
    inquiry_rate: '300',
    inquiry_status: 'Negotiation',
    inquiry_assigned: 'Patel Ashok Bhai',
    sales_district: 'Surat',
    created_by: 'GPPC',

  },


  {
    inquiryid: '005',
    name: 'Adani Wilmar Ltd.',
    whatsapp_number: '9825432145',
    product_name: 'Product #1',
    inquiry_quantity: '250',
    inquiry_rate: '300',
    inquiry_status: 'Confirmed',
    inquiry_assigned: 'Bhaiyalal',
    sales_district: 'Sabarkantha',
    created_by: 'INEOS',

  },

  

]


export interface QuotationModel {
  date?: string
  quot_number?: string
  ref?: string
  buyer?: string
  inqiry_no?: string
  hsn?: string
  quantity?: string
  rate?: string
  amount?: string
  tax?: string
  status?: string
}

const defaultQuotations: Array<QuotationModel> = [
  {
    date: '24/07/21',
    quot_number: '45789',
    ref: 'Mr. Aiyub Panara',
    buyer: 'Mr. Dharmendra kushwah',
    inqiry_no: '25467',
    hsn: '4570',
    quantity: '30',
    rate: '350',
    amount: '10500',
    tax: '864',
    status: 'Active',
  },
  {
    date: '28/08/21',
    quot_number: '45789',
    ref: 'Mr. Aiyub Panara',
    buyer: 'Mr. Dharmendra kushwah',
    inqiry_no: '56874',
    hsn: '4570',
    quantity: '30',
    rate: '350',
    amount: '11,200',
    tax: '864',
    status: 'Active',
  },
  {

    date: '30/11/21',
    quot_number: '45789',
    ref: 'Mr. Aiyub Panara',
    buyer: 'Mr. Dharmendra kushwah',
    inqiry_no: '98547',
    hsn: '4570',
    quantity: '30',
    rate: '350',
    amount: '13,800',
    tax: '864',
    status: 'Active',
  },
  {
    date: '15/01/22',
    quot_number: '45789',
    ref: 'Mr. Aiyub Panara',
    buyer: 'Mr. Dharmendra kushwah',
    inqiry_no: '65874',
    hsn: '4570',
    quantity: '30',
    rate: '350',
    amount: '25,600',
    tax: '864',
    status: 'Active',
  },
  {

    date: '30/01/22',
    quot_number: '45789',
    ref: 'Mr. Aiyub Panara',
    buyer: 'Mr. Dharmendra kushwah',
    inqiry_no: '35874',
    hsn: '4570',
    quantity: '30',
    rate: '350',
    amount: '28,800',
    tax: '864',
    status: 'Active',
  },
  {
    date: '26/03/22',
    quot_number: '45789',
    ref: 'Mr. Aiyub Panara',
    buyer: 'Mr. Dharmendra kushwah',
    inqiry_no: '98574',
    hsn: '4570',
    quantity: '30',
    rate: '350',
    amount: '26,200',
    tax: '864',
    status: 'Active',
  },
  {
    date: '18/04/22',
    quot_number: '45789',
    ref: 'Mr. Aiyub Panara',
    buyer: 'Mr. Dharmendra kushwah',
    inqiry_no: '98541',
    hsn: '4570',
    quantity: '30',
    rate: '350',
    amount: '30,500',
    tax: '864',
    status: 'Active',
  },
  {
    date: '18/04/22',
    quot_number: '45789',
    ref: 'Mr. Aiyub Panara',
    buyer: 'Mr. Dharmendra kushwah',
    inqiry_no: '98857',
    hsn: '4570',
    quantity: '30',
    rate: '350',
    amount: '25,100',
    tax: '864',
    status: 'Active',
  },
  {
    date: '18/04/22',
    quot_number: '45789',
    ref: 'Mr. Aiyub Panara',
    buyer: 'Mr. Dharmendra kushwah',
    inqiry_no: '98542',
    hsn: '4570',
    quantity: '30',
    rate: '350',
    amount: '20,500',
    tax: '864',
    status: 'Active',
  },
 

]


export interface SellerModel {
  seller?: string
  category?: string
  email?: string
  firm_name?: string
  sales_district?: string
  status?: string
  approved?: string
  epr?:string
  sup?:string

}

const defaultSellers: Array<SellerModel> = [
  {
    seller: "MR.RAJENDRA",
    category: 'Domestic',
    email: "dharmendra.kushwah@gmail.com",
    firm_name: 'KHUSHI ENTERPRISE',
    sales_district: 'Rajkot',
    status: 'Active',
    approved: 'Approved',
    epr:'Confirmed',
    sup:'Not Applicable'
  },

  {
    seller: "MR.R D PATEL",
    category: 'International',
    email: "dharmendra.kushwah@gmail.com",
    firm_name: 'KAHAN CORPORATION',
    sales_district: 'Vadodara',
    status: 'Active',
    approved: 'Approved',
    epr:'Applied ',
    sup:'Applicable'
  },
  {
    seller: "MR.PRAKASH",
    category: 'Domestic',
    email: "dharmendra.kushwah@gmail.com",
    firm_name: 'BHAGWATI AGENCY',
    sales_district: 'Bhavangar',
    status: 'Inactive',
    approved: 'Reject',
    epr:'Not Applied',
    sup:'To be checked'
  },
  {
    seller: "MR. MAHESHKUMAR AGRAWAL ",
    category: 'Domestic',
    email: "dharmendra.kushwah@gmail.com",
    firm_name: 'GEETA TRADING COMPANY',
    sales_district: 'Ahmedabad',
    status: 'Active',
    approved: 'Approved',
    epr:'Confirmed',
    sup:'Not Applicable'
  },
  {
    seller: "MR.JANAK PATEL",
    category: 'International',
    email: "dharmendra.kushwah@gmail.com",
    firm_name: 'NOVA CORPORATION',
    sales_district: 'Kutch',
    status: 'Inactive',
    approved: 'Reject',
    epr:'Not Applied ',
    sup:'To be checked'
  },

  {
    seller: "MR.K.D. PATEL ",
    category: 'Domestic',
    email: "dharmendra.kushwah@gmail.com",
    firm_name: 'RIVIERA OVERSEAS PVT LTD',
    sales_district: 'Ahmedabad',
    status: 'Active',
    approved: 'Approved',
    epr:'Confirmed',
    sup:'Not Applicable'
  }
]

export interface ProductModel {
  product_name?: string
  price?: string
  grade_group?: string
  manufactured_by?: string
  sector?: string
  product?: string
  mfi?: string
  density?: string
  special_chara?: string
  application?: string
  place_origin?: string
}

const defaultProducts: Array<ProductModel> = [
  {
    product_name: 'ABS EXTRUSION FORMOSA AE10EPProduct #1',
    price: '300',
    grade_group: 'ABS',
    manufactured_by: "FORMOSA",
    sector: 'PP RAFFIA',
    product: 'PP RAFFIA',
    mfi: '0.4 MFI',
    density: '0.985 Density',
    special_chara: 'Charactor #1',
    application: 'Applicable  #1',
    place_origin: 'Domestic'
  },

  {
    product_name: 'EVA 18% ARAMCO A1825',
    price: '250',
    grade_group: 'EVA',
    manufactured_by: "SIPCHEM ",
    sector: 'PP YARN',
    product: 'PP YARN',
    mfi: '0.5 MFI',
    density: '0.985 Density',
    special_chara: 'Charactor #1',
    application: 'Applicable  #1',
    place_origin: 'Domestic'
  },
  {
    product_name: 'HDPE BLOW BASELL ACP5331A',
    price: '480',
    grade_group: 'HDPE',
    manufactured_by: "BASELL",
    sector: 'LL FILM',
    product: 'LL FILM',
    mfi: '0.3 MFI',
    density: '0.985 Density',
    special_chara: 'Charactor #1',
    application: 'Applicable  #1',
    place_origin: 'International'
  },
  {
    product_name: 'LDPE HEAVY RIL J22FA002',
    price: '450',
    grade_group: 'LDPE',
    manufactured_by: "RIL",
    sector: 'LL FILM',
    product: 'LL FILM',
    mfi: '0.6 MFI',
    density: '0.985 Density',
    special_chara: 'Charactor #1',
    application: 'Applicable  #1',
    place_origin: 'International'
  },
  {
    product_name: 'LLDPE NONSLIP SABIC 118NJ',
    price: '560',
    grade_group: 'LLDPE',
    manufactured_by: "SABIC",
    sector: 'PP RAFFIA',
    product: 'PP RAFFIA',
    mfi: '0.4 MFI',
    density: '0.985 Density',
    special_chara: 'Charactor #1',
    application: 'Applicable  #1',
    place_origin: 'Domestic'
  },

  {
    product_name: 'METALLOCENE NONSLIP EXXON 8784ML',
    price: '300',
    grade_group: 'METALLOCENE',
    manufactured_by: "EXXON",
    sector: 'LL FILM',
    product: 'LL FILM',
    mfi: '0.7 MFI',
    density: '0.985 Density',
    special_chara: 'Charactor #1',
    application: 'Applicable  #1',
    place_origin: 'International'
  }
]

export interface TransportModel {
  firm_name?: string
  cpname?: string
  mobile_no?: string
  state?: string
  city?: string
  status?: string
}

const defaultTransports: Array<TransportModel> = [
  {
    firm_name: 'ABC Roadways Pvt. Ltd',
    cpname: "MR.SANJAY",
    mobile_no: '98254 63524',
    state: 'Gujarat',
    city: "Ahmedabad",
    status: "Active",

  },

  {
    firm_name: 'Punjab Transport Pvt. Ltd',
    cpname: "MR.SHAILESH PATEL ",
    mobile_no: '9852145632',
    state: 'Chhattisgarh',
    city: "Pendra",
    status: "Active",
  },
  {
    firm_name: 'Sartaj Roadways Pvt. Ltd',
    cpname: "MR.DURGA SHARMA ",
    mobile_no: '9824536524',
    state: 'Bihar',
    city: "Darbhanga",
    status: "Active",
  },
  {
    firm_name: 'Janta Roadways',
    cpname: "MR.JANAK PATEL",
    mobile_no: '9425632145',
    state: 'Goa',
    city: "Panaji",
    status: "Inactive",
  },
  {
    firm_name: 'All India Transport Corporation',
    cpname: "MR.SANDIP PATEL ",
    mobile_no: '9913254687',
    state: 'Maharashtra ',
    city: "Thane",
    status: "Active",
  },

  {
    firm_name: 'RK Logistic ',
    cpname: "MR.KANTI PATEL ",
    mobile_no: '9852145678',
    state: 'Mizoram',
    city: "Kolasib",
    status: "Inactive",
  }
]


export interface UserModel {

  first_name?: string
  last_name?: string
  email?: string
  role?: string
  status?: string
}

const defaultUsers: Array<UserModel> = [
  {
    first_name: "MR.TEJAS ",
    last_name: 'SHAH',
    email: 'tejasshah@gmail.com',
    role: 'Leader',
    status: 'Active'

  },

  {
    first_name: "MR.VISHNU ",
    last_name: 'PATEL',
    email: 'vishnupatel@gmail.com',
    role: 'Leader',
    status: 'Active'
  },
  {
    first_name: "MR.DINESH ",
    last_name: 'AGARAWAL ',
    email: 'dineshagarawal@gmail.com',
    role: 'BackOffice',
    status: 'Active'
  },
  {
    first_name: "MR.RAJENDRA",
    last_name: 'Damor',
    email: 'rajendradamor@gmail.com',
    role: 'Leader',
    status: 'Active'
  },
  {
    first_name: "MR.DURGA ",
    last_name: 'SHARMA',
    email: 'durgasharma@gmail.com',
    role: 'Leader',
    status: 'Active'
  },

  {
    first_name: "MR.AMIT  ",
    last_name: 'AGARAWAL',
    email: 'amitagarawal@gmail.com',
    role: 'Leader',
    status: 'Active'
  }
]


export interface PurchaseModel {
  date?: string
  po_number?: string
  ref?: string
  seller?: string
  product_name?: string
  quantity?: string
  rate?: string
  amount?: string
  total_quantity?:string
  tax?: string
  status?: string
}

const defaultPurchases: Array<PurchaseModel> = [
  {
    date: '25/06/22',
    po_number: '35214',
    ref: 'Mr. Dharmendra',
    seller: "ACE POLYMERS",
    product_name: 'BOPP FILM ARPC-IRAN T36F',
    quantity: '125',
    rate: '400',
    amount: '20000',
    total_quantity:'100',
    tax: '800',
    status: 'Confirmed',
  },

  {
    date: '28/07/22',
    po_number: '89654',
    ref: 'Mr. Dharmendra',
    seller: "ADINATH POLYMERS",
    product_name: 'BOPP FILM ARPC-IRAN T36F',
    quantity: '125',
    rate: '400',
    amount: '27000',
    total_quantity:'500',
    tax: '800',
    status: 'Confirmed',
  },
  {
    date: '15/08/22',
    po_number: '87945',
    ref: 'Mr. Dharmendra',
    seller: "BAJARANG PLASTIC",
    product_name: 'BOPP FILM ARPC-IRAN T36F',
    quantity: '125',
    rate: '400',
    amount: '34000',
    total_quantity:'200',
    tax: '800',
    status: 'Draft',
  },
  {
    date: '19/08/22',
    po_number: '69854',
    ref: 'Mr. Dharmendra',
    seller: "BHAGWATI AGENCY",
    product_name: 'BOPP FILM ARPC-IRAN T36F',
    quantity: '125',
    rate: '400',
    amount: '28000',
    total_quantity:'300',
    tax: '800',
    status: 'Sent',
  },
  {
    date: '17/09/22',
    po_number: '65874',
    ref: 'Mr. Dharmendra',
    seller: "KAHAN CORPORATION",
    product_name: 'BOPP FILM ARPC-IRAN T36F',
    quantity: '125',
    rate: '400',
    amount: '16500',
    total_quantity:'100',
    tax: '800',
    status: 'Sent',
  },

  {
    date: '05/10/22',
    po_number: '45864',
    ref: 'Mr. Dharmendra',
    seller: "NOVA CORPORATION",
    product_name: 'BOPP FILM ARPC-IRAN T36F',
    quantity: '125',
    rate: '400',
    amount: '15000',
    total_quantity:'500',
    tax: '800',
    status: 'Confirmed',
  }
]


export interface GradeModel {
  grade_name?: string
}

const defaultGrades: Array<GradeModel> = [
  {
    grade_name: 'HDPE',
  },
  {
    grade_name: 'ABS',
  },
  {
    grade_name: 'EVA',
  },
  {
    grade_name: 'LDPE',
  },
  {
    grade_name: 'LLDPE',
  },
  {
    grade_name: 'NYLON',
  },
  {
    grade_name: 'PC',
  },
  {
    grade_name: 'POM ',
  }
]


export interface ProductgroupModel {
  productgroup_name?: string
  profit?: string
}

const defaultProductgroups: Array<ProductgroupModel> = [
  {
    productgroup_name: 'ABS EXTRUSION FORMOSA AE10EPProduct',
    profit:'200',
  },
  {
    productgroup_name: 'GPPS MOULDING MOHEB MP08',
    profit:'100',
  },
  {
    productgroup_name: 'GPPS MOULDING SUPREME 203EL',
    profit:'400',
  },
  {
    productgroup_name: 'GPPS MOULDING TJAPC G1551',
    profit:'600',
  },
  {
    productgroup_name: 'HIPS EXTRUSION TABRIZ 7240',
    profit:'800',
  },
  {
    productgroup_name: 'HIPS MOULDING INEOS 495F',
    profit:'200',
  },
  {
    productgroup_name: 'BOPP FILM BOREALIS HC318BF',
    profit:'500',
  },
  {
    productgroup_name: 'PP BLOW OPAL EI02N',
    profit:'100',
  }
]



export interface FreightModel {
  source_name?: string
  destination?: string
  rate_mt?: string
  transt_day?: string

}

const defaultFreights: Array<FreightModel> = [
  {
    source_name: 'Mumbai',
    destination: 'Surat',
    rate_mt: '150',
    transt_day: '5'
  },
  {
    source_name: 'Kutch',
    destination: 'Ahmedabad',
    rate_mt: '180',
    transt_day: '2'
  },
  {
    source_name: 'Bhavangar',
    destination: 'Bombay',
    rate_mt: '700',
    transt_day: '2'
  },
  {
    source_name: 'Rajkot',
    destination: 'Delhi',
    rate_mt: '1200',
    transt_day: '3'
  },
  {
    source_name: 'Ahmedabad',
    destination: 'Punjab',
    rate_mt: '1400',
    transt_day: '2'
  },
  {
    source_name: 'Morbi',
    destination: 'jaipur',
    rate_mt: '900',
    transt_day: '4'
  },
  {
    source_name: 'Vadodara',
    destination: 'Goa',
    rate_mt: '1100',
    transt_day: '3'
  },
  {
    source_name: 'Surendranagar',
    destination: 'karnatak',
    rate_mt: '1500',
    transt_day: '4'
  }
]


export interface StateModel {
  state_name?: string

}

const defaultStates: Array<StateModel> = [
  {
    state_name: 'Andhra Pradesh'
  },
  {
    state_name: 'Arunachal Pradesh'
  },
  {
    state_name: 'Bihar'
  },
  {
    state_name: 'Chhattisgarh'
  },
  {
    state_name: 'Goa'
  },
  {
    state_name: 'Gujarat '
  },
  {
    state_name: 'Haryana'
  },
  {
    state_name: 'Himachal Pradesh '
  }
]

export interface DistrictModel {
  district_name?: string
  state?: string

}

const defaultDistricts: Array<DistrictModel> = [
  {
    district_name: "Andhra Pradesh",
    state: 'Vizianagaram'
  },
  {
    district_name: "Arunachal Pradesh",
    state: 'Anjaw'
  },
  {
    district_name: "Bihar",
    state: 'Begusarai'
  },
  {
    district_name: "Chhattisgarh",
    state: 'Dhamtari'
  },
  {
    district_name: "Goa",
    state: 'South Goa'
  },
  {
    district_name: "Gujarat",
    state: 'Ahmedabad'
  },
  {
    district_name: "Haryana",
    state: 'Faridabad'
  },
  {
    district_name: "Himachal Pradesh ",
    state: 'Kangra'
  }
]


export interface BuyersellerModel {
  firm_name?: string
  full_name?: string
  email?: string
  status?:string
  last_logged_date?: string
  last_activity_date?: string
  last_offer_inquiry?: string

}

const defaultBuyersellers: Array<BuyersellerModel> = [
  {
    firm_name:'KHUSHI ENTERPRISE',
    full_name: 'MR.SANDIP PATEL ',
    email: 'contact@poojaplastic.com',
    status: 'Active',
    last_logged_date: '25/07/20, 12:05pm',
    last_activity_date: '28/10/20, 15:36pm',
    last_offer_inquiry: '21/09/20, 14:36pm',
  },
  {
    firm_name:'KAHAN CORPORATION',
    full_name: 'MR.VISHNU PATEL ',
    email: 'rlpolyfabs@gmail.com',
    status: 'Active',
    last_logged_date: '21/08/20, 14:25pm',
    last_activity_date: '27/11/20, 18:34pm',
    last_offer_inquiry: '12/11/20, 12:15pm',
  },
  {
    firm_name:'BHAGWATI AGENCY',
    full_name: 'MR.DINESH ',
    email: 'pawangoyal@jrgindia.com',
    status: 'Inactive',
    last_logged_date: '14/09/20, 17:15pm',
    last_activity_date: '22/10/20, 14:20pm',
    last_offer_inquiry: '12/07/20, 11:20pm',
  },
  {
    firm_name:'GEETA TRADING COMPANY',
    full_name: 'MR. MAHESHKUMAR AGRAWAL ',
    email: 'kdpatel@sachininternational.com',
    status: 'Inactive',
    last_logged_date: '26/02/21, 13:17pm',
    last_activity_date: '28/03/21, 19:30pm',
    last_offer_inquiry: '25/08/21, 14:38pm',
  },
  {
    firm_name:'NOVA CORPORATION',
    full_name: 'MR.RAJENDRA ',
    email: 'samippolypack@yahoo.com',
    status: 'Active',
    last_logged_date: '24/01/21, 11:08am,',
    last_activity_date: '17/02/21 14:06pm',
    last_offer_inquiry: '05/02/21, 17:52pm',
  },
  {
    firm_name:'RIVIERA OVERSEAS PVT LTD',
    full_name: 'MR.CHIRAG PATEL',
    email: 'purchase@seaplastindia.com',
    status: 'Inactive',
    last_logged_date: '04/04/21, 11:45am',
    last_activity_date: '25/04/21 18:30pm',
    last_offer_inquiry: '18/04/21, 18:26pm',
  },
  {
    full_name: 'MR.K.D. PATEL ',
    firm_name:'KHUSHI ENTERPRISE',
    email: 'sahilpolypack@gmail.com',
    status: 'Active',
    last_logged_date: '31/05/21, 17:02pm',
    last_activity_date: '26/06/21. 15:31pm',
    last_offer_inquiry: '10/06/21, 15:11pm',
  },
  {
    firm_name:'KHUSHI ENTERPRISE',
    full_name: 'MR.PRAKASH PATEL ',
    email: 'rd29885@yahoo.com',
    status: 'Inactive',
    last_logged_date: '27/07/21, 17:05pm',
    last_activity_date: '18/08/21, 17:45pm',
    last_offer_inquiry: '06/08/21, 14:22pm',
  }
]


export interface OrderModel {
  customer_name?: string
  order_number?: string
  assigned?:string
  pending?: string
  negotiation?: string
  quotation_sent?: string
  quotation_confirmed?: string
  vehicle_assigned?: string
  invoice_generated?: string
  payment_received?: string
  cancel?: string


}

const defaultOrders: Array<OrderModel> = [
  {
    customer_name: 'Aashray Polymers',
  order_number: '3658756',
  assigned:'Ramesh BHai',
  pending: '20/12/2022, 14:35pm',
  negotiation: '15min',
  quotation_sent: '25min',
  quotation_confirmed: '20min',
  vehicle_assigned: '1hr 30min',
  invoice_generated: '30min',
  payment_received: '21/12/2022, 16:00pm',
  cancel: '-',
  },
  {
    customer_name: 'Aera Plast',
  order_number: '5879452',
  assigned:'Akash Bhai',
  pending: '08/12/2022, 13:30pm',
  negotiation: '18min',
  quotation_sent: '22min',
  quotation_confirmed: '30min',
  vehicle_assigned: '2hr',
  invoice_generated: '20min',
  payment_received: '10/12/20, 14:30pm',
  cancel: '-',
  },
  {
    customer_name: 'Adani Wilmar Ltd.',
    order_number: '65479846',
    assigned:'Mr. Mavani Bhavesh',
    pending: '20/11/2022 15:33pm',
    negotiation: '35min',
    quotation_sent: '-',
    quotation_confirmed: '-',
    vehicle_assigned: '-',
    invoice_generated: '-',
    payment_received: '-',
    cancel: '20/11/2022, 16:08pm',
  },
  {
    customer_name: 'Accord Polypack Pvt. Ltd.',
    order_number: '684971',
    assigned:'Aiyub Panara',
    pending: '05/11/2022, 15:45pm',
    negotiation: '30min',
    quotation_sent: '20min',
    quotation_confirmed: '15min',
    vehicle_assigned: '1hr 30min',
    invoice_generated: '30min',
    payment_received: '07/11/20, 17:52pm',
    cancel: '-',
  },

  {
    customer_name: 'Abs Moulding Bansali',
    order_number: '5879452',
    assigned:'Parth Dave',
    pending: '25/10/2022 15:00pm',
    negotiation: '20min',
    quotation_sent: '-',
    quotation_confirmed: '-',
    vehicle_assigned: '-',
    invoice_generated: '-',
    payment_received: '-',
    cancel: '25/10/2022 15:20pm',
  },

  {
    customer_name: 'Aashray Polymers',
  order_number: '3658756',
  assigned:'Patel Ashok Bhai',
  pending: '20/12/2022, 14:35pm',
  negotiation: '15min',
  quotation_sent: '25min',
  quotation_confirmed: '20min',
  vehicle_assigned: '1hr 30min',
  invoice_generated: '30min',
  payment_received: '21/12/2022, 16:00pm',
  cancel: '-',
  },
  {
    customer_name: 'Aera Plast',
  order_number: '5879452',
  assigned:'Milap Gajjar',
  pending: '08/12/2022, 13:30pm',
  negotiation: '18min',
  quotation_sent: '22min',
  quotation_confirmed: '30min',
  vehicle_assigned: '2hr',
  invoice_generated: '20min',
  payment_received: '10/12/20, 14:30pm',
  cancel: '-',
  },
  {
    customer_name: 'Adani Wilmar Ltd.',
    order_number: '65479846',
    assigned:'Dharmendra Kushwah',
    pending: '20/11/2022 15:33pm',
    negotiation: '35min',
    quotation_sent: '-',
    quotation_confirmed: '-',
    vehicle_assigned: '-',
    invoice_generated: '-',
    payment_received: '-',
    cancel: '20/11/2022, 16:08pm',
  },
  {
    customer_name: 'Accord Polypack Pvt. Ltd.',
    order_number: '684971',
    assigned:'Milap Gajjar',
    pending: '05/11/2022, 15:45pm',
    negotiation: '30min',
    quotation_sent: '20min',
    quotation_confirmed: '15min',
    vehicle_assigned: '1hr 30min',
    invoice_generated: '30min',
    payment_received: '07/11/20, 17:52pm',
    cancel: '-',
  },

  {
    customer_name: 'Abs Moulding Bansali',
    order_number: '5879452',
    assigned:'Parth Dave',
    pending: '25/10/2022 15:00pm',
    negotiation: '20min',
    quotation_sent: '-',
    quotation_confirmed: '-',
    vehicle_assigned: '-',
    invoice_generated: '-',
    payment_received: '-',
    cancel: '25/10/2022 15:20pm',
  },

  
  
]

export interface InquiryModel {
  date?: string
  inquiry_id?: string
  firm_name?: string
  district?: string
  amount?: string
  status?: string
 


}
const defaultInquirys: Array<InquiryModel> = [
  {
    date: '24/01/2023',
    inquiry_id:'#001',
    firm_name:'Arya Packthread Pvt. Ltd.',
    district:'Ahmedabad',
    amount:'2000',
    status:'Invoice Generated',
  },

  {
    date: '22/01/2023',
    inquiry_id:'#002',
    firm_name:'Aashirwad Plastic',
    district:'Rajkot',
    amount:'4400',
    status:'Payment Received',
  },

  {
    date: '20/01/2023',
    inquiry_id:'#003',
    firm_name:'Accord Polytech Pvt. Ltd.',
    district:'Surat',
    amount:'2500',
    status:'Invoice Generated',
  },
  
  {
    date: '18/01/2023',
    inquiry_id:'#004',
    firm_name:'Adani Wilmar Ltd.',
    district:'Vadodara',
    amount:'5000',
    status:'Payment Received',
  },

  {
    date: '24/01/2023',
    inquiry_id:'#005',
    firm_name:'Arya Packthread Pvt. Ltd.',
    district:'Ahmedabad',
    amount:'3000',
    status:'Invoice Generated',
  },

  {
    date: '22/01/2023',
    inquiry_id:'#006',
    firm_name:'Aashirwad Plastic',
    district:'Rajkot',
    amount:'5310',
    status:'Payment Received',
  },

  {
    date: '20/01/2023',
    inquiry_id:'#007',
    firm_name:'Accord Polytech Pvt. Ltd.',
    district:'Surat',
    amount:'6580',
    status:'Invoice Generated',
  },
  
  {
    date: '18/01/2023',
    inquiry_id:'#008',
    firm_name:'Aashirwad Plastic',
    district:'Vadodara',
    amount:'9800',
    status:'Payment Received',
  },

 
]

export interface InvoiceModel {
  date?: string
  quotation_number?: string
  customer_name?: string
}

const defaultInvoices: Array<InvoiceModel> = [
  {
    date: '28/08/2022',
    quotation_number: '#4215',
    customer_name: 'Mr. Dharmendra Kushwah',
  },
  {
    date: '08/09/2022',
    quotation_number: '#4215',
    customer_name: 'Mr. Dharmendra Kushwah',
  },
  {
    date: '20/09/2022',
    quotation_number: '#4215',
    customer_name: 'Mr. Dharmendra Kushwah',
  },
  {
    date: '10/10/2022',
    quotation_number: '#4215',
    customer_name: 'Mr. Dharmendra Kushwah',
  },
  {
    date: '15/11/2022',
    quotation_number: '#4215',
    customer_name: 'Mr. Dharmendra Kushwah',
  },
  {
    date: '27/11/2022',
    quotation_number: '#4215',
    customer_name: 'Mr. Dharmendra Kushwah',
  },
  {
    date: '5/12/2022',
    quotation_number: '#4215',
    customer_name: 'Mr. Dharmendra Kushwah',
  },
  {
    date: '10/12/2022',
    quotation_number: '#4215',
    customer_name: 'Mr. Dharmendra Kushwah',
  },
]


export interface SellerofferModel {
  date?: string
  product_name?: string
  seller_name?: string
  price?: string
}

const defaultSelleroffers: Array<SellerofferModel> = [
  {
    date: '28/08/2022',
    product_name: 'ABS EXTRUSION LOTTE SV-0155',
    seller_name: 'MR.PRAKASH PATEL ',
    price: '125'

  },
  {
    date: '14/09/2022',
    product_name: 'HDPE MOULDING DOW KT10000UE',
    seller_name: 'MR.SHAILESH PATEL ',
    price: '150'

  },
  {
    date: '27/09/2022',
    product_name: 'HDPE PE100 OPAL P50H03',
    seller_name: 'MR.JAYESH PATEL ',
    price: '235'

  },
  {
    date: '12/10/2022',
    product_name: 'HDPE RAFFIA OPAL Y5710',
    seller_name: 'MR.SANJAY',
    price: '450'

  },
  {
    date: '28/10/2022',
    product_name: 'HDPE MBM OPAL B55H02',
    seller_name: 'MR.AMIT AGARAWAL ',
    price: '350'
  },
  {
    date: '11/11/2022',
    product_name: 'HDPE MBM INNOPLUS HD6200B',
    seller_name: 'KHUSHI ENTERPRISE',
    price: '180'
  },
  {
    date: '25/11/2022',
    product_name: 'HDPE GPBM BASELL HY55430',
    seller_name: 'BAJRANG PLASTIC',
    price: '380'
  },
  {
    date: '05/12/2022',
    product_name: 'HDPE HM BASELL L5005',
    seller_name: 'RIVIERA POLYMERS PVT LTD',
    price: '520'
  },
]

export interface GradegroupModel {
  grade_group?: string
  district?: string
  buy_quantity?: string
  sell_quantity?: string
}

const defaultGradegroups: Array<GradegroupModel> = [
  {
    grade_group: 'ABS',
    district: 'Ahmedabad',
    buy_quantity: '800',
    sell_quantity: '650'

  },
  {
    grade_group: 'HDPE',
    district: 'Surat',
    buy_quantity: '650',
    sell_quantity: '400'

  },
  {
    grade_group: 'EVA ',
    district: 'Nagpur',
    buy_quantity: '700',
    sell_quantity: '300'

  },
  
    {
      grade_group: 'LDPE ',
      district: 'Jaipur',
      buy_quantity: '400',
      sell_quantity: '250'
  
    },

    {
      grade_group: 'LLDPE',
      district: 'Bombay',
      buy_quantity: '500',
      sell_quantity: '300'
  
    },
    {
      grade_group: 'METALLOCENE',
      district: 'Punjab',
      buy_quantity: '600',
      sell_quantity: '450'
  
    },
    {
      grade_group: 'NYLON 6',
      district: 'Bhopal',
      buy_quantity: '550',
      sell_quantity: '380'
  
    },
    {
      grade_group: 'PMMA',
      district: 'Rajkot',
      buy_quantity: '620',
      sell_quantity: '350'
  
    },
]


export interface ExpireofferModel {
  offerid?: string
  name?: string
  whatsapp_number?: string
  productname?: string
  offer_price?: string
  validity?: string
  valid_till_date?: string
  createby?: string
  status?: string


}

const defaultExpireoffers: Array<ExpireofferModel> = [
  {
    offerid: '001',
    name: 'ASTRON POLYMERS',
    whatsapp_number: '9426006675',
    productname: 'HDPE RAFFIA OPAL R5410',
    offer_price: '500',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },


  {
    offerid: '002',
    name: 'JATIN PLASTIC',
    whatsapp_number: '9825039885',
    productname: 'HDPE GPBM INDORAMA HBG00356',
    offer_price: '350',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },

  {
    offerid: '003',
    name: 'BHAGWATI AGENCY',
    whatsapp_number: '9426006675',
    productname: 'HDPE GPBM IOCL 012DB60',
    offer_price: '400',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },

  {
    offerid: '004',
    name: 'MANSI POLYMERS PVT. LTD.',
    whatsapp_number: '9327617805',
    productname: 'HDPE MOULDING GAIL I56A200UA',
    offer_price: '500',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },


  {
    offerid: '005',
    name: 'M S PLAST',
    whatsapp_number: '9825114411',
    productname: 'HDPE GPBM TOTAL 5502',
    offer_price: '200',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },

  {
    offerid: '006',
    name: 'DOSHI MARKETING',
    whatsapp_number: '9825084107',
    productname: 'HDPE MOULDING GAIL I60A080A',
    offer_price: '350',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },

  {
    offerid: '007',
    name: 'ASTRON POLYMERS',
    whatsapp_number: '9426006675',
    productname: 'HDPE RAFFIA OPAL R5410',
    offer_price: '500',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },


  {
    offerid: '008',
    name: 'JATIN PLASTIC',
    whatsapp_number: '9825039885',
    productname: 'HDPE GPBM INDORAMA HBG00356',
    offer_price: '350',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },

  {
    offerid: '009',
    name: 'BHAGWATI AGENCY',
    whatsapp_number: '9426006675',
    productname: 'HDPE GPBM IOCL 012DB60',
    offer_price: '400',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },

  {
    offerid: '010',
    name: 'MANSI POLYMERS PVT. LTD.',
    whatsapp_number: '9327617805',
    productname: 'HDPE MOULDING GAIL I56A200UA',
    offer_price: '500',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },


  {
    offerid: '011',
    name: 'M S PLAST',
    whatsapp_number: '9825114411',
    productname: 'HDPE GPBM TOTAL 5502',
    offer_price: '200',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },

  {
    offerid: '012',
    name: 'DOSHI MARKETING',
    whatsapp_number: '9825084107',
    productname: 'HDPE MOULDING GAIL I60A080A',
    offer_price: '350',
    valid_till_date: '13/01/2023',
    createby: 'Mr. Dharmendra Kushwah',
    status: 'Active'

  },
]


export interface ActiveinquiryModel {
  activeinquiryid?: string
  name?: string
  firm?:string
  grade_name?:string
  whatsapp_number?: string
  product_name?: string
  inquiry_quantity?: string
  inquiry_rate?: string
  inquiry_status?: string
  inquiry_assigned?: string
  sales_district?: string
  created_by?: string



}

const defaultActiveinquiries: Array<ActiveinquiryModel> = [
  {
    activeinquiryid: '001',
    firm:'KHUSHI ENTERPRISE',
    name: 'ANAND TRADELINK PVT LTD',
    grade_name:'Grade name 1',
    whatsapp_number: '9825064231',
    product_name: 'HDPE MOULDING GAIL I56A200U-OFFGRADE',
    inquiry_quantity: '250',
    inquiry_rate: '300',
    inquiry_status: 'Pending',
    inquiry_assigned: 'MR.PRAKASH PATEL ',
    sales_district: 'Vadodara',
    created_by: 'MR.SANJAY',

  },


  {
    activeinquiryid: '002',
    firm:'KAHAN CORPORATION',
    name: 'BHAGWATI AGENCY',
    grade_name:'Grade name 2',
    whatsapp_number: '9426006675',
    product_name: 'HDPE GPBM IOCL 012DB60',
    inquiry_quantity: '200',
    inquiry_rate: '135',
    inquiry_status: 'Sent',
    inquiry_assigned: 'MR.PRAKASH',
    sales_district: 'Bhavangar',
    created_by: 'MR.DINESH ',

  },

  {
    activeinquiryid: '003',
    firm:'BHAGWATI AGENCY',
    name: 'AMRUT ENTERPRISE',
    grade_name:'Grade name 3',
    whatsapp_number: '9825353499',
    product_name: 'HDPE MBM HALDIA B5500',
    inquiry_quantity: '350',
    inquiry_rate: '170',
    inquiry_status: 'Pending',
    inquiry_assigned: 'MR.DINESH ',
    sales_district: 'Morbi',
    created_by: 'MR.HITESH',

  },

  {
    activeinquiryid: '004',
    firm:'GEETA TRADING COMPANY',
    name: 'DOSHI MARKETING',
    grade_name:'Grade name 4',
    whatsapp_number: '7228902800',
    product_name: 'HDPE PE100 HALDIA P5300',
    inquiry_quantity: '450',
    inquiry_rate: '150',
    inquiry_status: 'Cofirmed',
    inquiry_assigned: 'MR.CHIRAG PATEL',
    sales_district: 'rajkot',
    created_by: 'MR.KANTI PATEL',

  },


  {
    activeinquiryid: '005',
    firm:'NOVA CORPORATION',
    name: 'M L TRADING CO',
    grade_name:'Grade name 5',
    whatsapp_number: '9327617805',
    product_name: 'HDPE MOULDING GAIL I56A200UA',
    inquiry_quantity: '550',
    inquiry_rate: '170',
    inquiry_status: 'Sent',
    inquiry_assigned: 'MR.SANJAY',
    sales_district: 'Bhavnagar',
    created_by: 'MR.R D PATEL',

  },

  {
    activeinquiryid: '006',
    firm:'RIVIERA OVERSEAS PVT LTD',
    name: 'BAJRANG PLASTIC',
    grade_name:'Grade name 6',
    whatsapp_number: '9426006675',
    product_name: 'HDPE GPBM IOCL 012DB60',
    inquiry_quantity: '430',
    inquiry_rate: '140',
    inquiry_status: 'Pending',
    inquiry_assigned: 'MR.JAYESH PATEL ',
    sales_district: 'Kutch',
    created_by: 'MR.TEJAS SHAH',

  },

  {
    activeinquiryid: '007',
    firm:'BHAGWATI AGENCY',
    name: 'ANAND TRADELINK PVT LTD',
    grade_name:'Grade name 7',
    whatsapp_number: '9825064231',
    product_name: 'HDPE MOULDING GAIL I56A200U-OFFGRADE',
    inquiry_quantity: '250',
    inquiry_rate: '300',
    inquiry_status: 'Pending',
    inquiry_assigned: 'MR.PRAKASH PATEL ',
    sales_district: 'Vadodara',
    created_by: 'MR.SANJAY',

  },


  {
    activeinquiryid: '008',
    firm:'KAHAN CORPORATION',
    name: 'BHAGWATI AGENCY',
    grade_name:'Grade name 8',
    whatsapp_number: '9426006675',
    product_name: 'HDPE GPBM IOCL 012DB60',
    inquiry_quantity: '200',
    inquiry_rate: '135',
    inquiry_status: 'Sent',
    inquiry_assigned: 'MR.PRAKASH',
    sales_district: 'Bhavangar',
    created_by: 'MR.DINESH ',

  },

  {
    activeinquiryid: '009',
    firm:'BHAGWATI AGENCY',
    name: 'AMRUT ENTERPRISE',
    grade_name:'Grade name 9',
    whatsapp_number: '9825353499',
    product_name: 'HDPE MBM HALDIA B5500',
    inquiry_quantity: '350',
    inquiry_rate: '170',
    inquiry_status: 'Pending',
    inquiry_assigned: 'MR.DINESH ',
    sales_district: 'Morbi',
    created_by: 'MR.HITESH',

  },

  {
    activeinquiryid: '010',
    firm:'GEETA TRADING COMPANY',
    name: 'DOSHI MARKETING',
    grade_name:'Grade name 10',
    whatsapp_number: '7228902800',
    product_name: 'HDPE PE100 HALDIA P5300',
    inquiry_quantity: '450',
    inquiry_rate: '150',
    inquiry_status: 'Cofirmed',
    inquiry_assigned: 'MR.CHIRAG PATEL',
    sales_district: 'rajkot',
    created_by: 'MR.KANTI PATEL',

  },


  {
    activeinquiryid: '011',
    firm:'NOVA CORPORATION',
    name: 'M L TRADING CO',
    grade_name:'Grade name 11',
    whatsapp_number: '9327617805',
    product_name: 'HDPE MOULDING GAIL I56A200UA',
    inquiry_quantity: '550',
    inquiry_rate: '170',
    inquiry_status: 'Sent',
    inquiry_assigned: 'MR.SANJAY',
    sales_district: 'Bhavnagar',
    created_by: 'MR.R D PATEL',

  },

  {
    activeinquiryid: '012',
    firm:'RIVIERA OVERSEAS PVT LTD',
    name: 'BAJRANG PLASTIC',
    grade_name:'Grade name 12',
    whatsapp_number: '9426006675',
    product_name: 'HDPE GPBM IOCL 012DB60',
    inquiry_quantity: '430',
    inquiry_rate: '140',
    inquiry_status: 'Pending',
    inquiry_assigned: 'MR.JAYESH PATEL ',
    sales_district: 'Kutch',
    created_by: 'MR.TEJAS SHAH',

  },


]


export interface CompleteinquiryModel {
  name?: string
  firm_name?: string
  grade_name?:string
  completeinquiryid?: string
  whatsapp_number?: string
  product_name?: string
  inquiry_quantity?: string
  inquiry_rate?: string
  inquiry_status?: string
  inquiry_assigned?: string
  sales_district?: string
  created_by?: string



}

const defaultCompleteinquiries: Array<CompleteinquiryModel> = [
  {
    completeinquiryid: '001',
    firm_name:'M S PLAST',
    name: 'ANAND TRADELINK PVT LTD',
    grade_name:' Grade Name',
    whatsapp_number: '9825064231',
    product_name: 'HDPE MOULDING GAIL I56A200U-OFFGRADE',
    inquiry_quantity: '250',
    inquiry_rate: '300',
    inquiry_status: 'Payment Received',
    inquiry_assigned: 'MR.PRAKASH PATEL ',
    sales_district: 'Vadodara',
    created_by: 'MR.SANJAY',

  },


  {
    completeinquiryid: '002',
    firm_name:'GEETA TRADING COMPANY',
    name: 'BHAGWATI AGENCY',
    grade_name:' Grade Name',
    whatsapp_number: '9426006675',
    product_name: 'HDPE GPBM IOCL 012DB60',
    inquiry_quantity: '200',
    inquiry_rate: '135',
    inquiry_status: 'Payment Received',
    inquiry_assigned: 'MR.PRAKASH',
    sales_district: 'Bhavangar',
    created_by: 'MR.DINESH ',

  },

  {
    completeinquiryid: '003',
    firm_name:'BHAGWATI AGENCY',
    name: 'AMRUT ENTERPRISE',
    grade_name:' Grade Name',
    whatsapp_number: '9825353499',
    product_name: 'HDPE MBM HALDIA B5500',
    inquiry_quantity: '350',
    inquiry_rate: '170',
    inquiry_status: 'Vehicle Assigned',
    inquiry_assigned: 'MR.DINESH ',
    sales_district: 'Morbi',
    created_by: 'MR.HITESH',

  },

  {
    completeinquiryid: '004',
    firm_name:'BAJARANG PLASTIC',
    grade_name:' Grade Name',
    name: 'DOSHI MARKETING',
    whatsapp_number: '7228902800',
    product_name: 'HDPE PE100 HALDIA P5300',
    inquiry_quantity: '450',
    inquiry_rate: '150',
    inquiry_status: 'Payment Received',
    inquiry_assigned: 'MR.CHIRAG PATEL',
    sales_district: 'rajkot',
    created_by: 'MR.KANTI PATEL',

  },


  {
    completeinquiryid: '005',
    firm_name:'M S PLAST',
    grade_name:' Grade Name',
    name: 'M L TRADING CO',
    whatsapp_number: '9327617805',
    product_name: 'HDPE MOULDING GAIL I56A200UA',
    inquiry_quantity: '550',
    inquiry_rate: '170',
    inquiry_status: 'Payment Received',
    inquiry_assigned: 'MR.SANJAY',
    sales_district: 'Bhavnagar',
    created_by: 'MR.R D PATEL',

  },

  {
    completeinquiryid: '006',
    firm_name:'GEETA TRADING COMPANY',
    name: 'BAJRANG PLASTIC',
    grade_name:' Grade Name',
    whatsapp_number: '9426006675',
    product_name: 'HDPE GPBM IOCL 012DB60',
    inquiry_quantity: '430',
    inquiry_rate: '140',
    inquiry_status: 'Vehicle Assigned',
    inquiry_assigned: 'MR.JAYESH PATEL ',
    sales_district: 'Kutch',
    created_by: 'MR.TEJAS SHAH',

  },

  {
    completeinquiryid: '007',
    firm_name:'M S PLAST',
    name: 'ANAND TRADELINK PVT LTD',
    grade_name:' Grade Name',
    whatsapp_number: '9825064231',
    product_name: 'HDPE MOULDING GAIL I56A200U-OFFGRADE',
    inquiry_quantity: '250',
    inquiry_rate: '300',
    inquiry_status: 'Invoice Generated',
    inquiry_assigned: 'MR.PRAKASH PATEL ',
    sales_district: 'Vadodara',
    created_by: 'MR.SANJAY',

  },


  {
    completeinquiryid: '008',
    firm_name:'GEETA TRADING COMPANY',
    name: 'BHAGWATI AGENCY',
    whatsapp_number: '9426006675',
    grade_name:' Grade Name',
    product_name: 'HDPE GPBM IOCL 012DB60',
    inquiry_quantity: '200',
    inquiry_rate: '135',
    inquiry_status: 'Vehicle Assigned',
    inquiry_assigned: 'MR.PRAKASH',
    sales_district: 'Bhavangar',
    created_by: 'MR.DINESH ',

  },

  {
    completeinquiryid: '009',
    firm_name:'BHAGWATI AGENCY',
    name: 'AMRUT ENTERPRISE',
    whatsapp_number: '9825353499',
    grade_name:' Grade Name',
    product_name: 'HDPE MBM HALDIA B5500',
    inquiry_quantity: '350',
    inquiry_rate: '170',
    inquiry_status: 'Payment Received',
    inquiry_assigned: 'MR.DINESH ',
    sales_district: 'Morbi',
    created_by: 'MR.HITESH',

  },

  {
    completeinquiryid: '010',
    firm_name:'BAJARANG PLASTIC',
    name: 'DOSHI MARKETING',
    grade_name:' Grade Name',
    whatsapp_number: '7228902800',
    product_name: 'HDPE PE100 HALDIA P5300',
    inquiry_quantity: '450',
    inquiry_rate: '150',
    inquiry_status: 'Invoice Generated',
    inquiry_assigned: 'MR.CHIRAG PATEL',
    sales_district: 'rajkot',
    created_by: 'MR.KANTI PATEL',

  },


  {
    completeinquiryid: '011',
    firm_name:'AMRUT ENTERPRISE',
    name: 'M L TRADING CO',
    grade_name:' Grade Name',
    whatsapp_number: '9327617805',
    product_name: 'HDPE MOULDING GAIL I56A200UA',
    inquiry_quantity: '550',
    inquiry_rate: '170',
    inquiry_status: 'Invoice Generated',
    inquiry_assigned: 'MR.SANJAY',
    sales_district: 'Bhavnagar',
    created_by: 'MR.R D PATEL',

  },

  {
    completeinquiryid: '012',
    firm_name:'M S PLAST',
    name: 'BAJRANG PLASTIC',
    whatsapp_number: '9426006675',
    grade_name:' Grade Name',
    product_name: 'HDPE GPBM IOCL 012DB60',
    inquiry_quantity: '430',
    inquiry_rate: '140',
    inquiry_status: 'Payment Received',
    inquiry_assigned: 'MR.JAYESH PATEL ',
    sales_district: 'Kutch',
    created_by: 'MR.TEJAS SHAH',

  },


]


export interface UserinquiryModel {
  name?: string
  pending?: string
  negotiation?: string
  quotation_sent?: string
  quotation_confirmed?: string
  vehicle_assigned?: string
  invoice_generated?: string
  payment_received?: string
  cancel?: string
}

const defaultUserinquiries: Array<UserinquiryModel> = [
  {
    name: 'Mr. Aiyub Panara',
    pending: '2',
    negotiation: '5',
    quotation_sent: '4',
    quotation_confirmed: '8',
    vehicle_assigned: '3',
    invoice_generated: '2',
    payment_received: '4',
    cancel: '1',

    
  },


  {
    name: 'Mr. Dharmendra Kushwah',
    pending: '5',
    negotiation: '4',
    quotation_sent: '3',
    quotation_confirmed: '8',
    vehicle_assigned: '2',
    invoice_generated: '8',
    payment_received: '1',
    cancel: '1',

  },

  {
    name: 'Mr. Darshak Perajapati',
    pending: '5',
    negotiation: '4',
    quotation_sent: '6',
    quotation_confirmed: '5',
    vehicle_assigned: '3',
    invoice_generated: '8',
    payment_received: '3',
    cancel: '3',
  },

  {
    name: 'Mr. Akib Javed',
    pending: '6',
    negotiation: '5',
    quotation_sent: '6',
    quotation_confirmed: '3',
    vehicle_assigned: '7',
    invoice_generated: '2',
    payment_received: '5',
    cancel: '2',
  },


  {
    name: 'Mr. Divyesh parmar',
    pending: '5',
    negotiation: '5',
    quotation_sent: '6',
    quotation_confirmed: '4',
    vehicle_assigned: '6',
    invoice_generated: '4',
    payment_received: '3',
    cancel: '1',

  },

  {
    name: 'Mr. Ashok Vaghela',
    pending: '6',
    negotiation: '6',
    quotation_sent: '5',
    quotation_confirmed: '3',
    vehicle_assigned: '2',
    invoice_generated: '1',
    payment_received: '2',
    cancel: '1',

  },

  {
    name: 'Mr. Pawan ',
    pending: '8',
    negotiation: '5',
    quotation_sent: '4',
    quotation_confirmed: '6',
    vehicle_assigned: '5',
    invoice_generated: '8',
    payment_received: '4',
    cancel: '0',
  },

  {
    name: 'Mr. Brij ',
    pending: '8',
    negotiation: '5',
    quotation_sent: '3',
    quotation_confirmed: '2',
    vehicle_assigned: '6',
    invoice_generated: '5',
    payment_received: '2',
    cancel: '0',
  },

  
]



export interface ArticleModel {
  articleid?:string
  title?: string
  category?: string
  date_time?: string
}

const defaultArticles: Array<ArticleModel> = [
  {
    articleid: '#001',
    title: 'Solid State PP Plastic Polymer',
    date_time: '25/04/21, 15:36pm',
    category: 'Packing Materials'
   

  },

  {
    articleid: '#002',
    title: 'Black HDPE Granule, Grade',
    date_time: '29/05/21, 13:25pm',
    category: 'Insulation'

  },
  {
    articleid: '#003',
    title: 'Industrial Grade, 0.962 G/Cm3',
    date_time: '15/06/21, 16:35pm',
    category: 'Wood substitutes'

  },
  {
    articleid: '#004',
    title: 'Opal HDPE Blow Moulding Granules,',
    date_time: '18/07/21, 17:35pm',
    category: 'HDPE GPBM'

  },
  {
    articleid: '#005',
    title: 'High Molecular High Density Polyethylene',
    date_time: '21/08/21, 19:00pm',
    category: 'HM Film'

  },
  {
    articleid: '#006',
    title: 'Solid State PP Plastic Polymer',
    date_time: '25/04/21, 15:36pm',
    category: 'Packing Materials'
   

  },

  {
    articleid: '#007',
    title: 'Black HDPE Granule, Grade',
    date_time: '29/05/21, 13:25pm',
    category: 'Insulation'

  },
  {
    articleid: '#008',
    title: 'Industrial Grade, 0.962 G/Cm3',
    date_time: '15/06/21, 16:35pm',
    category: 'Wood substitutes'

  },
  {
    articleid: '#009',
    title: 'Opal HDPE Blow Moulding Granules,',
    date_time: '18/07/21, 17:35pm',
    category: 'HDPE GPBM'

  },
  {
    articleid: '#0010',
    title: 'High Molecular High Density Polyethylene',
    date_time: '21/08/21, 19:00pm',
    category: 'HM Film'

  },

  
]


export interface CateModel {
  cate_id?:string
  category_name?: string
  number_article?: string
  date_time?: string
}

const defaultCates: Array<CateModel> = [
  {
    cate_id: '#001',
    category_name: 'Packing Materials',
    number_article: "4",
    date_time: '25/04/21, 15:36pm',
    
   

  },

  {
    cate_id: '#002',
    category_name: 'Insulation',
    number_article: "5",
    date_time: '29/05/21, 13:25pm',
   

  },
  {
    cate_id: '#003',
    category_name: '	Wood substitutes',
    number_article: "8",
    date_time: '15/06/21, 16:35pm',
    

  },
  {
    cate_id: '#004',
    category_name: 'HDPE GPBM',
    number_article: "3",
    date_time: '18/07/21, 17:35pm',
  

  },
  {
    cate_id: '#005',
    category_name: 'HM Film',
    number_article: "2",
    date_time: '21/08/21, 19:00pm',
   

  },
  {
    cate_id: '#006',
    category_name: 'Wood substitutes',
    number_article: "4",
    date_time: '25/10/21, 15:31pm',
    

  },

  {
    cate_id: '#007',
    category_name: 'HDPE GPBM',
    number_article: "7",
    date_time: '29/10/21, 14:22pm',
    

  },

  {
    cate_id: '#008',
    category_name: 'Insulation',
    number_article: "5",
    date_time: '30/11/21, 16:18pm',
    

  },

  
]

export {
  defaultMessages, defaultUserInfos, defaultAlerts, defaultBuyers, defaultOffers, defaultInquiries, defaultSellers,
  defaultQuotations, defaultLogs, messageFromClient, defaultProducts, defaultTransports, defaultUsers, defaultPurchases,
  defaultGrades, defaultFreights, defaultStates, defaultDistricts, defaultBuyersellers, defaultOrders, defaultInvoices, 
  defaultSelleroffers, defaultGradegroups, defaultExpireoffers, defaultActiveinquiries, defaultCompleteinquiries,
   defaultUserinquiries, defaultProductgroups, defaultArticles, defaultCates, defaultInquirys }
