import {Col, Form, Row} from 'react-bootstrap'
import { StatesListWrapper } from './states-list/StatesList'

const State = () => {
  return (
    <div>
      <Row>
        <Col>
          <Form noValidate className='border mt-6 p-3' id='kt_modal_add_user_form'>
            <h4 className='card-label font-weight-bolder text-dark font-size-h5 mb-6'>
              {' '}
              <StatesListWrapper />
            </h4>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default State
