import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import AddEditBuyer from "./AddEditBuyer";


const wizardsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Buyer Management',
      path: '/crafted/pages/wizards/horizontal',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
const EditBuyer = () => {

    const navigate = useNavigate();
    const navigateToListBuyer = () => {
        // 👇️ navigate to /contacts
        navigate('/buyer-management/buyers')
      }

      const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
      })
    
    
      const files = acceptedFiles.map((file: any) => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      ))
    

    return(
        <>
        <PageTitle>Edit Buyer</PageTitle>
        <Col className="position-absolute me-5 end-0 tp-btn-mn pt-6">
        <button 
          type="button" 
          onClick={navigateToListBuyer}
          value="" 
          className="btn btn-sm fs-5 me-5 btn btn-primary mt-3"> 
          Back
          </button>
          </Col>
          <div className='card card-body p-9'>
          <AddEditBuyer
           />
      </div>
        
        </>
    )
}

export default EditBuyer