import Multiselect from 'multiselect-react-dropdown'
import React, {useRef, useState} from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import AddEditInquiry from './AddEditInquiry'
import AddEditInquiryModal from './AddEditInquiry'

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Inquiry Management',
    path: '/crafted/pages/wizards/horizontal',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const buyerList = [
  {value: 'buyer1', label: 'Buyer Name 01'},
  {value: 'buyer2', label: 'Buyer Name 02'},
  {value: 'buyer3', label: 'Buyer Name 03'},
  {value: 'buyer4', label: 'Buyer Name 04'},
  {value: 'buyer5', label: 'Buyer Name 05'},
  {value: 'buyer6', label: 'Buyer Name 06'},
  {value: 'buyer7', label: 'Buyer Name 07'},
  {value: 'buyer8', label: 'Buyer Name 08'},
  {value: 'buyer9', label: 'Buyer Name 09'},
  {value: 'buyer10', label: 'Buyer Name 10'},
  {value: 'buyer11', label: 'Buyer Name 11'},
  {value: 'buyer12', label: 'Buyer Name 12'},
]

const productList = [
  {value: 'product1', label: 'Product Name 01'},
  {value: 'product2', label: 'Product Name 02'},
  {value: 'product3', label: 'Product Name 03'},
  {value: 'product4', label: 'Product Name 04'},
  {value: 'product5', label: 'Product Name 05'},
  {value: 'product6', label: 'Product Name 06'},
  {value: 'product7', label: 'Product Name 07'},
  {value: 'product8', label: 'Product Name 08'},
  {value: 'product9', label: 'Product Name 09'},
  {value: 'product10', label: 'Product Name 10'},
  {value: 'product11', label: 'Product Name 11'},
  {value: 'product12', label: 'Product Name 12'},
]

const assigneeList = [
  {value: 'assignee1', label: 'Assignee Name 01'},
  {value: 'assignee2', label: 'Assignee Name 02'},
  {value: 'assignee3', label: 'Assignee Name 03'},
  {value: 'assignee4', label: 'Assignee Name 04'},
  {value: 'assignee5', label: 'Assignee Name 05'},
  {value: 'assignee6', label: 'Assignee Name 06'},
  {value: 'assignee7', label: 'Assignee Name 07'},
  {value: 'assignee8', label: 'Assignee Name 08'},
  {value: 'assignee9', label: 'Assignee Name 09'},
  {value: 'assignee10', label: 'Assignee Name 10'},
  {value: 'assignee11', label: 'Assignee Name 11'},
  {value: 'assignee12', label: 'Assignee Name 12'},
]

const EditInquiry = () => {
  const navigate = useNavigate()

  const navigateInuiryListing = () => {
    //   // 👇️ navigate to /
    navigate('/inquiry-management/Inquiries')
  }

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [isShowSourceFild, setIsShowSourceFild] = useState(false)

  return (
    <>
      <PageTitle>Edit Inquiry</PageTitle>
      <Col className='position-absolute me-5 end-0 tp-btn-mn  '>
        <button
          type='button'
          onClick={navigateInuiryListing}
          value=''
          className='btn btn-sm fs-5  me-5 btn btn-primary'
        >
          Back
        </button>
      </Col>
      <div className='card card-body p-12 mt-10'>
        <div ref={stepperRef}>
          <AddEditInquiry />
        </div>
      </div>
    </>
  )
}
export default EditInquiry
