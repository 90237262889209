/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {OverlayTrigger, Toast, ToastContainer, Tooltip} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID } from '../../../../../../../_metronic/helpers'
import { useLayout } from '../../../../../../../_metronic/layout/core'
import EditDistrictModal from '../../../EditDistrict'
// import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
// import {ID} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { updateDistrictApi } from '../../core/_requests'
// import {useQueryResponse} from '../../core/QueryResponseProvider'
// import {deleteDistrict} from '../../core/_requests'

type Props = {
  id: ID
  status: any
}

const DistrictActionsCell: FC<Props> = ({id,status}) => {
  const {refetch} = useQueryResponse()
  const {setItemIdForUpdate} = useListView()
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  const updateStatusAction = async (values:any) => {
    try{
      let response = await updateDistrictApi(id,values)
      if(response.responseStatus == 200){
        refetch()
        setSessionMessage({type: 'success',message: response.responseMessage ,show: true})      
      }
      else{
        setSessionMessage({type: 'danger',message: response.responseMessage ,show: true})
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setSessionMessage({type: 'danger',message: message ,show: true})
    }
  }

  useEffect(()=>{
    setTimeout(()=>{
      setSessionMessage({type: '',message: '',show: false})
    },3000)
  },[sessionMessage.show])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const navigate = useNavigate()
  const navigateEditDistrict = () => {
    // 👇️ navigate to /
    navigate('/editdistrict')
  }

  const navigateViewDistrict = () => {
    // 👇️ navigate to /
    navigate('/viewdistrict')
  }

  const {config} = useLayout()
  const [ShowEditDistrictModal, setShowEditDistrictModal] = useState<boolean>(false)

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [isShowNameError, setIsShowNameError] = useState(false)
  const [isShowSourceFild, setIsShowSourceFild] = useState(false)
  const [ishideSourceFild, setishideSourceFild] = useState(false)

  return (
    <>
      <div
        className=' d-flex justify-content-start menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 '
        // data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-1'>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip className='py-2 px-2 fs-5 '>Edit</Tooltip>}
          >
            <a className='menu-link px-1'onClick={() => setShowEditDistrictModal(true)}>
              <i className='fa-solid fa-user-pen fs-4 text-warning' title='Edit'></i>
            </a>
          </OverlayTrigger>
          <EditDistrictModal
            show={ShowEditDistrictModal}
            handleClose={() => setShowEditDistrictModal(false)}
            id={id}
          />
        </div>
        {/* end::Menu item */}
        {status ? (
          <div className='menu-item px-1'>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Show</Tooltip>}
            >
              <a
                onClick={() => {
                  updateStatusAction({status:0})
                  // setIsShowSourceFild(true)
                }}
                className='menu-link px-1'
              >
                <i className='fa-sharp fa-solid fa-eye fs-4 text-primary'></i>
              </a>
            </OverlayTrigger>
          </div>
        )
        :(
          <div className='menu-item px-1'>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Hide</Tooltip>}
            >
              <a className='menu-link px-1'
              onClick={() => {
                updateStatusAction({status:1})
                // setIsShowSourceFild(false)
              }}
              >
                <i className='fa-sharp fa-solid fa-eye-slash fs-4 text-light-grey'></i>
              </a>
            </OverlayTrigger>
          </div>
        )}
        <ToastContainer className='p-3 position-fixed' position={'top-center'}>
          <Toast
            show={sessionMessage.show}
            bg={sessionMessage.type}
          >
            <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
          </Toast>
        </ToastContainer>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {DistrictActionsCell}
