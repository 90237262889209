import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../_metronic/layout/core'
import {AddTransportModal} from './add-transport/AddTransportModal'
import { ViewTransportModal } from './view-transport/ViewTransportModal'

const TransportManagement = () => {
  const {config} = useLayout()
  const [showAddTransportModal, setShowAddTransportModal] = useState<boolean>(false)
  const [showViewTransportModal, setShowViewTransportModal] = useState<boolean>(false)


  return (
    <div className='card card-body pt-9 pe-5 pb-5 ps-5'>
      <div className='d-flex align-items-center justify-content-between'>
        <h2>Transports list</h2>
        <div>
          {config.app?.toolbar?.primaryButton && (
            <Link
              to='#'
              onClick={() => setShowAddTransportModal(true)}
              className='btn fw-bold btn-primary me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_user'
            >
              Add Transport
            </Link>
          )}
          <AddTransportModal
            show={showAddTransportModal}
            handleClose={() => setShowAddTransportModal(false)}
          />
          {config.app?.toolbar?.primaryButton && (
            <Link
              to='#'
              onClick={() => setShowViewTransportModal(true)}
              className='btn fw-bold btn-primary me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_user'
            >
              View Transport
            </Link>
          )}
          <ViewTransportModal
            show={showViewTransportModal}
            handleClose={() => setShowViewTransportModal(false)}
          />
        </div>
      </div>
      <div>
        {/* <Form id='kt_modal_add_user_form' className='mt-10'>
          <Row className='mb-7'>
            <Col xs={12} md={6}>
              <Form.Label className='required fw-bold fs-6 mb-2 form-label'>Firm Name</Form.Label>
              <Form.Control type='text' className='form-control form-control-solid mb-lg-0' />
              <div className=' mb-3 fv-plugins-message-container'>
                <div className='fv-help-block'>“Please enter valid Name”</div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                Contact Person Name
              </Form.Label>
              <Form.Control type='text' className='form-control form-control-solid mb-lg-0' />
              <div className=' mb-3 fv-plugins-message-container'>
                <div className='fv-help-block'>“Please enter valid Person Name”</div>
              </div>
            </Col>
          </Row>
          <Row className='mb-7'>
            <Col xs={12} md={6}>
              <Form.Label className='required fw-bold fs-6 mb-2 form-label'>GST Number</Form.Label>
              <Form.Control type='number' className='form-control form-control-solid mb-lg-0' />
              <div className=' mb-3 fv-plugins-message-container'>
                <div className='fv-help-block'>“Please enter valid GST Number”</div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                Mobile Number
              </Form.Label>
              <Form.Control type='number' className='form-control form-control-solid mb-lg-0' />
              <div className=' mb-3 fv-plugins-message-container'>
                <div className='fv-help-block'>“Please enter valid Mobile Number”</div>
              </div>
            </Col>
          </Row>
          <Row className='mb-7'>
            <Col xs={12} md={6}>
              <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                Address - State/City
              </Form.Label>
              <Form.Control type='text' className='form-control form-control-solid mb-lg-0' />
              <div className=' mb-3 fv-plugins-message-container'>
                <div className='fv-help-block'>“Please enter valid Address”</div>
              </div>
            </Col>
            <Col xs={12} md={6}></Col>
          </Row>
          <Row className='mb-7'>
            <div className='text-end pt-10'>
              <Button
                data-kt-users-modal-action='cancel'
                className='btn btn-danger me-3 btn btn-primary'
              >
                Cancel
              </Button>
              <Button
                data-kt-users-modal-action='cancel'
                className='btn btn-primary btn btn-primary'
              >
                Save
              </Button>
            </div>
          </Row>
        </Form> */}
      </div>
    </div>
  )
}

export default TransportManagement
