import React, {useRef} from 'react'
import {Col, Modal, Row} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

type Props = {
  show: boolean
  handleClose: () => void
  fileName: any
  loadingFile: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SellerGstCertificate = ({show, handleClose, fileName, loadingFile}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  console.log(
    `${process.env.REACT_APP_API_URL_NEW}/${fileName}`,
    '${process.env.REACT_APP_API_URL_NEW}/${fileName}'
  )

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header border-bottom border-gray-300 border-bottom-dashed'>
        <h2> GST Certificate </h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
      {!loadingFile && (
        <iframe
          src={`${process.env.REACT_APP_API_URL_NEW}/uploads/buyerSeller/${fileName}`}
          frameBorder='0'
          scrolling='auto'
          height='600px'
          width='100%'
        ></iframe>
      )}
    </Modal>,
    modalsRoot
  )
}

export default SellerGstCertificate
