import {useListView} from '../../core/ListViewProvider'
import {StateListToolbar} from './StateListToolbar'
import {StateListGrouping} from './StatesListGrouping'
import {StatesListSearchComponent} from './StatesListSearchComponent'

const StatesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <StatesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <StateListGrouping /> : <StateListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {StatesListHeader}
