import {useQuery} from 'react-query'
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers'
// import { StateEditModalForm } from './StateEditModalForm'
// import { isNotEmpty, QUERIES } from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getStateById} from '../core/_requests'
import { StateEditModalForm } from './StateEditModalForm'

const StateEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: state,
    error,
  } = useQuery(
    `${QUERIES.STATES_LIST}-state-${itemIdForUpdate}`,
    () => {
      return getStateById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <StateEditModalForm isStateLoading={isLoading} state={{id: undefined}} />
  }

  if (!isLoading && !error && state) {
    return <StateEditModalForm isStateLoading={isLoading} state={state} />
  }

  return null
}

export {StateEditModalFormWrapper}
