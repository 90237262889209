import {CKEditor} from 'ckeditor4-react'
import React, { useCallback, useEffect, useState } from 'react'
import {Button, Col, Form, Row, Spinner, Toast, ToastContainer} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import adminRoles from '../../utils/adminRoles'
import CustomCkEditor from '../ckeditor/CustomCkEditor'
import { getSettingApi, updateSettingApi, addGraphApi, downloadGraphApi, downloadMediaApi } from './_request'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Setting',
    path: '/setting',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
export const Setting = () => {
  const [isLoading,setIsLoading] = useState<any>(true)
  const [SGST,setSGST] = useState<any>()
  const [CGST,setCGST] = useState<any>()
  const [IGST,setIGST] = useState<any>()
  const [price_thresold_limit,set_price_thresold_limit] = useState<any>()
  const [t_c_buyer, set_t_c_buyer] = useState<any>({value:""})
  const [t_c_quotation, set_t_c_quotation] = useState<any>({value:""})
  const [t_c_PO, set_t_c_PO] = useState<any>({value:""})
  const [t_c_seller, set_t_c_seller] = useState<any>({value:""})
  const [t_c_buyer_inquiry_add, set_t_c_buyer_inquiry_add] = useState<any>({value:""})
  const [file, setFile] = useState<any>("")
  const [downloadFile, setDownloadFile] = useState<any>("")

  const onDrop = useCallback((acceptedFiles:any) => {
    acceptedFiles.forEach((file:any) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = (event:any) => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result
        setFile(file)
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])
  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx','.xls'],
      'application/vnd.ms-excel': ['.xlsx','.xls']
    }
  })
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const setSetting = async () => {
    try {
      let response = await getSettingApi()
      if(response.responseStatus == 200){
        let list = response.responseData.list
        setSGST(list.find((x:any)=>x.key == "SGST") ?? {})
        setCGST(list.find((x:any)=>x.key == "CGST") ?? {})
        setIGST(list.find((x:any)=>x.key == "IGST") ?? {})
        set_price_thresold_limit(list.find((x:any)=>x.key == "price_thresold_limit") ?? {})
        set_t_c_buyer(list.find((x:any)=>x.key == "t_c_buyer") ?? {})
        set_t_c_quotation(list.find((x:any)=>x.key == "t_c_quotation") ?? {})
        set_t_c_PO(list.find((x:any)=>x.key == "t_c_PO") ?? {})
        set_t_c_seller(list.find((x:any)=>x.key == "t_c_seller") ?? {})
        set_t_c_buyer_inquiry_add(list.find((x:any)=>x.key == "t_c_buyer_inquiry_add") ?? {})
        setIsLoading(false)
      }
      else{
        setIsLoading(false)
        setSessionMessage({type: 'danger',message: response.responseMessage,show: true})
      }
    }
    catch(err){
      setIsLoading(false)
      setSessionMessage({type: 'danger',message: "Something Went Wrong",show: true})
    }
  }

  const updateSetting = async (data:any) => {
    try {
      if(!data.value && data.value !== 0){
        setSessionMessage({type: 'danger',message: "This field is required",show: true})
        return 
      }
      let payload = {
        key: data.key,
        value: data.value,
      }
      let response = await updateSettingApi(data.id,payload)
      if(response.responseStatus == 200){
        setSessionMessage({type: 'success',message: response.responseMessage ,show: true})
      }
      else{
        setSessionMessage({type: 'danger',message: response.responseMessage ,show: true})
      }
    }
    catch(err){
      setSessionMessage({type: 'danger',message: "Something Went Wrong" ,show: true})
    }
  }

  const sendFile = async () => {
    if(!file){
      setSessionMessage({type: 'danger',message: "This field is required",show: true})
      return 
    }else{
      try{
        let formdata = new FormData()
        formdata.append("file",file)
        let response = await addGraphApi(formdata)
        if(response.responseStatus == 200){
          setSessionMessage({type: 'success',message: response.responseMessage ,show: true})
        }
        else{
          setSessionMessage({type: 'danger',message: response.responseMessage ,show: true})
        }
      }
      catch(err:any){
        setSessionMessage({type: 'danger',message: err.message ?? "Something Went Wrong" ,show: true})
      }
    }
  }

  const sampleFileDownload = async () => {
    let response = await downloadGraphApi()
    if(response.responseStatus == 200){
      if(response.responseData.url){
        setDownloadFile(downloadMediaApi(response.responseData.url))
      }
    }
  }

  useEffect(()=>{
    sampleFileDownload()
    setSetting()
  },[])

  console.log("file",file)

  useEffect(()=>{
    setTimeout(()=>{
      setSessionMessage({type: '',message: '',show: false})
    },5000)
  },[sessionMessage.show])

  return (
    <>
      <PageTitle> Setting </PageTitle>
      {isLoading ? <>
        <div
          style={{
            height: '550px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation='border' />
        </div>
      </> : (  
      <Row>
        {adminRoles() ?
        <>
          {/* <Col xl={6} className='mb-8 '>
            <div className='card card-body py-12'>
              <Form id='kt_modal_add_user_form'>
                <Col xl={10} className='ms-auto me-auto b-7'>
                  <Col className='mb-6'>
                    <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                      Price threshold limit (%)
                    </Form.Label>
                    <Form.Control type='number' 
                      className='form-control form-control-solid mb-lg-0'
                      value={price_thresold_limit?.value}
                      onChange={(e)=>{
                        set_price_thresold_limit({...price_thresold_limit,...{value:e.target.value == "" ? "" : parseInt(e.target.value)}})
                      }} 
                    />
                    <Col className='text-end'>
                      <Button
                        onClick={()=>{
                          updateSetting(price_thresold_limit)
                        }}
                        data-kt-users-modal-action='cancel'
                        className='btn btn-primary btn btn-primary mt-8 ms-auto'
                      >
                        Save
                      </Button>
                    </Col>
                  </Col>
                </Col>
              </Form>
            </div>
          </Col> */}

          <Col xl={4} className='mb-8 '>
            <div className='card card-body py-12'>
              <Form id='kt_modal_add_user_form'>
                <Col xl={10} className='ms-auto me-auto b-7'>
                  <Col className='mb-6'>
                    <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                      SGST % (%)
                    </Form.Label>
                    <Form.Control type='number' 
                      className='form-control form-control-solid mb-lg-0' 
                      value={SGST?.value}
                      onChange={(e)=>{
                        setSGST({...SGST,...{value:e.target.value == "" ? "" : parseInt(e.target.value)}})
                      }}
                    />
                    <Col className='text-end'>
                      <Button
                        onClick={()=>{
                          updateSetting(SGST)
                        }}
                        data-kt-users-modal-action='cancel'
                        className='btn btn-primary btn btn-primary mt-8 ms-auto'
                      >
                        Save
                      </Button>
                    </Col>
                  </Col>
                </Col>
              </Form>
            </div>
          </Col>

          <Col xl={4} className='mb-8 '>
            <div className='card card-body py-12'>
              <Form id='kt_modal_add_user_form'>
                <Col xl={10} className='ms-auto me-auto b-7'>
                  <Col className='mb-6'>
                    <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                      CGST (%)
                    </Form.Label>
                    <Form.Control type='number' 
                      className='form-control form-control-solid mb-lg-0' 
                      value={CGST?.value}
                      onChange={(e)=>{
                        setCGST({...CGST,...{value:e.target.value == "" ? "" : parseInt(e.target.value)}})
                      }}
                    />
                    <Col className='text-end'>
                      <Button
                        onClick={()=>{
                          updateSetting(CGST)
                        }}
                        data-kt-users-modal-action='cancel'
                        className='btn btn-primary btn btn-primary mt-8 ms-auto'
                      >
                        Save
                      </Button>
                    </Col>
                  </Col>
                </Col>
              </Form>
            </div>
          </Col>

          <Col xl={4} className='mb-8'>
            <div className='card card-body py-12'>
              <Form id='kt_modal_add_user_form'>
                <Col xl={10} className='ms-auto me-auto b-7'>
                  <Col className='mb-6'>
                    <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                      IGST % (%)
                    </Form.Label>
                    <Form.Control type='number' 
                      className='form-control form-control-solid mb-lg-0' 
                      value={IGST?.value}
                      onChange={(e)=>{
                        setIGST({...IGST,...{value:e.target.value == "" ? "" : parseInt(e.target.value)}})
                      }}
                    />
                    <Col className='text-end'>
                      <Button
                        onClick={()=>{
                          updateSetting(IGST)
                        }}
                        data-kt-users-modal-action='cancel'
                        className='btn btn-primary btn btn-primary mt-8 ms-auto'
                      >
                        Save
                      </Button>
                    </Col>
                  </Col>
                </Col>
              </Form>
            </div>
          </Col>
        </> : ""}

        <Col xl={6} className='mb-8'>
          <div className='card card-body py-12'>
            <Form id='kt_modal_add_user_form'>
              <Col xl={10} className='ms-auto me-auto b-7'>
                <Col className='mb-6'>
                  <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                    Terms And Conditions For Quotation
                  </Form.Label>
                  {/* <Form.Control
                    type='text'
                    as="textarea"
                    rows={5}
                    className='form-control-solid'
                    placeholder='Application'
                  /> */}
                  <CustomCkEditor key={'TCQ'} data={t_c_quotation.value} 
                    changeData={(data:any)=>{
                      set_t_c_quotation({...t_c_quotation,...{value:data}})
                    }}/>
                </Col>
                <Col className='text-end'>
                  <Button
                    onClick={()=>{
                      updateSetting(t_c_quotation)
                    }}
                    data-kt-users-modal-action='cancel'
                    className='btn btn-primary btn btn-primary mt-8 ms-auto'
                  >
                    Save
                  </Button>
                </Col>
              </Col>
            </Form>
          </div>
        </Col>

        <Col xl={6}>
          <div className='card card-body py-12'>
            <Form id='kt_modal_add_user_form'>
              <Col xl={10} className='ms-auto me-auto b-7'>
                <Col className='mb-6'>
                  <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                    Terms And Conditions For PO
                  </Form.Label>
                  <CustomCkEditor key={'TCP'} data={t_c_PO.value} 
                    changeData={(data:any)=>{
                      set_t_c_PO({...t_c_PO,...{value:data}})
                    }}
                  />
                </Col>
                <Col className='text-end'>
                  <Button
                    onClick={()=>{
                      updateSetting(t_c_PO)
                    }}
                    data-kt-users-modal-action='cancel'
                    className='btn btn-primary btn btn-primary mt-8 ms-auto'
                  >
                    Save
                  </Button>
                </Col>
              </Col>
            </Form>
          </div>
        </Col>

        <Col xl={6} className='mb-8'>
          <div className='card card-body py-12'>
            <Form id='kt_modal_add_user_form'>
              <Col xl={10} className='ms-auto me-auto b-7'>
                <Col className='mb-6'>
                  <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                    Terms And Conditions For Buyer
                  </Form.Label>
                  {/* <Form.Control
                    type='text'
                    as="textarea"
                    rows={5}
                    className='form-control-solid'
                    placeholder='Application'
                  /> */}
                  <CustomCkEditor key={'TCB'} data={t_c_buyer.value} 
                    changeData={(data:any)=>{
                      set_t_c_buyer({...t_c_buyer,...{value:data}})
                    }}
                  />
                </Col>
                <Col className='text-end'>
                  <Button
                    onClick={()=>{
                      updateSetting(t_c_buyer)
                    }}
                    data-kt-users-modal-action='cancel'
                    className='btn btn-primary btn btn-primary mt-8 ms-auto'
                  >
                    Save
                  </Button>
                </Col>
              </Col>
            </Form>
          </div>
        </Col>

        <Col xl={6}>
          <div className='card card-body py-12'>
            <Form id='kt_modal_add_user_form'>
              <Col xl={10} className='ms-auto me-auto b-7'>
                <Col className='mb-6'>
                  <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                    Terms And Conditions For Seller
                  </Form.Label>
                  <CustomCkEditor key={'TCS'} data={t_c_seller.value} 
                    changeData={(data:any)=>{
                      set_t_c_seller({...t_c_seller,...{value:data}})
                    }}
                  />
                </Col>
                <Col className='text-end'>
                  <Button
                    onClick={()=>{
                      updateSetting(t_c_seller)
                    }}
                    data-kt-users-modal-action='cancel'
                    className='btn btn-primary btn btn-primary mt-8 ms-auto'
                  >
                    Save
                  </Button>
                </Col>
              </Col>
            </Form>
          </div>
        </Col>

        <Col xl={6}>
          <div className='card card-body py-12'>
            <Form id='kt_modal_add_user_form'>
              <Col xl={10} className='ms-auto me-auto b-7'>
                <Col className='mb-6'>
                  <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                    Terms and condition For Add Inquiry
                  </Form.Label>
                  <CustomCkEditor key={'TCAI'} data={t_c_buyer_inquiry_add.value} 
                    changeData={(data:any)=>{
                      set_t_c_buyer_inquiry_add({...t_c_buyer_inquiry_add,...{value:data}})
                    }}
                  />
                </Col>
                <Col className='text-end'>
                  <Button
                    onClick={()=>{
                      updateSetting(t_c_buyer_inquiry_add)
                    }}
                    data-kt-users-modal-action='cancel'
                    className='btn btn-primary btn btn-primary mt-8 ms-auto'
                  >
                    Save
                  </Button>
                </Col>
              </Col>
            </Form>
          </div>
        </Col>

        <Col xl={6}>
          <div className='card card-body py-12'>
            <Form id='kt_modal_add_user_form'>
              <Col xl={10} className='ms-auto me-auto b-7'>
                <Col className='mb-6'>
                  <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                    Graph for Buyer/Seller
                  </Form.Label>
                  <div onClick={open}>
                    <div {...getRootProps({className: 'dropzone py-12'})}>
                      <input {...getInputProps()} />
                      <div className='dz-message needsclick'>
                        <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                          <KTSVG
                            path='/media/icons/duotune/files/fil009.svg'
                            className='svg-icon-muted svg-icon-2hx'
                          />
                        </span>
                        <div className='ms-4'>
                          <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                            Drag/Drop files here or click here
                          </h3>
                          {/* <span className='fw-semibold fs-4 text-muted'>Upload files</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {file?.name ?? ""}
                </Col>
                <Col md={6} className='text-Start'>
                  <Form.Label className=' form-label text'>
                  {!downloadFile ? 
                    <Link
                      to={""}
                      onClick={()=>{
                        setSessionMessage({type: 'danger',message: "File not found",show: true})
                      }}
                      className='btn me-3 text-primary px-0 cursor-pointer'
                    >
                      <u>Download Sample File</u>
                    </Link>
                    :
                    <a
                      href={downloadFile}
                      target='_blank'
                      className='btn me-3 text-primary px-0 cursor-pointer'
                      download
                    >
                      <u>Download Sample File</u>
                    </a>
                  }
                  </Form.Label>
                </Col>
                <Col className='text-end'>
                  <Button
                    onClick={()=>{
                      sendFile()
                    }}
                    data-kt-users-modal-action='cancel'
                    className='btn btn-primary btn btn-primary mt-8 ms-auto'
                  >
                    Upload
                  </Button>
                </Col>
              </Col>
            </Form>
          </div>
        </Col>
        <ToastContainer className='p-3 position-fixed' position={'top-center'}>
          <Toast
            show={sessionMessage.show}
            bg={sessionMessage.type}
          >
            <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      </Row>
      )}
    </>
  )
}
