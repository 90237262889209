import React, { useEffect, useState } from 'react'
import { checkDisplayValue } from '../../../utils/utils'
import { getTopBuyersApi } from '../_requests'

type Props = {
  setMessage: (type:string,message:string) => void
}

const TopBuyer = ({setMessage}:Props) => {

  const [topBuyerData,setTopBuyerData] = useState([])
  const [loading,setLoading] = useState(true)

  const callDashboardReport = async () => {
    try{
      setLoading(true)
      let {responseStatus, responseMessage, responseData} = await getTopBuyersApi()
      if(responseStatus == 200){
        if(responseData.list && responseData.list.length){
          setTopBuyerData(responseData.list)
        }
        else{
          setTopBuyerData([])
        }
        setLoading(false)
      }
      else{
        setMessage("danger",responseMessage)
        setLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setMessage("danger",message)
      setLoading(false)
    }
  } 

  useEffect(()=>{
    callDashboardReport()
  },[])

  return (
    <div className='row'>
      <div className='col-xl-12'>
        <div className='p-9'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr>
                <th className='fs-6 fw-bold border-0 text-gray-400 text-uppercase'>Buyer Name</th>
                <th className='fs-6 me-13 d-block text-end fw-bold border-0 text-gray-400 me-5 d-block text-uppercase'>MT</th>
              </tr>
            </thead>
            <tbody>
              {loading ? 
              <h4 className='d-flex justify-content-center mt-3'>Loading...</h4> : topBuyerData.length == 0 ?
              <h4 className='d-flex justify-content-center mt-3'>No data available in table</h4> :
              topBuyerData.map((x:any)=>{
                  return (
                    <tr key={x.id}>
                      <td className='text-dark fw-bold mb-1 fs-6'>{checkDisplayValue(x.name)}</td>
                      <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>{checkDisplayValue(x.totalQuantity)}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TopBuyer
