import React, { useRef, useState } from "react"; 
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../../../_metronic/layout/core";
import CategoriesPage from "./CategoriesPage";

const AddCategory = () => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const [isShowNameError, setIsShowNameError] = useState(false)
    const [isShowSourceFild, setIsShowSourceFild] = useState(false)
  
    const navigate = useNavigate();
    // const navigateViewBuyer = () => {
    //   // 👇️ navigate to /
    //   navigate('/viewbuyer');
    // };
    const navigateCateList = () => {
      // 👇️ navigate to /
      navigate('/news-category/cates');
    };
  
    return (
      <>
        <PageTitle> Add Category </PageTitle>
        <Col className='position-absolute me-5 end-0 tp-btn-mn  '>
          <button
            type='button'
            onClick={navigateCateList}
            value=''
            className='btn btn-sm fs-5 me-5 btn btn-primary'
          >
            Back
          </button>
        </Col>
        <Col className='card card-body backgroung-transparent   p-12  mt-10 2'>
          <CategoriesPage />
        </Col>
      </>
    )
  }

export default AddCategory