/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {OverlayTrigger, Toast, ToastContainer, Tooltip} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID } from '../../../../../../../_metronic/helpers'
// import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
// import {ID} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { updateStateApi } from '../../core/_requests'
// import {useQueryResponse} from '../../core/QueryResponseProvider'
// import {deleteState} from '../../core/_requests'

type Props = {
  id: ID,
  status:any
}

const StateActionsCell: FC<Props> = ({id,status}) => {
  const {setItemIdForUpdate} = useListView()
  // const {query} = useQueryResponse()
  // const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const {refetch} = useQueryResponse()
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  const updateStatusAction = async (values:any) => {
    try{
      let response = await updateStateApi(id,values)
      if(response.responseStatus == 200){
        refetch()
        setSessionMessage({type: 'success',message: response.responseMessage ,show: true})      
      }
      else{
        setSessionMessage({type: 'danger',message: response.responseMessage ,show: true})
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setSessionMessage({type: 'danger',message: message ,show: true})
    }
  }

  useEffect(()=>{
    setTimeout(()=>{
      setSessionMessage({type: '',message: '',show: false})
    },3000)
  },[sessionMessage.show])

  // const deleteItem = useMutation(() => deleteState(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.STATES_LIST}-${query}`])
  //   },
  // })

  const navigate = useNavigate()
  const navigateEditState = () => {
    // 👇️ navigate to /
    navigate('/editstate')
  }

  const navigateViewState = () => {
    // 👇️ navigate to /
    navigate('/viewstate')
  }

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [isShowNameError, setIsShowNameError] = useState(false)
  const [isShowSourceFild, setIsShowSourceFild] = useState(false)
  const [ishideSourceFild, setishideSourceFild] = useState(false)


  return (
    <>
      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
         data-kt-menu-trigger='click'
         data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a> */}

      {/* begin::Menu */}
      <div
        className=' d-flex justify-content-start menu-gray-600 menu-state-bg-light-primary fw-bold fs-7'
        // data-kt-menu='true'
      >
        {status ? (
          <div className='menu-item px-1'>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Show</Tooltip>}
            >
              <a
                onClick={() => {
                  updateStatusAction({status:0})
                }}
                className='menu-link px-1'
              >
                <i className='fa-sharp fa-solid fa-eye fs-4 text-primary'></i>
              </a>
            </OverlayTrigger>
          </div>
        ):
        (
          <div className='menu-item px-1'>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Hide</Tooltip>}
            >
              <a className='menu-link px-1'
              onClick={() => {
                updateStatusAction({status:1})
              }}
              >
                <i className='fa-sharp fa-solid fa-eye-slash fs-4 text-light-grey'></i>
              </a>
            </OverlayTrigger>
          </div>
        )}
        <ToastContainer className='p-3 position-fixed' position={'top-center'}>
          <Toast
            show={sessionMessage.show}
            bg={sessionMessage.type}
          >
            <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
      {/* end::Menu */}
    </>
  )
}

export {StateActionsCell}
