import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Toast, ToastContainer } from "react-bootstrap";
import Select from 'react-select'
import { createDistrictApi, getStateData, updateDistrictApi } from "./districts-list/core/_requests";
import { useQueryResponse, useQueryResponseData as districtReponse } from "./districts-list/core/QueryResponseProvider";

type Props = {
  isEditing: boolean
  id: any
  handleClose: () => void
}

const AddEditDistrict  = ({isEditing,id,handleClose}:Props) => {
  const {refetch} = useQueryResponse()
  const districtList = districtReponse()
  const [stateList,setStateList] = useState<any>([])
  const [isLoading,setIsLoading] = useState(false)
  const [isSuccess,setIsSuccess] = useState(false)
  const [showError,setShowError] = useState(false)
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  const getStateList = async () => {
    let {responseStatus, data} = await getStateData()
    if(responseStatus == 200 && data.length){
      setStateList(data.map((x:any)=>{
        x.label = x.name
        x.value = x.id
        return x
      }))
    }
  }

  const validate = (values:any) => {
    const errors = {} as any;
    if(!values.name){
      errors.name = "The name field is required"
    }
    if(!values.state_id){
      errors.state_id = "The state field is required"
    }
    return errors
  };

  useEffect(()=>{
    if(id && isEditing){
      let editData = districtList.find((x:any)=>x.id == id) as any
      if(editData){
        formik.setFieldValue("name",editData.name)
        formik.setFieldValue("state_id",editData.state_id)
        formik.setFieldValue("status",editData.status === true ? 1 : 2)
      }
    }
  },[id,isEditing])

  useEffect(()=>{
    getStateList()
  },[])

  const addEditAction = async (values:any) => {
    try{
      setIsLoading(true)
      if(isEditing){
        var response = await updateDistrictApi(id,values)
      }
      else{
        var response = await createDistrictApi(values)
      }
      if(response.responseStatus == 200){
        refetch()
        setSessionMessage({type: 'success',message: response.responseMessage ,show: true})      
        if(isEditing){
          setIsSuccess(true)
          setTimeout(()=>{handleClose()},1500)
          setTimeout(()=>{
            setIsLoading(false)
            setIsSuccess(false)
          },2000)
        }
        else{
          formik.setFieldValue("name","")
          formik.setFieldValue("state_id","")
          formik.setFieldError("name","")
          formik.setFieldError("state_id","")
          setIsLoading(false)
        }
        setShowError(false)
      }
      else{
        setSessionMessage({type: 'danger',message: response.responseMessage ,show: true})
        setIsLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setSessionMessage({type: 'danger',message: message ,show: true})
      setIsLoading(false)
    }
  }

  useEffect(()=>{
    setTimeout(()=>{
      setSessionMessage({type: '',message: '',show: false})
    },3000)
  },[sessionMessage.show])

  const formik = useFormik({
    validate,
    initialValues: {
      name: '',
      state_id: '',
      status: 1,
    },
    onSubmit: values => {
      addEditAction(values)
    },
  });

  return (
    <>
      <Form noValidate className='border-end mt-6 p-5' id='kt_modal_add_user_form' onSubmit={formik.handleSubmit}>
        <h4 className='card-label font-weight-bolder text-dark font-size-h5 mb-6'>
          {' '}
          {isEditing ? "Edit District" : "Add District"}
        </h4>
        <Row className='mb-7'>
          <Col>
            <Form.Group>
              <Form.Label className='required fw-bold fs-6 mb-1 form-label'>
                District Name
              </Form.Label>
              <Form.Control type='text' 
                className='form-control form-control-solid mb-lg-0' 
                placeholder="District Name"
                onChange={(e:any)=>{
                  formik.setFieldValue("name",e.target.value.trimStart())
                }}
                value={formik.values.name} 
              />
              <p className="text-danger mt-2">{showError ? formik.errors.name : ""}</p>
            </Form.Group>
          </Col>
        </Row>
        <Row className='mb-7'>
          <Col>
            <Form.Group>
              <Form.Label className='required fw-bold fs-6 mb-1 form-label'>
                Select State
              </Form.Label>
              <Select
                options={stateList}
                className='custom_select mb-3'
                classNamePrefix='Select'
                placeholder='Select State'
                onChange={(e:any)=>{
                  formik.setFieldValue("state_id",e.value)
                }}
                value={formik.values.state_id ? stateList.find((x:any)=>x.value == formik.values.state_id) : ""}
              />
              <p className="text-danger mt-2">{showError ? formik.errors.state_id : ""}</p>
            </Form.Group>
          </Col>
        </Row>
        <Row className='mb-7'>
          <Col>
            <Form.Group>
              <Button
                type='submit'
                onClick={()=>{
                  setShowError(true)
                }}
                className='btn btn-primary font-weight-bold mr-2 btn-block'
                data-kt-users-modal-action='submit'
                disabled={isLoading || isSuccess}
              >
                <span className='indicator-label'>{isLoading ? "Please Wait..." : "Save"}</span>
              </Button>
            </Form.Group>
          </Col>
        </Row>
        <ToastContainer className='p-3 position-fixed' position={'top-center'}>
          <Toast
            show={sessionMessage.show}
            bg={sessionMessage.type}
          >
            <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      </Form>
    </>
  )
}
export default AddEditDistrict