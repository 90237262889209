import {FC} from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import userRoles from '../utils/userRoles'

const LeaderRoute: FC = () => {
  const leaderRole = userRoles()
  return <>{
    (leaderRole === 2 || leaderRole === 1)  ? <Outlet/> : 
    <Navigate to='/offer-management/offers' />
  }</>
}

export {LeaderRoute}
