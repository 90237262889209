import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { SERVER_URL } from '../../../../ServerConfig'
import { AddGradegroup, AddGradegroupResponse, Grade, GradesQueryResponse, EditGradegroupResponse } from './_models'

const API_URL = SERVER_URL
const GRADE_URL = `${API_URL}/grade-group`
const GET_GRADES_URL = `${API_URL}/grade-group`

const getGrade = async (query: string): Promise<GradesQueryResponse> => {
  const d = await axios
    .get(`${GET_GRADES_URL}?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
      },
    })
  return d.data
}

const getGradeById = async (id: ID): Promise<Grade | undefined> => {
  const response = await axios
    .get(`${GRADE_URL}/${id}`)
  const response_1 = response.data
  return response_1.data
}

const createGrade = async (gradeObj: AddGradegroup[]): Promise<AddGradegroupResponse | undefined> => {
  const response = await axios
    .post(GRADE_URL, gradeObj)
  const response_1 = response.data
  return response_1
}

// const updateGrade = async (
//   id: string,
//   gradeGroupObj: AddGradegroup
// ): Promise<EditGradegroupResponse | undefined> => {
//   const response = await axios
//     .patch(`${GET_GRADES_URL}/${id}`, gradeGroupObj)
//   const response_1 = response.data
//   return response_1
// }

const updateGrade = (
  id: string,
  gradeGroupObj: AddGradegroup
): Promise<EditGradegroupResponse | undefined> => {
  return axios
    .patch(`${GET_GRADES_URL}/${id}`, gradeGroupObj)
    .then((response: AxiosResponse<Response<EditGradegroupResponse>>) => response.data)
    .then((response: Response<EditGradegroupResponse>) => response)
}

const deleteGrade = async (gradeId: ID): Promise<void> => {
  await axios.delete(`${GRADE_URL}/${gradeId}`)
}

const deleteSelectedGrades = async (gradeIds: Array<ID>): Promise<void> => {
  const requests = gradeIds.map((id) => axios.delete(`${GRADE_URL}/${id}`))
  await axios.all(requests)
}

const showHideGradesApi = (id:any,data: any): Promise<any> => {
  return axios
    .patch(`${GRADE_URL}/${id}`,data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

export { getGrade, deleteGrade, deleteSelectedGrades, getGradeById, createGrade, updateGrade, showHideGradesApi }
