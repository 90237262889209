import React, { useEffect, useState } from 'react'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Pie} from 'react-chartjs-2'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { getOfferGradeGroupApi } from '../_requests'
import { colorHexcode } from '../../../utils/hexcodeInquiryGradeGroup'

ChartJS.register(ArcElement, Tooltip, Legend)

type Props = {
  setMessage: (type:string,message:string) => void
}

export const data = {
  labels: [],
  datasets: [
    {
      label: '# of Votes',
      data: [],
      backgroundColor: [],
      borderWidth: 1,
    },
  ],
}

export function OffersGradeGroup ({setMessage}:Props) {

  const [offersGradeData,setOffersGradeData] = useState<any>(data)
  const [loading,setLoading] = useState(true)
  console.log("offersGradeDataoffersGradeData",offersGradeData)
  const callDashboardReport = async () => {
    try{
      setLoading(true)
      let {responseStatus, responseMessage, responseData} = await getOfferGradeGroupApi()
      if(responseStatus == 200){
        if(responseData && responseData.list && responseData.list.length){
          offersGradeData.labels = responseData.list.map((x:any)=>x.gradeGroupName)
          offersGradeData.datasets[0].data = responseData.list.map((x:any)=>x.totalQuantity)
          colorHexcode.length = offersGradeData.labels.length
          offersGradeData.datasets[0].backgroundColor = colorHexcode
          setOffersGradeData(offersGradeData)
        }
        setTimeout(()=>{
          setLoading(false)
        },200)
      }
      else{
        setMessage("danger",responseMessage)
        setLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setMessage("danger",message)
      setLoading(false)
    }
  } 

  useEffect(()=>{
    callDashboardReport()
  },[])

    return (
      <div className='row g-5 g-xl-8' onClick={(e:any)=>{
        e.preventDefault()
      }}>
        <div className='col-xl-12'>
          <a href='#' className='card offers-grade hoverable card-xl-stretch mb-xl-8'>
          <CardHeader className='pt-10 pb-0 border-0'>
          <h2> Offer By Grade Group </h2>
          </CardHeader>
            <div className='card-body'>
            {loading ? 
              <h4 className='d-flex justify-content-center mt-3'>Loading...</h4> : offersGradeData.labels.length == 0 ?
              <h4 className='d-flex justify-content-center mt-3'>No data available</h4>
            : 
              <Pie data={offersGradeData} style={{height:'400px'}} options={{ maintainAspectRatio: false }} />
            }
            </div>
          </a>
        </div>
      </div>
    )
  }