import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import AddEditProduct from "./AddEditProduct";



const wizardsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Buyer Management',
      path: '/crafted/pages/wizards/horizontal',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

const EditProduct = () => {
    const navigate = useNavigate();
    const navigateToListProduct = () => {
        // 👇️ navigate to /contacts
        navigate('/product-management/products')
      }
    return(

        <>
        <PageTitle>Edit Product</PageTitle>
        <Col className="position-absolute me-5 end-0 tp-btn-mn  ">
        <button 
          type="button" 
          onClick={navigateToListProduct}
          value="" 
          className="btn btn-sm fs-5 me-5 btn btn-primary"> 
          Back 
          </button>
          </Col>
          <div className='card card-body p-12 mt-10'>
       
        <AddEditProduct isEditing={true}/>
      
      </div>
        </>
    )
}

export default EditProduct