import React, { useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
// import { KTSVG } from "../../../_metronic/helpers";
// import { useLayout } from "../../../_metronic/layout/core";
import Select from 'react-select'
import { createPortal } from "react-dom";
// import { StepperComponent } from "../../../_metronic/assets/ts/components";
import AddArticleForm from "./AddArticle";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { KTSVG } from "../../../../_metronic/helpers";
import { PageTitle } from "../../../../_metronic/layout/core";
import { useNavigate } from "react-router-dom";
import AddEditNewsArticle from "./AddEditNewsArticle";

const categoryList = [
    {value: 'Select', label: 'Select Category'},
      {value: 'category1', label: 'Category Name 01'},
      {value: 'category2', label: 'Category Name 02'},
      {value: 'category3', label: 'Category Name 03'},
      {value: 'category4', label: 'Category Name 04'},
      {value: 'category5', label: 'Category Name 05'},
      {value: 'category6', label: 'Category Name 06'},

    ]

    
    const modalsRoot = document.getElementById('root-modals') || document.body

    const EditArticle = () => {
      const stepperRef = useRef<HTMLDivElement | null>(null)
      const stepper = useRef<StepperComponent | null>(null)

    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
      })
    
      const files = acceptedFiles.map((file: any) => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      ))

      const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
      }

      const navigate = useNavigate();
      const navigateListarticle = () => {
        // 👇️ navigate to /
        navigate('/news-articles/articles');
      };
    

      return (
        <>
      <PageTitle> Edit Article </PageTitle>
    <Col className='position-absolute me-5 end-0 tp-btn-mn  '>
    <button
          type='button'
          onClick={navigateListarticle}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
        </Col>
          <Col className='card card-body backgroung-transparent   p-12  mt-10 2'>
           <AddEditNewsArticle isEditing={true}/>
          </Col>
        </>
)

}

export default EditArticle