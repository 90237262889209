import {createContext, FC, useContext, useMemo, useState} from 'react'
import {Grade} from './_models'

import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListViewGradegroup,
  ListViewContextGradegroupProps,
  groupingOnSelectAll,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'

const ListViewContext = createContext<ListViewContextGradegroupProps>(initialListViewGradegroup)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListViewGradegroup.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListViewGradegroup.itemIdForUpdate)

  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  const [isShowAddGradegroupModal, setShowAddGradegroupModal] = useState<boolean>(
    initialListViewGradegroup.isShowAddGradegroupModal
  )
  const [isLoadingAddGradegroup, setLoadingAddGradegroupModal] = useState<boolean>(
    initialListViewGradegroup.isLoadingAddGradegroup)

  const [selectedItemForEditGrade, setSelectedItemForEditGrade] = useState<Grade>(
    initialListViewGradegroup.selectedItemForEditGrade
  )
  const [isEditGradegroup, setEditGradegroup] = useState<boolean>(initialListViewGradegroup.isEditGradegroup)
  
  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
        isShowAddGradegroupModal,
        handleAddGradegroupShow: () => {
          setShowAddGradegroupModal(true)
        },
        handleAddGradegroupHide: () => {
          setShowAddGradegroupModal(false)
        },
        isLoadingAddGradegroup,
        handleEnableLoaderAddGradegroup: () => {
          setLoadingAddGradegroupModal(true)
        },
        handleDisableLoaderAddGradegroup: () => {
          setLoadingAddGradegroupModal(false)
        },

        selectedItemForEditGrade,
        setSelectedItemForEditGrade,
        isEditGradegroup,
        handleEnableEditGradegroup: () => {
          setEditGradegroup(true)
        },
        handleDisableEditGradegroup: () => {
          setEditGradegroup(false)
        },


      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
