import React, { useEffect, useState } from 'react'
import { checkDisplayValue } from '../../../utils/utils'
import { getTopSalesmanApi, getUserwiseInquiryApi } from '../_requests'

type Props = {
  setMessage: (type:string,message:string) => void
}

const SalesMan = ({setMessage}:Props) => {

  const [topSalesmanData,setTopSalesmanData] = useState([])
  const [loading,setLoading] = useState(true)

  const callDashboardReport = async () => {
    try{
      setLoading(true)
      let {responseStatus, responseMessage, responseData} = await getTopSalesmanApi()
      if(responseStatus == 200){
        if(responseData.list && responseData.list.length){
          setTopSalesmanData(responseData.list)
        }
        else{
          setTopSalesmanData([])
        }
        setLoading(false)
      }
      else{
        setMessage("danger",responseMessage)
        setLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setMessage("danger",message)
      setLoading(false)
    }
  } 

  useEffect(()=>{
    callDashboardReport()
  },[])

  return (
    // <div className='row'>
    //   <div className='col-xl-12'>
    //     <div className='p-9'>
    //       {/* <h3 className='card-title align-items-start flex-column'>
    //         <span className='card-label fw-bold fs-2 mb-1'>Users Wise Open Inquiry</span>
    //       </h3> */}
    //       <table className='table table-success'>
    //         {/* <thead>
    //           <tr>
    //             <th className='text-dark fw-bold mb-1 fs-4'>Users Name</th>
    //             <th className='text-end text-dark fw-bold fs-4 '>Total Inquiry</th>
    //           </tr>
    //         </thead> */}
    //         <tbody>
    //           <tr>
    //             <td className='text-dark fw-bold mb-1 fs-6'>Francis Mitcham</td>
    //             <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>13</td>
    //           </tr>
    //           <tr>
    //             <td className='text-dark fw-bold mb-1 fs-6'>Brian Cox</td>
    //             <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>10</td>
    //           </tr>
    //           <tr>
    //             <td className='text-dark fw-bold mb-1 fs-6'>Francis Mitcham</td>
    //             <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>13</td>
    //           </tr>
    //           <tr>
    //             <td className='text-dark fw-bold mb-1 fs-6'>Francis Mitcham</td>
    //             <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>13</td>
    //           </tr>
    //           <tr>
    //             <td className='text-dark fw-bold mb-1 fs-6'>Brian Cox</td>
    //             <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>10</td>
    //           </tr>
    //           <tr>
    //             <td className='text-dark fw-bold mb-1 fs-6'>Francis Mitcham</td>
    //             <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>13</td>
    //           </tr>
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>
    // </div>
    <div className='row'>
    <div className='col-xl-12'>
      <div className='card top-salesman p-9'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark fs-2'>Top Salesman</span>
        </h3>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr>
              <th className='fs-6 fw-bold border-0 text-gray-400 text-uppercase'>Salesman Name</th>
              <th className='fs-6 text-end me-13 d-block fw-bold border-0 text-gray-400 text-uppercase'>MT</th>
            </tr>
          </thead>
          <tbody>
          {loading ? 
            <h4 className='d-flex justify-content-center mt-3'>Loading...</h4> : topSalesmanData.length == 0 ?
            <h4 className='d-flex justify-content-center mt-3'>No data available in table</h4> :
          topSalesmanData.map((x:any)=>{
            return (
              <tr key={x.id}>
                <td className='text-dark fw-bold fs-6'>{checkDisplayValue(x.name)}</td>
                <td className='text-end text-dark fw-bold fs-6 me-12 d-block'>{checkDisplayValue(x.totalProductsQuantity)}</td>
              </tr>
            )
          })}  
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default SalesMan
