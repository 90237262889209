import React, { useState } from 'react'
import SalesMan from './SalesMan'
import TopBuyer from './TopBuyer'
import TopDestinationLucation from './TopDestinationLucation'
import TopSeller from './TopSeller'
import TopSourceLucation from './TopSourceLucation'

type Props = {
  setMessage: (type:string,message:string) => void
}

const TopMain = ({setMessage}:Props) => {
  const [showSourceDestination,setShowSourceDestination] = useState("source")
  return (
    <div className=''>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <div className='card menu menu-column ' data-kt-menu='true'>
            <div className='d-flex flex-column bgi-no-repeat rounded-top'>
              <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold mt-4 px-0 '>
                <li className='nav-item'>
                  <a
                    className='nav-link text-dark opacity-75 fs-4 px-9 opacity-state-100 pb-4  active'
                    data-bs-toggle='tab'
                    href='#kt_topbar_notifications_1'
                  >
                    Top Buyers
                  </a>
                </li>

                <li className='nav-item'>
                  <a
                    className='nav-link text-dark opacity-75 fs-4 opacity-state-100 pb-4'
                    data-bs-toggle='tab'
                    href='#kt_topbar_notifications_2'
                  >
                    Top Sellers
                  </a>
                </li>
              </ul>
            </div>
            <div className='tab-content mb-10'>
              <div
                className='tab-pane show active fade'
                id='kt_topbar_notifications_1'
                role='tabpanel'
              >
                <TopBuyer setMessage={(type:string,message:string)=>{
                  setMessage(type,message)
                }}/>
              </div>
              <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
                <TopSeller setMessage={(type:string,message:string)=>{
                  setMessage(type,message)
                }} />
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div className='card menu menu-column ' data-kt-menu='true'>
            <div className='d-flex flex-column bgi-no-repeat rounded-top'>
              <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold mt-4 px-0 '>
                <li className='nav-item'>
                  <a
                    className='nav-link text-dark opacity-75 px-9 fs-4 opacity-state-100 pb-4'
                    data-bs-toggle='tab'
                    href='#kt_topbar_notifications_3'
                    onClick={()=>{
                      setShowSourceDestination("source")
                    }}
                  >
                    Top Source
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-dark opacity-75 fs-4 opacity-state-100 pb-4'
                    data-bs-toggle='tab'
                    href='#kt_topbar_notifications_4'
                    onClick={()=>{
                      setShowSourceDestination("destination")
                    }}
                  >
                    Top Destination
                  </a>
                </li>
              </ul>
            </div>

            <div className='tab-content mb-10'>
            {showSourceDestination == "source" ?
              <div className='tab-pane show active fade' id='' role='tabpanel'>
                <TopSourceLucation setMessage={(type:string,message:string)=>{
                  setMessage(type,message)
                }}/>
              </div>
              :
              <div className='tab-pane show active fade' id='' role='tabpanel'>
                <TopDestinationLucation setMessage={(type:string,message:string)=>{
                  setMessage(type,message)
                }}/>
              </div>
            }  
            </div>
          </div>
        </div>
      </div>
      {/* <div className='card menu menu-column ' data-kt-menu='true'>
        <div className='d-flex flex-column bgi-no-repeat rounded-top'>
          <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold mt-4 px-0 '>
            <li className='nav-item' style={{width: '20%'}}>
              <a
                className='nav-link text-dark opacity-75 fs-4 px-9 opacity-state-100 pb-4  active'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_1'
              >
                Top Buyers
              </a>
            </li>

            <li className='nav-item' style={{width: '20%'}}>
              <a
                className='nav-link text-dark opacity-75 fs-4 opacity-state-100 pb-4'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_2'
              >
                Top Sllers
              </a>
            </li>
            <li className='nav-item' style={{width: '20%'}}>
              <a
                className='nav-link text-dark opacity-75 fs-4 opacity-state-100 pb-4'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_3'
              >
                Top Source
              </a>
            </li>
            <li className='nav-item' style={{width: '20%'}}>
              <a
                className='nav-link text-dark opacity-75 fs-4 opacity-state-100 pb-4'
                data-bs-toggle='tab'
                href='#kt_topbar_notifications_4'
              >
                Top Destination
              </a>
            </li>
          </ul>
        </div>

        <div className='tab-content mb-10'>
          <div className='tab-pane show active fade' id='kt_topbar_notifications_1' role='tabpanel'>
            <TopBuyer />
          </div>
          <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
            <TopSeller />
          </div>
          <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
            <TopSourceLucation />
          </div>
          <div className='tab-pane fade' id='kt_topbar_notifications_4' role='tabpanel'>
            <TopDestinationLucation />
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default TopMain
