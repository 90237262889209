import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Error403: FC = () => {
  const logout = () => {
    localStorage.clear();
    window.location.replace('/')
  }
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>403</h1>
      {/* end::Title */}

      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Permission Denied</h1>


      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-5'>You don’t have permission to access this resource</div>
      {/* end::Text */}

      {/* begin::Illustration */}
      {/* <div className='mb-3'>
        <img
          src={toAbsoluteUrl('/media/auth/404-error.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div> */}
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <button type="button" className='btn btn-sm btn-primary'
          onClick={() => {
            logout()
          }}>
          Logout
        </button>
      </div>
      {/* end::Link */}
    </>
  )
}

export { Error403 }
