import Multiselect from 'multiselect-react-dropdown'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Table, Toast, ToastContainer } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { getQuotationByIdNew } from '../quotation-management/quotatuins-list/core/_requests'
import { getPurchaseByIdNew, sendEmailPurchaseOrder } from './purchases-list/core/_requests'
import Select from 'react-select'
import moment from 'moment/moment'
import {
  getCGST,
  getIGST,
  getSGST,
  purchaseTC,
} from '../inquiry-management/Inquiry-list/core/_requests'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { checkDisplayValue, getFormatPrice, getFormatedAddress } from '../../utils/utils'
import { ToWords } from 'to-words';

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Purchase Order Management',
    path: '/purchase',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ViewPurchaseOrder = () => {
  const toWords = new ToWords();
  const navigate = useNavigate()
  const params = useParams()
  const id: string = params.id ? params.id : ''
  const [viewPurchase, setViewPurchase] = useState<any>(null)
  const [lstMasterProduct, setLstMasterProduct] = useState([])
  const [valueSGST, setValueSGST] = useState(0)
  const [valueIGST, setValueIGST] = useState(0)
  const [valueCGST, setValueCGST] = useState(0)
  const [isGujarat, setIsGujarat] = useState<any>(null)
  const [tc, setTc] = useState<any>('')
  const [billingAddress, setBillingAddress] = useState<any>({})
  const [lstProductDetails, setLstProductDetails] = useState([
    {
      product_id: '',
      quantity: '',
      rate: '',
    },
  ])
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  const navigateAddPurchaseOrder = () => {
    // 👇️ navigate to /
    navigate('/addpurchase')
  }
  const navigatePurchaseOrderListing = () => {
    // 👇️ navigate to /
    navigate('/purchase-order/purchases')
  }

  useEffect(() => {
    getViewPurchase()
  }, [])

  const getViewPurchase = async () => {
    let aAndc: any = await purchaseTC()
    console.log(aAndc.responseData.value, 'aAndc')
    setTc(aAndc.responseData.value)
    // SGST
    let SGST: any = await getSGST()

    let tmpSGST = SGST.responseData.value
    setValueSGST(tmpSGST)

    // IGST
    let IGST: any = await getIGST()
    console.log(IGST, 'IGST')
    let tmpIGST = IGST.responseData.value
    setValueIGST(tmpIGST)

    // CGST
    let CGST: any = await getCGST()
    let tmpCGST = CGST.responseData.value
    setValueCGST(tmpCGST)

    let user = await getPurchaseByIdNew(id)
    console.log(user.responseData, 'View purchase Order Data')
    setViewPurchase(user.responseData)
    let billingAddressData = user?.responseData?.billingAddressLocationInfo
    billingAddressData.district = user?.responseData?.billingLocationInfo?.name
    billingAddressData.state = user?.responseData?.billingLocationInfo?.stateInfo?.name
    setBillingAddress(billingAddressData)
    setIsGujarat(user?.responseData?.billingLocationInfo?.state_id ?? "")
    setLstProductDetails(user.responseData.purchaseOrderDetailsInfo)
  }

  // Product Table

  // Get master object
  const getMasterProductObject = (id: string) => {
    let selected = lstMasterProduct.filter((obj: any) => obj.id == id)
    if (selected.length) {
      return selected[0]
    } else {
      return {}
    }
  }
  const getTotalAmoutWithoutTex = () => {
    let total: any = 0

    lstProductDetails.map((obj) => {
      let tmp =
        parseFloat(obj.rate) && parseFloat(obj.quantity)
          ? parseFloat(obj.rate) * parseFloat(obj.quantity)
          : 0
      total = total + tmp
    })
    return total * 1000
  }

  const getSGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let sGst = (total / 100) * valueSGST
    return sGst
  }

  const getCGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let cGst = (total / 100) * valueCGST
    return cGst
  }

  const getIGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let iGst = (total / 100) * valueIGST
    console.log(total, valueIGST, 'total,iGst')
    return iGst
  }

  const getTotalAmoutWithTex = () => {
    let total: any =
      getTotalAmoutWithoutTex() +
      (isGujarat !== '3749b049-6434-4e8b-8358-478251a33f8d'
        ? getIGSTValue()
        : getSGSTValue() + getCGSTValue())
    return total.toFixed(2)
  }

  const Print = () => {
    let printContentsRoot: any = document.getElementById('printablediv')
    let printContents = printContentsRoot.innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
    window.location.reload()
  }

  const exportPdf = () => {
    html2canvas(document.querySelector('#printablediv') as any).then(function (canvas:any) {
      var imgWidth = 210
      var pageHeight = 290
      var imgHeight = (canvas.height * imgWidth) / canvas.width
      var heightLeft = imgHeight

      var doc = new jsPDF('p', 'mm')
      var position = 0
      var pageData = canvas.toDataURL('image/jpeg', 1.0)
      var imgData = encodeURIComponent(pageData)
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
      doc.setLineWidth(5)
      doc.setDrawColor(255, 255, 255)
      doc.rect(0, 0, 210, 295)
      heightLeft -= pageHeight

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        doc.addPage()
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        doc.setLineWidth(5)
        doc.setDrawColor(255, 255, 255)
        doc.rect(0, 0, 210, 295)
        heightLeft -= pageHeight
      }
      doc.save(`PolyMart_${viewPurchase?.purchase_order_number ?? "purchase_order_file"}.pdf`)
    })
  }

  const newPrintPdf = () => {
    // html2canvas(document.querySelector('#printablediv') as any).then((canvas: any) => {
    //   const imgData = canvas.toDataURL('image/png')
    //   const pdf: any = new jsPDF()
    //   var width = pdf.internal.pageSize.getWidth()
    //   var height = pdf.internal.pageSize.getHeight()

    //   pdf.addImage(imgData, 'PNG', 0, 0, width, height)
    //   pdf.save('download.pdf')
    // })
    html2canvas(document.querySelector('#printablediv') as any).then(function (canvas:any) {
      var imgWidth = 210
      var pageHeight = 290
      var imgHeight = (canvas.height * imgWidth) / canvas.width
      var heightLeft = imgHeight

      var doc = new jsPDF('p', 'mm')
      var position = 0
      var pageData = canvas.toDataURL('image/jpeg', 1.0)
      var imgData = encodeURIComponent(pageData)
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
      doc.setLineWidth(5)
      doc.setDrawColor(255, 255, 255)
      doc.rect(0, 0, 210, 295)
      heightLeft -= pageHeight

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        doc.addPage()
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        doc.setLineWidth(5)
        doc.setDrawColor(255, 255, 255)
        doc.rect(0, 0, 210, 295)
        heightLeft -= pageHeight
      }
      doc.autoPrint();
      //This is a key for printing
      doc.output('dataurlnewwindow');
      // doc.save(`PolyMart_${quotation?.quotation_number ?? "quotation_file"}.pdf`)
    })
  }

  const sendEmail = async () => {
    try {
      await sendEmailPurchaseOrder(id).then((data: any) => {
        if (data.responseStatus == 200) {
          setSessionMessage({ type: 'success', message: data.responseMessage, show: true })
        } else {
          setSessionMessage({ type: 'danger', message: data.responseMessage ?? "Something Went Wrong", show: true })
        }
      }).catch((err: any) => {
        setSessionMessage({ type: 'danger', message: err.message ?? "Something Went Wrong", show: true })
      })
    }
    catch (err: any) {
      setSessionMessage({ type: 'danger', message: err?.message ?? "Something Went Wrong", show: true })
      console.log(err)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setSessionMessage({ type: '', message: '', show: false })
    }, 3000)
  }, [sessionMessage.show])

  return (
    <>
      <div className={'mt-5'}>
        <PageTitle> Purchase Order Details </PageTitle>
      </div>
      <Col className='position-absolute  me-5 end-0 mt-n20 '></Col>
      <Col className='position-absolute me-5 end-0 tp-btn-mn quote-btn'>
        <Button
          type='button'
          value=''
          className='btn btn-sm fs-5 btn-light-danger print-icon me-5'
          onClick={() => {
            newPrintPdf()
            // Print()
            // window.print()
          }}
        >
          <span className=''>
            {' '}
            <i className='fa-solid fa-print fs-5 '></i>{' '}
          </span>{' '}
          Print{' '}
        </Button>

        <Button
          type='button'
          value=''
          className='btn btn-light-success  dwld-icon me-3'
          onClick={() => {
            exportPdf()
            // window.print()
          }}
        >
          <span className='svg-icon svg-icon-2 text-center '>
            <KTSVG path='/media/icons/duotune/files/fil018.svg' className='svg-icon-muted me-0' />
          </span>
          Download PDF
        </Button>
        {/* </Link> */}

        <Button
          type='button'
          value=''
          className='btn btn-sm fs-5 btn-light-warning  wtsp-icon me-5'
          onClick={() =>
            window.open(
              `https://wa.me/${viewPurchase?.buyerSellerInfo?.whatsapp_number ?
                viewPurchase?.buyerSellerInfo?.whatsapp_number : 'send'}?text=Dear ${viewPurchase?.buyerSellerInfo?.name
                  ? viewPurchase?.buyerSellerInfo?.name
                  : ' Customer'} your purchase order ${viewPurchase.purchase_order_number
                    ? viewPurchase.purchase_order_number
                    : ''} is created please contact us.`
            )
          }
        >
          <span className='svg-icon svg-icon-2 text-center'>
            {' '}
            <i className='fa-brands fa-whatsapp fs-3'></i>{' '}
          </span>{' '}
          Whatsapp{' '}
        </Button>

        {/* <a href={`mailto:${viewPurchase?.buyerSellerInfo?.email}`}> */}
        <a href="#" onClick={(e: any) => { e.preventDefault() }}>
          <Button
            type='button'
            onClick={(e: any) => { sendEmail() }}
            className='btn btn-sm fs-5  btn-light-info email-icon me-5'
          >
            <span className='svg-icon svg-icon-2 text-center'>
              {' '}
              <KTSVG
                path='/media/icons/duotune/communication/com002.svg'
                className='svg-icon-muted me-0 '
              />{' '}
            </span>{' '}
            Send Email{' '}
          </Button>
        </a>
        <button
          type='button'
          onClick={navigatePurchaseOrderListing}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
      </Col>

      <Col className='card card-body backgroung-transparent   p-12  mt-10   p-12'>
        <div className='menu menu-column ' data-kt-menu='true'>
          <div
            className='d-flex flex-column bgi-no-repeat rounded-top'
            style={{ backgroundImage: `url('{toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
          >
            <ul className='nav nav-line-tabs border-0 nav-stretch fw-bold  px-0'></ul>
          </div>

          <div className='tab-content '>
            {viewPurchase && (
              <div
                className='tab-pane show active fade purchase-view-pdng'
                // id='kt_topbar_notifications_1'
                role='tabpanel'
                id='printablediv'
              >
                <Row className='d-flex flex-column justify-content-center flex-lg-row mb-1'>
                  {/* <Col xl={12} className='ms-auto me-auto mt-5 '>
                    <Table className='quot-table po-tbl mb-0'>
                      <tbody style={{ border: '1px solid #ddd' }}>
                        <tr>
                          <td className='text-bottom align-bottom'>
                            <img
                              className='h-60px logo'
                              src={toAbsoluteUrl('/media/logos/polymart-logo-blue.png')}
                            />
                          </td>
                          <td className='text-center border-right-0 align-center'>
                            <b className='fs-2'>Polymart Private Limited</b><br />
                            301, Shanti Mall, Sattadhar Cross Road,
                            <br />
                            Ahmedabad-380061, Gujarat, India
                            <br />
                            Email ID :info@polymart.in, <br /> Phone: 6357111121,
                            <br />
                            GSTIN :24AANCP3559K1ZX
                          </td>
                          <td className='text-center border-left-0 position-relative'>
                           
                            <h2 className='text-primary mt-10'> Purchase <br /> Order No: </h2>
                            <p className='fs-4 mb-5 text-blak fw-bold'>
                              {viewPurchase.purchase_order_number
                                ? viewPurchase.purchase_order_number
                                : '-'}
                            </p>
                           
                          </td>
                        </tr>

                        <tr className='h-125px'>
                          <td colSpan={2} className='text-left border-right-0 pt-20'>
                            To, <br />
                            <h4 className=''>{viewPurchase?.buyerSellerInfo?.name} </h4>
                          </td>

                          <td colSpan={3} className='text-end position-relative'>
                            <Table>
                              <tr>
                                <td> Reference: </td>
                                <td> {viewPurchase.reference ? viewPurchase.reference : '-'} </td>
                              </tr>
                              <tr>
                                <td> Date: </td>
                                <td>
                                  {' '}
                                  {viewPurchase
                                    ? moment(new Date(viewPurchase?.date)).format('DD-MM-YYYY')
                                    : ''}
                                </td>
                              </tr>
                            </Table>
                          </td>
                        </tr>

                        <tr className='h-100px'>
                          <td colSpan={2} className='text-left border-right-0 pt-20'>
                            Billing From: <br />
                            <h6 className='w-50 mt-1'>{getFormatedAddress(billingAddress)} </h6>
                          </td>

                          <td colSpan={3} className='position-relative'>
                            Shipping From: <br />
                            <h6 className='w-50 mt-1'>{getFormatedAddress(viewPurchase?.shipping_address)} </h6>
                          </td>
                        </tr>

                      </tbody>
                    </Table>
                  </Col> */}
                  <Row style={{ borderBottom: '1.5px solid #000', }} className='flex-nowrap mt-5 pb-5 d-flex justify-content-center'>
                    <Col>
                      <td className='text-bottom'>
                        <img
                          style={{ margin: '0 auto', }}
                          className='h-60px logo d-block'
                          src={toAbsoluteUrl('/media/logos/polymart-icon.svg')}
                        />
                        <img
                          className='h-60px logo'
                          src={toAbsoluteUrl('/media/logos/polymart-logo-blue.png')}
                        />

                      </td>
                    </Col>
                    <Col className='text-center justify-content-center d-flex'>
                      <td className='text-center'><p className='fs-1 fw-bold mb-0 m-0'>PolyMart Private Limited</p>
                      <p className='fs-4 fw-semibold text-dark mb-0 m-0'>
                        311, Shanti Mall, Sattadhar Cross Road,                
                        Ahmedabad-380061, Gujarat, India <br />
                        {/* Email ID: info@polymart.in, <br />  Phone: 6357111121, <br /> */}
                        <b>GSTIN</b> :24AANCP3559K1ZX
                        </p>
                      </td>
                    </Col>
                    <Col className='text-center justify-content-center d-flex'>
                      <h2 style={{ textAlign: 'left', }} className='purchase-text'> Purchase Order</h2>
                    </Col>
                  </Row>
                  <Row className='mt-10 mb-5 flex-nowrap w-100' >
                    <Col style={{ width: '33%', padding: '0 30px 0 5px' }}>

                      <span style={{ width: '130px', display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }}>Bill From:</span><br></br>
                      <span className='text-dark fw-semibold fs-4'>
                        <span className='d-block fw-bold'>
                          {checkDisplayValue(viewPurchase?.buyerSellerInfo?.firm_name)}
                        </span>
                        {getFormatedAddress(billingAddress)}
                      </span>

                    </Col>
                    <Col style={{ width: '35%', padding: '0 30px 0 5px' }}>
                      <span style={{ width: '130px', display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }}>Ship From:</span><br></br>
                      <span className='text-dark fw-semibold fs-4'>
                        <span className='d-block fw-bold'>
                        {checkDisplayValue(viewPurchase?.buyerSellerInfo?.firm_name)}
                        </span>
                        {getFormatedAddress(viewPurchase?.shipping_address)} 
                      </span>
                    </Col>
                    <Col style={{ width: '30%', padding: '0 30px 0 5px' }}>
                      <div>
                        <span style={{  display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }} >Order Number : </span>
                        <span className="ps-2 fs-4 text-dark fw-semibold">{viewPurchase.purchase_order_number
                          ? viewPurchase.purchase_order_number
                          : '-'}</span>
                      </div>
                      <div>
                        <span style={{ display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }} >Order Date : </span>
                        <span className="ps-2 fs-4 text-dark fw-semibold">
                          {viewPurchase
                            ? moment(new Date(viewPurchase?.date)).format('DD-MM-YYYY')
                            : ''}
                        </span>
                      </div>
                      <div>
                        <span style={{  display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }} >Reference : </span>
                        <span className="ps-2 fs-4 text-dark fw-semibold">
                          {viewPurchase.reference ? viewPurchase.reference : '-'}
                        </span>
                      </div>
                      <div>
                        <span style={{  display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }} >Payment Terms : </span>
                        <span className="ps-2 fs-4 text-dark fw-semibold">
                          Pay on Delivery
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-1 mb-5 flex-nowrap w-100'>
                    <Col style={{ width: '35%', padding: '0 30px 0 15px' }}>
                      <div>
                        <span style={{ width: '130px', display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }}>State : </span>
                        <span className="ps-2 fs-4 text-dark fw-semibold">{checkDisplayValue(billingAddress?.state)}</span>
                      </div>
                      <div>
                        <span style={{ width: '130px', display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }}>GSTIN : </span>
                        <span className="ps-2 fs-4 text-dark fw-semibold">{checkDisplayValue(billingAddress?.gst)}</span>
                      </div>
                      {/* <div>
                        <span style={{ width: '130px', display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }}>Contact Person : </span>
                        <span className="ps-2 fs-4 text-dark fw-semibold">{checkDisplayValue(viewPurchase?.buyerSellerInfo?.name)}</span>
                      </div>
                      <div>
                        <span style={{ width: '130px', display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }}>Mobile No : </span>
                        <span className="ps-2 fs-4 text-dark fw-semibold">{checkDisplayValue(viewPurchase?.buyerSellerInfo?.whatsapp_number)}</span>
                      </div> */}
                    </Col>
                    <Col style={{ width: '35%', padding: '0 0 0 64px' }}>
                      <div>
                        <span style={{ width: '130px', display: 'inline-block', fontWeight: 'bold', fontSize: '18px' }}>State : </span>
                        <span className="ps-2 fs-4 text-dark fw-semibold">{checkDisplayValue(viewPurchase?.shipping_address?.state)}</span>
                      </div>
                    </Col>
                    <Col style={{ width: '30%', padding: '0 30px' }}>

                    </Col>
                  </Row>
                  <Col xl={12} className='ms-auto me-auto mt-0'>
                    <Table responsive className='quot-table text-center'>
                      <thead>
                        <tr>
                          <th style={{ width: '12%' }}>Sr. No.</th>
                          <th>
                            Grade Name
                          </th>
                          <th>
                            Product Name
                          </th>
                          <th>
                            {' '}
                            HSN Code
                          </th>
                          <th style={{ width: '16%' }}>
                            Qty (MT)
                          </th>
                          <th style={{ width: '14%' }}>
                            Rate/Kg
                          </th>
                          <th style={{ width: '16%' }}>Amount (&#8377;)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lstProductDetails.map((objProduct: any, index) => {
                          {
                            console.log(objProduct, 'objProduct')
                          }
                          let objMain = getMasterProductObject(objProduct.product_id) as any
                          return (
                            <tr id='row1'>
                              <td>{index + 1}</td>
                              <td>
                                {checkDisplayValue(objProduct?.productInfo?.gradeGroupInfo?.name)}
                              </td>
                              <td>
                                <Form.Group>
                                  {objProduct?.productInfo &&
                                    objProduct.productInfo?.product_variation &&
                                    objProduct.productInfo?.product_variation
                                    ? objProduct.productInfo?.product_variation
                                    : '-'}
                                </Form.Group>
                              </td>
                              <td>
                                {objProduct &&
                                  objProduct?.productInfo?.hsn &&
                                  objProduct?.productInfo?.hsn
                                  ? objProduct?.productInfo?.hsn
                                  : '-'}
                              </td>
                              <td>
                                <Form.Group className=''>
                                  {objProduct.quantity && objProduct.quantity}
                                </Form.Group>
                              </td>

                              <td>
                                <Form.Group className=''>
                                  {objProduct.rate && objProduct.rate}
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group className=''>
                                  {getFormatPrice(parseFloat(objProduct.rate) && parseFloat(objProduct.quantity)
                                    ? (parseFloat(objProduct.rate) *
                                      parseFloat(objProduct.quantity) *
                                      1000
                                    ).toFixed(2)
                                    : 0)}
                                </Form.Group>
                              </td>
                            </tr>
                          )
                        })}

                        {/* {isShowSourceFild && (
                  <tr id='row2'>
                    <td>2</td>
                    <td>
                      <Select
                        options={productList}
                        className='custom_select'
                        classNamePrefix='Select'
                        placeholder='Select Product'
                      />
                    </td>
                    <td>
                      <Form.Control
                        type='text'
                        disabled
                        className='required form-control-solid'
                        placeholder='HSN Code'
                      />
                    </td>

                    <td>
                      <Form.Group className=''>
                        <Form.Control
                          type='text'
                          className='required form-control-solid'
                          placeholder='Qty (MT)'
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group className=''>
                        <Form.Control
                          type='text'
                          className='required form-control-solid'
                          placeholder='Rate/Kg'
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group className=''>
                        <Form.Control
                          type='text'
                          className='required form-control-solid'
                          placeholder='Amount'
                        />
                      </Form.Group>
                    </td>
                    <td className='text-center pt-6 align-items-center'>
                      <div className='d-flex'>
                        <a
                          href='#row1'
                          onClick={() => {
                            // setIsShowSourceFild(false)
                            // handleClose()
                          }}
                          className='btn btn-sm btn-icon mt-3 btn-color-danger'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen036.svg'
                            className='svg-icon-muted svg-icon-2hx'
                          />
                        </a>
                        <a
                          href='#'
                          onClick={() => {
                            // setIsShowSourceFild(true)
                            // handleClose()
                          }}
                          className='btn btn-sm btn-icon mt-3 btn-color-success'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen035.svg'
                            className='svg-icon-muted svg-icon-2hx'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                )} */}

                        {/* <tr>
                          <td> Total </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                          <td> &nbsp; </td>
                        </tr> */}
                        <tr>
                          <td rowSpan={7} colSpan={5}>
                            {' '}
                            &nbsp;{' '}
                          </td>
                          <td style={{ verticalAlign: 'middle' }}> Taxable Amount </td>
                          <td colSpan={2}>
                            {/* {' '}
                            <Form.Group className=''>
                              <Form.Control
                                type='text'
                                className='required form-control-solid'
                                value={getFormatPrice(getTotalAmoutWithoutTex().toFixed(2)) as any}
                                placeholder='Amount'
                              />
                            </Form.Group>{' '} */}
                            {getFormatPrice(getTotalAmoutWithoutTex().toFixed(2)) as any}
                          </td>
                        </tr>
                        {isGujarat !== '3749b049-6434-4e8b-8358-478251a33f8d' ? (
                          <tr>
                            <td style={{ verticalAlign: 'middle' }}> IGST </td>
                            <td colSpan={2}>
                              {/* <Form.Group className=''>
                                <Form.Control
                                  type='text'
                                  className='required form-control-solid'
                                  placeholder='IGST'
                                  value={getFormatPrice(getIGSTValue().toFixed(2)) as any}
                                />
                              </Form.Group> */}
                              {getFormatPrice(getIGSTValue().toFixed(2)) as any}
                            </td>
                          </tr>
                        ) : (
                          <>
                            <tr>
                              <td style={{ verticalAlign: 'middle' }}> CGST </td>
                              <td colSpan={2}>
                                {/* {' '}
                                <Form.Group className=''>
                                  <Form.Control
                                    type='text'
                                    className='required form-control-solid'
                                    placeholder='CGST'
                                    value={getFormatPrice(getCGSTValue().toFixed(2))  as any}
                                  />
                                </Form.Group>{' '} */}
                                {getFormatPrice(getCGSTValue().toFixed(2)) as any}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: 'middle' }}> SGST </td>
                              <td colSpan={2}>
                                {/* {' '}
                                <Form.Group className=''>
                                  <Form.Control
                                    type='text'
                                    className='required form-control-solid'
                                    placeholder='SGST'
                                    value={getFormatPrice(getSGSTValue().toFixed(2))  as any}
                                  />
                                </Form.Group>{' '} */}
                                {getFormatPrice(getSGSTValue().toFixed(2)) as any}
                              </td>
                            </tr>
                          </>
                        )}

                        <tr>
                          <td style={{ verticalAlign: 'middle' }}> Total Amount (With Tax) </td>
                          <td colSpan={2}>
                            {/* {' '}
                            <Form.Group className=''>
                              <Form.Control
                                type='text'
                                className='required form-control-solid'
                                placeholder='Total Amount'
                                value={getFormatPrice(getTotalAmoutWithTex()) as any}
                              />
                            </Form.Group>{' '} */}
                            {getFormatPrice(getTotalAmoutWithTex()) as any}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row className='mb-20 mt-2'>
                    <Col>
                    <h5 className='text-left'>
                      Amount in Words: Rs.{' '}{toWords.convert(getTotalAmoutWithTex(), { currency: true })}
                    </h5>
                    </Col>
                  </Row>
                <Row className='mb-5 mt-9'>
                  <Col className='text-center'>
                    <img
                      style={{ margin: '0 auto', }}
                      className='logo d-block'
                      height={'100px'}
                      src={toAbsoluteUrl('/media/logos/polymart-icon-01.svg')}
                    />
                    <span style={{display: 'inline-block', fontWeight: 'bold', marginTop:'10px', fontSize: '16px' }} >Let's unite to achieve a sustainable hunger free India. Our Commmitment: 1 meal per ton, 1 tree per order</span>
                  </Col>
                </Row>
                <Row className='mb-5 mt-9'>
                  <Col xl={12} className='ms-auto me-auto mt-0'>
                    <Table responsive className=' quot-tablepo-trm-cdtn'>
                      <tr className='po-trm-cdtn'>
                        <td colSpan={6} className='border-transparent border-bottom-transparent'>
                          <h4 className='mb-2'> Terms &amp; Conditions </h4>
                          <div className='fs-4 text-dark' dangerouslySetInnerHTML={{ __html: tc.replace(/\\n|\\t/g, '').replaceAll('"', '') }} />
                          {/* <div dangerouslySetInnerHTML={{__html: tc.replace(/^\s+|\s+$/g, '').replace(/\n|\t/g, '') }} /> */}
                          {/* <ul>
                            <li className='mb-3 fs-6'>
                              Lorem Ipsum is simply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's standard dummy text ever
                              since the 1500s, when an unknown printer took a galle
                            </li>
                            <li className='mb-3 fs-6'>
                              Lorem Ipsum is simply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's standard dummy text ever
                              since the 1500s, when an unknown printer took a galle
                            </li>
                            <li className='mb-3 fs-6'>
                              Lorem Ipsum is simply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's standard dummy text ever
                              since the 1500s, when an unknown printer took a galle
                            </li>
                          </ul> */}
                        </td>
                      </tr>
                      {/* <tr className='po-trm-cdtn'>
                        <td
                          colSpan={6}
                          className='border-transparent border-bottom-transparent text-left'
                        >
                          <p className='mt-20 fs-6'>Authorised Signature</p>
                        </td>
                      </tr> */}
                      <tr className='mt-10'>
                        <td colSpan={6} className='text-center'>
                          <b>This is a system generated sales Order, hence the signature is not required.</b>
                        </td>
                      </tr>
                      <div style={{ borderTop: '1.5px solid #000', }} className='d-flex justify-content-center mt-3 mb-3'>
                        <h5 className='mt-5'>
                          Email: info@polymart.in <br />  
                          Phone: 6357111121 <br />
                        </h5>
                      </div>
                    </Table>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
        <ToastContainer className='p-3 position-fixed' position={'top-center'}>
          <Toast
            show={sessionMessage.show}
            bg={sessionMessage.type}
          >
            <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      </Col>
      {viewPurchase && (viewPurchase?.eway_bill_file || viewPurchase?.invoice_file) && (
        <Col className='card card-body backgroung-transparent   p-12  mt-10   p-12'>
          <div className='menu menu-column ' data-kt-menu='true'>
            <div
              className='d-flex flex-column bgi-no-repeat rounded-top'
              style={{ backgroundImage: `url('{toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
            >
              <ul className='nav nav-line-tabs border-0 nav-stretch fw-bold  px-0'></ul>
            </div>

            <Row className='tab-content'>
              <Col md={4} className="px-3">
                <h2>E Way Bill </h2>
                {viewPurchase?.eway_bill_file ?
                  <a href={`${process.env.REACT_APP_API_URL_NEW}/downloads/download?folder_name=invoice&image=${viewPurchase?.eway_bill_file}`}
                    download
                    target="_blank"
                  ><i className='fa-solid fa-file-lines text-primary fs-4'></i>
                    <span className='px-3'>{viewPurchase?.eway_bill_file}</span>

                  </a> :
                  <span>No File Found</span>}
              </Col>
              <Col md={4} className="px-3">
                <h2>Invoice File </h2>
                {viewPurchase?.invoice_file ?
                  <a href={`${process.env.REACT_APP_API_URL_NEW}/downloads/download?folder_name=invoice&image=${viewPurchase?.invoice_file}`}
                    download
                    target="_blank"
                  ><i className='fa-solid fa-file-lines text-primary fs-4'></i>
                    <span className='px-3'>
                      {viewPurchase?.invoice_file}
                    </span>
                  </a> :
                  <span>No File Found</span>}
              </Col>
            </Row>
          </div>
        </Col>)}
    </>
  )
}

export default ViewPurchaseOrder
