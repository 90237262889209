import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import { SERVER_URL } from '../../../../../ServerConfig'
// import { ID, Response } from '../../../../../_metronic/helpers'
import {State, StatesQueryResponse} from './_models'

const API_URL = SERVER_URL
const STATE_URL = `${API_URL}/state`
const GET_STATES_URL = `${API_URL}/state`

const getState = (query: string): Promise<StatesQueryResponse> => {
  return axios
    .get(`${GET_STATES_URL}?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
      },
    })
    .then((d: AxiosResponse<StatesQueryResponse>) => d.data)
}

const getStateById = (id: ID): Promise<State | undefined> => {
  return axios
    .get(`${STATE_URL}/${id}`)
    .then((response: AxiosResponse<Response<State>>) => response.data)
    .then((response: Response<State>) => response.data)
}

const createState = (state: State): Promise<State | undefined> => {
  return axios
    .put(STATE_URL, state)
    .then((response: AxiosResponse<Response<State>>) => response.data)
    .then((response: Response<State>) => response.data)
}

const updateState = (state: State): Promise<State | undefined> => {
  return axios
    .post(`${STATE_URL}/${state.id}`, state)
    .then((response: AxiosResponse<Response<State>>) => response.data)
    .then((response: Response<State>) => response.data)
}

const deleteState = (stateId: ID): Promise<void> => {
  return axios.delete(`${STATE_URL}/${stateId}`).then(() => {})
}

const deleteSelectedStates = (stateIds: Array<ID>): Promise<void> => {
  const requests = stateIds.map((id) => axios.delete(`${STATE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const updateStateApi = (id:any, data: any): Promise<any> => {
  return axios
    .patch(`${STATE_URL}/${id}`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

export {getState, deleteState, deleteSelectedStates, getStateById, createState, updateState, updateStateApi}
