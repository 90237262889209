import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import { checkDisplayValue } from '../../../utils/utils'
import { getStaticsReportApi } from '../_requests'

type Props = {
  setMessage: (type:string,message:string) => void
}

const Statistics = ({setMessage}:Props) => {
  const [statisticsData,setStatisticsData] = useState({
    "activeOffers": "",
    "openInquiries": "",
    "activeSellers": "",
    "activeBuyer": "",
    "pendingInvoiceCount": ""
  })
  const [loading,setLoading] = useState(true)

  const callStaticsReport = async () => {
    try{
      setLoading(true)
      let {responseStatus, responseMessage, responseData} = await getStaticsReportApi()
      if(responseStatus == 200){
        setStatisticsData({
          "activeOffers": responseData?.activeOffers ?? "",
          "openInquiries": responseData?.openInquiries ?? "",
          "activeSellers": responseData?.activeSellers ?? "",
          "activeBuyer": responseData?.activeBuyer ?? "",
          "pendingInvoiceCount": responseData?.pendingInvoiceCount ?? ""
        })
        setLoading(false)
      }
      else{
        setMessage("danger",responseMessage)
        setLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setMessage("danger",message)
      setLoading(false)
    }
  } 

  useEffect(()=>{
    callStaticsReport()
  },[])

  return (
    <div>
      <div className='row g-5 g-xl-8 mt-sm-4 mt-md-2 mt-xl-0'>
        <div className='statistics-status'>
          <a href='#' className='card bg-white hoverable card-xl-stretch mb-xl-8'>
            <div className='card-body'>
              <span className='svg-icon svg-icon-primary svg-icon-3x ms-n1'>
                <KTSVG
                  className='svg-icon svg-icon-primary svg-icon-3x ms-n1'
                  path='/media/icons/duotune/general/gen032.svg'
                />
              </span>
              <div className='text-undefined fw-bold fs-2 mb-2 mt-5'>
              {loading ? <Spinner animation="border" variant="primary" /> : checkDisplayValue(statisticsData?.activeOffers)}
              </div>
              <div className='fw-semibold text-undefined'>Active Offers</div>
            </div>
          </a>
        </div>
        <div className='statistics-status'>
          <a href='#' className='card bg-primary hoverable card-xl-stretch mb-xl-8'>
            <div className='card-body'>
              <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                <KTSVG
                  className='svg-icon svg-icon-white svg-icon-3x ms-n1'
                  path='/media/icons/duotune/ecommerce/ecm001.svg'
                />
              </span>
              <div className='text-white fw-bold fs-2 mb-2 mt-5'>
              {loading ? <Spinner animation="border" variant="primary" /> : checkDisplayValue(statisticsData?.activeBuyer)}
              </div>
              <div className='text-white fw-semibold text-undefined'>Active Buyers</div>
            </div>
          </a>
        </div>
        <div className='statistics-status'>
          <a href='#' className='card bg-success hoverable card-xl-stretch mb-5 mb-xl-8'>
            <div className='card-body'>
              <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                <KTSVG
                  className='svg-icon svg-icon-white svg-icon-3x ms-n1'
                  path='/media/icons/duotune/ecommerce/ecm004.svg'
                />
              </span>
              <div className='text-white text-undefined fw-bold fs-2 mb-2 mt-5'>
                {loading ? <Spinner animation="border" variant="primary" /> : checkDisplayValue(statisticsData?.activeSellers)}
              </div>
              <div className='text-white fw-semibold text-undefined'>Active Sellers</div>
            </div>
          </a>
        </div>
        <div className='statistics-status'>
          <a href='#' className='card bg-warning hoverable card-xl-stretch mb-xl-8'>
            <div className='card-body'>
              <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                <KTSVG
                  className='svg-icon svg-icon-white svg-icon-3x ms-n1'
                  path='/media/icons/duotune/ecommerce/ecm008.svg'
                />
              </span>
              <div className='text-white text-undefined fw-bold fs-2 mb-2 mt-5'>
                {loading ? <Spinner animation="border" variant="primary" /> : checkDisplayValue(statisticsData?.openInquiries)}
              </div>
              <div className='text-white fw-semibold text-undefined'>Open Inquiries</div>
            </div>
          </a>
        </div>
        <div className='statistics-status'>
          <a href='#' className='card bg-info hoverable card-xl-stretch mb-5 mb-xl-8'>
            <div className='card-body'>
              <span className='svg-icon svg-icon-white svg-icon-3x ms-n1'>
                <KTSVG
                  className='svg-icon svg-icon-white svg-icon-3x ms-n1'
                  path='/media/icons/duotune/graphs/gra007.svg'
                />
              </span>
              <div className='text-white text-undefined fw-bold fs-2 mb-2 mt-5'>
                {loading ? <Spinner animation="border" variant="primary" /> : checkDisplayValue(statisticsData?.pendingInvoiceCount)}
              </div>
              <div className='text-white fw-semibold text-undefined'> Pending Invoice Amount</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Statistics
