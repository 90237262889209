const QUERIES = {
  USERS_LIST: 'users-list',
  BUYERS_LIST: 'buyers-list',
  OFFERS_LIST: 'offers-list',
  INQUIRIES_LIST: 'inquiries-list',
  QUOTATIONS_LIST: 'quotations-list',
  SELLERS_LIST: 'sellers-list',
  PRODUCTS_LIST: 'products-list',
  TRANSPORTS_LIST: 'transports-list',
  PURCHASES_LIST: 'purchases-list',
  GRADES_LIST: 'grades-list',
  PRODUCTGROUPS_LIST: 'productgroups-list',
  FREIGHTS_LIST: 'freights-list',
  STATES_LIST: 'states-list',
  DISTRICTS_LIST: 'districts-list',
  BUYERSELLERS_LIST: 'buyersellers-list',
  ORDERS_LIST: 'orders-list',
  INVOICES_LIST: 'invoices-list',
  SELLEROFFERS_LIST: 'selleroffers-list',
  GRADEGROUPS_LIST: 'gradegroups-list',
  EXPIREOFFERS_LIST: 'expireoffers-list',
  ACTIVEINQUIRIES_LIST: 'activeinquiries-list',
  COMPLETEINQUIRIES_LIST: 'completeinquiries-list',
  USERINQUIRIES_LIST: 'userinquiries-list',
  ARTICLES_LIST: 'articles-list',
  CATES_LIST: 'cates-list'
}

export {QUERIES}