import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import * as Yup from 'yup'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import { createGrade, updateGrade } from "./grades-list/core/_requests";
import { AddGradegroup } from "./grades-list/core/_models";
import { useListView } from './grades-list/core/ListViewProvider'
import { useQueryResponse } from "./grades-list/core/QueryResponseProvider";

type Props = {
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

export interface GradegroupValues {
  name?: string
}

export interface FormValues {
  addGradegroupValues: GradegroupValues[]
}

const initialValues: FormValues = {
  addGradegroupValues: [
    {
      name: ''
    },
  ],
}

const AddEditGradeGroup = ({ handleClose }: Props) => {
  const { refetch } = useQueryResponse()
  const [isFormSuccess, setFormSuccess] = useState<boolean>(false)
  const [isFormError, setFormError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const { isLoadingAddGradegroup, handleEnableLoaderAddGradegroup, handleDisableLoaderAddGradegroup,
    isEditGradegroup, setSelectedItemForEditGrade, selectedItemForEditGrade } =
    useListView()

  useEffect(() => {
    const { name } =
      selectedItemForEditGrade || {}

    if (isEditGradegroup) {
      initialValues.addGradegroupValues[0].name = name
    } else {
      initialValues.addGradegroupValues[0].name = ''
    }
  }, [selectedItemForEditGrade, isEditGradegroup])


  const addGradegroupSchema = Yup.object().shape({
    addGradegroupValues: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .min(1, 'Please enter name')
          .max(50, 'Name should not be more than 50 characters.')
          .required('Please enter name'),
      })
    ),
  })

  const AddGradegroupApiCall = async (values: FormValues) => {
    try {
      handleEnableLoaderAddGradegroup()
      const { addGradegroupValues } = values || {}
      const addGradegroupObj: AddGradegroup[] = addGradegroupValues.map((obj: any) => {
        const ob = {
          name: obj.name,
        }
        return ob
      })
      const addGradegroupResponse = await createGrade(addGradegroupObj)
      handleDisableLoaderAddGradegroup()
      const { responseStatus, responseMessage } = addGradegroupResponse || {}

      if (responseStatus === 200) {
        setFormSuccess(true)
        setFormError(false)
        setTimeout(() => {
          refetch()
          handleClose()
        }, 3000)
      } else {
        setErrorMessage(responseMessage ?? "Something Went Wrong")
        setFormSuccess(false)
        setFormError(true)
      }
    } catch (error:any) {
      setErrorMessage(error?.message ?? "Something Went Wrong")
      console.log(error)
      setFormSuccess(false)
      setFormError(true)
    }
  }

  const EditGradegroupApiCall = async (values: FormValues) => {
    try {
      handleEnableLoaderAddGradegroup()
      const { addGradegroupValues } = values || {}
      const { id: id } = selectedItemForEditGrade || {}
      const EditGradegroup: AddGradegroup[] = addGradegroupValues.map((obj: any) => {
        const ob = {
          name: obj.name,
          id: obj.id
        }
        return ob
      })
      const [addGradegroupObj] = EditGradegroup || []
      const editGradegroupResponse = await updateGrade('' + id, addGradegroupObj)
      handleDisableLoaderAddGradegroup()
      const { responseStatus, responseMessage } = editGradegroupResponse || {}
      if (responseStatus === 200) {
        setFormSuccess(true)
        setFormError(false)
        setTimeout(() => {
          refetch()
          handleClose()
        }, 3000)
      } else {
        setErrorMessage(responseMessage ?? "Something Went Wrong")
        setFormSuccess(false)
        setFormError(true)
      }
    } catch (error:any) {
      setErrorMessage(error?.message ?? "Something Went Wrong")
      console.log(error)
      setFormSuccess(false)
      setFormError(true)
    }
  }



  return (
    <>
      {isFormSuccess && (
        <div className='mb-lg-15 alert alert-primary'>
          <div className='alert-text font-weight-bold'>
            <h5 className='text-primary'>Information</h5>
            {isEditGradegroup ? 'Grade Edited Successfully' : 'Grade Created Successfully'}
          </div>
        </div>
      )}

      {isFormError && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            <h5 className='text-danger'>Error</h5>
            {errorMessage}
          </div>
        </div>
      )}

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (isEditGradegroup) {
            EditGradegroupApiCall(values)
          } else {
            AddGradegroupApiCall(values)
          }

        }}
        onReset={() => {
          handleClose()
        }}
        validationSchema={addGradegroupSchema}
      >
        {({ values, handleSubmit, handleReset, setFieldValue }) => (
          <Form onSubmit={handleSubmit} onReset={handleReset}>
            <FieldArray name='addGradegroupValues'>
              {({ insert, remove, push }) => (
                <div>
                  {values.addGradegroupValues.length > 0 &&
                    values.addGradegroupValues.map((addGradegroupValues, index) => (
                      <Row key={index} className='mb-7 align-items-center prdct-dtl-grd'>
                        <Col>
                          <Form.Label className=' required fw-bold fs-6 mb-2 form-label'>
                            Grade Group Name
                          </Form.Label>
                          <Form.Control
                            id={`name${index}`}
                            name={`addGradegroupValues.${index}.name`}
                            type='text'
                            className='form-control form-control-solid mb-lg-0'
                            placeholder='Grade group name'
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue(`addGradegroupValues.${index}.name`, event.target.value.trimStart())
                            }}
                            value={addGradegroupValues?.name}
                          />
                          <ErrorMessage name={`addGradegroupValues.${index}.name`}>
                            {(msg) => (
                              <div className='mb-3 fv-plugins-message-container'>
                                <div className='fv-help-block'>{msg}</div>
                              </div>
                            )
                            }
                          </ErrorMessage>
                        </Col>
                        <Col>
                          {!isEditGradegroup && (
                            <div className='d-flex'>
                              <button
                                type='button'
                                onClick={() =>
                                  push({
                                    name: '',
                                  })
                                }
                                className='btn btn-sm btn-icon mt-8 btn-color-success'
                                disabled={isFormSuccess}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen035.svg'
                                  className='svg-icon-muted svg-icon-2hx me-2'
                                />
                              </button>
                              <button
                                type='button'
                                onClick={() => remove(index)}
                                className='btn btn-sm btn-icon mt-8 btn-color-danger'
                                style={{ visibility: index === 0 ? 'hidden' : 'visible' }}
                                disabled={isFormSuccess}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen036.svg'
                                  className='svg-icon-muted svg-icon-2hx'
                                />
                              </button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    ))}
                </div>
              )}
            </FieldArray>
            <Row className='mb-7'>
              <div className='text-end pt-10'>
                <Button
                  type='submit'
                  data-kt-users-modal-action='submit'
                  className='btn btn-primary btn btn-primary fs-5 me-5'
                  disabled={isLoadingAddGradegroup || isFormSuccess}
                >
                  {!isLoadingAddGradegroup && (isEditGradegroup ? 'Edit' : 'Add')}
                  {isLoadingAddGradegroup && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
                <Button
                  type='reset'
                  data-kt-users-modal-action='cancel'
                  className='btn btn-light-primary fs-5'
                  disabled={isLoadingAddGradegroup}
                >
                  Cancel
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </>

  )
}





export default AddEditGradeGroup

