import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Offer, OfferNew, OffersQueryResponse} from './_models'

const API_URL_NEW = process.env.REACT_APP_API_URL_NEW
const API_URL = process.env.REACT_APP_THEME_API_URL
const OFFER_URL = `${API_URL}/offer`
const ALL_FIRMS_URL = `${API_URL_NEW}/buyer-seller/allfirms`
const ALL_PRODUCT_URL = `${API_URL_NEW}/product`
const OFFER_URL_NEW = `${API_URL_NEW}/offer`
const GET_GROUP_URL = `${API_URL_NEW}/product/group/list/all?perPage=100&page=1`
const token = localStorage.getItem('authtoken')

const getOffer = (query: string): Promise<OffersQueryResponse> => {
  return axios
    .get(`${OFFER_URL_NEW}?${query}`)
    .then((d: AxiosResponse<OffersQueryResponse>) => d.data)
}

const getOfferByIdNew = (id: string): Promise<any | undefined> => {
  return axios
    .get(`${OFFER_URL_NEW}/${id}`)
    .then((response: AxiosResponse<Response<Offer>>) => response.data)
    .then((response: Response<Offer>) => response)
}

const deleteOfferNew = (offerId: string): Promise<void> => {
  return axios.delete(`${OFFER_URL_NEW}/${offerId}`).then(() => {})
}

const getOfferById = (id: ID): Promise<Offer | undefined> => {
  return axios
    .get(`${OFFER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Offer>>) => response.data)
    .then((response: Response<Offer>) => response.data)
}

const createOffer = (offer: Offer): Promise<Offer | undefined> => {
  return axios
    .put(OFFER_URL, offer)
    .then((response: AxiosResponse<Response<Offer>>) => response.data)
    .then((response: Response<Offer>) => response.data)
}

const updateOffer = (offer: Offer): Promise<Offer | undefined> => {
  return axios
    .post(`${OFFER_URL}/${offer.id}`, offer)
    .then((response: AxiosResponse<Response<Offer>>) => response.data)
    .then((response: Response<Offer>) => response.data)
}

const deleteOffer = (offerId: ID): Promise<void> => {
  return axios.delete(`${OFFER_URL_NEW}/${offerId}`).then(() => {})
}

const deleteSelectedOffers = (offerIds: Array<ID>): Promise<void> => {
  const requests = offerIds.map((id) => axios.delete(`${OFFER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getBuyerSeller = (): Promise<OffersQueryResponse> => {
  return axios.get(`${ALL_FIRMS_URL}`).then((d: AxiosResponse<OffersQueryResponse>) => d.data)
}

const getGroupList = (): Promise<OffersQueryResponse> => {
  return axios.get(`${GET_GROUP_URL}`).then((d: AxiosResponse<OffersQueryResponse>) => d.data)
}

const getSellerList = (): Promise<OffersQueryResponse> => {
  let state = `?type=2`
  return axios
    .get(`${ALL_FIRMS_URL}${state}`)
    .then((d: AxiosResponse<OffersQueryResponse>) => d.data)
}

const getBuyerSellerList = (search: string): Promise<OffersQueryResponse> => {
  let state = `?search=${search}&perPage=2&page=1&type=2`
  return axios
    .get(`${ALL_FIRMS_URL}${state}`)
    .then((d: AxiosResponse<OffersQueryResponse>) => d.data)
}

const getListProduct = (): Promise<OffersQueryResponse> => {
  return axios.get(`${ALL_PRODUCT_URL}`).then((d: AxiosResponse<OffersQueryResponse>) => d.data)
}

const createOfferNew = (offer: OfferNew): Promise<any | undefined> => {
  return axios
    .post(OFFER_URL_NEW, offer)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const editOfferNew = (id: string, offer: OfferNew): Promise<any | undefined> => {
  return axios
    .patch(`${OFFER_URL_NEW}/${id}`, offer)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response)
}

const getOnlySellerList = (): Promise<OffersQueryResponse> => {
  return axios.get(`${ALL_FIRMS_URL}?type=2`).then((d: AxiosResponse<OffersQueryResponse>) => d.data)
}

export {
  getGroupList,
  getOffer,
  deleteOfferNew,
  deleteOffer,
  deleteSelectedOffers,
  getOfferById,
  createOffer,
  updateOffer,
  getBuyerSeller,
  getListProduct,
  createOfferNew,
  getOfferByIdNew,
  editOfferNew,
  getBuyerSellerList,
  getSellerList,
  getOnlySellerList
}
