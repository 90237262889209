import React, {useState} from 'react'
import {Button, Col, Form, OverlayTrigger, Row, Table, Tooltip} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'
import Select from 'react-select'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useLayout } from '../../../../_metronic/layout/core'
// import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
// import {useLayout} from '../../../_metronic/layout/core'
import AddArticleForm from './AddArticle'
import DeleteArticleModal from './DeleteArticle'
import EditArticleForm from './EditArticle'
import EditArticleModal from './EditArticle'

const categoryList = [
  {value: 'Select', label: 'Select Category'},
  {value: 'category1', label: 'Category Name 01'},
  {value: 'category2', label: 'Category Name 02'},
  {value: 'category3', label: 'Category Name 03'},
  {value: 'category4', label: 'Category Name 04'},
  {value: 'category5', label: 'Category Name 05'},
  {value: 'category6', label: 'Category Name 06'},
]

const NewsArticles = () => {
  const {config} = useLayout()
  const [ShowEditArticleModal, setShowEditArticleModal] = useState<boolean>(false)
  const [ShowDeleteArticleModal, setShowDeleteArticleModal] = useState<boolean>(false)

  return (
    <>
      <div>
        <Row className='gx-5'>
          <Col xl={12}>
            <AddArticleForm />
          </Col>

          <Col xl={12}>
            <h3 className='px-6 py-11' >News Articles Listing</h3>
            <Table bordered className='article-tbl'>
              <thead>
                <tr className=' bg-light text-muted text-uppercase'>
                  <th> ID </th>
                  <th> Title </th>
                  <th> Image </th>
                  <th>CREATED DATE & TIME</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>#001</td>
                  <td>Article #1</td>
                  <td>
                    {' '}
                    <img src={toAbsoluteUrl('/media/books/1.png')} />
                  </td>
                  <td> 25/04/21, 15:36pm </td>
                  <td className='d-flex border-0 border-0'>
                    <div className='menu-item px-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip className='py-2 px-2 fs-5 '>Edit</Tooltip>}
                      >
                        <a className='menu-link px-1'>
                          <i className='fa-solid fa-user-pen fs-4 text-warning' title='Edit'></i>
                        </a>
                      </OverlayTrigger>
                    </div>

                    <div className='menu-item px-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip className='py-2 px-2 fs-5 '>Delete</Tooltip>}
                      >
                        <a
                          className='menu-link px-1'
                          onClick={() => setShowDeleteArticleModal(true)}
                        >
                          <i className='fa-solid fa-trash-can fs-4 text-danger'></i>
                        </a>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>#002</td>
                  <td>Article #2</td>
                  <td>
                    {' '}
                    <img src={toAbsoluteUrl('/media/books/2.png')} />
                  </td>
                  <td> 25/04/21, 15:36pm </td>
                  <td className='d-flex border-0 border-0'>
                    <div className='menu-item px-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip className='py-2 px-2 fs-5 '>Edit</Tooltip>}
                      >
                        <a className='menu-link px-1' >
                          <i className='fa-solid fa-user-pen fs-4 text-warning' title='Edit'></i>
                        </a>
                      </OverlayTrigger>
                    </div>

                    <div className='menu-item px-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip className='py-2 px-2 fs-5 '>Delete</Tooltip>}
                      >
                        <a
                          className='menu-link px-1'
                          onClick={() => setShowDeleteArticleModal(true)}
                        >
                          <i className='fa-solid fa-trash-can fs-4 text-danger'></i>
                        </a>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>#003</td>
                  <td>Article #3</td>
                  <td>
                    {' '}
                    <img src={toAbsoluteUrl('/media/books/3.png')} />
                  </td>
                  <td> 25/04/21, 15:36pm </td>
                  <td className='d-flex border-0 border-0'>
                    <div className='menu-item px-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip className='py-2 px-2 fs-5 '>Edit</Tooltip>}
                      >
                        <a className='menu-link px-1'>
                          <i className='fa-solid fa-user-pen fs-4 text-warning' title='Edit'></i>
                        </a>
                      </OverlayTrigger>
                    </div>

                    <div className='menu-item px-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip className='py-2 px-2 fs-5 '>Delete</Tooltip>}
                      >
                        <a
                          className='menu-link px-1'
                          onClick={() => setShowDeleteArticleModal(true)}
                        >
                          <i className='fa-solid fa-trash-can fs-4 text-danger'></i>
                        </a>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>#004</td>
                  <td>Article #4</td>
                  <td>
                    {' '}
                    <img src={toAbsoluteUrl('/media/books/4.png')} />
                  </td>
                  <td> 25/04/21, 15:36pm </td>
                  <td className='d-flex border-0 border-0'>
                    <div className='menu-item px-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip className='py-2 px-2 fs-5 '>Edit</Tooltip>}
                      >
                        <a className='menu-link px-1'>
                          <i className='fa-solid fa-user-pen fs-4 text-warning' title='Edit'></i>
                        </a>
                      </OverlayTrigger>
                    </div>

                    <div className='menu-item px-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip className='py-2 px-2 fs-5 '>Delete</Tooltip>}
                      >
                        <a
                          className='menu-link px-1'
                          onClick={() => setShowDeleteArticleModal(true)}
                        >
                          <i className='fa-solid fa-trash-can fs-4 text-danger'></i>
                        </a>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>#005</td>
                  <td>Article #5</td>
                  <td>
                    {' '}
                    <img src={toAbsoluteUrl('/media/books/5.png')} />
                  </td>
                  <td> 25/04/21, 15:36pm </td>
                  <td className='d-flex border-0 border-0'>
                    <div className='menu-item px-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip className='py-2 px-2 fs-5 '>Edit</Tooltip>}
                      >
                        <a className='menu-link px-1'>
                          <i className='fa-solid fa-user-pen fs-4 text-warning' title='Edit'></i>
                        </a>
                      </OverlayTrigger>
                    </div>

                    <div className='menu-item px-1'>
                      <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip className='py-2 px-2 fs-5 '>Delete</Tooltip>}
                      >
                        <a
                          className='menu-link px-1'
                          onClick={() => setShowDeleteArticleModal(true)}
                        >
                          <i className='fa-solid fa-trash-can fs-4 text-danger'></i>
                        </a>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
            
            <DeleteArticleModal
              id={""}
              show={ShowDeleteArticleModal}
              handleClose={() => setShowDeleteArticleModal(false)}
            />

          </Col>
        </Row>
      </div>
    </>
  )
}

export default NewsArticles
