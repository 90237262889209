import { Col, Row, Toast, ToastContainer } from "react-bootstrap"
import { useEffect, useState } from "react"
import { disable2faApi } from "../core/_requests";
import { useNavigate } from "react-router";

const Disable2faPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [sessionMessage, setSessionMessage] = useState({
        type: '',
        message: '',
        show: false,
    })

    const disable2FA = async () => {
        try {
            setLoading(true)
            let userData = localStorage.getItem("objUser") as any
            userData = JSON.parse(userData)
            await disable2faApi({ user_id: userData?.id ?? "" }).then((data: any) => {
                if (data?.responseStatus == 200) {
                    userData.is_2fa_enable = false
                    localStorage.setItem("objUser", JSON.stringify(userData))
                    setLoading(false)
                    setSessionMessage({ type: 'success', message: data?.responseMessage ?? "Response Close With 200 Status Code", show: true })
                    setTimeout(() => [
                        navigate('/offer-management/offers')
                    ], 2000)
                } else {
                    setLoading(false)
                    setSessionMessage({ type: 'danger', message: data?.responseMessage ?? "SomeThing Went Wrong", show: true })
                }
            }).catch((error: any) => {
                setLoading(false)
                setSessionMessage({ type: 'danger', message: error?.responseMessage ?? "SomeThing Went Wrong", show: true })
            })
        }
        catch (error: any) {
            setSessionMessage({ type: 'danger', message: error?.message ?? "SomeThing Went Wrong", show: true })
            setLoading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        if (sessionMessage.show) {
            setTimeout(() => {
                setSessionMessage({ type: '', message: '', show: false })
            }, 4500)
        }
    }, [sessionMessage.message])

    useEffect(() => {
        try {
            let userData = localStorage.getItem("objUser") as any
            userData = JSON.parse(userData)
            if (userData.role_id !== 1 || userData.is_2fa_enable != true || userData.is_2fa_enable != 1) {
                window.location.replace('/offer-management/offers')
            }
        }
        catch (err) {
            console.error(err)
        }
    }, [])

    return <>
        <div className='card card-body backgroung-transparent'>
            <Col xl={6} className='ms-auto me-auto'>

                <Row className='mb-10'>
                    <Col xl={12} className='fw-semibold fs-6 text-muted'>
                        <div className='modal-body pt-4'>
                            {/* <div className='dlt-mdl-icon text-center mb-10'> */}
                            {/* <i className='fa-solid fa-circle-exclamation text-danger'></i> */}
                            {/* <i class="fa-regular fa-circle-exclamation"></i> */}
                            {/* </div> */}
                            <h3 className='text-danger text-center'>Disable 2FA?</h3>
                            <p className='text-center mb-5 fs-4'> Are you Sure, you wan't to disable 2FA ?</p>

                            <Col className='me-5  mt-10 mb-10 text-center '>
                                <button
                                    type='button'
                                    value=''
                                    className='btn btn-sm px-12 py-3  me-5 btn btn-danger fs-4'
                                    onClick={() => {
                                        disable2FA()
                                    }}
                                    disabled={loading}
                                >
                                    {' '}
                                    {loading ? "Please Wait..." : "Disable 2FA"}
                                </button>
                                <button
                                    type='button'
                                    value=''
                                    className='btn btn-sm px-12 py-3 me-5 btn btn-light fs-4'
                                    onClick={() => {
                                        navigate('/offer-management/offers')
                                    }}
                                >
                                    {' '}
                                    No{' '}
                                </button>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Col>
        </div>
        <ToastContainer className='p-3' position={'top-end'}>
            <Toast
                show={sessionMessage.show}
                onClose={() => {
                    setSessionMessage({
                        type: '',
                        message: '',
                        show: false,
                    })
                }}
                bg={sessionMessage.type}
            >
                {/* <Toast.Header>Error</Toast.Header> */}
                <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
            </Toast>
        </ToastContainer>
    </>
}
export default Disable2faPage