import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Bar} from 'react-chartjs-2'
import CardHeader from 'react-bootstrap/esm/CardHeader'
import { getLocationWiseInquiriesApi } from '../_requests'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type Props = {
  setMessage: (type:string,message:string) => void
}

export const option = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Location Wise Inquiry and Offers  ',
    },
  },
}

// const labels = ['AHM', 'RJT', 'GWL', 'DEL', 'BAN', 'CHE', 'SUR']

export const data = {
  labels:[],
  datasets: [
    {
      label: 'Inquiry',
      data: [],
      // data: labels.map(() => Math.random()),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Offers',
      data: [],
      // data: labels.map(() => Math.random()),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
}

export function LocationInterestAndOffers({setMessage}:Props) {

  const [locationInquiryData,setLocationInquiryData] = useState<any>(data)
  const [loading,setLoading] = useState(true)

  const callDashboardReport = async () => {
    try{
      setLoading(true)
      let {responseStatus, responseMessage, responseData} = await getLocationWiseInquiriesApi()
      if(responseStatus == 200){
        locationInquiryData.labels = responseData.listLocation.map((x:any)=>x.name)
        locationInquiryData.datasets[0].data = responseData.listOffersArr.map((x:any)=>x.totalQuantity)
        locationInquiryData.datasets[1].data = responseData.listInquiriesArr.map((x:any)=>x.inquiryInfo[0].totalQuantity)
        setLocationInquiryData(locationInquiryData)
        setTimeout(()=>{
          setLoading(false)
        },300)
      }
      else{
        setMessage("danger",responseMessage)
        setLoading(false)
      }
    }
    catch(err:any){
      let message = err?.message ? err.message : err?.responseMessage ? err.responseMessage : "Something went wrong" as any
      setMessage("danger",message)
      setLoading(false)
    }
  } 

  useEffect(()=>{
    callDashboardReport()
  },[])

  return (
    <div className='row g-5 g-xl-8' onClick={(e:any)=>{
      e.preventDefault()
    }}>
      <div className='col-xl-12'>
        <a href='#' className='card location-grade hoverable card-xl-stretch mb-xl-8'>
          <CardHeader className='pt-10 pb-0 border-0'>
            <h2> Location Wise Inquiry and Offers </h2>
          </CardHeader>
          <div className='card-body'>
            {loading ? 
              <h4 className='d-flex justify-content-center mt-3'>Loading...</h4> : locationInquiryData.labels.length == 0 ?
              <h4 className='d-flex justify-content-center mt-3'>No data available</h4>
            :
              <Bar data={locationInquiryData} style={{height: '500px'}} options={{maintainAspectRatio: false}} />
            }
          </div>
        </a>
      </div>
    </div>
  )
}
