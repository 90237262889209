import Multiselect from 'multiselect-react-dropdown'
import {Button, Col, Form, Row} from 'react-bootstrap'
import Select from 'react-select'
import AddEditDistrict from './AddEditDistrict'
import { QueryResponseProvider } from './districts-list/core/QueryResponseProvider'
import { DistrictsListWrapper } from './districts-list/DistrictsList'
import { QueryResponseProvider as StateResponseProvider } from './states-list/core/QueryResponseProvider'

const stateList = [
  {value: 'state1', label: 'State Name 01'},
  {value: 'state2', label: 'State Name 02'},
  {value: 'state3', label: 'State Name 03'},
  {value: 'state4', label: 'State Name 04'},
  {value: 'state5', label: 'State Name 05'},
  {value: 'state6', label: 'State Name 06'},
  {value: 'state7', label: 'State Name 07'},
  {value: 'state8', label: 'State Name 08'},
  {value: 'state9', label: 'State Name 09'},
  {value: 'state10', label: 'State Name 10'},
  {value: 'state11', label: 'State Name 11'},
  {value: 'state12', label: 'State Name 12'},
]

const District = () => {
  return (
    <div>
      <QueryResponseProvider>
        <Row className='gx-5'>
          <Col md={4} sm={12}>
            <AddEditDistrict isEditing={false} id={""} handleClose={()=>{}}/>
          </Col>
          <Col md={8} sm={12}>
            <Form noValidate className='border p-3' id='kt_modal_add_user_form'>
              <Col className='card-label font-weight-bolder text-dark font-size-h5 mb-6 '>
              <DistrictsListWrapper />
              </Col>
            </Form>
          </Col>
        </Row>
      </QueryResponseProvider>
    </div>
  )
}

export default District
