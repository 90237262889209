import {FC} from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import userRoles from '../utils/userRoles'

const MarketingRoute: FC = () => {
  const MarketingRole = userRoles()
  return <>{
    (MarketingRole === 1 || MarketingRole === 2 || MarketingRole === 3)  ? <Outlet/> : 
    <Navigate to='/offer-management/offers' />
  }</>
}

export {MarketingRoute}
