import React, {useState} from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {useLayout} from '../../../_metronic/layout/core'
import AddInvoiceManagementModal from './invoice-management-modal/AddInvoiceManagementModal'

const InvoiceManagement = () => {
  const {config} = useLayout()
  const [showAddInvoiceModal, setAddShowInvoiceModal] = useState<boolean>(false)
  return (
    <div className='card card-body'>
      <div className='d-flex align-items-center justify-content-between'>
        <h2>Invoice List</h2>
        <div>
          {config.app?.toolbar?.primaryButton && (
            <Link
              to='#'
              onClick={() => setAddShowInvoiceModal(true)}
              className='btn fw-bold btn-primary me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_user'
            >
              Add Invoice
            </Link>
          )}

          <AddInvoiceManagementModal
            show={showAddInvoiceModal}
            handleClose={() => setAddShowInvoiceModal(false)}
          />
        </div>
      </div>
      <Form noValidate id='kt_modal_add_user_form'>
        <Row className='mb-7 mt-8 align-items-center'>
          <Col md={6}>
            <Form.Label className=' fs-6 mb-2 form-label'>Quotation Number</Form.Label>
            <div className='fw-bold fs-6 mb-2 form-label'>2021001</div>
          </Col>
          <Col md={6} className='text-end mt-8'>
            <Button type='button' className='btn btn-light-success my-1'>
              <span className='svg-icon svg-icon-2'>
                <KTSVG path='/media/icons/duotune/files/fil003.svg' className='svg-icon-muted' />
              </span>
              Download
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default InvoiceManagement
