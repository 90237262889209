import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {KTCard} from '../../../../_metronic/helpers'
import { StatesListHeader } from './components/header/StatesListHeader'
import { StatesTable } from './table/StatesTable'
import { StateEditModal } from './state-edit-modal/StateEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
// import { SufalamsTable } from './table/SufalamsTable'
// import { SufalamEditModal } from './sufalam-edit-modal/SufalamEditModal'

const StatesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <StatesListHeader />
        <StatesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <StateEditModal />}
    </>
  )
}

const StatesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StatesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {StatesListWrapper}
