import React, {useEffect, useRef, useState} from 'react'
import {Col, Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { useQueryResponse } from './articles-list/core/QueryResponseProvider'
import { deleteArticleApi } from './articles-list/core/_requests'
// import {StepperComponent} from '../../../_metronic/assets/ts/components'
// import {KTSVG} from '../../../_metronic/helpers'

type Props = {
  show: boolean
  handleClose: () => void
  id:any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const DeleteArticleModal = ({show, handleClose, id}: Props) => {
  const {refetch} = useQueryResponse()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const [isDeleteConfirm, setDeleteConfirm] = useState(false);
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const deleteApi = async () => {
    setDisableButton(true)
    try{
      let data = await deleteArticleApi(id)
      if(data.responseStatus == 200){
        refetch()
        setDeleteConfirm(true)
        setTimeout(() => {
          setDisableButton(false)
          setDeleteConfirm(false)
          handleClose();
        }, 2000);
      }else{
        setMessage(data.responseMessage)
        setDisableButton(false)
      }
    }
    catch(err){
      setMessage("Something Went Wrong")
      setDisableButton(false)
    }
  }

  const enableDeleteLoading = () => {
    setDeleteConfirm(true);
  };

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 500);
  }, []);

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-body pt-10'>
        {!isDeleteConfirm && (
        <>
        <div className='dlt-mdl-icon text-center mb-10'>
          <i className='fa-solid fa-trash-can text-danger'></i>
        </div>
        <h3 className='text-danger text-center'>Delete Article?</h3>
        <p className='text-center mb-5 fs-4'> Are you Sure, you wan't delete Article ? </p>

        <Col className='me-5  mt-10 mb-10 text-center '>
          <button
            type='button'
            value=''
            className='btn btn-sm px-12 py-3 me-5 btn btn-danger fs-4'
            disabled={disableButton}
            onClick={() => {
              deleteApi()
            }}
          >
            {' '}
            Yes, Delete
          </button>
          <button type='button' value='' className='btn btn-sm px-12 py-3 me-5 btn btn-light fs-4'
            onClick={handleClose}
          >
            {' '}
            No{' '}
          </button>
          <span className='text-danger'>{message}</span>
        </Col>
        </>
        )}
        {isDeleteConfirm && (
        <div className='deleteconfirmmessage'>
          Article is removed from the system
        </div>
        )}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default DeleteArticleModal
