import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useLayout } from '../../../_metronic/layout/core'
import AddGradeGroupManagementModal from './grade-group-management-modal/AddGradeGroupManagementModal'
import ViewGradeGroupManagementModal from './grade-group-management-modal/EditGradeGroupManagementModal'


const GradeGroupManagement = () => {
  const {config} = useLayout()
  const [showGradeModal, setShowGradeModal] = useState<boolean>(false)
  const [showViewGradeModal, setViewShowGradeModal] = useState<boolean>(false)

  return (
      <div className='card card-body'>
        <div className='d-flex align-items-center justify-content-between'>
          <h2>Grade Group Management</h2>
          <div>
            <Link to='#' className='btn btn-light-primary me-3'>
              <span className='svg-icon svg-icon-2'>
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-muted' />
              </span>
              Filter
            </Link>
            <Link to='#' className='btn btn-light-primary me-3'>
              <span className='svg-icon svg-icon-2'>
                <KTSVG path='/media/icons/duotune/files/fil018.svg' className='svg-icon-muted' />
              </span>
              Export
            </Link>
            {config.app?.toolbar?.primaryButton && (
              <Link
                to='#'
                onClick={() => setShowGradeModal(true)}
                className='btn fw-bold btn-primary me-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_user'
              >
                Add Grade Group
              </Link>
            )}
            {config.app?.toolbar?.primaryButton && (
              <Link
                to='#'
                onClick={() => setViewShowGradeModal(true)}
                className='btn fw-bold btn-primary me-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_user'
              >
                View Grade Group
              </Link>
            )}
            <ViewGradeGroupManagementModal
              show={showViewGradeModal}
              handleClose={() => setViewShowGradeModal(false)}
            />
            <AddGradeGroupManagementModal
              show={showGradeModal}
              handleClose={() => setShowGradeModal(false)}
            />
          </div>
        </div>
      </div>
  )
}

export default GradeGroupManagement ;
