import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Buyer, BuyersQueryResponse} from './_models'
import {InquirysQueryResponse} from '../../../inquiry-management/Inquiry-list/core/_models'

const API_URL_NEW = process.env.REACT_APP_API_URL_NEW
const API_URL = process.env.REACT_APP_THEME_API_URL
const BUYER_URL = `${API_URL}/buyer`
const GET_BUYERS_URL = `${API_URL}/users/query`
const GET_BUYERS_URL_NEW = `${API_URL_NEW}/buyer-seller`
const GET_GRADE_WISE_PRODUCT_URL_NEW = `${API_URL_NEW}/product/allProductWithGroup`
const GET_ALL_GRADE_GROUP_URL = `${API_URL_NEW}/grade-group/allGradeGroups`

const PRODUCT_BY_ID_URL = `${API_URL_NEW}/buyer-seller`
const DELETE_BUYER = `${API_URL_NEW}/buyer-seller`

const GET_ALL_FIRMS_URL= `${API_URL_NEW}/buyer-seller/allfirms`
const BUYER_SELLER_EDIT_HISTORY = `${API_URL_NEW}/buyer-seller-edit-history/?buyer_seller_id=`
const CHANGE_BUYER_EDIT_HSITORY_STATUS= `${API_URL_NEW}/buyer-seller-edit-history/changeBuyerSellerEditHistoryStatus`
const DOWNLOAD_MEDIA_BUYER= `${API_URL_NEW}/downloads/download?folder_name=buyerSeller&image=`

const IMPORT_BUYER = `${API_URL_NEW}/buyer-seller/importBuyerSeller`
const ALL_PRODUCT_GROUP_URL = `${API_URL_NEW}/product/group/list/all?perPage=100000&page=1`

const GET_ALL_STATE = `${API_URL_NEW}/state/allStates`
const ALL_LOCATION_URL = `${API_URL_NEW}/location`

const deleteBuyer = (ID: string): Promise<any> => {
  return axios.delete(`${DELETE_BUYER}/${ID}`).then((response: AxiosResponse<any>) => response.data)
}

const getSingleProductByID = (query: string): Promise<InquirysQueryResponse> => {
  return axios
    .get(`${PRODUCT_BY_ID_URL}/${query}`)
    .then((response: AxiosResponse<any>) => response.data)
}

const getBuyerNew = (query: string): Promise<BuyersQueryResponse> => {
  const setQuery = `${query}&type=1`
  return axios
    .get(`${GET_BUYERS_URL_NEW}?${setQuery}`)
    .then((d: AxiosResponse<BuyersQueryResponse>) => d.data)
}

const getBuyerSellerList = (): Promise<BuyersQueryResponse> => {
  return axios
    .get(`${GET_BUYERS_URL_NEW}?page=1&perPage=5000&type=1`)
    .then((d: AxiosResponse<BuyersQueryResponse>) => d.data)
}

const getAllFirms = (): Promise<BuyersQueryResponse> => {
  return axios.get(`${GET_ALL_FIRMS_URL}`).then((d: AxiosResponse<BuyersQueryResponse>) => d.data)
}

const getGradeGroupWiseProducts = (query: string): Promise<any> => {
  return axios
    .get(`${GET_GRADE_WISE_PRODUCT_URL_NEW}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getAllGradeGroup = (): Promise<any> => {
  return axios.get(`${GET_ALL_GRADE_GROUP_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getBuyer = (query: string): Promise<BuyersQueryResponse> => {
  return axios
    .get(`${GET_BUYERS_URL}?${query}`)
    .then((d: AxiosResponse<BuyersQueryResponse>) => d.data)
}

const getBuyerById = (id: ID): Promise<Buyer | undefined> => {
  return axios
    .get(`${BUYER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Buyer>>) => response.data)
    .then((response: Response<Buyer>) => response.data)
}

const createBuyer = (buyer: Buyer): Promise<Buyer | undefined> => {
  return axios
    .put(BUYER_URL, buyer)
    .then((response: AxiosResponse<Response<Buyer>>) => response.data)
    .then((response: Response<Buyer>) => response.data)
}

const importBuyerSeller = (buyer: any): Promise<any | undefined> => {
  return (
    axios
      .post(IMPORT_BUYER, buyer)
      .then((response: AxiosResponse<Response<any>>) => response.data)
      // .then((response: Response<any>) => response.data)
  )
}

const createBuyerNew = (buyer: any): Promise<any | undefined> => {
  return (
    axios
      .post(GET_BUYERS_URL_NEW, buyer)
      .then((response: AxiosResponse<Response<any>>) => response.data)
      // .then((response: Response<any>) => response.data)
  )
}

const updateBuyer = (buyer: Buyer): Promise<Buyer | undefined> => {
  return axios
    .post(`${BUYER_URL}/${buyer.id}`, buyer)
    .then((response: AxiosResponse<Response<Buyer>>) => response.data)
    .then((response: Response<Buyer>) => response.data)
}
const updateBuyerNew = (id: string, buyer: any): Promise<Buyer | undefined> => {
  return axios
    .patch(`${GET_BUYERS_URL_NEW}/${id}`, {...buyer,type:1})
    // .then((response: AxiosResponse<Response<Buyer>>) => response.data)
    .then((response: AxiosResponse<Buyer>) => response.data)
}

// const deleteBuyer = (buyerId: ID): Promise<void> => {
//   return axios.delete(`${BUYER_URL}/${buyerId}`).then(() => {})
// }

const deleteSelectedBuyers = (buyerIds: Array<ID>): Promise<void> => {
  const requests = buyerIds.map((id) => axios.delete(`${BUYER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getBuyerSellerEditHistoryList = (id:any): Promise<any> => { 
  return axios
    .get(`${BUYER_SELLER_EDIT_HISTORY}${id}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const changeBuyerEditHistory = (id:any,payload:any): Promise<any> => { 
  return axios
    .patch(`${CHANGE_BUYER_EDIT_HSITORY_STATUS}/${id}`,payload)
    .then((d: AxiosResponse<any>) => d.data)
}

const downloadBuyerEditHistoryFile = (payload:any) => { 
  return `${DOWNLOAD_MEDIA_BUYER}${payload}`
}

const getProductGroup = (): Promise<any> => {
  return axios.get(`${ALL_PRODUCT_GROUP_URL}`).then((d: AxiosResponse<any>) => d.data)
}

const getAllStateList = (): Promise<any> => {
  return axios.get(`${GET_ALL_STATE}`).then((d: AxiosResponse<any>) => d.data)
}

const getDistrictLocationASC = (): Promise<any> => {
  let state = `?perPage=100000&page=1&sort=name&order=ASC`
  return axios.get(`${ALL_LOCATION_URL}${state}`).then((d: AxiosResponse<any>) => d.data)
}

export {
  getBuyerNew,
  getBuyer,
  deleteBuyer,
  deleteSelectedBuyers,
  getBuyerById,
  createBuyer,
  updateBuyer,
  getAllGradeGroup,
  getGradeGroupWiseProducts,
  createBuyerNew,
  getSingleProductByID,
  updateBuyerNew,
  getAllFirms,
  getBuyerSellerList,
  getBuyerSellerEditHistoryList,
  changeBuyerEditHistory,
  downloadBuyerEditHistoryFile,
  importBuyerSeller,
  getProductGroup,
  getAllStateList,
  getDistrictLocationASC
}
