import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import { object, string, ref } from "yup";
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {forgotPasswordApi, requestPassword, resetPasswordApi} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { Toast, ToastContainer } from 'react-bootstrap'

const initialValues = {
  token: '',
  password: '',
  confirm_password: '',
}

const getCharacterValidationError = (str: string) => {
  return `Your password must have at least 1 ${str} character`;
};

const resetPasswordSchema = object({
  password: string()
    .required("Please enter a password")
    .min(8, "Password must have at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[!@#$&*]/, "Password must contain at least one special character")
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase")),  
  confirm_password: string()
    .required("Please re-type your password")
    .oneOf([ref("password")], "Passwords does not match"),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {token} = useParams()
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  useEffect(()=>{
    if(sessionMessage.show){
      setTimeout(()=>{
        setSessionMessage({type: '', message: '', show: false})
      },4500)
    }
  },[sessionMessage.message])

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await resetPasswordApi(values).then((data:any)=>{
          if (data?.responseStatus == 200) {
            setLoading(false)
            setSessionMessage({type: 'success', message: data?.responseMessage ?? "Response Close With 200 Status Code", show: true})
            setTimeout(()=>{
              navigate('/auth')
            },3000)
          }else{
            setSessionMessage({type: 'danger', message: data?.responseMessage ?? "SomeThing Went Wrong", show: true})
            setLoading(false)
          }
        }).catch((err:any)=>{
          if(err?.response?.status == 429){
            setSessionMessage({type: 'danger', message: err?.response?.statusText ?? "SomeThing Went Wrong", show: true})
          }
          else{
            setSessionMessage({type: 'danger', message: err?.message ?? "SomeThing Went Wrong", show: true})
          }
          setSubmitting(false)
          setLoading(false)
        })
        
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
  }})

  useEffect(()=>{
    formik.setFieldValue('token',token)
  },[token])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Reset Password ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        {/* <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </div> */}
        {/* end::Link */}
      </div>

      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
        <input
          type='password'
          placeholder='Enter Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Enter Confirm Password'
          autoComplete='off'
          {...formik.getFieldProps('confirm_password')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password},
            {
              'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
            }
          )}
        />
        {formik.touched.confirm_password && formik.errors.confirm_password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirm_password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' disabled={loading}>
          {loading ? "Please Wait..." : "Submit"}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            onClick={()=>{
              navigate('/auth')
            }}
            disabled={loading}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      <ToastContainer className='p-3' position={'top-end'}>
        <Toast
          show={sessionMessage.show}
          onClose={() => {
            setSessionMessage({
              type: '',
              message: '',
              show: false,
            })
          }}
          bg={sessionMessage.type}
        >
          {/* <Toast.Header>Error</Toast.Header> */}
          <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
        </Toast>
      </ToastContainer>
      {/* end::Form group */}
    </form>
  )
}
