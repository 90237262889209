import React from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import Select from 'react-select'
import { KTSVG } from "../../../_metronic/helpers";
import Multiselect from "multiselect-react-dropdown";
import AddEditPurchaseOrder from "./AddEditPurchase";


const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Purchase Order',
      path: 'purchase Order/purchase Order',
      isSeparator: false,
      isActive: false,
      
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]


  const sellersList = [
    {value: 'seller1', label: 'Seller Name 01'},
    {value: 'seller2', label: 'Seller Name 02'},
    {value: 'seller3', label: 'Seller Name 03'},
    {value: 'seller4', label: 'Seller Name 04'},
    {value: 'seller5', label: 'Seller Name 05'},
    {value: 'seller6', label: 'Seller Name 06'},
    {value: 'seller7', label: 'Seller Name 07'},
    {value: 'seller8', label: 'Seller Name 08'},
    {value: 'seller9', label: 'Seller Name 09'},
    {value: 'seller10', label: 'Seller Name 10'},
    {value: 'seller11', label: 'Seller Name 11'},
    {value: 'seller12', label: 'Seller Name 12'},
  ]
  
  const productList = [
    {value: 'product1', label: 'Product Name 01'},
    {value: 'product2', label: 'Product Name 02'},
    {value: 'product3', label: 'Product Name 03'},
    {value: 'product4', label: 'Product Name 04'},
    {value: 'product5', label: 'Product Name 05'},
    {value: 'product6', label: 'Product Name 06'},
    {value: 'product7', label: 'Product Name 07'},
    {value: 'product8', label: 'Product Name 08'},
    {value: 'product9', label: 'Product Name 09'},
    {value: 'product10', label: 'Product Name 10'},
    {value: 'product11', label: 'Product Name 11'},
    {value: 'product12', label: 'Product Name 12'},
  ]
  
  const hsnList = [
    {value: 'hsn1', label: 'HSN 01'},
    {value: 'hsn2', label: 'HSN 02'},
    {value: 'hsn3', label: 'HSN 03'},
    {value: 'hsn4', label: 'HSN 04'},
    {value: 'hsn5', label: 'HSN 05'},
    {value: 'hsn6', label: 'HSN 06'},
    {value: 'hsn7', label: 'HSN 07'},
    {value: 'hsn8', label: 'HSN 08'},
    {value: 'hsn9', label: 'HSN 09'},
    {value: 'hsn10', label: 'HSN 10'},
    {value: 'hsn11', label: 'HSN 11'},
    {value: 'hsn12', label: 'HSN 12'},
  ]
  
const EditPurchaseOrder = () => {
    const navigate = useNavigate()
    const navigatePOListing = () => {
      // 👇️ navigate to /
      navigate('/purchase-order/purchases')
    }

    return (

        <>
 <PageTitle> Edit Purchase Order </PageTitle>
      <Col className='position-absolute me-5 end-0 tp-btn-mn  '>
        <button
          type='button'
          onClick={navigatePOListing}
          value=''
          className='btn btn-sm fs-5 me-5 btn btn-primary'
        >
          Back
        </button>
      </Col>
      <div className='card card-body p-12 mt-10'>
        <AddEditPurchaseOrder />
      </div>
        
        </>
    )
}

export default EditPurchaseOrder
